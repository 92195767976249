import React from "react";
import { Chip } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Check } from "./icons/check.svg";
import { ReactComponent as Blocked } from "./icons/blocked.svg";
import { ReactComponent as Cancel } from "./icons/cancel.svg";
import { palette } from "../../theme";

const useStyles = makeStyles(theme => ({
  chip: props => ({
    padding: theme.spacing(0.5, 1),
    borderRadius: "5px",
    marginBottom: "5px",
    height: "auto",
    ...props.colorScheme
  }),
  chipIcon: {
    margin: 0,
    marginRight: 4
  },
  label: {
    textTransform: "capitalize"
  },
  iconBusy: {
    "& > path": {
      fill: theme.palette.text.blue,
      stroke: theme.palette.text.blue
    }
  }
}));

function CallStatusBadge({ type }) {
  const classes = useStyles({ colorScheme: getColors(type) });

  function getColors(type) {
    let color;
    let backgroundColor;
    switch (type) {
      case "Answered":
        color = palette.background.green;
        backgroundColor = palette.background.lightGreen;
        break;
      case "No answer":
        color = palette.text.yellow;
        backgroundColor = palette.background.lightYellow;
        break;
      case "Error":
      case "Canceled":
        color = palette.background.red;
        backgroundColor = palette.background.lightRed;
        break;
      case "Busy":
        color = palette.text.blue;
        backgroundColor = palette.background.lightBlue;
        break;
      default:
        return {};
    }
    return {
      color,
      backgroundColor
    };
  }

  function renderIcon(type) {
    switch (type) {
      case "Answered":
        return <Check />;
      case "No answer":
        return <Blocked />;
      case "Error":
      case "Canceled":
        return <Cancel />;
      case "Busy":
        return <Blocked className={classes.iconBusy} />;
      default:
        return null;
    }
  }

  return (
    <Chip
      classes={{
        root: classes.chip,
        icon: classes.chipIcon,
        label: classes.label
      }}
      icon={renderIcon(type)}
      label={type}
    />
  );
}

export default CallStatusBadge;
