import { Box } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import useAppBarHeight from "../../../../utils/useAppBarHeight";
import BasePageDrawerHandle from "./BasePageDrawerHandle";
import { DRAWER_EXPANDED_WIDTH } from "./BasePageDesktopLayout";
import { LogoStripesSmallSvg, LogoSvg } from "../../../../assets";
import BasePageDrawerMenu from "./BasePageDrawerMenu/BasePageDrawerMenu";

const useStyles = makeStyles(theme => ({
  drawer: {
    maxWidth: props => props.width,
    borderRight: `1px solid ${theme.palette.menuLine}`,
    transition: "max-Width 100ms linear",
    width: DRAWER_EXPANDED_WIDTH,
    display: "flex",
    flexDirection: "column"
  },
  logoBox: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    borderBottom: `1px solid ${theme.palette.menuLine}`,
    position: "relative"
  },
  logo: {
    height: 38,
    width: 160,
    marginLeft: theme.spacing(3.75)
  },
  logoSmall: {
    height: 36,
    width: 56,
    margin: theme.spacing(3.5, 2.75)
  }
}));

const BasePageDrawer = ({ expanded, width, setDrawerExpanded }) => {
  const classes = useStyles({ width });
  const history = useHistory();
  const appBarHeight = useAppBarHeight();

  function handleClickLogo() {
    history.push({ pathname: "/" });
  }

  return (
    <Box
      width={width}
      variant={"permanent"}
      height={"100vh"}
      className={classes.drawer}
    >
      <Box flexShrink={0} height={appBarHeight} className={classes.logoBox}>
        {expanded ? (
          <LogoSvg className={classes.logo} onClick={handleClickLogo} />
        ) : (
          <LogoStripesSmallSvg
            className={classes.logoSmall}
            onClick={handleClickLogo}
          />
        )}

        <Box
          position={"absolute"}
          zIndex={1}
          top={"calc(50% - 20px)"}
          right={-16}
        >
          <BasePageDrawerHandle
            expanded={expanded}
            onClick={() => setDrawerExpanded(expanded => !expanded)}
          />
        </Box>
      </Box>

      <Box
        display={"flex"}
        flexDirection={"column"}
        flex={1}
        ml={expanded ? 2 : 3.75}
        pt={3}
        pb={3}
        pr={2}
        width={expanded ? "calc(100% - 16px)" : "calc(100% - 30px)"}
        style={{ overflowY: "auto" }}
      >
        <Box
          display={"flex"}
          flexDirection={"column"}
          maxWidth={expanded ? 153 : 40}
          height={"100%"}
        >
          <BasePageDrawerMenu expanded={expanded} />
        </Box>
      </Box>
    </Box>
  );
};

export default BasePageDrawer;
