import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography
} from "@material-ui/core";
import LanguagesBadge, {
  LanguagesBadgeIcons
} from "../../../components/LanguagesBadge/LanguagesBadge";
import StyledTableRow from "../StyledTableRow";
import StyledTableCell from "../StyledTableCell";
import CallStatusBadge from "../CallStatusBadge";
import EmptyPage from "../../EmptyPage/EmptyPage";
import StyledTableHeadCell from "../StyledTableHeadCell";
import { getFormattedDate, getFormattedDuration } from "../../../utils/time";
import { makeStyles } from "@material-ui/core/styles";
import { figmaFonts } from "../../../theme";
import { useInView } from "react-intersection-observer";

const useStyles = makeStyles(theme => ({
  tabPanel: {
    height: "100%",
    width: "100%",
    maxWidth: 898
  },
  table: {
    width: "100%",
    maxWidth: 898
  },
  smallDate: {
    ...figmaFonts.mobileSmallTextRegular,
    color: theme.palette.text.grey,
    marginTop: theme.spacing(0.75),
    whiteSpace: "nowrap"
  },
  pricePerMinute: {
    ...figmaFonts.mobileSmallTextRegular,
    color: theme.palette.text.grey,
    whiteSpace: "nowrap"
  }
}));

const PhoneCallHistoryTab = ({ data, showDesktopLayout }) => {
  const classes = useStyles();
  const { ref, inView } = useInView();
  const [showedRows, setShowedRows] = useState(40);

  useEffect(() => {
    if (inView && data && showedRows < data.length) {
      setShowedRows(prev => prev + 20);
    }
    // eslint-disable-next-line
  }, [inView]);

  function renderRow(row, index) {
    const languagesBadge = (
      <Box mt={showDesktopLayout ? 0 : 0.75}>
        <LanguagesBadge
          firstCountry={row.fromLang.Country}
          secondCountry={row.toLang.Country}
          icon={LanguagesBadgeIcons.arrowsMid}
          iconSize={showDesktopLayout ? 14 : 10}
          iconMargin={0.5}
          size={showDesktopLayout ? 24 : 16}
          theme={"dark"}
        />
      </Box>
    );

    let price = parseFloat(row.price) || "";
    let callCost = (parseFloat(row.price) / 60) * row.duration || "";
    if (callCost) {
      callCost = (
        "$" + (parseFloat(row.price) / 60) * row.duration || ""
      ).substring(0, 6);
      const charsToAdd = callCost.length - 6 > 0 ? callCost.length - 6 : 0;
      callCost = callCost + "0".repeat(charsToAdd);
    }

    return (
      <StyledTableRow key={row.startAt + index.toString()}>
        {showDesktopLayout && (
          <StyledTableCell>{getFormattedDate(row.startAt)}</StyledTableCell>
        )}

        <StyledTableCell>
          {!showDesktopLayout && (
            <Box mb={0.375}>
              <CallStatusBadge type={row.status} />
            </Box>
          )}
          {row.number}
          {!showDesktopLayout && (
            <Typography className={classes.smallDate}>
              {getFormattedDate(row.startAt)}
            </Typography>
          )}
        </StyledTableCell>

        {showDesktopLayout && (
          <StyledTableCell align="right">{languagesBadge}</StyledTableCell>
        )}

        {!showDesktopLayout && (
          <StyledTableCell>
            <Box mb={3.875} />
            {getFormattedDuration(row.duration)}
            {!showDesktopLayout && languagesBadge}
          </StyledTableCell>
        )}

        <StyledTableCell align="left">
          {!showDesktopLayout && <Box mb={3.875} />}
          {callCost || "$0.000"}
          <Typography className={classes.pricePerMinute}>
            (${price || "0.123"}/min)
          </Typography>
        </StyledTableCell>

        {showDesktopLayout && (
          <StyledTableCell>
            {getFormattedDuration(row.duration)}
            {!showDesktopLayout && languagesBadge}
          </StyledTableCell>
        )}

        {showDesktopLayout && (
          <StyledTableCell style={{ verticalAlign: "top" }}>
            <CallStatusBadge type={row.status} />
          </StyledTableCell>
        )}
      </StyledTableRow>
    );
  }

  let dataToRender = [...data].slice(0, showedRows);

  if (dataToRender.length === 0) {
    return <EmptyPage />;
  }

  return (
    <Box position={"relative"}>
      <TableContainer component={Box}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <StyledTableRow>
              {showDesktopLayout && (
                <StyledTableHeadCell
                  style={{ width: showDesktopLayout ? 215 : "initial" }}
                >
                  Date/time
                </StyledTableHeadCell>
              )}
              <StyledTableHeadCell
                style={{
                  width: showDesktopLayout ? 215 : 128,
                  display: "block"
                }}
              >
                Phone number
              </StyledTableHeadCell>

              {showDesktopLayout && (
                <StyledTableHeadCell>Language</StyledTableHeadCell>
              )}

              {showDesktopLayout && (
                <StyledTableHeadCell
                  style={{ width: showDesktopLayout ? "initial" : 87 }}
                >
                  Call Cost
                </StyledTableHeadCell>
              )}

              <StyledTableHeadCell
                style={{ width: showDesktopLayout ? "initial" : 87 }}
              >
                Spent min.
              </StyledTableHeadCell>

              <StyledTableHeadCell>
                {showDesktopLayout ? "Status" : "Price"}
              </StyledTableHeadCell>
            </StyledTableRow>
          </TableHead>

          <TableBody>
            {dataToRender.map((row, index) => {
              return renderRow(row, index);
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ position: "absolute", bottom: 0, height: 400 }} ref={ref} />
    </Box>
  );
};

export default PhoneCallHistoryTab;
