import React, { Fragment, useEffect, useState } from "react";
import { ReactComponent as HomeIcon } from "./icons/homeIcon.svg";
import { ReactComponent as PhoneIcon } from "./icons/phoneIcon.svg";
import { ReactComponent as CallsIcon } from "./icons/callsIcon.svg";
import { ReactComponent as MeetingsIcon } from "./icons/meetingsIcon.svg";
import { ReactComponent as MessagesIcon } from "./icons/messagesIcon.svg";
import { useHistory } from "react-router-dom";
import useBreakpoints from "../../utils/useBreakpoints";
import MobileBottomTabBar from "./MobileBottomTabBar";
import TabletBottomTabBar from "./TabletBottomTabBar";
import SmallTabletBottomBar from "./SmallTabletBottomBar";
import useStripePortalUrl from "../../utils/useStripePortalUrl";
import withRequest from "../../utils/withRequest";
import { chatsStore } from "../../utils/stores/ChatsStore";
import { observer } from "mobx-react";

function BottomNavBar(props) {
  const history = useHistory();
  const { isMobile, isSmallTablet, isTablet, isDesktop } = useBreakpoints();
  const [value, setValue] = useState();
  const portalUrl = useStripePortalUrl({
    postDataWithCallback: props.postDataWithCallback
  });

  useEffect(() => {
    setValue(history.location.pathname);
  }, [history.location.pathname]);

  function handleCallClick() {
    history.push("/createlocalcall");
  }

  function navigationOnChange(event, value) {
    if (value === portalUrl) {
      window.open(value, "_self", "noopener noreferrer");
    } else {
      history.push(value);
    }
  }

  const navigationItems = [
    {
      path: "/",
      label: "Home",
      icon: <HomeIcon />
    },
    {
      path: "/history",
      label: "History",
      icon: <PhoneIcon />
    },
    {
      path: "/createlocalcall",
      label: "Meetings",
      icon: <MeetingsIcon />
    },
    {
      path: "/phonecallcreate",
      label: "Calls",
      icon: <CallsIcon style={{ fill: "none !important" }} />
    },
    {
      path: "/messages",
      label: "Messages",
      icon: <MessagesIcon />,
      counter: chatsStore.totalUnreadMessages
    }
  ];

  // /billing/paysuccess => /subscription
  const matchedValue = "/" + (value ? value.split("/")[1] : "");

  return (
    <Fragment>
      {isMobile && (
        <MobileBottomTabBar
          navigationItems={navigationItems}
          value={matchedValue}
          handleCallClick={handleCallClick}
          navigationOnChange={navigationOnChange}
        />
      )}
      {isSmallTablet && (
        <SmallTabletBottomBar
          navigationItems={navigationItems}
          value={matchedValue}
          navigationOnChange={navigationOnChange}
          handleCallClick={handleCallClick}
        />
      )}
      {(isTablet || isDesktop) && (
        <TabletBottomTabBar
          navigationItems={navigationItems}
          value={value}
          navigationOnChange={navigationOnChange}
        />
      )}
    </Fragment>
  );
}

export default withRequest(observer(BottomNavBar));
