import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import BasePage from "../BasePage/BasePage";
import { useHistory, useParams } from "react-router-dom";
import withRequest from "../../utils/withRequest";
import { BaseContext } from "../../utils/baseProvider";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button } from "@material-ui/core";
import WaitForConnectionTitle from "../LocalCallPage/WaitForConnectionTitle";
import withBottomBar from "../../components/BottomNavBar/withBottomBar";
import useBreakpoints from "../../utils/useBreakpoints";
import useLanguages from "../../utils/hooks/useLanguages";
import LanguageSelect from "../CreatelocalcallPage/components/LanguageSelect";
import RadioSelectGroup from "../../components/RadioSelectGroup/RadioSelectGroup";
import useAppBarLeftContent from "../../utils/hooks/useAppBarLeftContent";
import JoinLocalCallPageCallData from "./components/JoinLocalCallPageCallData";
import { appStore } from "../../utils/stores/AppStore";
import { observer } from "mobx-react";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    maxWidth: "325px",
    display: "flex",
    flexDirection: "column",
    boxSizing: "content-box",
    [theme.breakpoints.up("lg")]: {
      maxWidth: "384px"
    }
  },
  linkButton: {
    padding: 0,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      marginBottom: theme.spacing(0)
    }
  }
}));

function JoinLocalCallPageAuthorized({ postDataWithCallback }) {
  const classes = useStyles();
  const baseContext = useContext(BaseContext);
  const history = useHistory();
  const [callerData, setCallerData] = useState();
  const { remoteId } = useParams();
  const { isDesktop } = useBreakpoints();
  const [callSettings, setCallSettings] = useState({
    from: null,
    to: "",
    toGender: null,
    fromGender: null,
    name: null,
    gender: null
  });
  const { languagesTo } = useLanguages();
  const appBarTitleComponent = useMemo(() => <WaitForConnectionTitle />, []);
  useAppBarLeftContent({ memoizedComponent: appBarTitleComponent });

  useEffect(() => {
    baseContext.setShowWaitingForConnection(true);
    baseContext.setSign("in");

    return () => {
      baseContext.setShowWaitingForConnection(false);
    };
    // eslint-disable-next-line
  }, []);

  const getLangTo = useCallback(
    (callerLangTo, callerLangFrom, userDefaultLang = null) => {
      if (userDefaultLang) {
        return userDefaultLang === callerLangFrom
          ? callerLangTo
          : userDefaultLang;
      }

      return callerLangTo || "";
    },
    []
  );

  const loadCaller = useCallback(() => {
    if (remoteId) {
      postDataWithCallback(
        "/api/sfuinfo",
        { id: remoteId },
        data => {
          setCallerData(data);
          const callerLangTo = (data && data.to && data.to.Key) || "";
          const callerLangFrom = (data && data.from && data.from.Key) || null;
          setCallSettings(prevState => ({
            ...prevState,
            to: getLangTo(
              callerLangTo,
              callerLangFrom,
              baseContext.currentUser && baseContext.currentUser.fromLang
            ),
            from: (data && data.from && data.from.Key) || null,
            toGender: data.toGender || null,
            fromGender: data.fromGender || null
          }));
        },
        () => {
          history.push({ pathname: "/invalidUrl" });
        },
        false
      );
    }
  }, [
    baseContext.currentUser,
    getLangTo,
    history,
    postDataWithCallback,
    remoteId
  ]);

  useEffect(() => {
    if (appStore.authorized !== undefined && !callerData) {
      loadCaller();
    }
    // eslint-disable-next-line
  }, [appStore.authorized, callerData]);

  const handleClickJoin = useCallback(() => {
    const state = {
      ...callSettings,
      remoteId
    };

    history.push({ pathname: "/localcall", state: state });
  }, [callSettings, history, remoteId]);

  const onFieldChange = useCallback((key, value) => {
    setCallSettings(prevState => ({
      ...prevState,
      [key]: value
    }));
  }, []);

  const topAvatarMargin = isDesktop ? 5 : 3;

  return (
    <BasePage>
      {!callerData ? null : (
        <Box
          pl={isDesktop ? 8 : 3.125}
          pr={isDesktop ? 0 : 3.125}
          mx={isDesktop ? 0 : "auto"}
          pb={3}
          mt={topAvatarMargin}
          className={classes.root}
        >
          {!isDesktop && (
            <>
              <Box mb={3} mt={isDesktop ? 5 : 2.125}>
                <WaitForConnectionTitle forCallee />
              </Box>
            </>
          )}
          <JoinLocalCallPageCallData
            callerData={callerData}
            callSettings={callSettings}
          />

          <Box mt={isDesktop ? 5 : 0} width={"100%"}>
            <LanguageSelect
              onChange={event => onFieldChange("to", event.target.value)}
              value={callSettings.to}
              languages={languagesTo}
              label="Your native language"
            />

            <RadioSelectGroup
              onChange={event => onFieldChange("toGender", event.target.value)}
              value={callSettings.toGender}
              label={"Your voice"}
              items={["Male", "Female"]}
            />

            <Button
              variant="contained"
              color="primary"
              size="large"
              fullWidth
              onClick={handleClickJoin}
            >
              JOIN
            </Button>
          </Box>
        </Box>
      )}
    </BasePage>
  );
}

export default withBottomBar(
  withRequest(observer(JoinLocalCallPageAuthorized)),
  false
);
