import React, { useContext } from "react";
import { ReactComponent as BalanceIcon } from "./icons/balance.svg";
import { makeStyles } from "@material-ui/core/styles";
import { BaseContext } from "../../../utils/baseProvider";
import { figmaFonts } from "../../../theme";
import { Box, SvgIcon, Typography } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    backgroundColor: theme.palette.background.input,
    color: theme.palette.text.grey,
    padding: theme.spacing(0.5, 1),
    borderRadius: 5
  },
  rootLight: {
    backgroundColor: theme.palette.background.default
  },
  rootDark: {
    backgroundColor: "rgba(40, 40, 40, 0.3)",
    backdropFilter: "blur(50px)",
    color: theme.palette.background.default
  },
  icon: {
    width: 14,
    height: 14,
    marginRight: theme.spacing(0.5)
  },
  text: {
    ...figmaFonts.mobileSmallTextMedium
  }
}));

function Balance({ showIcon = true, mode }) {
  const classes = useStyles();
  const baseContext = useContext(BaseContext);

  function getBalanceFormatted(balance) {
    return balance < 3600
      ? new Date(balance * 1000).toISOString().substr(14, 5)
      : "60:00";
  }

  return baseContext.secondsBalance !== undefined ? (
    <Box
      className={clsx(classes.root, {
        [classes.rootLight]: mode === "light",
        [classes.rootDark]: mode === "dark"
      })}
    >
      {showIcon && (
        <SvgIcon className={classes.icon} viewBox={"0 0 14 14"}>
          <BalanceIcon />
        </SvgIcon>
      )}

      <Box width={40}>
        <Typography
          className={classes.text}
          color={mode === "dark" ? "inherit" : "primary"}
          style={{
            opacity: mode === "dark" ? 0.7 : 1
          }}
        >
          {getBalanceFormatted(baseContext.secondsBalance)}
        </Typography>
      </Box>
      <Typography
        style={{
          opacity: mode === "dark" ? 0.7 : 1
        }}
        className={classes.text}
        color={"inherit"}
      >
        &nbsp;min left
      </Typography>
    </Box>
  ) : null;
}

export default Balance;
