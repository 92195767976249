import React, { useCallback, useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { Box, Typography } from "@material-ui/core";
import SearchInput from "./components/SearchInput";
import { peopleSearchStore } from "../../utils/stores/PeopleSearchStore";
import UserList from "./components/UserList";
import { makeStyles } from "@material-ui/core/styles";
import ChatPage from "./components/ChatPage/ChatPage";
import { chatsStore } from "../../utils/stores/ChatsStore";
import ChatsList from "./components/ChatsList";
import { Resizable } from "re-resizable";
import { palette } from "../../theme";
import img from "./icons/img.png";
import useAppBarLeftContent from "../../utils/hooks/useAppBarLeftContent";
import { useHistory } from "react-router-dom";
import querystring from "query-string";
import useRequest from "../../utils/hooks/useRequest";
import useBreakpoints from "../../utils/useBreakpoints";

const useStyles = makeStyles(theme => ({
  container: {
    position: "absolute",
    inset: 0,
    display: "flex",
    overflow: "hidden"
  },
  chat: {
    background: theme.palette.background.input,
    borderLeft: `1px solid ${theme.palette.background.input}`,
    width: "100%",
    height: "100%",
    display: "flex"
  }
}));

const MessagesPageDesktop = () => {
  const classes = useStyles();
  const [width, setWidth] = useState();
  const chatRef = useRef();
  useAppBarLeftContent({ title: "Messages" });
  const history = useHistory();
  const params = querystring.parse(history.location.search);
  const { postDataWithCallback } = useRequest();
  const { isDesktop } = useBreakpoints();

  useEffect(() => {
    if (params.toId) {
      void loadUser(params.toId);
    }
    setWidth(chatRef.current.clientWidth);
    // eslint-disable-next-line
  }, []);

  const loadUser = id => {
    const chat = chatsStore.chats.find(chat => chat.remoteId === id);
    if (chat) {
      chatsStore.openChat(chat);
    } else {
      postDataWithCallback("/api/userinfo", { id: parseInt(id) }, data => {
        if (data && data !== "err") {
          onUserSelect(data);
          history.replace({
            search: ""
          });
        }
      });
    }
  };

  const onResize = () => {
    setWidth(chatRef.current.clientWidth);
  };

  const onChatSelect = useCallback(chat => {
    chatsStore.openChat(chat);
  }, []);

  const onUserSelect = useCallback(
    user => {
      peopleSearchStore.setSearchValue("");
      onChatSelect(chatsStore.findExistingOrCreateNew(user));
    },
    [onChatSelect]
  );

  return (
    <Box height={"100%"} flex={1} position={"relative"}>
      <Box className={classes.container}>
        <Resizable
          defaultSize={{
            width: 400
          }}
          maxWidth={"60%"}
          minWidth={"30%"}
          onResize={onResize}
        >
          <Box
            px={isDesktop ? 0 : 3.75}
            height={"100%"}
            display={"flex"}
            flexDirection={"column"}
          >
            <Box px={isDesktop ? 3.75 : 0} py={2.25}>
              <SearchInput />
            </Box>
            {!!peopleSearchStore.searchValue ? (
              <UserList
                onUserSelect={onUserSelect}
                users={peopleSearchStore.users}
              />
            ) : (
              <ChatsList
                selectedChat={chatsStore.openedChat}
                onChatSelect={onChatSelect}
                chats={chatsStore.chats}
                loaded={chatsStore.chatsLoaded}
              />
            )}
          </Box>
        </Resizable>
        <Box ref={chatRef} className={classes.chat}>
          {chatsStore.openedChat ? (
            <ChatPage width={width} onChatSelect={onChatSelect} />
          ) : (
            <Box m={"auto"} textAlign={"center"} color={palette.text.grey}>
              <img
                style={{ maxWidth: 254, width: "100%" }}
                src={img}
                alt={"empty messages"}
              />
              <Typography style={{ marginTop: "40px" }}>
                Select a chat
              </Typography>
              <Typography>to start messaging</Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default observer(MessagesPageDesktop);
