import React, { Fragment, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { BaseContext } from "../utils/baseProvider";
import { Box, CircularProgress } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  progress: {
    zIndex: "9999"
  },
  overlay: {
    position: "fixed",
    top: "0",
    left: 0,
    zIndex: "9999",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));

export default function Loader() {
  const baseContext = useContext(BaseContext);
  const classes = useStyles();
  return (
    <Fragment>
      {baseContext.loading && (
        <Box height="100%" width="100%" className={classes.overlay}>
          <div>
            <CircularProgress className={classes.progress} />
          </div>
        </Box>
      )}
    </Fragment>
  );
}
