import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguagesBadge, {
  LanguagesBadgeIcons
} from "../../../../components/LanguagesBadge/LanguagesBadge";
import { LocalCallContext } from "../../localCallProvider";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { formatElapsed } from "../../../../utils/time";
import { AppContext } from "../../../../utils/AppContext";
import useLanguages from "../../../../utils/hooks/useLanguages";
import SettingsButton from "../../../../components/CallSettings/SettingsButton";
import { figmaFonts } from "../../../../theme";
import { ReactComponent as MicOffDark } from "../../icons/micOffDark.svg";

const useStyles = makeStyles(theme => ({
  callInfo: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "top"
  },
  firstRowBox: {
    display: "flex",
    alignItems: "baseline"
  },
  name: {
    maxWidth: 200,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: "26px",
    lineHeight: "31px",
    fontWeight: 600
  },
  time: {
    fontWeight: "normal",
    color: theme.palette.text.grey,
    ...figmaFonts.desktopSmallTextRegular
  },
  timeDark: {
    color: theme.palette.text.grey
  }
}));

function CallDataDesktop({ showLanguagesBadge = true, elapsedText, isCallee }) {
  const classes = useStyles();
  const localCallContext = useContext(LocalCallContext);
  const { localCallUIStore, localCallStore } = useContext(AppContext);
  const { getLanguageByKey } = useLanguages();

  const firstCountry = getLanguageByKey(
    isCallee ? localCallStore.callSettings.to : localCallStore.callSettings.from
  ).Country;
  const secondCountry = getLanguageByKey(
    isCallee ? localCallStore.callSettings.from : localCallStore.callSettings.to
  ).Country;

  return (
    <Box className={classes.callInfo}>
      <Box display={"flex"}>
        <SettingsButton
          small
          onClick={localCallUIStore.toggleSettingsModalShow}
          size={32}
        />

        <Box ml={2} display={"flex"} flexDirection={"column"}>
          <Box mb={0.25}>
            <Box display={"flex"} alignItems={"center"}>
              <Typography className={classes.name}>
                {localCallContext.remoteName || ""}
              </Typography>
              {!localCallContext.calleeAudioEnabled && (
                <Box display="flex" alignItems={"center"} ml={1}>
                  <MicOffDark />
                </Box>
              )}
            </Box>
          </Box>

          <Box display={"flex"} alignItems={"center"}>
            <Box mr={1}>
              <Typography className={clsx(classes.time)} variant="subtitle2">
                {elapsedText
                  ? elapsedText
                  : formatElapsed(localCallContext.elapsed)}
              </Typography>
            </Box>

            <Box mt={0} alignSelf={"center"}>
              {localCallContext.translationEnabled && showLanguagesBadge && (
                <LanguagesBadge
                  icon={LanguagesBadgeIcons.arrowsBlack}
                  iconMargin={0}
                  size={16}
                  firstCountry={firstCountry}
                  secondCountry={secondCountry}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default CallDataDesktop;
