import React from "react";
import { Box, Button, Typography, useMediaQuery } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import img_3 from "../../assets/PeopleBackground.png";
import img from "./background.png";
import { figmaFonts } from "../../../../../../theme";

const useStyles = makeStyles(theme => ({
  bottomBlock: props => ({
    border: `1px solid ${theme.palette.background.input}`,
    display: "flex",
    width: "calc(100% - 50px)",
    boxSizing: "border-box",
    position: "relative",
    borderRadius: 5,
    marginTop: 64,
    paddingTop: 40,
    maxWidth: 600,

    [props.desktopBreakpoint]: {
      padding: 0,
      paddingTop: 50,
      height: 347,
      marginTop: 151,
      maxWidth: 950
    }
  }),
  bottomBlockImage: props => ({
    width: "100%",
    position: "absolute",
    bottom: 0,
    left: 0,
    maxWidth: "100%",
    [props.desktopBreakpoint]: {
      width: 464
    }
  }),
  bottomBlockButton: {
    ...figmaFonts.desktopCapsBtn14
  },
  bottomBlockImageMobile: {
    display: "flex",
    width: "100%"
  },
  bottomBlockText: props => ({
    [props.desktopBreakpoint]: {
      maxWidth: 396
    }
  })
}));

function BottomBlock({ showDesktopLayout }) {
  const desktopBreakpoint = "@media (min-width:950px)";
  const classes = useStyles({ desktopBreakpoint });
  const isDesktop = useMediaQuery(desktopBreakpoint);
  const history = useHistory();

  function openStore() {
    history.push("/bonus?tab=2", { tab: 2 });
  }

  return (
    <Box mx={showDesktopLayout ? 0 : "auto"} className={classes.bottomBlock}>
      {isDesktop && (
        <Box width={"50%"}>
          <img
            className={classes.bottomBlockImage}
            src={img_3}
            alt={"background"}
          />
        </Box>
      )}

      <Box
        width={isDesktop ? "50%" : "100%"}
        display={"flex"}
        flexDirection={"column"}
        justifyContent={isDesktop ? "initial" : "center"}
        textAlign={isDesktop ? "initial" : "center"}
        whiteSpace={isDesktop ? "" : "pre-line"}
      >
        <Box mt={"auto"} mb={3}>
          <Typography variant={"h2"}>
            What can I spend{isDesktop ? " " : "\n"}my bonuses on?
          </Typography>
        </Box>
        <Typography className={classes.bottomBlockText} variant={"subtitle1"}>
          You can spend the received bonuses{isDesktop ? " " : "\n"}from the
          referral program on useful{isDesktop ? " " : "\n"}
          products:&nbsp;
          <Typography
            component={"span"}
            display={"inline"}
            color={"primary"}
            onClick={openStore}
            variant={"subtitle1"}
          >
            branded T&#8209;shirts, hoodies,{isDesktop ? " " : "\n"}phone
            cases&nbsp;
          </Typography>
          with your personal QR{isDesktop ? " " : "\n"}code, in which your
          referral link is{isDesktop ? " " : "\n"}hidden, or you can exchange
          bonuses{isDesktop ? " " : "\n"}for&nbsp;
          <Typography
            component={"span"}
            display={"inline"}
            color={"primary"}
            onClick={openStore}
            variant={"subtitle1"}
          >
            paid YOUS services.
          </Typography>
        </Typography>

        <Box
          mt={3}
          mb={isDesktop ? 6.25 : 2}
          width={180}
          mx={isDesktop ? 0 : "auto"}
        >
          <Button
            fullWidth
            variant="contained"
            color="primary"
            onClick={openStore}
            className={classes.bottomBlockButton}
          >
            Go to the Store
          </Button>
        </Box>

        {!isDesktop && (
          <Box>
            <img
              className={classes.bottomBlockImageMobile}
              src={img}
              alt={"background"}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default BottomBlock;
