import React, { useCallback, useRef, useState } from "react";
import { Box, Popover } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Dots } from "./icons/dots.svg";
import { ReactComponent as Delete } from "./icons/delete.svg";
import { ReactComponent as Report } from "./icons/report.svg";
import { ReactComponent as Block } from "./icons/block.svg";
import ChatMenuButton from "../ChatMenuButton/ChatMenuButton";
import ChatMenuModal from "./ChatMenuModal";

const useStyles = makeStyles(theme => ({
  button: {
    width: 18,
    height: 18,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: 5,
    color: props =>
      props.popOverOpen ? theme.palette.primary.main : theme.palette.text.grey,
    backgroundColor: props =>
      props.popOverOpen ? theme.palette.background.input : "initial",
    "&:hover": {
      backgroundColor: theme.palette.background.input,
      color: theme.palette.primary.main
    },
    [theme.breakpoints.up("sm")]: {
      width: 40,
      height: 40
    }
  },
  popover: {
    marginTop: theme.spacing(1)
  },
  popoverPaper: {
    padding: theme.spacing(2),
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.07)",
    marginTop: theme.spacing(2)
  }
}));

const ChatMenu = ({ chat }) => {
  const [popOverOpen, setPopOverOpen] = useState(false);
  const [modalType, setModalType] = useState();
  const classes = useStyles({ popOverOpen });
  const iconRef = useRef();

  const togglePopOver = () => {
    setPopOverOpen(!popOverOpen);
  };

  const onMenuButtonClick = useCallback(type => {
    setModalType(type);
  }, []);

  return (
    <>
      <Box onClick={togglePopOver} className={classes.button} ref={iconRef}>
        <Dots />
      </Box>
      <Popover
        open={popOverOpen}
        onClick={togglePopOver}
        anchorEl={iconRef.current}
        className={classes.popover}
        PaperProps={{
          className: classes.popoverPaper
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <ChatMenuButton
          onClick={() => onMenuButtonClick("report")}
          icon={<Report />}
          text={"Report"}
        />
        <ChatMenuButton
          onClick={() => onMenuButtonClick("block")}
          icon={<Block />}
          text={chat.remoteBan ? "Unblock" : "Block"}
        />
        <ChatMenuButton
          onClick={() => onMenuButtonClick("delete")}
          icon={<Delete />}
          text={"Delete"}
        />
      </Popover>
      <ChatMenuModal
        modalType={modalType}
        onClose={() => setModalType(undefined)}
        chat={chat}
      />
    </>
  );
};

export default ChatMenu;
