import React, { useEffect, useRef } from "react";
import { ReactComponent as VoiceIcon } from "./VoiceIcon.svg";
import { ReactComponent as MessageIcon } from "./icons/MessageIcon.svg";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { figmaFonts } from "../../../theme";
import {
  Box,
  Grid,
  ListItem,
  ListItemText,
  Typography
} from "@material-ui/core";
import useIsVisible from "../../../utils/hooks/useIsVisible";

const useStyles = makeStyles(theme => ({
  listItem: {
    textAlign: "right",
    marginBottom: theme.spacing(1),
    padding: 0
  },
  listItemB: {
    textAlign: "left"
  },
  listItemMargin: {
    marginTop: theme.spacing(2)
  },
  message: {
    whiteSpace: "break-spaces",
    wordBreak: "break-word",
    padding: theme.spacing(0.75, 1.5),
    borderRadius: "10px 10px 0px 10px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.secondary,
    display: "flex",
    textAlign: "left",
    ...figmaFonts.textRegular14
  },
  messageB: {
    borderRadius: "10px 10px 10px 0px",
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.default
  },
  listItemText: {
    display: "inline-block",
    margin: theme.spacing(0, 0, 1, 0),
    position: "relative"
  },
  messageType: {
    marginLeft: theme.spacing(0.5),
    ...figmaFonts.desktopSmallTextRegular,
    color: theme.palette.background.default,
    [theme.breakpoints.up("lg")]: {
      color: theme.palette.text.grey
    }
  },
  messageTypeCVD: {
    color: theme.palette.text.grey
  },
  messageTypeBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& > svg": {
      fill: theme.palette.background.default,
      [theme.breakpoints.up("lg")]: {
        fill: theme.palette.text.grey
      }
    }
  },
  messageTypeBoxCVD: {
    "& > svg": {
      fill: theme.palette.text.grey
    }
  },
  messageTypeBoxB: {
    justifyContent: "flex-start"
  }
}));

function TranscriptionChatMessage({
  currentUserSide,
  message,
  calleeVideoEnabled,
  prevMessageSide,
  setMessageRead
}) {
  const classes = useStyles();

  const isBSide = currentUserSide !== message.side;

  const prevMessageIsFromAnotherSide =
    prevMessageSide && prevMessageSide !== message.side;

  const isVoice = message.type === "original" || message.type === "translation";

  const ref = useRef();
  const isVisible = useIsVisible(ref);

  useEffect(() => {
    if (!message.read && isVisible && setMessageRead) {
      setMessageRead(message);
    }
  }, [isVisible, message, message.read, setMessageRead]);

  const renderMessageTime = (timestamp, placeholder = false) => {
    const hours = new Date(timestamp).getHours();
    let minutes = new Date(timestamp).getMinutes();
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    return (
      <span
        style={{
          unicodeBidi: "embed",
          position: !placeholder ? "absolute" : "initital",
          right: 12,
          bottom: 6,
          marginLeft: 8,
          textAlignLast: "right",
          fontWeight: 500,
          fontSize: "11px",
          lineHeight: "24px",
          color: isBSide ? "#9595B1" : "#B1BDFD",
          visibility: placeholder ? "hidden" : "visible"
        }}
      >
        {hours + ":" + minutes}
      </span>
    );
  };

  return (
    <ListItem
      ref={ref}
      className={clsx(
        classes.listItem,
        isBSide ? classes.listItemB : "",
        prevMessageIsFromAnotherSide ? classes.listItemMargin : ""
      )}
    >
      <Grid container>
        <Grid item xs={12}>
          <ListItemText
            className={classes.listItemText}
            primary={
              <span style={{ unicodeBidi: "bidi-override" }}>
                {message.text}
                {renderMessageTime(message.timestamp, true)}
                {renderMessageTime(message.timestamp)}
              </span>
            }
            primaryTypographyProps={{
              className: clsx(classes.message, isBSide ? classes.messageB : "")
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Box
            className={clsx(
              classes.messageTypeBox,
              isBSide ? classes.messageTypeBoxB : "",
              calleeVideoEnabled ? "" : classes.messageTypeBoxCVD
            )}
          >
            {isVoice ? <VoiceIcon /> : <MessageIcon />}

            <Typography
              className={clsx(
                classes.messageType,
                calleeVideoEnabled ? "" : classes.messageTypeCVD
              )}
            >
              {isVoice ? "Voice" : "Message"}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </ListItem>
  );
}

export default React.memo(TranscriptionChatMessage);
