import React from "react";
import { Box, Typography } from "@material-ui/core";
import { ReactComponent as Icon } from "./subscriptionIcon.svg";
import { ReactComponent as IconSmall } from "./subscriptionIconSmall.svg";
import SavingsBadge from "../../../../../../../SubscriptionPage/SubscribedContent/components/SavingsBadge";
import { plans } from "../../../../../../../../constants/plans";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    height: 128,
    paddingBottom: theme.spacing(0.5),
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
    borderTop: `4px solid ${theme.palette.background.green}`,
    borderRadius: 5,
    justifyContent: "center"
  },
  containerDesktop: {
    padding: theme.spacing(2.75),
    height: 228,
    boxSizing: "content-box"
  },
  subtitle: {
    color: theme.palette.text.grey
  }
}));

function SubscriptionCard({ period, isDesktop }) {
  const classes = useStyles();
  const containerClassName = clsx(classes.container, {
    [classes.containerDesktop]: isDesktop
  });
  return (
    <Box className={containerClassName}>
      <Box
        display={"flex"}
        flexDirection={isDesktop ? "column" : "row"}
        alignItems={"center"}
      >
        <Box
          mt={isDesktop ? 1 : 0}
          mb={isDesktop ? 2 : 0}
          mr={isDesktop ? 0 : 1}
        >
          {isDesktop ? <Icon /> : <IconSmall />}
        </Box>

        <Typography variant={"h1"}>Plus</Typography>
        <Box ml={isDesktop ? 0 : 1} mt={isDesktop ? 0 : 1}>
          <Typography className={classes.subtitle} variant={"subtitle1"}>
            ({period})
          </Typography>
        </Box>
      </Box>
      <Box mt={isDesktop ? 2.75 : 1} mb={isDesktop ? 2.75 : 0}>
        <SavingsBadge mt={0}>
          <Typography color={"primary"} variant={"inherit"}>
            <Typography
              component={"span"}
              variant={"inherit"}
              display={"inline"}
              color={"textPrimary"}
            >
              Save&nbsp;
            </Typography>

            {period === "yearly" ? (
              <>${plans.plus.prices.year}/year</>
            ) : (
              <>${plans.plus.prices.month}/month</>
            )}
          </Typography>
        </SavingsBadge>
      </Box>
    </Box>
  );
}

export default SubscriptionCard;
