import React from "react";
import { Redirect } from "react-router-dom";
import { TopNavigationItems } from "../../../components/AppBarTop/TopNavigation/TopNavigation";
import withTopNavigation from "../../../components/AppBarTop/TopNavigation/withTopNavigation";
import withBottomBar from "../../../components/BottomNavBar/withBottomBar";
import withRequest from "../../../utils/withRequest";
import useBreakpoints from "../../../utils/useBreakpoints";
import PhoneCallCreatePageDesktop from "./PhoneCallCreatePageDesktop";
import PhoneCallCreatePageMobile from "./PhoneCallCreatePageMobile";
import { appStore } from "../../../utils/stores/AppStore";
import { observer } from "mobx-react";

function PhoneCallCreatePage() {
  const { isDesktop } = useBreakpoints();

  if (appStore.authorized === false) {
    return <Redirect to="/" />;
  }

  if (isDesktop) {
    return <PhoneCallCreatePageDesktop />;
  } else {
    return <PhoneCallCreatePageMobile />;
  }
}

export default withTopNavigation(
  withBottomBar(withRequest(observer(PhoneCallCreatePage)), false),
  {
    left: TopNavigationItems.backButton,
    right: TopNavigationItems.usdBalance
  }
);
