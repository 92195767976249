import React from "react";
import { removeTokenValue } from "./localStorage";
import { AppContext } from "./AppContext";
import { appStore } from "./stores/AppStore";

export const BaseContext = React.createContext();

class BaseProvider extends React.Component {
  static contextType = AppContext;

  state = {
    authorized: undefined,
    currentUser: undefined,
    errorTitle: null,
    loading: false,
    sign: "up",
    showTopBar: false,
    showWaitingForConnection: false,
    showBottomBar: false,
    showCreateCall: false,
    topNavigationProps: null,
    keyboardOpen: false,
    secondsBalance: undefined,
    stripe: undefined,
    centsBalance: 0,
    appBarTopLeftItem: undefined
  };

  setSign = sign => this.setState({ sign: sign });
  setShowTopBar = showTopBar => this.setState({ showTopBar: showTopBar });
  setShowWaitingForConnection = showWaitingForConnection =>
    this.setState({ showWaitingForConnection: showWaitingForConnection });

  setAuthorized = authorized => {
    if (authorized === false) {
      removeTokenValue();
    }
    this.setState({ authorized: authorized });
  };

  setErrorTitle = errorTitle => {
    if (this.state.errorTitle) {
      this.setState({ errorTitle: errorTitle });
    } else {
      this.setState({ errorTitle: null });
      this.setState({ errorTitle: errorTitle });
    }
  };

  setLoading = loading => this.setState({ loading: loading });

  setCurrentUser = currentUser => {
    // TODO: refactor context to mobx store
    appStore.setCurrentUser(currentUser);
    this.setState({ currentUser: currentUser });

    // update selected language key
    if (currentUser && currentUser.fromLang) {
      this.context.languagesStore.setSelectedFormLanguageKey(
        currentUser.fromLang
      );
    } else {
      this.context.languagesStore.setSelectedFormLanguageKey(null);
    }
  };

  setTopNavigationProps = topNavigationProps =>
    this.setState({ topNavigationProps: topNavigationProps });

  setShowBottomBar = showBottomBar =>
    this.setState({ showBottomBar: showBottomBar });

  setShowCreateCall = showCreateCall =>
    this.setState({ showCreateCall: showCreateCall });

  setKeyboardOpen = keyboardOpen =>
    this.setState({ keyboardOpen: keyboardOpen });

  setUserSecondsBalance = secondsBalance => {
    if (
      secondsBalance === undefined ||
      this.state.secondsBalance === undefined
    ) {
      this.setState({
        secondsBalance: secondsBalance > 0 ? secondsBalance : 0
      });
    } else if (
      secondsBalance < this.state.secondsBalance &&
      secondsBalance >= 0
    ) {
      this.setState({ secondsBalance: secondsBalance });
    }
  };

  setUserCentsBalance = centsBalance => {
    this.setState({ centsBalance });
  };

  updateUserBalance = elapsed => {
    if (this.state.currentUser && this.state.currentUser.secondsBalance) {
      this.setUserSecondsBalance(
        this.state.currentUser.secondsBalance - elapsed
      );
    }
  };

  setStripe = userCurrentResponse => {
    this.setState({
      stripe: {
        stripePublic: userCurrentResponse.stripePublic,
        stripePriceMonth: userCurrentResponse.stripePriceMonth,
        stripePriceYear: userCurrentResponse.stripePriceYear
      }
    });
  };

  setAppBarTopLeftItem = ({
    showBackButton = false,
    showWaitingForConnection = false,
    title = null
  }) => {
    this.setState({
      appBarTopLeftItem: {
        showBackButton,
        showWaitingForConnection,
        title
      }
    });
  };

  render() {
    return (
      <BaseContext.Provider
        value={{
          authorized: this.state.authorized,
          setAuthorized: this.setAuthorized,
          sign: this.state.sign,
          setSign: this.setSign,
          showTopBar: this.state.showTopBar,
          setShowTopBar: this.setShowTopBar,
          showWaitingForConnection: this.state.showWaitingForConnection,
          setShowWaitingForConnection: this.setShowWaitingForConnection,
          errorTitle: this.state.errorTitle,
          setErrorTitle: this.setErrorTitle,
          loading: this.state.loading,
          setLoading: this.setLoading,
          currentUser: this.state.currentUser,
          setCurrentUser: this.setCurrentUser,
          showCreateCall: this.state.showCreateCall,
          setShowCreateCall: this.setShowCreateCall,
          topNavigationProps: this.state.topNavigationProps,
          setTopNavigationProps: this.setTopNavigationProps,
          showBottomBar: this.state.showBottomBar,
          setShowBottomBar: this.setShowBottomBar,
          keyboardOpen: this.state.keyboardOpen,
          setKeyboardOpen: this.setKeyboardOpen,
          secondsBalance: this.state.secondsBalance,
          setUserSecondsBalance: this.setUserSecondsBalance,
          updateUserBalance: this.updateUserBalance,
          stripe: this.state.stripe,
          setStripe: this.setStripe,
          centsBalance: this.state.centsBalance,
          setUserCentsBalance: this.setUserCentsBalance,
          appBarTopLeftItem: this.state.appBarTopLeftItem,
          setAppBarTopLeftItem: this.setAppBarTopLeftItem
        }}
      >
        {this.props.children}
      </BaseContext.Provider>
    );
  }
}

export const BaseConsumer = BaseContext.Consumer;

export default BaseProvider;
