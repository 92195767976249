export function getCountryCodeByKey(key, languages) {
  if (languages) {
    const language = languages.filter(lang => lang["Key"] === key)[0];

    if (language) {
      return language.Country;
    }
  }
  return null;
}
