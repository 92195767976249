import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import BackButton from "./BackButton";
import Balance from "./Balance";
import { Box } from "@material-ui/core";
import useAppBarHeight from "../../../utils/useAppBarHeight";
import { BaseContext } from "../../../utils/baseProvider";
import { palette } from "../../../theme";
import UsdBalanceChip from "../../chips/UsdBalanceChip";
import SettingsButton from "../../CallSettings/SettingsButton";
import EventEmitter from "../../../utils/events/EventEmitter";
import PHONE_CALL_EVENTS from "../../../utils/events/PhoneCallEvents";

export const TopNavigationItems = {
  backButton: <BackButton />,
  balance: <Balance />,
  usdBalance: <UsdBalanceChip />,
  settings: (
    <SettingsButton
      onClick={() => EventEmitter.emit(PHONE_CALL_EVENTS.toggleSettings)}
      small
    />
  )
};

const useStyles = makeStyles(theme => {
  return {
    topNavigation: {
      zIndex: 2,
      position: "sticky",
      top: props => props.appBarHeight,
      height: 39,
      borderBottom: `1px solid ${theme.palette.background.input}`,
      display: "flex",
      backgroundColor: theme.palette.background.default,
      alignItems: "center",
      justifyContent: "space-between",
      padding: theme.spacing(0, 3.125),
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(0, 10, 0, 10)
      }
    }
  };
});

function TopNavigation(props) {
  const appBarHeight = useAppBarHeight();
  const classes = useStyles({ appBarHeight });
  const baseContext = useContext(BaseContext);
  const authorized = baseContext.currentUser !== undefined;

  return (
    <Box
      className={classes.topNavigation}
      borderTop={!authorized ? `1px solid ${palette.background.input}` : "none"}
    >
      <Box py={3.125}>{props.left}</Box>
      <Box>{props.right}</Box>
    </Box>
  );
}
export default TopNavigation;
