import React from "react";
import useStripe from "../../../../utils/useStripe";
import withRequest from "../../../../utils/withRequest";
import { Box, Button, Typography } from "@material-ui/core";
import PlanCard from "./PlanCard";
import AnimatedArrows from "../../../../components/AnimatedArrows/AnimatedArrows";
import IconBadge from "../../../../components/IconBadge";
import { ReactComponent as WarnIcon } from "../../icons/warnIcon.svg";
import { makeStyles } from "@material-ui/core/styles";
import { figmaFonts } from "../../../../theme";
import { plans } from "../../../../constants/plans";
import * as PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  noteText: {
    maxWidth: 482,
    ...figmaFonts.mobileSmallTextMedium,
    [theme.breakpoints.up("lg")]: {
      ...figmaFonts.desktopSmallTextMedium
    }
  },
  button: {
    ...figmaFonts.desktopCapsBtn14
  }
}));

function BuyButton(props) {
  return (
    <Button
      fullWidth
      variant={"contained"}
      color={"primary"}
      onClick={props.stripe.year}
      className={props.classes.button}
    >
      Buy now
    </Button>
  );
}

BuyButton.propTypes = {
  stripe: PropTypes.shape({ month: PropTypes.func, year: PropTypes.func }),
  classes: PropTypes.any
};

function Note(props) {
  return (
    <IconBadge icon={<WarnIcon />}>
      <Typography className={props.classes.noteText}>
        <strong>Note:</strong>{" "}
        {
          "when you switch to an annual subscription, your current monthly plan will be canceled, the remaining minutes and days will be canceled."
        }
      </Typography>
    </IconBadge>
  );
}

Note.propTypes = { classes: PropTypes.any };

function SwitchToYearlyModal({
  postDataWithCallback,
  currentPlanPeriod,
  desktopMode
}) {
  const classes = useStyles();
  const stripe = useStripe({
    postDataWithCallback
  });

  function renderDesktopContent() {
    return (
      <>
        <Box
          mt={4}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Box flex={1}>
            <PlanCard
              currentPlanPeriod={currentPlanPeriod}
              price={plans.plus.prices.month}
              period={"month"}
            />
          </Box>
          <Box mx={2}>
            <AnimatedArrows />
          </Box>
          <Box flex={1}>
            <PlanCard
              currentPlanPeriod={currentPlanPeriod}
              price={plans.plus.prices.year}
              period={"year"}
            />
          </Box>
        </Box>
        <Box mt={4}>
          <Note classes={classes} />
        </Box>
        <Box mt={4} mx={"auto"} width={"100%"} maxWidth={187}>
          <BuyButton stripe={stripe} classes={classes} />
        </Box>
      </>
    );
  }

  function renderMobileContent() {
    return (
      <>
        <Box
          mt={2}
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          flexDirection={"column"}
        >
          <Box flex={1}>
            <PlanCard
              currentPlanPeriod={currentPlanPeriod}
              price={plans.plus.prices.month}
              period={"month"}
              horizontal
            />
          </Box>
          <Box my={1.5}>
            <AnimatedArrows horizontal />
          </Box>
          <Box flex={1}>
            <PlanCard
              currentPlanPeriod={currentPlanPeriod}
              price={plans.plus.prices.year}
              period={"year"}
              horizontal
            />
          </Box>
        </Box>
        <Box mt={3} mx={"auto"} width={"100%"} maxWidth={177}>
          <BuyButton stripe={stripe} classes={classes} />
        </Box>
        <Box mt={3} maxWidth={270}>
          <Note classes={classes} />
        </Box>
      </>
    );
  }

  return (
    <Box px={desktopMode ? 5 : 2} pb={desktopMode ? 5 : 3}>
      <Typography align={"center"} variant={"h2"}>
        Switch to yearly{desktopMode ? " " : <br />}subscription
      </Typography>
      {desktopMode ? renderDesktopContent() : renderMobileContent()}
    </Box>
  );
}
export default withRequest(SwitchToYearlyModal);
