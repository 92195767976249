import React from "react";
import "./animatedArrows.scss";

function AnimatedArrows({ horizontal }) {
  const className = "container" + (horizontal ? " container-horizontal" : "");
  return (
    <div className={className} style={{ display: "flex" }}>
      <div className="scroll-arrow" />
      <div className="scroll-arrow" />
      <div className="scroll-arrow" />
    </div>
  );
}

export default AnimatedArrows;
