import React, { useEffect, useRef, useState } from "react";
import { Box, SvgIcon, Typography } from "@material-ui/core";
import { ReactComponent as CopyButtonIcon } from "../../assets/CopyButtonIcon.svg";
import { ReactComponent as CopyButtonSuccess } from "../../assets/copySuccess.svg";
import { makeStyles } from "@material-ui/core/styles";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import TouchRipple from "@material-ui/core/ButtonBase/TouchRipple";
import useBreakpoints from "../../utils/useBreakpoints";

const useStyles = makeStyles(theme => ({
  box: {
    backgroundColor: theme.palette.background.input,
    borderRadius: 10,
    padding: theme.spacing(2.5, 3),
    paddingRight: theme.spacing(7),
    position: "relative",
    color: theme.palette.primary.main,
    cursor: "pointer",
    width: "100%",
    overflow: "hidden"
  },
  text: {
    overflow: "hidden",
    textOverflow: "ellipsis"
  },
  icon: {
    position: "absolute",
    top: 16,
    right: 16,
    width: 32,
    height: 32
  }
}));

function SendLink(props) {
  const classes = useStyles();
  const ripple = useRef();
  const [copied, setCopied] = useState(false);
  const timer = useRef();
  const { isDesktop } = useBreakpoints();

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  function onCopy() {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    setCopied(true);
    timer.current = setTimeout(() => setCopied(false), 2000);
  }

  const onRippleStart = e => {
    ripple.current.start(e);
  };
  const onRippleStop = e => {
    ripple.current.stop(e);
  };

  return (
    <Box width={"100%"} mb={3}>
      <Typography variant={"body2"}>Send link</Typography>

      <CopyToClipboard onCopy={onCopy} text={props.link}>
        <Box
          onMouseDown={onRippleStart}
          onMouseUp={onRippleStop}
          className={classes.box}
          mt={isDesktop ? 1.5 : 1.25}
        >
          <Typography className={classes.text} variant={"subtitle1"}>
            {props.link}
          </Typography>

          <SvgIcon className={classes.icon} viewBox={"0 0 32 32"}>
            {copied ? <CopyButtonSuccess /> : <CopyButtonIcon />}
          </SvgIcon>
          <TouchRipple ref={ripple} />
        </Box>
      </CopyToClipboard>
    </Box>
  );
}

export default SendLink;
