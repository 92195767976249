import React, { useCallback, useEffect } from "react";
import { observer } from "mobx-react";
import { Box } from "@material-ui/core";
import SearchInput from "./components/SearchInput";
import { peopleSearchStore } from "../../utils/stores/PeopleSearchStore";
import UserList from "./components/UserList";
import { makeStyles } from "@material-ui/core/styles";
import ChatPage from "./components/ChatPage/ChatPage";
import { chatsStore } from "../../utils/stores/ChatsStore";
import ChatsList from "./components/ChatsList";
import { useHistory } from "react-router-dom";
import querystring from "query-string";
import useRequest from "../../utils/hooks/useRequest";

const useStyles = makeStyles(theme => ({
  container: {
    position: "absolute",
    inset: 0,
    overflowY: "scroll",
    paddingLeft: theme.spacing(3.125),
    paddingRight: theme.spacing(3.125)
  }
}));

const MessagesPageMobile = () => {
  const classes = useStyles();
  const history = useHistory();
  const params = querystring.parse(history.location.search);
  const { postDataWithCallback } = useRequest();

  useEffect(() => {
    if (params.toId) {
      void loadUser(params.toId);
    }
    // eslint-disable-next-line
  }, []);

  const loadUser = id => {
    const chat = chatsStore.chats.find(chat => chat.remoteId === id);
    if (chat) {
      chatsStore.openChat(chat);
    } else {
      postDataWithCallback("/api/userinfo", { id: parseInt(id) }, data => {
        if (data && data !== "err") {
          onUserSelect(data);
          history.replace({
            search: ""
          });
        }
      });
    }
  };

  const onChatSelect = useCallback(chat => {
    chatsStore.openChat(chat);
  }, []);

  const onUserSelect = useCallback(
    user => {
      onChatSelect(chatsStore.findExistingOrCreateNew(user));
    },
    [onChatSelect]
  );

  return (
    <Box flex={1} position={"relative"}>
      <Box className={classes.container}>
        {chatsStore.openedChat ? (
          <ChatPage onChatSelect={onChatSelect} />
        ) : (
          <>
            <Box pt={2} pb={1}>
              <SearchInput />
            </Box>
            {!!peopleSearchStore.searchValue ? (
              <UserList
                onUserSelect={onUserSelect}
                users={peopleSearchStore.users}
              />
            ) : (
              <ChatsList
                onChatSelect={onChatSelect}
                chats={chatsStore.chats}
                loaded={chatsStore.chatsLoaded}
              />
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

export default observer(MessagesPageMobile);
