import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { figmaFonts } from "../../../theme";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import moment from "moment";

const useStyles = makeStyles(theme => ({
  text: {
    height: 16,
    ...figmaFonts.mobileSmallTextRegular,
    [theme.breakpoints.up("lg")]: {
      ...figmaFonts.desktopSmallTextRegular
    }
  },
  online: {
    color: theme.palette.background.green
  },
  lastSeen: {
    color: theme.palette.text.grey,
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 1,
    overflow: "hidden"
  },
  typing: {
    color: theme.palette.primary.main
  }
}));

const ChatPageHeaderOnlineStatus = ({ online, lastOnline, typing }) => {
  const classes = useStyles();

  const formatLastOnline = useCallback(date => {
    if (date) {
      const d = moment(date);
      if (d.isSame(new Date(), "day")) {
        return "last seen today at " + d.format("HH:mm");
      }

      if (d.add(1, "day").isSame(new Date(), "day")) {
        return "last seen yesterday at " + d.format("HH:mm");
      }

      return "last seen " + d.format("DD/MM/YYYY") + " at " + d.format("HH:mm");
    }
  }, []);

  if (typing) {
    return (
      <Typography className={clsx(classes.text, classes.typing)}>
        typing...
      </Typography>
    );
  }

  if (online) {
    return (
      <Typography className={clsx(classes.text, classes.online)}>
        online
      </Typography>
    );
  }

  const formattedDate = formatLastOnline(lastOnline);
  if (formattedDate) {
    return (
      <Typography className={clsx(classes.text, classes.lastSeen)}>
        {formattedDate}
      </Typography>
    );
  }

  return null;
};

export default ChatPageHeaderOnlineStatus;
