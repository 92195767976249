import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { ReactComponent as TranscriptionOn } from "./icons/TranscriptionOn.svg";
import { ReactComponent as TranscriptionOff } from "./icons/TranscriptionOff.svg";
import { ReactComponent as VideoOn } from "./icons/VideoOn.svg";
import { ReactComponent as VideoOff } from "./icons/VideoOff.svg";
import { ReactComponent as TranslationOn } from "./icons/TranslationOn.svg";
import { ReactComponent as TranslationOff } from "./icons/TranslationOff.svg";
import { HangUp, MicOff, MicOn } from "../../../../assets/icons";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { LocalCallContext } from "../../localCallProvider";
import { Box, Button, Chip } from "@material-ui/core";
import debounce from "lodash.debounce";

const useStyles = makeStyles(theme => ({
  containerBox: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    position: "relative"
  },
  button: {
    position: "relative",
    width: 56,
    height: 56,
    minWidth: 56,
    borderRadius: "50%",
    backgroundColor: theme.palette.background.darkGrey,
    [theme.breakpoints.up("md")]: {
      width: 64,
      height: 64
    },
    "&:hover": {
      backgroundColor: theme.palette.background.darkGrey
    },
    "&  svg": {
      width: 28,
      height: 28,
      [theme.breakpoints.up("md")]: {
        width: 32,
        height: 32
      }
    },
    marginRight: theme.spacing(1),
    "&:last-child": {
      marginRight: 0
    }
  },
  buttonError: {
    backgroundColor: theme.palette.background.red,
    "&:hover": {
      backgroundColor: theme.palette.background.red
    }
  },
  chip: {
    borderRadius: 5,
    backgroundColor: theme.palette.background.darkGrey,
    height: 24,
    position: "absolute",
    top: -(theme.spacing(2) + 24),
    [theme.breakpoints.down("md")]: {
      left: 25
    }
  },
  chipLabel: {
    padding: theme.spacing(0.5, 1),
    color: theme.palette.text.secondary
  },
  disabled: {
    cursor: "not-allowed"
  },
  unreadCounter: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    position: "absolute",
    height: 17,
    minWidth: 17,
    top: 0,
    right: 0,
    color: theme.palette.background.default,
    backgroundColor: theme.palette.background.red,
    padding: "0 5px",
    fontWeight: "bold",
    fontSize: "11px",
    lineHeight: "13px",
    [theme.breakpoints.up("md")]: {
      padding: "0 5px",
      height: 20,
      minWidth: 20,
      borderRadius: 10
    }
  }
}));

function CallControls({
  toggleVideo,
  toggleAudio,
  toggleTranslation,
  handleDisconnect,
  Bconnected
}) {
  const classes = useStyles();
  const localCallContext = useContext(LocalCallContext);
  const [unreadCount, setUnreadCount] = useState(0);

  const isCaller = useMemo(() => !localCallContext.currentUserIsCallee, [
    localCallContext.currentUserIsCallee
  ]);

  const setUnreadCounter = useCallback(
    debounce(messages => {
      setUnreadCount(parseInt(messages.filter(msg => !msg.read).length / 2));
    }, 1000),
    []
  );

  useEffect(() => {
    setUnreadCounter(localCallContext.messages);
  }, [localCallContext.messages, setUnreadCounter]);

  return (
    <Box className={classes.containerBox}>
      {Bconnected && (
        <>
          {!localCallContext.myAudioEnabled && (
            <Chip
              classes={{ root: classes.chip, label: classes.chipLabel }}
              label={"Microphone is muted"}
              style={{
                top: !localCallContext.translationEnabled ? -72 : -40
              }}
            />
          )}

          {!localCallContext.translationEnabled && (
            <Chip
              classes={{ root: classes.chip, label: classes.chipLabel }}
              label={"Translation is disabled"}
            />
          )}
        </>
      )}

      <Button
        onClick={toggleVideo}
        className={classes.button}
        style={{
          order: 1
        }}
      >
        {localCallContext.myVideoEnabled ? <VideoOn /> : <VideoOff />}
      </Button>

      <Button
        onClick={toggleAudio}
        className={classes.button}
        style={{
          order: 2
        }}
      >
        {localCallContext.myAudioEnabled ? <MicOn /> : <MicOff />}
      </Button>

      <Button
        className={classes.button}
        onClick={localCallContext.toggleTranscription}
        style={{
          order: isCaller ? 4 : 3
        }}
      >
        {unreadCount > 0 && (
          <div className={classes.unreadCounter}>
            {unreadCount > 99 ? "99+" : unreadCount}
          </div>
        )}
        {localCallContext.showTranscription ? (
          <TranscriptionOn />
        ) : (
          <TranscriptionOff />
        )}
      </Button>

      <Button
        className={clsx(classes.button, classes.buttonError)}
        onClick={handleDisconnect}
        style={{
          order: isCaller ? 3 : 4
        }}
      >
        <HangUp />
      </Button>

      {isCaller && (
        <Button
          className={clsx(classes.button, { [classes.disabled]: !Bconnected })}
          onClick={Bconnected ? toggleTranslation : null}
          style={{
            order: 5
          }}
        >
          {localCallContext.translationEnabled ? (
            <TranslationOn />
          ) : (
            <TranslationOff />
          )}
        </Button>
      )}
    </Box>
  );
}

export default CallControls;
