import React, { useContext } from "react";
import { Box } from "@material-ui/core";
import { BaseContext } from "../../../utils/baseProvider";
import AppBarTopNonAuthorized from "../../../components/AppBarTop/AppBarTopNonAuthorized";
import AppBarTop from "../../../components/AppBarTop/AppBarTop";
import BottomNavBar from "../../../components/BottomNavBar/BottomNavBar";
import useBreakpoints from "../../../utils/useBreakpoints";
import { appStore } from "../../../utils/stores/AppStore";
import { observer } from "mobx-react";

const BasePageMobileLayout = ({ children, noPaddingBottom }) => {
  const baseContext = useContext(BaseContext);
  const {
    isHd,
    isMobile,
    isSmallTablet,
    isTablet,
    isDesktop
  } = useBreakpoints();
  const showBottomNav =
    !isHd && baseContext.currentUser !== undefined && baseContext.showBottomBar;

  if (appStore.authorized === undefined) {
    return null;
  }

  let containerBottomPadding = 0;
  if (showBottomNav && !baseContext.keyboardOpen) {
    if (isMobile || isSmallTablet) {
      containerBottomPadding = 0;
      if (baseContext.showCreateCall) {
        containerBottomPadding = 0;
      }
    }
    if (isTablet || isDesktop) {
      containerBottomPadding = 0;
    }
  }

  return (
    <>
      {baseContext.showTopBar &&
        (appStore.authorized === false ? (
          <AppBarTopNonAuthorized />
        ) : (
          <AppBarTop />
        ))}
      <Box
        style={{
          display: "flex",
          flex: "1",
          flexDirection: "column",
          maxWidth: "100vw",
          overflowY: "auto"
        }}
        pb={noPaddingBottom ? 0 : containerBottomPadding}
      >
        {children}
      </Box>
      {showBottomNav && <BottomNavBar />}
    </>
  );
};
export default observer(BasePageMobileLayout);
