import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import AvatarWithFallback from "../../components/Avatar/AvatarWithFallback";
import withBottomBar from "../../components/BottomNavBar/withBottomBar";
import { Box, Button, Typography } from "@material-ui/core";
import { figmaFonts } from "../../theme";
import InputGroup from "../../components/InputGroup/InputGroup";
import FakeInputGroup from "./FakeInputGroup";
import RadioSelectGroup from "../../components/RadioSelectGroup/RadioSelectGroup";
import ModalDialog from "../../components/ModalDialog/ModalDialog";
import AvatarEdit from "./AvatarEdit";
import LanguageSelect from "../CreatelocalcallPage/components/LanguageSelect";
import useLanguages from "../../utils/hooks/useLanguages";
import PlaceholderStartAdornment from "../../components/InputGroup/components/PlaceholderStartAdornment";
import ErrorWithIcon from "../../components/InputGroup/components/ErrorWithIcon";
import SuccessWithIcon from "../../components/InputGroup/components/SuccessWithIcon";
import CopyButton from "../../components/CopyButton/CopyButton";
import useAppBarLeftContent from "../../utils/hooks/useAppBarLeftContent";
import { observer } from "mobx-react";
import { ReactComponent as EditIcon } from "./icons/editIcon.svg";
import { ReactComponent as EmailIcon } from "./icons/emailIcon.svg";

const useStyles = makeStyles(theme => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: 325,
    [theme.breakpoints.up("lg")]: {
      maxWidth: 712,
      boxSizing: "content-box"
    },
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(8)
  },
  avatarBox: {
    border: `1px solid ${theme.palette.menuLine}`,
    borderRadius: 10,
    width: "100%",
    padding: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    boxSizing: "border-box",
    marginBottom: theme.spacing(2),
    cursor: "pointer"
  },
  heading: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("lg")]: {
      marginBottom: theme.spacing(5)
    }
  },
  deleteButton: {
    color: theme.palette.text.grey,
    ...figmaFonts.mobileSmallTextRegular,
    [theme.breakpoints.up("lg")]: {
      ...figmaFonts.desktopLink
    },
    width: "100%",
    marginTop: theme.spacing(3)
  },
  form: {
    width: "100%",
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(3, 0)
    }
  },
  email: {
    color: theme.palette.text.grey,
    marginLeft: theme.spacing(1)
  }
}));

const ProfileEditPageDesktop = ({
  usernameLink,
  send,
  saveButtonContent,
  handleDeleteAvatar,
  closeEdit,
  onOpenEdit,
  onAvatarSave,
  onChange,
  errors,
  loaded,
  disabled,
  formData,
  avatarEditModalOpen,
  usernameAvailable
}) => {
  const classes = useStyles();
  useAppBarLeftContent({ title: "Settings" });
  const history = useHistory();
  const { languagesFrom } = useLanguages();

  return (
    <div className={classes.paper}>
      {loaded && (
        <Fragment>
          <Box className={classes.avatarBox}>
            <Box
              position={"relative"}
              onClick={onOpenEdit}
              height={96}
              width={96}
              mr={3}
            >
              <AvatarWithFallback
                height={96}
                width={96}
                src={formData.avatar}
              />
              <Box
                width={32}
                height={32}
                position={"absolute"}
                bottom={0}
                right={0}
              >
                <EditIcon />
              </Box>
            </Box>
            <Box>
              <Typography variant={"h1"}>{formData.name}</Typography>
              <Box mt={0.5} display={"flex"}>
                {formData.username && (
                  <Typography variant={"subtitle1"} color={"primary"}>
                    @{formData.username}
                  </Typography>
                )}

                {formData.email && (
                  <Box
                    ml={formData.username ? 3 : 0}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <EmailIcon />
                    <Typography
                      className={classes.email}
                      variant={"subtitle1"}
                      color={"secondary"}
                    >
                      {formData.email}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          {/*<StyledLink*/}
          {/*  className={classes.deleteButton}*/}
          {/*  onClick={handleDeleteAvatar}*/}
          {/*>*/}
          {/*  Delete photo*/}
          {/*</StyledLink>*/}
          <form className={classes.form} noValidate onSubmit={send}>
            <Box display={"flex"}>
              <Box width={"100%"} mr={4}>
                <InputGroup
                  id="name"
                  label="Name"
                  type="text"
                  name="name"
                  autoComplete="name"
                  value={formData.name}
                  onChange={onChange}
                />

                <LanguageSelect
                  onChange={onChange}
                  value={formData.fromLang}
                  languages={languagesFrom}
                  label="Your native language"
                  name={"fromLang"}
                />

                <InputGroup
                  id="email"
                  label="Email"
                  type="email"
                  name="email"
                  disabled
                  onChange={onChange}
                  defaultValue={formData.email}
                />

                <Button
                  type="submit"
                  fullWidth
                  size={"large"}
                  color="primary"
                  variant={"contained"}
                  disabled={disabled}
                >
                  {saveButtonContent}
                </Button>
                {/*<StyledLink*/}
                {/*  className={classes.deleteButton}*/}
                {/*  to={"/profiledelete"}*/}
                {/*>*/}
                {/*  Delete my account*/}
                {/*</StyledLink>*/}
              </Box>
              <Box width={"100%"}>
                <InputGroup
                  id="username"
                  label="Username"
                  type="text"
                  name="username"
                  error={
                    errors["username"] ||
                    (usernameAvailable === false && (
                      <ErrorWithIcon text={"Not available"} />
                    ))
                  }
                  value={formData.username}
                  helperText={
                    usernameAvailable ? (
                      <SuccessWithIcon text={"Available"} />
                    ) : null
                  }
                  onChange={onChange}
                  startAdornment={
                    <PlaceholderStartAdornment
                      enabled={formData.username}
                      symbol={"@"}
                    />
                  }
                  endAdornment={
                    usernameLink ? (
                      <CopyButton textToCopy={usernameLink} />
                    ) : null
                  }
                />

                <RadioSelectGroup
                  label={"Your voice"}
                  onChange={onChange}
                  name={"gender"}
                  value={formData["gender"]}
                  items={["Male", "Female"]}
                />

                <FakeInputGroup
                  onClick={() => {
                    history.push("/profilepasswordverifyedit");
                  }}
                />
              </Box>
            </Box>
          </form>
        </Fragment>
      )}

      <ModalDialog open={avatarEditModalOpen} handleClose={closeEdit}>
        <AvatarEdit
          saveButtonContent={saveButtonContent}
          onClose={closeEdit}
          onSave={onAvatarSave}
        />
      </ModalDialog>
    </div>
  );
};

export default withBottomBar(observer(ProfileEditPageDesktop));
