import React, { useEffect, useState } from "react";
import { Box, Typography, useMediaQuery } from "@material-ui/core";
import ItemBottom from "../ItemBottom/ItemBottom";
import { makeStyles } from "@material-ui/core/styles";
import ItemMiddle from "../ItemMiddle/ItemMiddle";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import useBreakpoints from "../../../../../../../../utils/useBreakpoints";
import theme, { palette } from "../../../../../../../../theme";
import "./ProductItem.scss";
import clsx from "clsx";
import ButtonToggle from "../../../../../../../SubscriptionPage/components/ButtonToggle";
import { ReactComponent as CheckIcon } from "./assets/check.svg";

const useStyles = makeStyles(theme => ({
  card: {
    width: "100%",
    background: `linear-gradient(0deg, ${theme.palette.background.input} 90%, #fff 20%)`,
    borderRadius: 5
  },
  carouselImg: {
    height: "100%",
    objectFit: "contain"
  },
  indicator: {
    display: "inline-block",
    opacity: 0.6,
    border: `1px solid ${theme.palette.background.default}`
  },
  selectedIndicator: {
    opacity: 1,
    border: `1px solid ${theme.palette.primary.main}`
  },
  color: {
    position: "relative",
    minWidth: 25,
    padding: "0 1px",
    height: 25,
    border: `1px solid ${theme.palette.text.default}`,
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    background: theme.palette.text.default,
    cursor: "pointer",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      width: 32,
      height: 32
    }
  },
  colorWhite: {
    background: theme.palette.background.default,
    border: `1px solid ${theme.palette.text.grey}`
  },
  colorSelected: {
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main
  },
  size: {
    border: `2px solid transparent`,
    textTransform: "uppercase"
  },
  sizeSelected: {
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main
  },
  tickIcon: {
    position: "absolute",
    display: "flex"
  },
  optionsTitle: {
    color: theme.palette.text.grey
  },
  options: {
    border: `2px solid transparent`,
    width: "auto",
    padding: theme.spacing(0.5, 1),
    whiteSpace: "nowrap"
  },
  optionsSelected: {
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main
  }
}));

function ProductItem({ item }) {
  const classes = useStyles();
  const { isMobile } = useBreakpoints();
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"));
  const [color, setColor] = useState("white");
  const sizes = ["s", "m", "l", "xl", "2xl"];
  const [size, setSize] = useState(sizes[0]);
  const [gender, setGender] = useState("male");
  const [slide, setSlide] = useState(0);
  const [showGenderToggle, setShowGenderToggle] = useState(false);
  const [option, setOption] = useState();

  useEffect(() => {
    if (item.options && item.options.options[0]) {
      setOption(item.options.options[0]);
    }
  }, [item.options]);

  const condImages = item.color;
  const images = condImages
    ? item.images[color][gender]
    : (item.images && item.images[option]) || [];

  function sliderOnChange(index) {
    setSlide(index);
  }

  function renderIndicator(clickHandler, isSelected) {
    const boxClassName = clsx(classes.indicator, {
      [classes.selectedIndicator]: isSelected
    });
    return (
      <Box
        className={boxClassName}
        border={`2px solid ${
          isSelected ? palette.primary.main : palette.background.default
        }`}
        onClick={clickHandler}
        mx={0.5}
        width={`calc(100% / ${images.length} - 8px)`}
      />
    );
  }

  function onChangeGender(e, gender) {
    if (gender) {
      setSlide(0);
      setGender(gender);
    }
  }

  function changeColor(color) {
    setColor(color);
    setSlide(0);
  }

  return (
    <Box className={classes.card}>
      <Box
        position={"relative"}
        onMouseEnter={() => setShowGenderToggle(true)}
        onMouseLeave={() => setShowGenderToggle(false)}
      >
        <Carousel
          onChange={sliderOnChange}
          selectedItem={slide}
          showThumbs={false}
          showStatus={false}
          showIndicators={images.length > 1}
          renderIndicator={renderIndicator}
          showArrows={false}
          swipeable={isMobile}
        >
          {images.map((image, index) => {
            return (
              <Box height={isDesktop ? 385 : 356} key={index}>
                <img
                  alt={"product"}
                  className={classes.carouselImg}
                  src={image}
                />
              </Box>
            );
          })}
        </Carousel>

        {images.length > 1 && !isMobile && (
          <Box
            position={"absolute"}
            top={0}
            left={0}
            display={"flex"}
            width={"100%"}
            height={"100%"}
            zIndex={3}
          >
            {images.map((image, index) => {
              return (
                <Box
                  height={"100%"}
                  key={index}
                  width={`calc(100% / ${images.length})`}
                  onMouseOver={() => setSlide(index)}
                  onClick={() => setSlide(index)}
                />
              );
            })}
          </Box>
        )}

        {item.gender && (showGenderToggle || isMobile) && (
          <Box
            height={32}
            position={"absolute"}
            bottom={34}
            left={24}
            zIndex={4}
          >
            <ButtonToggle
              onChange={onChangeGender}
              value={gender}
              items={[
                { label: "Male", value: "male" },
                { label: "Female", value: "female" }
              ]}
              small
            />
          </Box>
        )}
      </Box>

      <ItemMiddle title={item.title}>
        <Box display={"flex"} justifyContent={"space-between"}>
          {item.color && (
            <Box mt={2}>
              <Typography
                variant={"subtitle2"}
                className={classes.optionsTitle}
              >
                Color:
              </Typography>
              <Box mt={isDesktop ? 0.5 : 1} display={"flex"}>
                <Box
                  onClick={() => changeColor("white")}
                  className={clsx(classes.color, {
                    [classes.colorSelected]: color === "white",
                    [classes.colorWhite]: true
                  })}
                  mr={0.5}
                >
                  {color === "white" && (
                    <Box className={classes.tickIcon}>
                      <CheckIcon />
                    </Box>
                  )}
                </Box>
                <Box
                  onClick={() => changeColor("black")}
                  className={clsx(classes.color, {
                    [classes.colorSelected]: color === "black",
                    [classes.colorWhite]: false
                  })}
                >
                  {color === "black" && (
                    <Box className={classes.tickIcon}>
                      <CheckIcon />
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          )}
          {item.size && (
            <Box mt={2}>
              <Typography
                variant={"subtitle2"}
                className={classes.optionsTitle}
              >
                Size:
              </Typography>
              <Box mt={isDesktop ? 0.5 : 1} display={"flex"}>
                {sizes.map(letter => {
                  return (
                    <Box
                      key={letter}
                      onClick={() => setSize(letter)}
                      className={clsx(classes.color, {
                        [classes.size]: true,
                        [classes.sizeSelected]: size === letter,
                        [classes.colorWhite]: true
                      })}
                      mr={0.5}
                    >
                      <Typography variant={"subtitle2"}> {letter}</Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )}

          {item.options && (
            <Box mt={2}>
              <Typography
                variant={"subtitle2"}
                className={classes.optionsTitle}
              >
                {item.options.title}
              </Typography>
              <Box mt={isDesktop ? 0.5 : 1} display={"flex"}>
                {item.options.options.map(opt => {
                  return (
                    <Box
                      key={opt}
                      onClick={() => setOption(opt)}
                      className={clsx(classes.color, {
                        [classes.options]: true,
                        [classes.optionsSelected]: option === opt,
                        [classes.colorWhite]: true
                      })}
                      mr={0.5}
                    >
                      <Typography variant={"subtitle2"}>{opt}</Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          )}
        </Box>
      </ItemMiddle>
      <ItemBottom price={item.price} />
    </Box>
  );
}
export default ProductItem;
