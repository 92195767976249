import { withStyles } from "@material-ui/core/styles";
import { TableRow } from "@material-ui/core";

const StyledTableRow = withStyles(theme => ({
  root: {
    border: "none",
    borderBottom: `1px solid ${theme.palette.background.input}`
  }
}))(TableRow);
export default StyledTableRow;
