import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { figmaFonts } from "../../theme";
import { Box, Typography } from "@material-ui/core";
import DotAnimation from "./DotAnimation";

const useStyles = makeStyles(theme => ({
  box: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    [theme.breakpoints.up("lg")]: {
      maxWidth: "100%",
      flexDirection: "row"
    }
  },
  textSmall: {
    maxWidth: 160,
    color: theme.palette.text.grey,
    ...figmaFonts.textMedium14
  },
  dots: {
    marginRight: 0,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("lg")]: {
      marginRight: theme.spacing(4),
      marginBottom: 0
    }
  }
}));

function WaitForConnectionTitle({ forCallee, small }) {
  const classes = useStyles();

  let text = <>Waiting for&nbsp;connection...</>;
  if (forCallee) {
    text = "Waiting connection...";
  }

  return (
    <Box className={classes.box} alignItems={small ? "left" : "center"}>
      <Box className={classes.dots}>
        <DotAnimation size={small ? 9 : 13} />
      </Box>

      <Typography className={small ? classes.textSmall : ""} variant="h1">
        {text}
      </Typography>
    </Box>
  );
}

export default WaitForConnectionTitle;
