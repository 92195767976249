import React, { useState } from "react";
import { Box, Button, Paper, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as WarnIcon } from "../icons/warnIcon.svg";
import { figmaFonts, palette } from "../../../theme";
import clsx from "clsx";
import withRequest from "../../../utils/withRequest";
import useStripePortalUrl from "../../../utils/useStripePortalUrl";
import ModalDialog from "../../../components/ModalDialog/ModalDialog";
import SwitchToYearlyModal from "./components/SwitchToYearlyModal";
import IconBadge from "../../../components/IconBadge";
import SavingsBadge from "./components/SavingsBadge";
import { SubscriptionCost } from "./components/SubscriptionCost";
import { CurrentPlan } from "./components/CurrentPlan";
import RenewCancelBox from "./components/RenewCancelBox";

const useStyles = makeStyles(theme => ({
  paper: {
    borderTop: "4px solid " + theme.palette.background.green,
    borderRadius: 0
  },
  switchButton: {
    ...figmaFonts.desktopCapsBtn14
  },
  noteText: {
    ...figmaFonts.mobileSmallTextMedium,
    [theme.breakpoints.up("lg")]: {
      ...figmaFonts.desktopSmallTextMedium
    }
  },
  noteTextLink: {
    cursor: "pointer"
  }
}));

function SubscribedContent({ currentUser, postDataWithCallback, desktopMode }) {
  const classes = useStyles();
  const portalUrl = useStripePortalUrl({ postDataWithCallback });
  const [switchToYearlyModalOpen, setSwitchToYearlyModalOpen] = useState(false);
  const currentPlanPeriod = currentUser.perYear === "0" ? "month" : "year";

  function renderPlanPaper() {
    const planPeriodText = currentPlanPeriod === "year" ? "yearly" : "monthly";
    const planPrice =
      currentPlanPeriod === "year" ? currentUser.perYear : currentUser.perMonth;

    if (desktopMode) {
      return (
        <Paper className={classes.paper}>
          <Box display={"flex"} pt={2.5} pb={3}>
            <Box
              pl={5}
              width={"50%"}
              borderRight={`1px solid ${palette.background.input}`}
            >
              <CurrentPlan
                desktopMode={desktopMode}
                planPeriodText={planPeriodText}
              />

              {currentPlanPeriod === "month" && (
                <SavingsBadge desktopMode={desktopMode} />
              )}
            </Box>

            <Box pl={5} width={"50%"}>
              <SubscriptionCost
                planPrice={planPrice}
                currentPlanPeriod={currentPlanPeriod}
                desktopMode={desktopMode}
              />

              {currentPlanPeriod === "month" && (
                <Box mt={4.125}>
                  <Button
                    className={classes.switchButton}
                    variant={"contained"}
                    color={"primary"}
                    onClick={() => setSwitchToYearlyModalOpen(true)}
                  >
                    switch to yearly
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Paper>
      );
    } else {
      return (
        <Paper className={classes.paper}>
          <Box display={"flex"} flexDirection={"column"} pt={2.5} pb={3} px={3}>
            <CurrentPlan
              desktopMode={desktopMode}
              planPeriodText={planPeriodText}
            />

            <Box mt={3}>
              <SubscriptionCost
                planPrice={planPrice}
                currentPlanPeriod={currentPlanPeriod}
              />
            </Box>

            {currentPlanPeriod === "month" && (
              <Box mt={3} display={"flex"} justifyContent={"center"}>
                <Button
                  className={classes.switchButton}
                  variant={"contained"}
                  color={"primary"}
                  onClick={() => setSwitchToYearlyModalOpen(true)}
                >
                  switch to yearly
                </Button>
              </Box>
            )}
            {currentPlanPeriod === "month" && (
              <SavingsBadge desktopMode={desktopMode} />
            )}
          </Box>
        </Paper>
      );
    }
  }

  function openStripePortal() {
    window.open(portalUrl, "_self", "noopener noreferrer");
  }

  return (
    <Box
      pt={desktopMode ? 5 : 3}
      pl={desktopMode ? 8 : 3.125}
      pr={desktopMode ? 0 : 3.125}
      width={"100%"}
      maxWidth={desktopMode ? 583 : 325}
      boxSizing={"content-box"}
      mx={desktopMode ? 0 : "auto"}
    >
      {renderPlanPaper()}
      {
        <RenewCancelBox
          currentUser={currentUser}
          desktopMode={desktopMode}
          onClick={openStripePortal}
        />
      }
      <Box mt={desktopMode ? 4 : 3}>
        <IconBadge icon={<WarnIcon />}>
          <Typography display={"inline"} className={classes.noteText}>
            Note: do not cancel your subscription if you just want to&nbsp;
            <Typography
              component={"span"}
              display={"inline"}
              onClick={openStripePortal}
              className={clsx(classes.noteText, classes.noteTextLink)}
              color={"primary"}
            >
              change payment method
            </Typography>
          </Typography>
        </IconBadge>
      </Box>

      <ModalDialog
        open={switchToYearlyModalOpen}
        handleClose={() => setSwitchToYearlyModalOpen(false)}
      >
        <SwitchToYearlyModal
          desktopMode={desktopMode}
          currentPlanPeriod={currentPlanPeriod}
          postDataWithCallback={postDataWithCallback}
        />
      </ModalDialog>
    </Box>
  );
}

export default withRequest(SubscribedContent);
