import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import BasePage from "../../BasePage/BasePage";
import withRequest from "../../../utils/withRequest";
import withTopNavigation from "../../../components/AppBarTop/TopNavigation/withTopNavigation";
import { TopNavigationItems } from "../../../components/AppBarTop/TopNavigation/TopNavigation";
import { Box, CardMedia } from "@material-ui/core";
import { Prompt, useHistory } from "react-router-dom";
import TranscriptionContainer from "../../LocalCallPage/Transcription/TranscriptionContainer";
import useBreakpoints from "../../../utils/useBreakpoints";
import { makeStyles } from "@material-ui/core/styles";
import PhoneCallAnimatedLogo from "./components/PhoneCallAnimatedLogo/components/PhoneCallAnimatedLogo";
import PhoneCallControls from "./components/PhoneCallControls/PhoneCallControls";
import PhoneCallDialer from "../components/PhoneCallDialer/PhoneCallDialer";
import { formatElapsed } from "../../../utils/time";
import { BaseContext } from "../../../utils/baseProvider";
import { Tone } from "./Tone";
import CallBackControls from "./components/CallBackControlls/CallBackControlls";
import SettingsModal from "../../../components/CallSettings/SettingsModal";
import EventEmitter from "../../../utils/events/EventEmitter";
import PHONE_CALL_EVENTS from "../../../utils/events/PhoneCallEvents";
import useLanguages from "../../../utils/hooks/useLanguages";
import PhoneCallCreateSettingsBadge from "../PhoneCallCreatePage/PhoneCallCreateSettingsBadge/PhoneCallCreateSettingsBadge";
import PhoneCallTopBarDesktop from "../components/PhoneCallTopBar/PhoneCallTopBarDesktop";
import useAppBarLeftContent from "../../../utils/hooks/useAppBarLeftContent";
import UsdBalanceChipDark from "../../../components/chips/UsdBalanceChipDark";
import PhoneCallTopBar from "../components/PhoneCallTopBar/PhoneCallTopBar";

const useStyles = makeStyles(theme => ({
  container: { width: "100%", display: "flex", minHeight: "100%" },
  gridContainer: {
    margin: 0,
    display: "flex",
    flexDirection: "column"
  },
  gridItem: {
    maxHeight: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative"
  },
  controlsContainer: {
    backgroundColor: theme.palette.primary.main,
    height: 64,
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3.125),
    paddingRight: theme.spacing(3.125),
    display: "flex",
    justifyContent: "space-between"
  },
  formBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "384px"
  },
  heading: {
    textAlign: "center",
    margin: theme.spacing(3, 0),
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(5, 0)
    }
  }
}));

function PhoneCallPage({ postDataWithCallback }) {
  const classes = useStyles();
  const history = useHistory();
  const baseContext = useContext(BaseContext);
  const { isDesktop } = useBreakpoints();
  const [id, setId] = useState(null);
  const [ready, setReady] = useState(false);
  const [ice, setIce] = useState(false);
  const [number, setNumber] = useState("");
  const [additionalNumber, setAdditionalNumber] = useState("");
  const [audioEnabled, setAudioEnabled] = useState(true);
  const [showTranscription, setShowTranscription] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [callTimeouted, setCallTimeouted] = useState(false);
  const [elapsed, setElapsed] = useState(0);
  const [messages, setMessages] = useState([]);
  const [dialerOpened, setDialerOpened] = useState(false);
  const [status, setStatus] = useState();
  const [returnToDial, setReturnToDial] = useState(false);
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);
  const [callSettings, setCallSettings] = useState();
  const localStreamRef = useRef();
  const audioRemoteRef = useRef();
  const dcRef = useRef();
  const pcRef = useRef();
  const { getLanguageByKey } = useLanguages();
  const [translationEnabled, setTranslationEnabled] = useState(true);

  useAppBarLeftContent({ title: "Create a phone call" });

  const ringtoneRef = useRef(new Tone(400, 450));

  const callBackStatuses = useMemo(() => ["No answer", "Busy", "Error"], []);

  const connected = useMemo(() => {
    return status === "Confirmed";
  }, [status]);

  const toggleSettingsModal = useCallback(() => {
    setSettingsModalOpen(prevState => !prevState);
  }, []);

  useEffect(() => {
    EventEmitter.on(PHONE_CALL_EVENTS.toggleSettings, toggleSettingsModal);
    const { phoneNumber, audioEnabled } = history.location.state;
    setNumber(phoneNumber);
    setAudioEnabled(audioEnabled);
    initWebrtc();

    return () => {
      EventEmitter.off(PHONE_CALL_EVENTS.toggleSettings, toggleSettingsModal);
      handleDisconnect();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (baseContext.currentUser && !callSettings) {
      // TODO: change gender to toGender
      const { to, from, gender, toGender, fromGender } = history.location.state;
      setCallSettings({
        fromGender: fromGender || baseContext.currentUser.gender || "Male",
        toGender: gender || toGender,
        to,
        from
      });
    }
  }, [baseContext.currentUser, callSettings, history.location.state]);

  useEffect(() => {
    if (isDesktop) {
      setShowTranscription(true);
    }
  }, [isDesktop]);

  useEffect(() => {
    if (ready) {
      call();
    }
    // eslint-disable-next-line
  }, [ready]);

  const call = () => {
    ringtoneRef.current.startRinging();
    const { phoneNumber, from, to, gender } = history.location.state;
    connect(
      phoneNumber,
      from,
      to,
      gender
    );
  };

  useEffect(() => {
    if (ice && id !== null) {
      sendAnswer();
    }
    // eslint-disable-next-line
  }, [ice, id]);

  useEffect(() => {
    if (callEnded && returnToDial) {
      const { phoneNumber, from, to, gender } = history.location.state;
      history.push("/phonecallcreate", {
        phoneNumber,
        from,
        to,
        gender
      });
    } else if (callEnded && (connected || status === "Terminated")) {
      history.push("/ratecall", {
        remoteid: id,
        type: "phone",
        elapsed,
        ...history.location.state,
        ...callSettings
      });
    } else if (callEnded && !callTimeouted) {
      history.push("/");
    } else if (callTimeouted) {
      history.push("/linkTimeout");
    }
    // eslint-disable-next-line
  }, [callEnded, callTimeouted, connected, status]);

  function initWebrtc() {
    let localPc = new RTCPeerConnection({
      iceServers: [
        {
          urls: "stun:web.yous.ai:33478"
        }
      ]
    });

    localPc.onsignalingstatechange = e => {
      log(`signalling: ${localPc.signalingState}`);
    };

    localPc.onicegatheringstatechange = () => {
      log(`onicegatheringstatechange: ${localPc.iceGatheringState}`);
    };

    localPc.oniceconnectionstatechange = e => {
      log(`connection: ${localPc.iceConnectionState}`);
    };

    localPc.ondatachannel = e => {
      let dc = e.channel;
      dcRef.current = dc;
      dc.onclose = () => {
        log("dc has closed");
      };
      dc.onopen = () => log("dc has opened");
      dc.onmessage = e => {
        handleDCMessage(e.data);
      };
    };

    localPc.ontrack = function(event) {
      if (event.track.kind === "audio") {
        audioRemoteRef.current.srcObject = new MediaStream(
          event.streams[0].getAudioTracks()
        );
      }
    };

    navigator.mediaDevices
      .getUserMedia({
        video: false,
        audio: true
      })
      .then(stream => {
        stream.getTracks().forEach(track => {
          localPc.addTrack(track, stream);
        });
        localStreamRef.current = stream;
        setReady(true);
      })
      .catch(log);

    pcRef.current = localPc;
  }

  function connect(phoneNumber, from, to, gender) {
    let off = {};
    // TODO: remove test data
    off.number = phoneNumber === "+111" ? "111" : phoneNumber;
    off.from = from;
    off.to = to;
    off.gender = gender;

    pcRef.current.onicecandidate = event => {
      console.log("candidate: ", event.candidate);
      setIce(true);
    };

    postDataWithCallback("/api/sfucalloffer", off, data => {
      pcRef.current
        .setRemoteDescription(new RTCSessionDescription(data.sdp))
        .catch(log);
      pcRef.current
        .createAnswer()
        .then(d => pcRef.current.setLocalDescription(d))
        .catch(log);
      setId(data.id);
    });
  }

  function sendAnswer() {
    let ans = {};
    ans.sdp = pcRef.current.localDescription;
    ans.id = id;
    postDataWithCallback("/api/sfucallanswer", ans, data => {
      log(data);
    });
  }

  function log(data) {
    console.log(data);
  }

  function handleDCMessage(data) {
    const ob = JSON.parse(data);

    switch (ob.type) {
      case "elapsed":
        setElapsed(ob.text);
        break;
      case "elapsedSecondsBalance":
        baseContext.updateUserBalance(ob.text);
        break;
      case "translatedText":
      case "originalText":
      case "translation":
      case "original":
        setMessages(oldArray => [
          ...oldArray,
          { ...ob, timestamp: new Date().getTime() }
        ]);
        break;
      case "control":
        if (ob.text === "timeout") {
          setCallTimeouted(true);
        }
        break;
      case "clientControl":
        if (ob.payload) {
          // TODO: set callee audio disabled ob.payload.audio || ignore
        }
        break;
      case "status":
        setStatus(ob.text);
        if (["Confirmed", ...callBackStatuses].includes(ob.text)) {
          ringtoneRef.current.stopRinging();
        }
        break;
      case "translationEnabled":
        setTranslationEnabled(ob.text === "enabled");
        break;
      case "reason":
        if (callBackStatuses.includes(ob.text)) {
          ringtoneRef.current.stopRinging();
          setStatus(ob.text);
          setDialerOpened(false);
          setShowTranscription(false);
        } else {
          switch (ob.text) {
            case "Answered":
            default:
              setCallEnded(true);
          }
        }

        break;
      default:
        log(ob);
    }
  }

  function handleDisconnect() {
    if (pcRef.current) {
      pcRef.current.close();
    }

    if (localStreamRef.current) {
      localStreamRef.current.getTracks().forEach(track => {
        track.stop();
      });
    }

    setCallEnded(true);
    ringtoneRef.current.stopRinging();
  }

  function toggleAudio() {
    const prevState = audioEnabled;
    if (localStreamRef.current) {
      localStreamRef.current.getAudioTracks()[0].enabled = !prevState;
    }
    setAudioEnabled(!prevState);
  }

  function toggleDialer() {
    setDialerOpened(prevState => !prevState);
  }

  const onBackSpace = useCallback(() => {
    if (dialerOpened) {
      setAdditionalNumber(prevState => prevState.slice(0, -1));
    } else {
      setNumber(prevState => prevState.slice(0, -1));
    }
  }, [dialerOpened]);

  const onDialerButtonClick = useCallback(
    symbol => {
      if (dialerOpened) {
        setAdditionalNumber(prevState => prevState + symbol);
      } else {
        if (symbol !== "#" && symbol !== "*") {
          setNumber(prevState => prevState + symbol);
        }
      }
    },
    [dialerOpened]
  );

  const callStatusTitle = useMemo(() => {
    if (callBackStatuses.includes(status)) {
      return status;
    }

    switch (status) {
      case "Confirmed":
        return formatElapsed(elapsed);
      default:
        return "Calling...";
    }
  }, [status, callBackStatuses, elapsed]);

  const recall = () => {
    setStatus("");
    setReady(false);
    setIce(false);
    initWebrtc();
  };

  const cancelCall = () => {
    setReturnToDial(true);
    handleDisconnect();
  };

  function sendMessageToDC(type, payload) {
    if (dcRef.current && dcRef.current.readyState === "open") {
      let message = payload;
      if (type !== "message") {
        message = JSON.stringify({
          type: type,
          payload
        });
      }

      dcRef.current.send(message);
    }
  }

  const onSettingsSave = useCallback(
    formData => {
      setCallSettings({ ...callSettings, ...formData });
      sendMessageToDC("translationConfig", formData);
      EventEmitter.emit(PHONE_CALL_EVENTS.toggleSettings);
    },
    [callSettings]
  );

  const toggleTranslation = useCallback(() => {
    sendMessageToDC("translationEnabled", {
      enabled: !translationEnabled
    });
    setTranslationEnabled(!translationEnabled);
  }, [translationEnabled]);

  const langTo = useMemo(() => {
    return getLanguageByKey(callSettings?.to);
  }, [callSettings, getLanguageByKey]);

  return (
    <BasePage noPaddingBottom>
      <Prompt
        when={!callEnded && !callTimeouted}
        message={`Are you sure you want to end call?`}
      />
      <Box className={classes.container}>
        <Box
          width={showTranscription && isDesktop ? "67%" : "100%"}
          pt={isDesktop ? 8 : 0}
          className={classes.gridContainer}
        >
          {!isDesktop ? (
            <Box className={classes.controlsContainer}>
              <UsdBalanceChipDark />
              <PhoneCallCreateSettingsBadge
                onClick={() => setSettingsModalOpen(true)}
                toCode={langTo.Country}
                toName={langTo.DisplayLang}
                backgroundColor={"#484ECA"}
                height={24}
                mx={0}
              />
            </Box>
          ) : (
            <Box mb={2} display={"flex"} alignItems={"center"} mx={"auto"}>
              <PhoneCallCreateSettingsBadge
                onClick={() => setSettingsModalOpen(true)}
                toCode={langTo.Country}
                toName={langTo.DisplayLang}
              />
            </Box>
          )}

          {isDesktop ? (
            <PhoneCallTopBarDesktop
              title={(dialerOpened ? additionalNumber : number) || " "}
              statusText={callStatusTitle}
            />
          ) : (
            <PhoneCallTopBar
              title={(dialerOpened ? additionalNumber : number) || " "}
              statusText={callStatusTitle}
              isMobileMode={true}
            />
          )}

          {dialerOpened ? (
            <Box mt={4} mb={isDesktop ? 5 : 2}>
              <PhoneCallDialer
                onBackspace={onBackSpace}
                onButtonClick={onDialerButtonClick}
                onCallClick={handleDisconnect}
                callHangup={true}
                onHide={() => setDialerOpened(false)}
              />
            </Box>
          ) : (
            (isDesktop || callBackStatuses.includes(status)) && (
              <Box my={0.75}>
                <PhoneCallAnimatedLogo
                  animated={!connected && !callBackStatuses.includes(status)}
                />
              </Box>
            )
          )}

          {callBackStatuses.includes(status) ? (
            <Box>
              <CallBackControls recall={recall} cancel={cancelCall} />
            </Box>
          ) : (
            <PhoneCallControls
              audioEnabled={audioEnabled}
              onMicToggle={toggleAudio}
              onHangUp={handleDisconnect}
              toggleTranslation={toggleTranslation}
              translationEnabled={translationEnabled}
              transcriptionEnabled={showTranscription}
              toggleTranscription={() =>
                setShowTranscription(prevState => !prevState)
              }
              onDialerOpen={connected ? toggleDialer : null}
              dialerOpened={dialerOpened}
            />
          )}
        </Box>

        {showTranscription &&
          (isDesktop ? (
            <Box width={"33%"} className={classes.gridItem}>
              <TranscriptionContainer
                toggleTranscription={() =>
                  setShowTranscription(prevState => !prevState)
                }
                showTranscription={showTranscription}
                Bconnected={connected}
                messages={messages}
                translationEnabled={translationEnabled}
                isPhoneCall={true}
              />
            </Box>
          ) : (
            <TranscriptionContainer
              toggleTranscription={() =>
                setShowTranscription(prevState => !prevState)
              }
              showTranscription={showTranscription}
              Bconnected={connected}
              messages={messages}
              translationEnabled={translationEnabled}
              isPhoneCall={true}
            />
          ))}
        <CardMedia
          component="audio"
          ref={audioRemoteRef}
          autoPlay={true}
          playsInline={true}
        />
      </Box>

      {callSettings && (
        <SettingsModal
          open={settingsModalOpen}
          onClose={() => setSettingsModalOpen(false)}
          onSettingsSave={onSettingsSave}
          isCaller={true}
          callSettings={callSettings}
        />
      )}
    </BasePage>
  );
}

export default withTopNavigation(withRequest(PhoneCallPage), {
  left: TopNavigationItems.settings,
  right: TopNavigationItems.usdBalance
});
