import { makeAutoObservable, runInAction } from "mobx";

import { chatsStore } from "./ChatsStore";
import { appStore } from "./AppStore";
import apiRoutes from "../api/apiRoutes";
import { api } from "../api/api";

export class ChatStore {
  constructor(chat) {
    this.chat = chat;
    makeAutoObservable(this);
  }

  isLoading = false;
  message = "";
  error = undefined;
  chat = undefined;
  messages = [];
  replyFor = undefined;

  onReply = id => {
    this.replyFor = id;
  };

  setMessage = value => {
    this.message = value;
  };

  sendMessage = async () => {
    console.log("ChatStore", "sendMessage");
    const trimmedMessage = this.message.trim();
    if (trimmedMessage) {
      this.message = "";
      const params = {
        toId: this.chat.remoteId,
        text: trimmedMessage
      };
      if (this.replyFor) {
        params.replyFor = this.replyFor;
      }
      this.onReply(); // clear reply
      await api.postDataWithCallback(apiRoutes.sendMessage, params);
    }
  };

  onNewMessage = async ({ message, chat }) => {
    console.log(
      JSON.stringify(
        {
          title: "[ChatStore.onNewMessage]",
          message: message,
          localChat: this.chat,
          newChat: chat
        },
        undefined,
        2
      )
    );

    if (this.chat.id && chat.id !== this.chat.id) return;
    if (chat?.id !== message.chatId) return;

    this.chat = {
      ...this.chat,
      ...chat,
      lastMessage: { ...message },
      lastRead: message.id
    };
    chatsStore.setRead(message.chatId, message.id);
    this.messages.unshift(message);
  };

  getMessageById = id => {
    // TODO: fetch if not found
    return this.messages.find(msg => msg.id === id);
  };

  fetchMessages = async () => {
    try {
      const id = this.chat.id;
      if (!id) return false;
      if (this.chat.messages?.length > 0) {
        this.messages = [...this.chat.messages];
      }
      console.log("[ChatStore.fetchMessages]", this.chat.messages);
      if (this.messages.length === 0) appStore.setLoading(true);
      this.isLoading = true;
      const response = await api.postDataWithCallback(apiRoutes.getMessages, {
        id
      });

      return runInAction(() => {
        this.isLoading = false;
        console.log("[ChatStore.fetchChats]", response);
        this.chat = { ...response, translationEnabled: true };
        this.messages = response.messages;
        response.lastMessage = this.messages.find(
          msg => msg.id === response.lastMessageId
        );
        chatsStore.replaceChat({ ...response, translationEnabled: true });
        if (this.messages && this.messages[0]) {
          chatsStore.setRead(id, this.messages[0].id);
        }
        return true;
      });
    } finally {
      appStore.setLoading(false);
    }
  };

  clear = () => {};
}
