import React from "react";
import BasePage from "../BasePage/BasePage";
import { Box, Typography } from "@material-ui/core";
import icon from "./icons/paySuccessIcon.svg";
import useBreakpoints from "../../utils/useBreakpoints";
import withBottomBar from "../../components/BottomNavBar/withBottomBar";
import useAppBarLeftContent from "../../utils/hooks/useAppBarLeftContent";
import { FullHeightContainerWithCenteredContent } from "../../components/layouts";

function PaySuccessPage() {
  const { isDesktop, isMobile } = useBreakpoints();
  const title = "Status Payment";
  useAppBarLeftContent({ title: isDesktop && title });

  return (
    <BasePage>
      <FullHeightContainerWithCenteredContent
        disableCentering={isMobile}
        pt={5}
      >
        {!isDesktop && (
          <Box mt={5} mb={5}>
            <Typography align={"center"} variant={"h1"}>
              {title}
            </Typography>
          </Box>
        )}
        <Box mx={"auto"} maxWidth={isDesktop ? 280 : 200}>
          <img
            style={{ width: "100%" }}
            src={icon}
            alt={"The payment was successful"}
          />
        </Box>
        <Box textAlign={"center"} mt={5} mb={5}>
          <Typography noWrap={false} variant={"h2"}>
            Congratulations!
          </Typography>
          <Typography noWrap={false} variant={"h2"}>
            The payment was successful.
          </Typography>
        </Box>
      </FullHeightContainerWithCenteredContent>
    </BasePage>
  );
}

export default withBottomBar(PaySuccessPage);
