import React, { useContext, useEffect, useState } from "react";
import BasePage from "../BasePage/BasePage";
import { BaseContext } from "../../utils/baseProvider";
import { Redirect, useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import LanguageSelect from "./components/LanguageSelect";
import withBottomBar from "../../components/BottomNavBar/withBottomBar";
import withTopNavigation from "../../components/AppBarTop/TopNavigation/withTopNavigation";
import { TopNavigationItems } from "../../components/AppBarTop/TopNavigation/TopNavigation";
import { Box, Button, Typography } from "@material-ui/core";
import BalanceEmptyModal from "../../components/BalanceEmptyModal/BalanceEmptyModal";
import { getCountryCodeByKey } from "../../utils/countriesHelper";
import useLanguages from "../../utils/hooks/useLanguages";
import useAppBarLeftContent from "../../utils/hooks/useAppBarLeftContent";
import useBreakpoints from "../../utils/useBreakpoints";
import TranscriptionPlaceholder from "../LocalCallPage/Transcription/TranscriptionPlaceholder";
import { appStore } from "../../utils/stores/AppStore";
import { observer } from "mobx-react";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    width: "100%"
  },
  formBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "384px",
    margin: "auto",
    [theme.breakpoints.up("lg")]: {
      margin: 0
    }
  },
  heading: {
    textAlign: "center",
    margin: theme.spacing(3, 0),
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(5, 0)
    }
  }
}));

function CreatelocalcallPage() {
  const history = useHistory();
  const classes = useStyles();
  const baseContext = useContext(BaseContext);
  const { isDesktop } = useBreakpoints();

  const {
    languagesFrom,
    languagesTo,
    selectedFrom,
    selectedTo
  } = useLanguages();
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [balanceEmptyModalOpen, setBalanceEmptyModalOpen] = useState(false);
  const [emptyModalType, setEmptyModalType] = useState();
  useAppBarLeftContent({ title: "Create an online meeting" });

  useEffect(() => {
    if (selectedFrom && selectedTo) {
      setFrom(selectedFrom);
      setTo(selectedTo);
    }
    // eslint-disable-next-line
  }, [selectedFrom, selectedTo]);

  function handleFromChange(event) {
    setFrom(event.target.value);
    if (event.target.value === to) {
      setTo("");
    }
  }

  function handleToChange(event) {
    setTo(event.target.value);
  }

  function handleClickCreate() {
    if (!baseContext.currentUser) {
      return;
    }

    if (
      baseContext.currentUser.plan === "plus" &&
      baseContext.currentUser.secondsBalance === 0 &&
      baseContext.centsBalance === 0
    ) {
      setEmptyModalType("notEnoughMinutes");
      setBalanceEmptyModalOpen(true);
      return;
    }

    if (
      baseContext.currentUser.plan === "basic" &&
      baseContext.currentUser.secondsBalance === 0
    ) {
      setEmptyModalType("switchToPlusMinutes");
      setBalanceEmptyModalOpen(true);
      return;
    }

    const fromCode = getCountryCodeByKey(from, languagesFrom);
    const toCode = getCountryCodeByKey(to, languagesTo);
    history.push({
      pathname: "/localcall",
      state: { from: from, to: to, fromCode, toCode }
    });
  }

  if (appStore.authorized === false) {
    return <Redirect to="/" />;
  }

  const containerClassname = isDesktop ? classes.container : "";
  return (
    <BasePage>
      <Box height={"100%"} className={containerClassname}>
        <Box
          pl={isDesktop ? 8 : 3.125}
          pr={isDesktop ? 0 : 3.125}
          pt={isDesktop ? 5 : 0}
          width={isDesktop ? "67%" : "100%"}
        >
          {!isDesktop && (
            <Typography className={classes.heading} variant="h1">
              Create an online meeting
            </Typography>
          )}

          <Box className={classes.formBox}>
            <LanguageSelect
              onChange={handleFromChange}
              value={from}
              languages={languagesFrom}
              label="Your native language"
            />
            <LanguageSelect
              onChange={handleToChange}
              value={to}
              languages={languagesTo}
              label="Target language"
            />
            <Button
              key={"disabled"}
              variant="contained"
              fullWidth
              color="primary"
              size="large"
              onClick={handleClickCreate}
              disabled={!to || !from}
            >
              START
            </Button>
          </Box>
        </Box>
        {isDesktop && (
          <Box width={"33%"}>
            <TranscriptionPlaceholder />
          </Box>
        )}
      </Box>

      {baseContext.currentUser && (
        <BalanceEmptyModal
          type={emptyModalType}
          open={balanceEmptyModalOpen}
          handleClose={() => setBalanceEmptyModalOpen(false)}
        />
      )}
    </BasePage>
  );
}

export default withTopNavigation(
  withBottomBar(observer(CreatelocalcallPage), false),
  {
    left: TopNavigationItems.backButton,
    right: TopNavigationItems.balance
  }
);
