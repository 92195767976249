import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  BillingIcon,
  HistoryIcon,
  InviteFriendsIcon,
  LogoutIcon,
  MeetingsIcon,
  MeetingsIconPrimary,
  MessagesIcon,
  PhoneCallsIcon,
  PhoneCallsIconPrimary,
  SettingsIcon,
  SubscriptionsIcon
} from "./icons";
import { Box } from "@material-ui/core";
import BasePageDrawerMenuButton from "./BasePageDrawerMenuButton";
import { BaseContext } from "../../../../../utils/baseProvider";
import useRequest from "../../../../../utils/hooks/useRequest";
import { useHistory } from "react-router-dom";
import { chatsStore } from "../../../../../utils/stores/ChatsStore";
import { observer } from "mobx-react";

const BasePageDrawerMenu = ({ expanded }) => {
  const history = useHistory();
  const baseContext = useContext(BaseContext);
  const { postDataWithCallback } = useRequest();
  const [url, setUrl] = useState();

  useEffect(() => {
    if (baseContext.currentUser && baseContext.currentUser.plan === "plus") {
      postDataWithCallback("/api/stripe/portal", {}, data => {
        setUrl(data.url);
      });
    }
    // eslint-disable-next-line
  }, [baseContext.currentUser]);

  const menuItems = [
    {
      title: "Messages",
      icon: <MessagesIcon />,
      path: "/messages",
      additionalPath: "/",
      counter: chatsStore.totalUnreadMessages
    },
    {
      title: "History",
      icon: <HistoryIcon />,
      path: "/history"
    },
    {
      title: "Subscription",
      icon: <SubscriptionsIcon />,
      path: "/subscription"
    },
    {
      title: "Billing",
      icon: <BillingIcon />,
      path: "/billing",
      onClick: () => {
        if (url) {
          window.open(url, "_self", "noopener noreferrer");
        } else {
          history.push("/billing");
        }
      }
    },
    {
      title: "Invite friends",
      icon: <InviteFriendsIcon />,
      path: "/bonus"
    },
    {
      title: "Settings",
      icon: <SettingsIcon />,
      path: "/profileedit"
    }
  ];

  const menuItemsCalls = useMemo(() => {
    return [
      {
        title: "Meetings",
        icon: expanded ? <MeetingsIcon /> : <MeetingsIconPrimary />,
        path: "/createlocalcall",
        active: true
      },
      {
        title: "Phone Calls",
        icon: expanded ? <PhoneCallsIcon /> : <PhoneCallsIconPrimary />,
        path: "/phonecallcreate",
        active: true
      }
    ];
  }, [expanded]);

  const logOutItem = {
    title: "Logout",
    icon: <LogoutIcon />,
    path: "/logout"
  };

  return (
    <>
      <Box mb={2}>
        {menuItemsCalls.map(item => {
          return (
            <Box key={item.path} mb={1}>
              <BasePageDrawerMenuButton expanded={expanded} item={item} />
            </Box>
          );
        })}
      </Box>

      {menuItems.map(item => {
        return (
          <Box key={item.path} mb={1}>
            <BasePageDrawerMenuButton expanded={expanded} item={item} />
          </Box>
        );
      })}

      <Box mt={"auto"} pb={"env(safe-area-inset-bottom)"}>
        <BasePageDrawerMenuButton expanded={expanded} item={logOutItem} />
      </Box>
    </>
  );
};

export default observer(BasePageDrawerMenu);
