import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    backgroundColor: theme.palette.background.default,
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
    zIndex: 2,
    position: "relative"
  },
  containerDesktop: {
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.07)",
    borderRadius: 5
  },
  title: {
    whiteSpace: "pre"
  }
}));

function ItemMiddle({ title, children = null, isDesktop }) {
  const classes = useStyles();
  const containerClassName = clsx(classes.container, {
    [classes.containerDesktop]: isDesktop
  });

  return (
    <Box p={3} className={containerClassName}>
      <Typography className={classes.title} variant={"body1"}>
        {title}
      </Typography>
      <Box>{children}</Box>
    </Box>
  );
}

export default ItemMiddle;
