import { makeAutoObservable } from "mobx";

export default class LanguagesStore {
  selectedFrom = null;
  languagesFrom = [];
  selectedTo = null;
  languagesTo = [];

  constructor() {
    makeAutoObservable(this);
  }

  setLanguagesFrom = (languages, selectedCode) => {
    this.languagesFrom = languages;
    this.selectedFrom = selectedCode;
  };

  setLanguagesTo = (languages, selectedCode) => {
    this.languagesTo = languages;
    this.selectedTo = selectedCode;
  };

  setSelectedFormLanguageKey = selectedFrom => {
    this.selectedFrom = selectedFrom;
  };
}
