import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import BasePage from "./BasePage/BasePage";
import { makeStyles } from "@material-ui/core/styles";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import withRequest from "../utils/withRequest";
import InputGroup from "../components/InputGroup/InputGroup";
import { Button, Typography } from "@material-ui/core";
import withBottomBar from "../components/BottomNavBar/withBottomBar";
import useAppBarLeftContent from "../utils/hooks/useAppBarLeftContent";
import useBreakpoints from "../utils/useBreakpoints";
import { appStore } from "../utils/stores/AppStore";
import { observer } from "mobx-react";

const useStyles = makeStyles(theme => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "384px",
    width: "100%",
    paddingBottom: theme.spacing(3),
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(8)
  },
  heading: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("lg")]: {
      marginBottom: theme.spacing(3)
    }
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("lg")]: {
      marginTop: theme.spacing(4)
    }
  }
}));

function ProfilePasswordeditPage(props) {
  const classes = useStyles();
  const history = useHistory();
  let location = useLocation();
  const prevFormData = location.state || {};
  const [formData, setFormData] = useState({ old: "", new: "", confirm: "" });
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState("");
  const { isDesktop } = useBreakpoints();
  useAppBarLeftContent({ title: "New password", showBackButton: true });

  useEffect(() => {
    checkDisabled();
    // eslint-disable-next-line
  }, [formData]);

  function onChange(event) {
    setError("");
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  }

  function checkDisabled() {
    if (
      formData.old.length < 5 ||
      formData.new.length < 8 ||
      formData.new !== formData.confirm
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }

  const onError = useCallback(() => {
    setError("Wrong password");
  }, []);

  function send(event) {
    event.stopPropagation();
    event.preventDefault();

    let params = {
      old: formData.old,
      code: parseInt(prevFormData.code, 10),
      new: formData.new
    };

    props.postDataWithCallback(
      "/api/userpasswordedit",
      params,
      data => {
        if (data === "ok") {
          history.push({ pathname: "/logout", state: { fromChange: true } });
        }
      },
      onError
    );
  }

  const notTheSameError = useMemo(() => {
    return (
      (formData.confirm || formData.new) &&
      formData.new !== formData.confirm &&
      "Password is not the same"
    );
  }, [formData.confirm, formData.new]);

  if (appStore.authorized === false) {
    return <Redirect to="/" />;
  }

  return (
    <BasePage>
      <Fragment>
        <div className={classes.paper}>
          {/*<Typography className={classes.heading} variant="h1">*/}
          {/*  New password*/}
          {/*</Typography>*/}
          <Typography align={isDesktop ? "inherit" : "center"} variant="h2">
            You can now make new password
          </Typography>
          <form className={classes.form} noValidate onSubmit={send}>
            <InputGroup
              label="Current password"
              required
              id="old"
              placeholder="At least 8 characters"
              name="old"
              type="password"
              autoComplete="password"
              onChange={onChange}
              error={error}
            />
            <InputGroup
              label="New password"
              required
              id="new"
              placeholder="At least 8 characters"
              name="new"
              type="password"
              autoComplete="password"
              onChange={onChange}
              error={notTheSameError}
            />
            <InputGroup
              label="Confirm password"
              required
              id="confirm"
              placeholder="At least 8 characters"
              name="confirm"
              type="password"
              autoComplete="password"
              onChange={onChange}
              error={notTheSameError}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              disabled={disabled}
            >
              CONFIRM
            </Button>
          </form>
          {/*<StyledLink to="/profileedit">Back to Profile</StyledLink>*/}
        </div>
      </Fragment>
    </BasePage>
  );
}

export default withBottomBar(withRequest(observer(ProfilePasswordeditPage)));
