import React, { useEffect, useRef } from "react";
import ChatMessage from "./TranscriptionChatMessage";
import { makeStyles } from "@material-ui/core/styles";
import { List } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  list: {
    padding: theme.spacing(0, 2.125, 0, 3.125),
    marginRight: theme.spacing(1),
    overflowY: "auto",
    height: 337,
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(0, 3, 0, 5),
      marginRight: theme.spacing(2),
      height: "auto",
      flex: 1
    },
    // Scrollbar start
    "&::-webkit-scrollbar": {
      width: 4
    },
    "&::-webkit-scrollbar-track": {
      background: theme.palette.background.default,
      borderRadius: 10
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme.palette.background.scrollBarGrey,
      borderRadius: 10
    }
    // Scrollbar end
  }
}));

function ChatList({
  original,
  messages = [],
  currentUserSide,
  calleeVideoEnabled,
  setMessageRead
}) {
  const classes = useStyles();

  const listEl = useRef();

  useEffect(() => {
    const listRef = listEl.current;
    listRef.addEventListener("DOMNodeInserted", scrollBottom);

    return () => {
      listRef.removeEventListener("DOMNodeInserted", scrollBottom);
    };
  }, []);

  const scrollBottom = event => {
    const { currentTarget: target } = event;
    target.scroll({ top: target.scrollHeight, behavior: "smooth" });
  };

  function filterMessages(messages, currentUserSide) {
    let filteredMessages = messages.slice(0);

    filteredMessages = filteredMessages.filter(message => {
      const messageIsOriginal =
        message.type === "original" || message.type === "originalText";
      const messageIsTranslated =
        message.type === "translation" || message.type === "translatedText";
      const messageIsFromCurrentUser = message.side === currentUserSide;

      // do not show current user translated messages
      if (messageIsFromCurrentUser && messageIsTranslated) {
        return false;
      }

      // always return original for current user
      if (messageIsFromCurrentUser) {
        return messageIsOriginal;
      }

      if (original) {
        return messageIsTranslated;
      } else {
        return messageIsOriginal;
      }
    });

    return filteredMessages;
  }

  const filteredMessages = filterMessages(messages, currentUserSide);

  return (
    <List ref={listEl} className={classes.list}>
      {filteredMessages.map((message, index) => {
        const prevMessage = filteredMessages[index - 1];
        return (
          <ChatMessage
            key={index}
            message={message}
            prevMessageSide={prevMessage && prevMessage.side}
            currentUserSide={currentUserSide}
            calleeVideoEnabled={calleeVideoEnabled}
            setMessageRead={setMessageRead}
          />
        );
      })}
    </List>
  );
}

export default React.memo(ChatList);
