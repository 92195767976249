import React, { useContext, useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import LinkTimeoutIcon from "./linkTimeout.svg";
import { makeStyles } from "@material-ui/core/styles";
import withBottomBar from "../../components/BottomNavBar/withBottomBar";
import BasePage from "../BasePage/BasePage";
import useAppBarHeight from "../../utils/useAppBarHeight";
import { BaseContext } from "../../utils/baseProvider";
import { appStore } from "../../utils/stores/AppStore";
import { observer } from "mobx-react";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    width: "100%"
  },
  emptyImg: {
    alignSelf: "center",
    maxWidth: 200,
    [theme.breakpoints.up("lg")]: {
      maxWidth: 292
    },
    margin: "0 auto"
  }
}));

function LinkTimeoutPage() {
  const appBarHeight = useAppBarHeight();
  const classes = useStyles({ appBarHeight });
  const baseContext = useContext(BaseContext);

  let boxStyle = {};
  if (appStore.authorized) {
    boxStyle = {
      height: `calc(100% - ${2 * appBarHeight}px)`
    };
  }

  useEffect(() => {
    baseContext.setSign("in");
    // eslint-disable-next-line
  }, []);

  return (
    <BasePage>
      <Box {...boxStyle} className={classes.container}>
        <Box m={"auto"} display={"flex"} flexDirection={"column"}>
          <img
            className={classes.emptyImg}
            src={LinkTimeoutIcon}
            alt={"Oops, link is not active"}
          />
          <Box textAlign={"center"} mt={5}>
            <Typography variant={"h2"}>
              The timeout expired,
              <br />
              the link was valid for 10 minutes
            </Typography>
          </Box>
        </Box>
      </Box>
    </BasePage>
  );
}

export default withBottomBar(observer(LinkTimeoutPage));
