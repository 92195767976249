import React, { useContext, useEffect, useMemo, useState } from "react";
import { Box } from "@material-ui/core";
import BasePageDrawer from "./BasePageDrawer";
import AppBarTopNonAuthorized from "../../../../components/AppBarTop/AppBarTopNonAuthorized";
import { BaseContext } from "../../../../utils/baseProvider";
import DesktopAppBarContent from "../../../../components/AppBarTop/DesktopAppBarContent";
import {
  getMenuExpanded,
  setMenuExpanded
} from "../../../../utils/localStorage";
import { appStore } from "../../../../utils/stores/AppStore";
import { observer } from "mobx-react";
import useAppBarHeight from "../../../../utils/useAppBarHeight";

export const DRAWER_EXPANDED_WIDTH = 232;
const DRAWER_COLLAPSED_WIDTH = 100;

const BasePageDesktopLayout = ({ children }) => {
  const baseContext = useContext(BaseContext);
  const [drawerExpanded, setDrawerExpanded] = useState(
    getMenuExpanded() === "true"
  );
  const appBarHeight = useAppBarHeight();

  useEffect(() => {
    setMenuExpanded(drawerExpanded);
  }, [drawerExpanded]);

  const drawerWidth = useMemo(() => {
    return drawerExpanded ? DRAWER_EXPANDED_WIDTH : DRAWER_COLLAPSED_WIDTH;
  }, [drawerExpanded]);

  if (appStore.authorized === undefined) {
    return null;
  }

  if (appStore.authorized === false) {
    return (
      <Box
        style={{
          display: "flex",
          flex: "1"
        }}
      >
        {baseContext.showTopBar && <AppBarTopNonAuthorized />}
        {children}
      </Box>
    );
  }

  return (
    <Box
      style={{
        display: "flex",
        flex: "1"
      }}
    >
      <BasePageDrawer
        setDrawerExpanded={setDrawerExpanded}
        expanded={drawerExpanded}
        width={drawerWidth}
      />

      <Box width={`calc(100% - ${drawerWidth}px)`} position={"relative"}>
        {baseContext.showTopBar && <DesktopAppBarContent />}

        <Box
          height={`calc(100vh - ${
            baseContext.showTopBar ? appBarHeight : 0
          }px)`}
          style={{
            overflowY: "auto"
          }}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};

export default observer(BasePageDesktopLayout);
