import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import bg from "./assets/bg.svg";
import bg_mobile from "./assets/bg_mobile.svg";
import { Box, Card, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  card: {
    backgroundColor: theme.palette.primary.main,
    backgroundImage: `url(${bg_mobile})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: 215,
    height: 136,
    [theme.breakpoints.up("md")]: {
      backgroundImage: `url(${bg})`,
      height: 165
    },
    borderRadius: 5
  },
  textBox: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    padding: theme.spacing(3, 0)
  },
  countText: {
    marginTop: theme.spacing(1),
    fontWeight: 500,
    fontSize: 34,
    lineHeight: "41px",
    [theme.breakpoints.up("md")]: {
      fontSize: 44,
      lineHeight: "53px"
    }
  }
}));

function TotalInvitedCountCard({ count = 0 }) {
  const classes = useStyles();
  return (
    <Card className={classes.card}>
      <Box className={classes.textBox}>
        <Typography variant={"subtitle1"} color={"secondary"}>
          Total number
          <br />
          of friends invited
        </Typography>

        <Typography className={classes.countText} color={"secondary"}>
          {count}
        </Typography>
      </Box>
    </Card>
  );
}

export default TotalInvitedCountCard;
