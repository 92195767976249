import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import tick from "./tick.svg";
import { Checkbox } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  checkbox: {
    padding: "0"
  },
  icon: {
    width: "32px",
    height: "32px",
    borderRadius: "5px",
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.07)"
  },
  checkedIcon: {
    background: `url("${tick}") no-repeat center`
  }
}));

function CustomCheckbox(props) {
  const classes = useStyles();

  return (
    <Checkbox
      checked={props.checked}
      onChange={props.handleCheckbox}
      name="checkbox"
      color="primary"
      className={classes.checkbox}
      icon={<span className={classes.icon} />}
      checkedIcon={<span className={clsx(classes.checkedIcon, classes.icon)} />}
    />
  );
}

export default CustomCheckbox;
