import useBreakpoints from "../../../utils/useBreakpoints";
import { Box, Typography } from "@material-ui/core";
import AvatarWithFallback from "../../../components/Avatar/AvatarWithFallback";
import LanguagesBadge from "../../../components/LanguagesBadge/LanguagesBadge";
import React from "react";
import useLanguages from "../../../utils/hooks/useLanguages";

const JoinLocalCallPageCallData = ({ callerData, callSettings }) => {
  const { isDesktop } = useBreakpoints();
  const { getLanguageByKey } = useLanguages();

  if (isDesktop) {
    return (
      <Box display="flex" flexDirection={"row"}>
        <Box flex={0} mr={3}>
          <AvatarWithFallback src={callerData.avatar} width={96} height={96} />
        </Box>

        <Box display={"flex"} flexDirection={"column"}>
          <Typography variant="h2">
            {callerData.name} invites you
            <br />
            to join the meeting
          </Typography>

          <Box mt={1.5}>
            <LanguagesBadge
              firstCountry={getLanguageByKey(callSettings.from).Country}
              secondCountry={getLanguageByKey(callSettings.to).Country}
              size={24}
            />
          </Box>
        </Box>
      </Box>
    );
  } else {
    return (
      <Box display="flex" alignItems="center" flexDirection={"column"} mb={3}>
        <Box mb={3}>
          <AvatarWithFallback src={callerData.avatar} width={96} height={96} />
        </Box>

        <Typography align={"center"} variant="h2">
          {callerData.name} invites you
          <br />
          to join the meeting
        </Typography>

        <Box mt={2}>
          <LanguagesBadge
            firstCountry={getLanguageByKey(callSettings.from).Country}
            secondCountry={getLanguageByKey(callSettings.to).Country}
            size={isDesktop ? 24 : 20}
          />
        </Box>
      </Box>
    );
  }
};

export default JoinLocalCallPageCallData;
