import React, { useContext, useMemo } from "react";
import BasePage from "../BasePage/BasePage";
import { BaseContext } from "../../utils/baseProvider";
import withBottomBar from "../../components/BottomNavBar/withBottomBar";
import { Box, Typography } from "@material-ui/core";
import useBreakpoints from "../../utils/useBreakpoints";
import UnsubscribedContent from "./UnsubscribedContent";
import SubscribedContent from "./SubscribedContent/SubscribedContent";
import { Redirect } from "react-router-dom";
import useAppBarLeftContent from "../../utils/hooks/useAppBarLeftContent";
import { appStore } from "../../utils/stores/AppStore";
import { observer } from "mobx-react";

function SubscriptionPage() {
  const baseContext = useContext(BaseContext);
  const { isDesktop, isHd } = useBreakpoints();
  useAppBarLeftContent({ title: "Subscription" });

  const desktopMode = isDesktop || isHd;

  const renderContent = useMemo(() => {
    if (!baseContext.currentUser) return null;

    if (baseContext.currentUser.plan === "basic") {
      return <UnsubscribedContent desktopMode={desktopMode} />;
    } else {
      return (
        <SubscribedContent
          desktopMode={desktopMode}
          currentUser={baseContext.currentUser}
        />
      );
    }
  }, [baseContext.currentUser, desktopMode]);

  if (appStore.authorized === false) {
    return <Redirect to="/" />;
  }

  return (
    <BasePage showTopBar={true}>
      {!desktopMode && (
        <Box mt={5}>
          <Typography align={"center"} variant={"h1"}>
            Subscription
          </Typography>
        </Box>
      )}

      {renderContent}
    </BasePage>
  );
}

export default withBottomBar(observer(SubscriptionPage));
