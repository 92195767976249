import { Box } from "@material-ui/core";
import React, { memo, useCallback } from "react";
import { ReactComponent as SettingsIcon } from "./icons/settingsIcon.svg";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { palette } from "../../theme";

const useStyles = makeStyles(theme => ({
  box: {
    background: props => props.background,
    borderRadius: 10,
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer"
  },
  icon: {
    background: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 5,
    cursor: "pointer"
  },
  disabled: {
    cursor: "not-allowed"
  }
}));

const SettingsButton = ({
  onClick,
  small,
  size = 24,
  disabled = false,
  background = palette.background.input
}) => {
  const classes = useStyles({ background });

  const onButtonClick = useCallback(() => {
    if (!disabled) {
      onClick();
    }
  }, [disabled, onClick]);

  return (
    <>
      {small ? (
        <Box
          width={size}
          height={size}
          onClick={onButtonClick}
          className={clsx(classes.icon, { [classes.disabled]: disabled })}
        >
          <SettingsIcon />
        </Box>
      ) : (
        <Box
          onClick={onButtonClick}
          className={clsx(classes.box, { [classes.disabled]: disabled })}
        >
          <Box width={size} height={size} className={classes.icon} mr={1}>
            <SettingsIcon />
          </Box>
          Settings
        </Box>
      )}
    </>
  );
};

export default memo(SettingsButton);
