import { CopyToClipboard } from "react-copy-to-clipboard";
import { ReactComponent as CopyButtonIcon } from "../../../assets/CopyButtonIcon.svg";
import { ReactComponent as CopyButtonSuccess } from "../../../assets/copySuccess.svg";
import React, { useEffect, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { figmaFonts } from "../../../theme";
import { Box, Button, Typography } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  title: {
    [theme.breakpoints.down("md")]: {
      fontSize: theme.typography.pxToRem(22),
      lineHeight: theme.typography.pxToRem(27)
    }
  },
  sendLinkSubtitle: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
    color: theme.palette.text.grey,
    wordBreak: "break-all"
  },
  copyLink: {
    backgroundColor: theme.palette.background.default,
    borderRadius: 10,
    ...figmaFonts.desktopLink,
    padding: theme.spacing(1, 2, 1, 1)
  },
  copyLinkSuccess: { backgroundColor: theme.palette.background.input },
  copyLinkButton: {
    textTransform: "none"
  }
}));

function CopyLink(props) {
  const classes = useStyles();
  const [copied, setCopied] = useState(false);
  const timer = useRef();

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  function onCopy() {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    setCopied(true);
    timer.current = setTimeout(() => setCopied(false), 2000);
  }

  if (!props.id) {
    return null;
  }

  return (
    <Box textAlign={"center"}>
      <Typography className={classes.title} variant="h1">
        Share the link:
      </Typography>
      <Typography
        className={classes.sendLinkSubtitle}
        variant="subtitle1"
      >{`${window.location.protocol}//${window.location.host}/join/${props.id}`}</Typography>
      <CopyToClipboard
        onCopy={onCopy}
        className={clsx(
          classes.copyLink,
          copied ? classes.copyLinkSuccess : ""
        )}
        text={`${window.location.protocol}//${window.location.host}/join/${props.id}`}
      >
        <Button
          classes={{ label: classes.copyLinkButton }}
          startIcon={copied ? <CopyButtonSuccess /> : <CopyButtonIcon />}
        >
          {copied ? "Copied to clipboard" : "Copy link"}
        </Button>
      </CopyToClipboard>
    </Box>
  );
}
export default CopyLink;
