import { getTokenValue } from "../localStorage";
import { useCallback, useContext } from "react";
import { BaseContext } from "../baseProvider";
import QueryString from "query-string";
import { appStore } from "../stores/AppStore";

const useRequest = () => {
  const { setErrorTitle, setLoading, setAuthorized } = useContext(BaseContext);

  const requestDataWithCallback = useCallback(
    (url, params, onResponse, onError, showDefaultLoaderAndError, method) => {
      if (showDefaultLoaderAndError) {
        setErrorTitle(null);
        setLoading(true);
      }
      let requestParams = {
        credentials: "same-origin",
        method: method,
        headers: {}
      };

      if (method === "post") {
        requestParams.headers["Content-Type"] = "application/json";
        requestParams.body = JSON.stringify(params);
      }

      let response;
      let token = getTokenValue();
      let bearer = null;
      if (token) {
        bearer = `bearer ${token}`;
      }
      if (bearer) {
        requestParams.headers["Authorization"] = bearer;
      }

      fetch(url, requestParams)
        .then(result => {
          response = result.clone();
          return result.text();
        })
        .then(data => {
          if (showDefaultLoaderAndError) {
            setLoading(false);
          }
          if (response.status === 401) {
            setAuthorized(false);
            appStore.setAuthorized(false);
            return;
          }

          if (response.status !== 200) {
            try {
              data = JSON.parse(data);
            } catch (e) {}

            if (onError) {
              onError(data);
            } else {
              if (showDefaultLoaderAndError) {
                setErrorTitle("Error");
              }
            }
            return;
          }

          try {
            data = JSON.parse(data);
          } catch (e) {
            console.log(e);
            if (onError) {
              onError();
            } else {
              if (showDefaultLoaderAndError) {
                setErrorTitle("Error");
              }
            }
            return;
          }
          if (onResponse != null) {
            onResponse(data);
          }
        })
        .catch(e => {
          if (showDefaultLoaderAndError) {
            setLoading(false);
          }
          console.log(e);
          if (response && response.status === 401) {
            setAuthorized(false);
            appStore.setAuthorized(false);
            return;
          }
          if (onError) {
            onError();
          } else {
            if (showDefaultLoaderAndError) {
              setErrorTitle("Error");
            }
          }
        });
    },
    [setAuthorized, setErrorTitle, setLoading]
  );

  const getDataWithCallback = useCallback(
    (
      url,
      params,
      onResponse = null,
      onError = null,
      showDefaultLoaderAndError = true
    ) => {
      let query = QueryString.stringify(params);
      let queryUrl = url + (query ? "?" + query : "");
      requestDataWithCallback(
        queryUrl,
        params,
        onResponse,
        onError,
        showDefaultLoaderAndError,
        "get"
      );
    },
    [requestDataWithCallback]
  );

  const postDataWithCallback = useCallback(
    (
      url,
      params,
      onResponse = null,
      onError = null,
      showDefaultLoaderAndError = true
    ) => {
      requestDataWithCallback(
        url,
        params,
        onResponse,
        onError,
        showDefaultLoaderAndError,
        "post"
      );
    },
    [requestDataWithCallback]
  );

  return { getDataWithCallback, postDataWithCallback };
};

export default useRequest;
