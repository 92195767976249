import React, { Fragment, useEffect, useRef, useState } from "react";
import ReactCodeInput from "react-code-input";
import { makeStyles } from "@material-ui/core/styles";
import { figmaFonts } from "../theme";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import useBreakpoints from "../utils/useBreakpoints";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex  !important",
    justifyContent: "space-between",
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    },
    "& input": {
      MozAppearance: "textfield",
      border: "none",
      margin: theme.spacing(0, 1.5),
      width: "63px",
      height: "63px",
      fontSize: "1.5rem",
      backgroundColor: theme.palette.background.input,
      textAlign: "center",
      borderRadius: "10px",
      outline: "none",
      ...figmaFonts.textRegular14,
      [theme.breakpoints.up("lg")]: {
        ...figmaFonts.textRegular16
      }
    },
    "& input:first-of-type": {
      marginLeft: 0
    },
    "& input:last-of-type": {
      marginRight: 0
    }
  }
}));

const errorStyle = {
  border: "1px solid #E80000"
};

function CodeInput({ error, getCode }) {
  const classes = useStyles();
  const [value, setValue] = useState();
  const { isDesktop } = useBreakpoints();
  const codeInputRef = useRef();
  const errorRef = useRef();
  errorRef.current = error;

  useEffect(() => {
    const codeInputNode = document.getElementsByClassName(
      "react-code-input"
    )[0];

    var inputs = Array.from(codeInputNode.getElementsByTagName("input"));
    inputs.forEach(input => {
      input.addEventListener("focus", onFocus);
    });

    return () => {
      inputs.forEach(input => {
        input.removeEventListener("focus", onFocus);
      });
    };
    // eslint-disable-next-line
  }, []);

  const onChange = value => {
    setValue(value);
    getCode(value);
  };

  function onFocus() {
    if (errorRef.current) {
      onChange("");
      clearInput();
    }
  }

  function clearInput() {
    codeInputRef.current.state.input[0] = "";
    codeInputRef.current.state.input[1] = "";
    codeInputRef.current.state.input[2] = "";
    codeInputRef.current.state.input[3] = "";
  }

  return (
    <Fragment>
      <ReactCodeInput
        ref={codeInputRef}
        onChange={onChange}
        value={value}
        type="number"
        name="code"
        autocomplete="off"
        inputMode="numeric"
        filterChars="^\\d+$"
        fields={4}
        isValid={!error}
        inputStyleInvalid={errorStyle}
        className={classes.root}
      />
      <Box mt={error ? 2 : 0} mb={error ? 2 : isDesktop ? 2.375 : 3}>
        {error && (
          <Typography align="center" variant="subtitle2" color="error">
            Code is not correct
          </Typography>
        )}
      </Box>
    </Fragment>
  );
}

export default CodeInput;
