import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import BasePage from "../BasePage/BasePage";
import { Prompt, useHistory, useLocation } from "react-router-dom";
import withRequest from "../../utils/withRequest";
import { makeStyles } from "@material-ui/core/styles";
import CallControls from "./components/CallControls/CallControls";
import CopyLink from "./Transcription/CopyLink";
import clsx from "clsx";
import AvatarWithFallback from "../../components/Avatar/AvatarWithFallback";
import CallData from "./CallData";
import { LocalCallContext } from "./localCallProvider";
import { Box, CardMedia, Grid } from "@material-ui/core";
import { BaseContext } from "../../utils/baseProvider";
import useBreakpoints from "../../utils/useBreakpoints";
import LocalCallTranscriptionWrapper from "./Transcription/TranscriptionWrapper";
import LocalcallPageHeader from "./components/LocalcallPageHeader/LocalcallPageHeader";
import SettingsModal from "../../components/CallSettings/SettingsModal";
import { AppContext } from "../../utils/AppContext";
import { observer } from "mobx-react";
import useAppBarHeight from "../../utils/useAppBarHeight";
import useAppBarLeftContent from "../../utils/hooks/useAppBarLeftContent";
import LocalCallAppBarLeftContent from "./components/LocalCallAppBarLeftContent/LocalCallAppBarLeftContent";
import { appStore } from "../../utils/stores/AppStore";
import { getInitialReadMessageStatus } from "../../utils/calls/getInitialReadMessageStatus";

const useStyles = makeStyles(theme => ({
  grid: {
    height: "100%",
    maxHeight: "100%",
    padding: 0,
    margin: theme.spacing(0),
    flex: 1,
    width: "100%"
  },
  gridItem: {
    maxHeight: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative"
  },
  videoContainer: {
    flexGrow: 1,
    height: "100%",
    position: "relative",
    backgroundColor: theme.palette.background.callGrey,
    overflow: "hidden"
  },
  videoContainerDisabledVideo: {
    background:
      "linear-gradient(0deg, rgba(78, 78, 137, 0) 64.12%, rgba(11, 11, 70, 0.21) 82.35%, rgba(11, 11, 33, 0.455) 100%)"
  },
  remoteVideo: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    objectFit: "cover",
    height: "100%",
    width: "100%"
  },
  localVideo: {
    position: "absolute",
    right: 25,
    maxHeight: 120,
    maxWidth: 92,
    bottom: 104,
    objectFit: "cover",
    zIndex: 2,
    [theme.breakpoints.up("lg")]: {
      maxHeight: 140,
      maxWidth: 210,
      right: 24,
      top: 24,
      bottom: "initial"
    }
  },
  videoHidden: {
    display: "none"
  },
  centeredBox: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    padding: theme.spacing(0, 3.125),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  callControls: {
    position: "absolute",
    bottom: "24px",
    left: 0,
    right: 0,
    marginBottom: "env(safe-area-inset-bottom)"
  }
}));

function LocalcallPage({ postDataWithCallback, isDirectCall }) {
  const classes = useStyles();
  const location = useLocation();
  const initData = location.state || {};
  const history = useHistory();
  const localCallContext = useContext(LocalCallContext);
  const baseContext = useContext(BaseContext);
  const [id, setId] = useState(null);
  const [ready, setReady] = useState(false);
  const [connection, setConnection] = useState("");
  const [Bconnection, setBconnection] = useState("");
  const [callEnded, setCallEnded] = useState(false);
  const [callTimeouted, setCallTimeouted] = useState(false);
  const { isDesktop, isMobile } = useBreakpoints();
  const [ice, setIce] = useState(false);
  const { localCallUIStore, localCallStore } = useContext(AppContext);
  const appBarHeight = useAppBarHeight();
  const Bconnected = useMemo(() => Bconnection === "connected", [Bconnection]);
  const isCallee = initData && initData.remoteId !== undefined;

  const appBarLeftComponent = useMemo(
    () => (
      <LocalCallAppBarLeftContent isCallee={isCallee} Bconnected={Bconnected} />
    ),
    [Bconnected, isCallee]
  );

  useAppBarLeftContent({
    memoizedComponent: appBarLeftComponent
  });

  const localStreamRef = useRef();
  const dcRef = useRef();
  const pcRef = useRef();
  const videoLocalRef = useRef();
  const videoRemoteRef = useRef();

  useEffect(() => {
    baseContext.setSign("in");
    return () => {
      handleDisconnect();
      localCallContext.resetToDefaultState();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (appStore.authorized !== undefined) {
      initWebrtc();

      localCallStore.initCallState({
        from: initData.from,
        to: initData.to,
        toGender: initData.toGender,
        fromGender:
          initData.fromGender ||
          (baseContext.currentUser ? baseContext.currentUser.gender : undefined)
      });
    }
    // eslint-disable-next-line
  }, [appStore.authorized]);

  useEffect(() => {
    if (ready) {
      connect();
    }
    // eslint-disable-next-line
  }, [ready]);

  useEffect(() => {
    if (ice && id !== null) {
      sendAnswer();
    }
    // eslint-disable-next-line
  }, [ice, id]);

  useEffect(() => {
    if (Bconnected) {
      sendMediaState(
        localCallContext.myAudioEnabled,
        localCallContext.myVideoEnabled
      );
    }
    // eslint-disable-next-line
  }, [
    Bconnected,
    localCallContext.myAudioEnabled,
    localCallContext.myVideoEnabled
  ]);

  // hide top bar for full screen during call
  useEffect(() => {
    if (Bconnected && !isDesktop) {
      baseContext.setShowTopBar(false);
    } else {
      baseContext.setShowTopBar(true);
    }
    return () => {
      baseContext.setShowTopBar(true);
    };
    // eslint-disable-next-line
  }, [Bconnected, isMobile]);

  useEffect(() => {
    if (callEnded && Bconnection === "connected") {
      history.push("/ratecall", {
        remoteid: (initData && initData.remoteId) || id,
        side: localCallContext.currentUserIsCallee ? "B" : "A",
        type: "meeting",
        elapsed: localCallContext.elapsed,
        name: localCallContext.remoteName,
        photo: localCallContext.remoteAvatar,
        to: localCallStore.callSettings.to,
        from: localCallStore.callSettings.from
      });
    } else if (callEnded && !callTimeouted) {
      history.push("/");
    } else if (callTimeouted) {
      history.push("/linkTimeout");
    }
    // eslint-disable-next-line
  }, [callEnded, callTimeouted]);

  function initWebrtc() {
    let localPc = new RTCPeerConnection({
      iceServers: [
        {
          urls: "stun:web.yous.ai:33478"
        }
      ]
    });

    localPc.onsignalingstatechange = e => {
      log(`signalling: ${localPc.signalingState}`);
    };

    localPc.onicegatheringstatechange = () => {
      log(`onicegatheringstatechange: ${localPc.iceGatheringState}`);
    };

    localPc.oniceconnectionstatechange = e => {
      setConnection(localPc.iceConnectionState);
      log(`connection: ${localPc.iceConnectionState}`);
      if (
        localPc.iceConnectionState === "disconnected" ||
        localPc.iceConnectionState === "closed"
      ) {
        setCallEnded(true);
      }
    };

    localPc.ondatachannel = e => {
      let dc = e.channel;
      dcRef.current = dc;
      dc.onclose = () => {
        log("dc has closed");
        setCallEnded(true);
      };
      dc.onopen = () => {
        log("dc has opened");
        if (initData.remoteId && !isDirectCall) {
          sendMessageToDC("translationConfig", localCallStore.callSettings);
        }
      };
      dc.onmessage = e => {
        handleDCMessage(e.data);
      };
    };

    localPc.ontrack = function(event) {
      if (event.track.kind === "video") {
        videoRemoteRef.current.srcObject = event.streams[0];
      }
    };

    navigator.mediaDevices
      .getUserMedia({
        video: true,
        audio: true
      })
      .then(stream => {
        stream.getTracks().forEach(track => {
          localPc.addTrack(track, stream);
        });
        localStreamRef.current = stream;
        videoLocalRef.current.srcObject = stream;
        setReady(true);
      })
      .catch(log);

    pcRef.current = localPc;
  }

  function connect() {
    let localId = "";

    let off = {};
    if (initData !== undefined) {
      if (initData.remoteId !== undefined) {
        off.remoteid = initData.remoteId;
        localCallContext.setCurrentUserIsCallee(true);
      }
      if (initData.name) {
        off.name = initData.name;
      }
      if (initData.toGender) {
        off.gender = initData.toGender;
      }
      if (initData.from !== undefined) {
        off.from = initData.from;
      }
      if (initData.to !== undefined) {
        off.to = initData.to;
      }
    }

    pcRef.current.onicecandidate = event => {
      console.log("candidate: ", event.candidate);
      setIce(true);
    };

    postDataWithCallback("/api/sfuoffer", off, data => {
      pcRef.current
        .setRemoteDescription(new RTCSessionDescription(data.sdp))
        .catch(log);
      pcRef.current
        .createAnswer()
        .then(d => pcRef.current.setLocalDescription(d))
        .catch(log);
      localId = data.id;
      setId(localId);
    });
  }

  function sendAnswer() {
    let ans = {};
    ans.sdp = pcRef.current.localDescription;
    ans.id = id;
    postDataWithCallback("/api/sfuanswer", ans, data => {
      log(data);
    });
  }

  function log(data) {
    console.log(data);
  }

  function sendMessageToDC(type, payload) {
    if (dcRef.current && dcRef.current.readyState === "open") {
      let message = payload;
      if (type !== "message") {
        message = JSON.stringify({
          type: type,
          payload
        });
      }
      dcRef.current.send(message);
    }
  }

  function handleDCMessage(data) {
    const ob = JSON.parse(data);

    switch (ob.type) {
      case "elapsed":
        localCallContext.setElapsed(ob.text);
        break;
      case "elapsedSecondsBalance":
        if (!isCallee) {
          baseContext.updateUserBalance(ob.text);
        }
        break;
      case "translatedText":
      case "originalText":
      case "translation":
      case "original":
        // here we assume that consecutive messages ( original and translated )
        // are received one after another
        localCallContext.setMessages(oldArray => [
          ...oldArray,
          {
            ...ob,
            id:
              oldArray.length % 2 === 1 ? oldArray.length - 1 : oldArray.length,
            read: getInitialReadMessageStatus(isCallee, ob),
            timestamp: new Date().getTime()
          }
        ]);
        break;
      case "control":
        if (ob.text === "timeout") {
          setCallTimeouted(true);
        } else if (ob.side === "B") {
          if (ob.text === "connected") {
            setBconnection(ob.text);
          }
        }
        break;
      case "remoteName":
        localCallContext.setRemoteName(ob.text);
        break;
      case "clientControl":
        if (ob.payload) {
          localCallContext.setCalleeAudioEnabled(ob.payload.audio);
          localCallContext.setCalleeVideoEnabled(ob.payload.video);
        }
        break;
      case "remoteId":
        if (isCallee && ob.side === "A") {
          localCallContext.setRemoteId(ob.text);
        } else {
          localCallContext.setRemoteId(ob.text);
        }
        break;
      case "translationEnabled":
        localCallContext.setTranslationEnabled(ob.text === "enabled");
        break;
      case "translationConfig":
        localCallStore.setCallSettings(ob.payload);
        break;
      default:
        log(ob);
    }
  }

  function handleDisconnect() {
    if (pcRef.current) {
      pcRef.current.close();
    }

    if (localStreamRef.current) {
      localStreamRef.current.getTracks().forEach(track => {
        track.stop();
      });
    }

    setCallEnded(true);
  }

  function sendMediaState(audio, video) {
    sendMessageToDC("clientControl", {
      audio,
      video
    });
  }

  function toggleVideo() {
    if (localStreamRef.current) {
      localStreamRef.current.getVideoTracks()[0].enabled = !localCallContext.myVideoEnabled;
      localCallContext.setMyVideoEnabled(!localCallContext.myVideoEnabled);
    }
  }

  function toggleAudio() {
    if (localStreamRef.current) {
      localStreamRef.current.getAudioTracks()[0].enabled = !localCallContext.myAudioEnabled;
      localCallContext.setMyAudioEnabled(!localCallContext.myAudioEnabled);
    }
  }

  function toggleTranslation() {
    localCallContext.setTranslationEnabled(
      !localCallContext.translationEnabled
    );
    sendMessageToDC("translationEnabled", {
      enabled: !localCallContext.translationEnabled
    });
  }

  function renderContent() {
    return (
      <>
        {!Bconnected && appStore.authorized && (
          <LocalcallPageHeader isCallee={isCallee} />
        )}

        <Box
          className={clsx(
            classes.videoContainer,
            Bconnected && !localCallContext.calleeVideoEnabled
              ? classes.videoContainerDisabledVideo
              : ""
          )}
        >
          {!isDesktop && (
            <CallData Bconnected={Bconnected} isCallee={isCallee} />
          )}

          <CardMedia
            className={clsx(
              classes.remoteVideo,
              !localCallContext.calleeVideoEnabled ? classes.videoHidden : ""
            )}
            component="video"
            ref={videoRemoteRef}
            autoPlay={true}
            playsInline={true}
          />

          <CardMedia
            className={clsx(
              classes.localVideo,
              !localCallContext.myVideoEnabled ? classes.videoHidden : ""
            )}
            component="video"
            ref={videoLocalRef}
            autoPlay={true}
            playsInline={true}
            volume={0}
            muted={true}
          />

          <Box className={classes.centeredBox}>
            {connection === "connected" &&
              !Bconnected &&
              !localCallContext.currentUserIsCallee && <CopyLink id={id} />}

            {Bconnected && !localCallContext.calleeVideoEnabled && (
              <AvatarWithFallback
                src={localCallContext.remoteAvatar}
                width={isDesktop ? 190 : 136}
                height={isDesktop ? 190 : 136}
              />
            )}
          </Box>

          <Box className={classes.callControls}>
            <CallControls
              Bconnected={Bconnected}
              toggleVideo={toggleVideo}
              toggleAudio={toggleAudio}
              handleDisconnect={handleDisconnect}
              toggleTranslation={toggleTranslation}
            />
          </Box>
        </Box>
      </>
    );
  }

  const onSettingsSave = useCallback(
    formData => {
      localCallStore.setCallSettings({
        ...localCallStore.callSettings,
        ...formData
      });
      localCallUIStore.toggleSettingsModalShow();
      sendMessageToDC("translationConfig", formData);
    },
    [localCallStore, localCallUIStore]
  );

  const containerStyles = isDesktop
    ? {
        height:
          appStore.authorized && !(!isDesktop && Bconnected) ? `100%` : "auto",
        flex: 1,
        paddingTop: appStore.authorized ? 0 : appBarHeight + "px",
        width: "100%"
      }
    : {
        position: "absolute",
        top: baseContext.showTopBar ? appBarHeight : 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: "100%"
      };

  return (
    <BasePage noPaddingBottom>
      <Prompt
        when={!callEnded && !callTimeouted}
        message={`Are you sure you want to end call?`}
      />
      <Box {...containerStyles}>
        <Grid
          className={classes.grid}
          container
          spacing={0}
          justifyContent="center"
        >
          <Grid
            className={classes.gridItem}
            item
            xs={isDesktop && localCallContext.showTranscription ? 8 : 12}
          >
            {renderContent()}
          </Grid>

          {localCallContext.showTranscription &&
            (isDesktop ? (
              <Grid className={classes.gridItem} item xs={4}>
                <LocalCallTranscriptionWrapper
                  Bconnected={Bconnected}
                  sendMessageToDC={sendMessageToDC}
                />
              </Grid>
            ) : (
              <LocalCallTranscriptionWrapper
                Bconnected={Bconnected}
                sendMessageToDC={sendMessageToDC}
              />
            ))}
        </Grid>
      </Box>

      <SettingsModal
        open={localCallUIStore.settingsModalShow}
        onClose={localCallUIStore.toggleSettingsModalShow}
        onSettingsSave={onSettingsSave}
        isCaller={!localCallContext.currentUserIsCallee}
        fromCountryCode={localCallStore.callSettings.from}
        toCountryCode={localCallStore.callSettings.to}
        callSettings={localCallStore.callSettings}
      />
    </BasePage>
  );
}

export default withRequest(observer(LocalcallPage));
