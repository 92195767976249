import PlanChip from "./PlanChip";
import { ReactComponent as PlusIcon } from "../../assets/icons/plusIconMain.svg";
import BalanceEmptyModal from "../BalanceEmptyModal/BalanceEmptyModal";
import React, { useContext, useState } from "react";
import { BaseContext } from "../../utils/baseProvider";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { figmaFonts } from "../../theme";

const useStyles = makeStyles(theme => ({
  balanceChip: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#484ECA",
    height: 24,
    padding: theme.spacing(0.5),
    borderRadius: 5
  },
  primaryText: {
    color: theme.palette.text.secondary
  },
  plusIcon: {
    height: 16,
    cursor: "pointer",
    marginLeft: theme.spacing(0.5)
  },
  text: {
    ...figmaFonts.mobileSmallTextMedium
  },
  chip: {
    backgroundColor: "transparent",
    height: 16,
    padding: 0,
    "& svg": {
      width: 16,
      height: 16
    }
  }
}));

const UsdBalanceChip = () => {
  const classes = useStyles();
  const baseContext = useContext(BaseContext);
  const [balanceEmptyModalOpen, setBalanceEmptyModalOpen] = useState(false);

  return (
    <div className={clsx(classes.balanceChip)}>
      <PlanChip
        background={"transparent"}
        className={classes.chip}
        plan={"save"}
        text={
          <span className={classes.text}>
            <span className={classes.primaryText}>
              ${baseContext.centsBalance / 100}
            </span>
          </span>
        }
      />
      <PlusIcon
        onClick={() => setBalanceEmptyModalOpen(true)}
        className={classes.plusIcon}
      />

      <BalanceEmptyModal
        type={"topUpUsdBalance"}
        open={balanceEmptyModalOpen}
        handleClose={() => setBalanceEmptyModalOpen(false)}
      />
    </div>
  );
};

export default UsdBalanceChip;
