import React from "react";
import { Box, Typography } from "@material-ui/core";
import BillingPlaceholderIcon from "../../assets/emptyPageIcon.svg";

import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  box: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    alignItems: "center",
    justifyContent: "center"
  },
  boxAbsolute: {
    [theme.breakpoints.up("sm")]: {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0
    }
  },
  emptyImg: {
    maxWidth: 200,
    [theme.breakpoints.up("lg")]: {
      maxWidth: 280
    }
  }
}));

function EmptyPage({ absolute = true }) {
  const classes = useStyles();

  const boxClass = clsx(classes.box, { [classes.boxAbsolute]: absolute });

  return (
    <Box className={boxClass}>
      <img
        className={classes.emptyImg}
        src={BillingPlaceholderIcon}
        alt={"There is nothing here yet"}
      />
      <Box textAlign={"center"} mt={5}>
        <Typography variant={"h2"}>There is nothing here yet</Typography>
      </Box>
    </Box>
  );
}

export default EmptyPage;
