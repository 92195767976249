import React, { useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import { setInvitedBy } from "../utils/localStorage";

function Invite(props) {
  const { invitedBy } = useParams();
  useEffect(() => {
    if (invitedBy) {
      setInvitedBy(invitedBy);
    }
  }, [invitedBy]);
  return <Redirect to={"/"} />;
}

export default Invite;
