import React from "react";
import { Box, Fab, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { figmaFonts } from "../../../../../theme";
import useBreakpoints from "../../../../../utils/useBreakpoints";

const useStyles = makeStyles(theme => ({
  button: {
    boxShadow: "none",
    width: 64,
    height: 64,
    ...figmaFonts.textRegular16,
    background: theme.palette.background.lightGrey,
    touchAction: "manipulation",
    "&.Mui-disabled": {
      background: theme.palette.background.lightGrey,
      color: "inherit"
    },
    "&:hover": {
      background: theme.palette.background.lighterGrey
    },
    [theme.breakpoints.up("lg")]: {
      width: 72,
      height: 72
    }
  },
  mobile: {
    width: 60,
    height: 60
  },
  transparent: {
    background: "transparent"
  },
  green: {
    background: theme.palette.buttons.greenActive
  },
  red: {
    background: theme.palette.background.red
  },
  number: {
    fontWeight: 500,
    fontSize: "26px",
    lineHeight: "31px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  subtitle: {
    fontWeight: "bold",
    fontSize: "9px",
    lineHeight: "11px",
    color: theme.palette.text.grey,
    display: "block",
    height: 11
  }
}));
const DialButton = ({
  content,
  onClick,
  disabled = false,
  color,
  subtitle
}) => {
  const classes = useStyles();
  const { isDesktop } = useBreakpoints();
  const buttonClassname = clsx(classes.button, {
    [classes[color]]: !!color,
    [classes.mobile]: !isDesktop
  });

  const _onClick = e => {
    e.preventDefault();
    onClick();
  };

  return (
    <Fab
      disabled={disabled}
      className={buttonClassname}
      onClick={_onClick}
      color="secondary"
    >
      <Box display={"flex"} flexDirection={"column"}>
        <Typography className={classes.number}>{content}</Typography>
        {subtitle !== undefined && (
          <Typography className={classes.subtitle}>{subtitle}</Typography>
        )}
      </Box>
    </Fab>
  );
};

export default DialButton;
