import { useContext, useEffect, useState } from "react";
import { BaseContext } from "./baseProvider";

function useStripePortalUrl({ postDataWithCallback }) {
  const baseContext = useContext(BaseContext);
  const [portalUrl, setPortalUrl] = useState();

  useEffect(() => {
    if (
      baseContext.currentUser &&
      baseContext.currentUser.plan === "plus" &&
      !portalUrl
    ) {
      postDataWithCallback("/api/stripe/portal", {}, data => {
        console.log(data);
        setPortalUrl(data.url);
      });
    }
    // eslint-disable-next-line
  }, [baseContext.currentUser]);

  return portalUrl;
}

export default useStripePortalUrl;
