import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import LogoStripes from "../../../../../../assets/logoStripes.svg";
import clsx from "clsx";
import RippleAnimation from "./RippleAnimation/RippleAnimation";
import useBreakpoints from "../../../../../../utils/useBreakpoints";

const useStyles = makeStyles(theme => ({
  container: {
    minHeight: 260,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  mobileContainer: {
    minHeight: 310
  },
  wrapper: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  animationContainer: {
    position: "absolute",
    inset: 0,
    zIndex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  logoBg: {
    position: "relative",
    zIndex: 2,
    width: 120,
    height: 120,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.background.default,
    boxShadow: "0px 10px 60px rgba(0, 0, 0, 0.07)",
    borderRadius: "50%",
    transition: "all 225ms linear ",
    transform: "scale(1.4)"
  },
  logoActive: {
    transform: "scale(1)"
  }
}));

const PhoneCallAnimatedLogo = ({ animated = true }) => {
  const classes = useStyles();
  const { isDesktop } = useBreakpoints();

  return (
    <div
      className={clsx(classes.container, {
        [classes.mobileContainer]: !isDesktop
      })}
    >
      <div className={classes.wrapper}>
        <div className={classes.animationContainer}>
          <RippleAnimation animated={animated} />
        </div>

        <div
          className={clsx(classes.logoBg, { [classes.logoActive]: animated })}
        >
          <img src={LogoStripes} alt={"Yous logo"} />
        </div>
      </div>
    </div>
  );
};

export default PhoneCallAnimatedLogo;
