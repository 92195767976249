import { Link as RouterLink } from "react-router-dom";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.text.purple,
    padding: 0,
    "&:hover": {
      backgroundColor: "inherit"
    }
  }
}));

function StyledLink(props) {
  const classes = useStyles();

  return (
    <Button
      component={props.onClick ? Button : RouterLink}
      className={props.className}
      classes={classes}
      color="primary"
      size="small"
      to={props.to}
      onClick={props.onClick}
    >
      {props.children}
    </Button>
  );
}

export default StyledLink;
