import React, { Fragment } from "react";
import { Redirect } from "react-router-dom";
import BasePage from "./BasePage/BasePage";
import { appStore } from "../utils/stores/AppStore";
import { observer } from "mobx-react";

function LoginPage() {
  return (
    <BasePage>
      <Fragment>
        {appStore.authorized !== undefined ? <Redirect to="/" /> : null}
      </Fragment>
    </BasePage>
  );
}

export default observer(LoginPage);
