import { useContext, useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { BaseContext } from "./baseProvider";

function useStripe({ postDataWithCallback }) {
  const [stripe, setStripe] = useState(undefined);
  const baseContext = useContext(BaseContext);

  useEffect(() => {
    if (baseContext.stripe && !stripe) {
      loadStripe(baseContext.stripe.stripePublic).then(s => {
        setStripe(s);
      });
    }

    // eslint-disable-next-line
  }, [baseContext.stripe]);

  const handleSubmitMonth = async event => {
    event.preventDefault();

    if (!stripe) {
      return;
    }

    postDataWithCallback(
      "/api/stripe/session",
      { priceId: baseContext.stripe.stripePriceMonth },
      data => {
        stripe
          .redirectToCheckout({
            sessionId: data.sessionId
          })
          .then(r => {
            console.log(r);
          });
      }
    );
  };

  const handleSubmitYear = async event => {
    event.preventDefault();

    if (!stripe) {
      return;
    }

    postDataWithCallback(
      "/api/stripe/session",
      { priceId: baseContext.stripe.stripePriceYear },
      data => {
        stripe
          .redirectToCheckout({
            sessionId: data.sessionId
          })
          .then(r => {
            console.log(r);
          });
      }
    );
  };

  return { month: handleSubmitMonth, year: handleSubmitYear };
}

export default useStripe;
