import React from "react";
import { Box, Typography } from "@material-ui/core";
import DialButton from "./components/DialButton";
import {
  CallActive,
  DialBackspace,
  HangUp,
  MicOff,
  MicOn
} from "../../../../assets/icons";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import useBreakpoints from "../../../../utils/useBreakpoints";
import { figmaFonts } from "../../../../theme";

const useStyles = makeStyles(theme => ({
  container: {
    width: "fit-content",
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  icon: {
    "& path": {
      fill: theme.palette.text.default
    }
  },
  callIcon: {
    transform: "rotate(-135deg)"
  },
  callDisabled: {
    "& path": {
      fill: theme.palette.text.grey
    }
  },
  callHangup: {
    transform: "none"
  },
  hide: {
    color: theme.palette.text.grey,
    textTransform: "none",
    ...figmaFonts.textRegular14
  }
}));

const PhoneCallDialer = ({
  audioEnabled,
  onMicToggle,
  onBackspace,
  onButtonClick,
  onCallClick,
  callActive = false,
  callHangup = false,
  onHide = false
}) => {
  const classes = useStyles();
  const { isDesktop } = useBreakpoints();

  const _onButtonClick = symbol => {
    onButtonClick(symbol);
  };

  const buttons = [
    {
      content: "1",
      subtitle: " "
    },
    {
      content: "2",
      subtitle: "A B C"
    },
    {
      content: "3",
      subtitle: "D E F"
    },
    {
      content: "4",
      subtitle: "G H I"
    },
    {
      content: "5",
      subtitle: "J K L"
    },
    {
      content: "6",
      subtitle: "M N O"
    },
    {
      content: "7",
      subtitle: "P Q R S"
    },
    {
      content: "8",
      subtitle: "T U V"
    },
    {
      content: "9",
      subtitle: "W X Y Z"
    },
    {
      content: "*"
    },
    {
      content: "0",
      subtitle: "+"
    },
    {
      content: "#"
    }
  ];

  if (onMicToggle) {
    buttons.push({
      content: audioEnabled ? (
        <MicOn className={classes.icon} />
      ) : (
        <MicOff className={classes.icon} />
      ),
      color: "transparent",
      onClick: onMicToggle
    });
  }

  buttons.push(
    {
      content: !callHangup ? (
        <CallActive />
      ) : (
        <HangUp className={clsx(classes.callIcon, classes.callHangup)} />
      ),
      color: callHangup ? "red" : "green",
      onClick: !callActive && !callHangup ? null : onCallClick
    },
    {
      content: !onHide ? (
        <DialBackspace />
      ) : (
        <Typography className={classes.hide}>Hide</Typography>
      ),
      color: "transparent",
      onClick: onHide || onBackspace
    }
  );

  const arrayOfArrays = [];
  for (let i = 0; i < buttons.length; i += 3) {
    arrayOfArrays.push(buttons.slice(i, i + 3));
  }

  return (
    <div className={classes.container}>
      {arrayOfArrays.map((arr, index) => {
        return (
          <Box ml={arr.length === 2 ? "auto" : undefined} key={"b" + index}>
            {arr.map((button, index) => {
              return (
                <Box
                  key={index}
                  px={isDesktop ? 1.5 : 1.25}
                  py={isDesktop ? 1 : 0.625}
                  display={"inline-block"}
                >
                  <DialButton
                    content={button.content}
                    disabled={button.disabled}
                    onClick={
                      button.onClick || (() => _onButtonClick(button.content))
                    }
                    subtitle={button.subtitle}
                    color={button.color}
                  />
                </Box>
              );
            })}
          </Box>
        );
      })}
    </div>
  );
};

export default PhoneCallDialer;
