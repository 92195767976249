import { plans } from "./plans";

export const FAQ_TEXT = [
  {
    question: "How can I pay for the Plus plan?",
    answer:
      "You can pay with a bank card. All payments are processed by our international partner Stripe, ensuring the security of all transactions."
  },
  {
    question: "How is billing calculated during a conversation?",
    answer:
      "If you are the creator of the meeting, then minutes for the conversation will be charged only from your tariff. Billing is per second and debiting starts after the invited interlocutor is connected."
  },
  {
    question:
      "What will happen to the current balance of minutes when the tariff is changed?",
    answer:
      "When switching from one tariff to another, your minutes balance and expiration date will be updated according to the terms of the new tariff."
  },
  {
    question: "Can I cancel my paid subscription and renew it later?",
    answer:
      "Yes, you can cancel your paid subscription at any time and enjoy all its privileges until the end of the plan."
  },
  {
    question: "What if my paid Plus plan runs out of minutes?",
    answer: `You can top up your main balance and pay $${plans.pricePerMinute} for each subsequent minute of your call.`
  },
  {
    question:
      "I have activated the annual rate Plus (yearly), how many minutes do I get each month?",
    answer: "Plus (yearly) rate includes 60 minutes of calls every month."
  }
];
