import React, { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  Typography
} from "@material-ui/core";
import StyledTableRow from "../../../../../History/StyledTableRow";
import StyledTableHeadCell from "../../../../../History/StyledTableHeadCell";
import { makeStyles } from "@material-ui/core/styles";
import { figmaFonts } from "../../../../../../theme";
import StyledTableCell from "../../../../../History/StyledTableCell";
import { ReactComponent as CoinIconSmall } from "../../../../../../assets/bonuses/coinIconSmall.svg";
import { Pagination } from "@material-ui/lab";

const useStyles = makeStyles(theme => ({
  table: {
    width: "100%"
  },
  smallDate: {
    ...figmaFonts.mobileSmallTextRegular,
    color: theme.palette.text.grey,
    marginTop: theme.spacing(0.75)
  },
  desktopFont: {
    ...figmaFonts.textRegular16,
    wordBreak: "break-word"
  },
  mobileFont: {
    ...figmaFonts.mobileSmallTextRegular,
    wordBreak: "break-word"
  }
}));

const PER_PAGE = 10;

const BonusesTable = ({ data, showDesktopLayout }) => {
  const classes = useStyles();
  const [page, setPage] = useState(1);

  const textClass = showDesktopLayout
    ? classes.desktopFont
    : classes.mobileFont;

  function renderRowTextBy(type) {
    switch (type) {
      case "register": {
        return "Registration of the invited user";
      }
      case "year": {
        return "Referred friend bought a subscription Plus (yearly)";
      }
      case "month": {
        return "Referred friend bought a subscription (monthly)";
      }
      default: {
        return type;
      }
    }
  }

  function renderRow(row, index) {
    return (
      <StyledTableRow key={row.date + index.toString()}>
        {showDesktopLayout && (
          <StyledTableCell>
            <Typography className={textClass}>
              {getFormattedDate(row.date)}
            </Typography>
          </StyledTableCell>
        )}

        <StyledTableCell>
          <Typography className={textClass}>
            {renderRowTextBy(row.type)}
          </Typography>
          {!showDesktopLayout && (
            <Typography className={classes.smallDate}>
              {getFormattedDate(row.date)}
            </Typography>
          )}
        </StyledTableCell>

        <StyledTableCell>
          <Box display={"flex"} alignItems={"center"}>
            <Typography
              className={textClass}
              color={row.amount < 0 ? "error" : "primary"}
            >
              {row.amount > 0 && "+"}
              {row.amount}
            </Typography>
            <Box width={24} height={24} ml={0.5}>
              <CoinIconSmall />
            </Box>
          </Box>
        </StyledTableCell>
      </StyledTableRow>
    );
  }

  function getFormattedDate(date1) {
    const date = new Date(date1);

    return (
      date.toLocaleDateString("en-US").replace(/\//g, ".") +
      ", " +
      addLeadingZero(date.getHours()) +
      ":" +
      addLeadingZero(date.getMinutes())
    );
  }

  function addLeadingZero(value) {
    if (value < 10) {
      return `0${value}`;
    }
    return value;
  }

  function onPageChange(event, page) {
    setPage(page);
  }

  function paginate(array, page_size, page) {
    return array.slice((page - 1) * page_size, page * page_size);
  }

  return (
    <TableContainer component={Box} px={showDesktopLayout ? 0 : 3.5}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <StyledTableRow>
            {showDesktopLayout && (
              <StyledTableHeadCell>Date/time</StyledTableHeadCell>
            )}
            <StyledTableHeadCell
              style={{ width: showDesktopLayout ? "initial" : 200 }}
            >
              History
            </StyledTableHeadCell>

            <StyledTableHeadCell
              style={{ width: showDesktopLayout ? 125 : 100 }}
            >
              Bonuses
            </StyledTableHeadCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {paginate(data, PER_PAGE, page).map((row, index) => {
            return renderRow(row, index);
          })}
        </TableBody>
      </Table>
      <Box
        mt={5}
        mb={5}
        width={"100%"}
        display={"flex"}
        justifyContent={"center"}
      >
        <Pagination
          onChange={onPageChange}
          count={Math.ceil(data.length / PER_PAGE)}
          shape="rounded"
          size={"large"}
        />
      </Box>
    </TableContainer>
  );
};

export default BonusesTable;
