import React from "react";
import { Box, useMediaQuery } from "@material-ui/core";
import SmallItem from "./components/SmallItem/SmallItem";
import SubscriptionCard from "./components/SubscriptionCard/SubscriptionCard";
import TopUpBalanceCard from "./components/TopUpBalanceCard/TopUpBalanceCard";
import ProductItem from "./components/ProductItem/ProductItem";

import maleHoodieWhite from "./assets/hoodie/hoodie-man-front-min.png";
import maleHoodieWhite1 from "./assets/hoodie/hoodie-man-back-min.png";
import hoodieWhite from "./assets/hoodie/hoodie-front-min.png";
import hoodieWhite1 from "./assets/hoodie/hoodie-back-min.png";
import femaleHoodieWhite from "./assets/hoodie/hoodie-woman-front-min.png";
import femaleHoodieWhite1 from "./assets/hoodie/hoodie-woman-back-min.png";

import blackHoodieManFront from "./assets/hoodie/black-hoodie-man-front-min.png";
import blackHoodieManBack from "./assets/hoodie/black-hoodie-man-back-min.png";
import blackHoodieWomanFront from "./assets/hoodie/black-hoodie-woman-front-min.png";
import blackHoodieWomanBack from "./assets/hoodie/black-hoodie-woman-back-min.png";
import blackHoodieFront from "./assets/hoodie/black-hoodie-front-min.png";
import blackHoodieBack from "./assets/hoodie/black-hoodie-back-min.png";

import blackTshirtManFront from "./assets/shirt/black-tshirt-man-front-min.png";
import blackTshirtManBack from "./assets/shirt/black-tshirt-man-back-min.png";
import blackTshirtFront from "./assets/shirt/black-tshirt-front-min.png";
import blackTshirtBack from "./assets/shirt/black-tshirt-back-min.png";
import blackTshirtWomanFront from "./assets/shirt/black-tshirt-woman-front-min.png";
import blackTshirtWomanBack from "./assets/shirt/black-tshirt-woman-back-min.png";

import tshirtManFront from "./assets/shirt/tshirt-man-front-min.png";
import tshirtManBack from "./assets/shirt/tshirt-man-back-min.png";
import tshirtFront from "./assets/shirt/tshirt-front-min.png";
import tshirtBack from "./assets/shirt/tshirt-back-min.png";
import tshirtWomanFront from "./assets/shirt/tshirt-woman-front-min.png";
import tshirtWomanBack from "./assets/shirt/tshirt-woman-back-min.png";

import iphone from "./assets/iphone/case-iphone-12-pro-min.png";
import iphoneMax from "./assets/iphone/case-iphone-12-pro-max-min.png";
import { makeStyles } from "@material-ui/core/styles";

const shopItems = [
  {
    title: "Eco T-Shirt",
    color: true,
    size: true,
    gender: true,
    images: {
      black: {
        male: [
          blackTshirtManFront,
          blackTshirtManBack,
          blackTshirtFront,
          blackTshirtBack
        ],
        female: [
          blackTshirtWomanFront,
          blackTshirtWomanBack,
          blackTshirtFront,
          blackTshirtBack
        ]
      },
      white: {
        male: [tshirtManFront, tshirtManBack, tshirtFront, tshirtBack],
        female: [tshirtWomanFront, tshirtWomanBack, tshirtFront, tshirtBack]
      }
    },
    price: 1000
  },
  {
    title: "Eco Hoodie",
    color: true,
    size: true,
    gender: true,
    images: {
      white: {
        male: [maleHoodieWhite, maleHoodieWhite1, hoodieWhite, hoodieWhite1],
        female: [
          femaleHoodieWhite,
          femaleHoodieWhite1,
          hoodieWhite,
          hoodieWhite1
        ]
      },
      black: {
        male: [
          blackHoodieManFront,
          blackHoodieManBack,
          blackHoodieFront,
          blackHoodieBack
        ],
        female: [
          blackHoodieWomanFront,
          blackHoodieWomanBack,
          blackHoodieFront,
          blackHoodieBack
        ]
      }
    },
    price: 2250
  },
  {
    title: "Eco iPhone Case",
    images: {
      "iPhone 12 Pro": [iphone],
      "iPhone 12 Pro Max": [iphoneMax]
    },
    options: {
      title: "Model",
      options: ["iPhone 12 Pro", "iPhone 12 Pro Max"]
    },
    price: 1000
  }
];

const useStyles = makeStyles(theme => ({
  cards: props => ({
    width: "100%",
    maxWidth: 950,
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    [props.customBreakpoint]: {
      flexDirection: "row"
    }
  })
}));

function StoreItems({ showDesktopLayout }) {
  const customBreakpoint = "@media (min-width:1000px)";
  const classes = useStyles({ customBreakpoint });
  const isDesktop = useMediaQuery(customBreakpoint);

  const subscriptionItems = [
    {
      title: "Subscription \nPlus (monthly)",
      component: <SubscriptionCard isDesktop={isDesktop} period={"monthly"} />,
      price: 750
    },
    {
      title: "Subscription \nPlus (yearly)",
      component: <SubscriptionCard isDesktop={isDesktop} period={"yearly"} />,
      price: 6000
    },
    {
      title: "Top up \nbalance for $10",
      component: <TopUpBalanceCard isDesktop={isDesktop} />,
      price: 500
    }
  ];

  return (
    <Box width={showDesktopLayout ? "auto" : "100%"}>
      <Box mx={showDesktopLayout ? 0 : "auto"} className={classes.cards}>
        {subscriptionItems.map((item, index) => {
          return (
            <Box
              mb={isDesktop ? 5 : 3}
              key={index}
              mx={"auto"}
              width={isDesktop ? 290 : 325}
            >
              <SmallItem
                component={item.component}
                price={item.price}
                title={item.title}
                isDesktop={isDesktop}
              />
            </Box>
          );
        })}
      </Box>
      <Box mx={showDesktopLayout ? 0 : "auto"} className={classes.cards}>
        {shopItems.map((item, index) => {
          return (
            <Box
              mb={isDesktop ? 5 : 3}
              key={index}
              mx={"auto"}
              width={isDesktop ? 290 : 325}
            >
              <ProductItem item={item} />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

export default StoreItems;
