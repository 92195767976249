import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { figmaFonts, palette } from "../../../../theme";
import TextFieldWithFocusListeners from "../../../../components/TextFieldWithFocusListeners";
import useBreakpoints from "../../../../utils/useBreakpoints";
import clsx from "clsx";
import { formatPhoneNumberIntl } from "react-phone-number-input";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%"
  },
  gridItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    flexDirection: "column"
  },
  title: {
    height: 31,
    whiteSpace: "nowrap",
    overflowX: "hidden",
    textOverflow: "ellipsis",
    width: "100%"
  },
  titleDesktop: {
    height: 39
  },
  gridItemLeft: {
    alignItems: "flex-start"
  },
  gridItemRight: {
    alignItems: "flex-end"
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 337
  },
  numberInput: {
    ...figmaFonts.mobileH1,
    caretColor: theme.palette.primary.main,
    padding: 0,
    textAlign: "center",
    "&::placeholder": {
      color: palette.text.grey
    },
    [theme.breakpoints.up("lg")]: {
      ...figmaFonts.desktopH1
    }
  },
  statusContainer: {
    display: "flex",
    justifyContent: "center",
    height: 20,
    alignItems: "center"
  },
  statusContainerDesktop: {
    height: 24
  },
  pricePerMinute: {
    color: theme.palette.text.grey,
    whiteSpace: "nowrap"
  }
}));

const PhoneCallTopBarDesktop = ({
  onInputChange,
  value,
  statusText,
  title
}) => {
  const classes = useStyles();

  const { isDesktop } = useBreakpoints();

  const onInputFocus = () => {
    if (!value) {
      onInputChange("+");
    }
  };

  return (
    <Grid container className={classes.container}>
      <Grid
        className={clsx(classes.gridItem, classes.gridItemLeft)}
        item
        lg={3}
      ></Grid>

      <Grid className={classes.gridItem} item xs={12} lg={6}>
        {title && (
          <Typography
            className={clsx(classes.title, {
              [classes.titleDesktop]: isDesktop
            })}
            variant="h1"
          >
            {title}
          </Typography>
        )}
        {onInputChange && !title && (
          <TextFieldWithFocusListeners
            inputProps={{ className: classes.numberInput }}
            InputProps={{
              disableUnderline: true,
              type: "tel",
              onFocus: onInputFocus
            }}
            className={classes.numberInput}
            value={formatPhoneNumberIntl(value) || value}
            onChange={event => onInputChange(event.target.value)}
            placeholder={"Enter phone number"}
          />
        )}
      </Grid>

      <Grid
        className={clsx(classes.gridItem, classes.gridItemRight)}
        item
        lg={3}
      ></Grid>

      <Grid item xs={12}>
        <div
          className={clsx(classes.statusContainer, {
            [classes.statusContainerDesktop]: isDesktop
          })}
        >
          {statusText && (
            <Typography
              className={classes.pricePerMinute}
              variant={"subtitle1"}
            >
              {statusText}
            </Typography>
          )}
        </div>
      </Grid>
    </Grid>
  );
};
export default PhoneCallTopBarDesktop;
