import InputGroup from "./InputGroup/InputGroup";
import React, { useContext, useEffect, useState } from "react";
import { setTokenValue } from "../utils/localStorage";
import { BaseContext } from "../utils/baseProvider";
import withRequest from "../utils/withRequest";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { appStore } from "../utils/stores/AppStore";

const useStyles = makeStyles(theme => ({
  submit: {
    [theme.breakpoints.up("lg")]: {
      marginTop: 3
    }
  }
}));

function SignInForm(props) {
  const baseContext = useContext(BaseContext);
  const classes = useStyles();
  const [formData, setFormData] = useState({ email: "", password: "" });
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState({ email: "", password: "" });

  useEffect(() => {
    checkDisabled();
    // eslint-disable-next-line
  }, [formData]);

  function checkDisabled() {
    if (formData.email.length < 5 || formData.password.length < 8) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }

  function onChange(event) {
    setError({ ...error, [event.target.name]: undefined });
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  }

  function send(event) {
    event.stopPropagation();
    event.preventDefault();

    props.postDataWithCallback(
      "/api/signin",
      formData,
      data => {
        if (data !== "err") {
          setTokenValue(data);
          appStore.setAuthorized(undefined);
          baseContext.setCurrentUser(undefined);
          props.onSignInSuccess();
        }
      },
      error => {
        if (error === "no user") {
          setError({ ...error, email: "Email not found" });
        }

        if (error === "wrong password") {
          setError({ ...error, password: "Password is not correct" });
        }
      }
    );
  }

  return (
    <form noValidate onSubmit={send}>
      <InputGroup
        label="Email"
        required
        id="email"
        placeholder="name@domain.com"
        name="email"
        autoComplete="email"
        onChange={onChange}
        type={"email"}
        error={error["email"]}
      />
      <InputGroup
        label="Password"
        required
        name="password"
        placeholder="At least 8 characters"
        type="password"
        id="password"
        autoComplete="password"
        onChange={onChange}
        error={error["password"]}
      />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        size="large"
        className={classes.submit}
        disabled={disabled}
      >
        SIGN IN
      </Button>
    </form>
  );
}

export default withRequest(SignInForm);
