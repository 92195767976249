import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Visibility } from "./icons/visibility.svg";
import { ReactComponent as VisibilityOff } from "./icons/visibilityOff.svg";
import TextFieldWithFocusListeners from "../TextFieldWithFocusListeners";
import {
  Box,
  IconButton,
  InputAdornment,
  InputLabel,
  Typography
} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  labelBox: {
    display: "flex",
    justifyContent: "space-between"
  },
  // create new typography variant?
  inputLabel: {
    color: theme.palette.text.default
  },
  textField: {
    background: theme.palette.background.input,
    borderRadius: "10px",
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
    padding: 0
  }
}));

function InputGroup(props) {
  const classes = useStyles();
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const getInputType = () => {
    if (props.type === "password") {
      return showPassword ? "text" : "password";
    }

    return props.type || "text";
  };

  let multilineProps = {};
  if (props.multiline) {
    multilineProps = {
      multiline: true,
      rows: props.rows,
      maxRows: props.maxRows
    };
  }

  return (
    <Fragment>
      <Box className={classes.labelBox}>
        <InputLabel className={classes.inputLabel}>{props.label}</InputLabel>
        {props.error && (
          <Typography color="error" variant="subtitle2">
            {props.error}
          </Typography>
        )}
      </Box>

      <TextFieldWithFocusListeners
        className={classes.textField}
        variant="outlined"
        margin="normal"
        fullWidth
        type={getInputType()}
        error={!!props.error}
        required={props.required}
        name={props.name}
        placeholder={props.placeholder}
        id={props.id}
        autoFocus={props.autoFocus}
        autoComplete={props.autoComplete}
        onChange={props.onChange}
        defaultValue={props.defaultValue}
        value={props.value}
        disabled={props.disabled}
        minRows={props.minRows}
        maxRows={props.maxRows}
        helperText={!props.error && props.helperText}
        InputProps={{
          className: props.inputClassName,
          startAdornment: props.startAdornment,
          endAdornment:
            props.type === "password" ? (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ) : props.endAdornment ? (
              props.endAdornment
            ) : null
        }}
        style={props.style || {}}
        {...multilineProps}
      />
    </Fragment>
  );
}

export default InputGroup;
