import moment from "moment";

export function formatElapsed(seconds) {
  let timeString = new Date(seconds * 1000).toISOString();

  if (seconds >= 3600) {
    timeString = timeString.substr(11, 8);
  } else {
    timeString = timeString.substr(14, 5);
  }

  return timeString;
}

// format seconds to XX:XX format
export function getFormattedDuration(seconds) {
  if (seconds < 0) {
    return "00:00";
  }
  return (
    (parseInt(seconds / 60) < 10 ? "0" : "") +
    parseInt(seconds / 60) +
    ":" +
    (parseInt(seconds % 60) < 10 ? "0" : "") +
    parseInt(seconds % 60)
  );
}

export function getFormattedDate(seconds) {
  const date = new Date(seconds * 1000);

  return (
    date.toLocaleDateString("en-US").replace(/\//g, ".") +
    ", " +
    addLeadingZero(date.getHours()) +
    ":" +
    addLeadingZero(date.getMinutes())
  );
}

export function getFormattedBalance(seconds) {
  return seconds < 3600
    ? new Date(seconds * 1000).toISOString().substr(14, 5)
    : "60:00";
}

function addLeadingZero(value) {
  if (value < 10) {
    return `0${value}`;
  }
  return value;
}

export function formatTimeForChatList(date) {
  const d = moment(date);
  if (d.isSame(new Date(), "day")) {
    return d.format("HH:mm");
  }
  if (d.isSame(new Date(), "week")) {
    return d.format("dddd");
  }
  return d.format("DD.MM.YYYY");
}

export function formatTimeForChatMessages(date) {
  const d = moment(date);
  if (d.isSame(new Date(), "day")) {
    return "Today";
  }
  if (d.add(1, "day").isSame(new Date(), "day")) {
    return "Yesterday";
  }
  if (d.isSame(new Date(), "year")) {
    return d.format("MMMM DD");
  }
  return d.format("DD.MM.YYYY");
}

export function formatTimeForCallMessages(seconds) {
  if (seconds < 60) {
    return `${seconds} seconds`;
  }
  return getFormattedDuration(seconds);
}
