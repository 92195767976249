import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as BalanceIconGreen } from "../../../Dashboard/components/BonusBalanceCard/assets/balanceIconGreen.svg";
import { figmaFonts } from "../../../../../../theme";

const useStyles = makeStyles(theme => ({
  bonusBalanceCard: {
    display: "flex",
    backgroundColor: theme.palette.background.green,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    height: "100%",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(0, 1.5)
    }
  },
  bonusBalanceText: {
    ...figmaFonts.desktopSmallTextMedium,
    marginLeft: theme.spacing(0.5),
    whiteSpace: "nowrap"
  },
  availableBonusCard: {
    display: "flex",
    height: "100%",
    alignItems: "center",

    backgroundColor: theme.palette.background.lightGreen,
    borderTopRightRadius: 5,
    borderBottomRightRadius: 5,
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(0, 3)
    }
  },
  availableBalanceText: {
    color: theme.palette.background.green,
    whiteSpace: "nowrap",
    fontWeight: 500
  }
}));

function StoreBalance({ balance = 0, showDesktopLayout }) {
  const classes = useStyles();

  return (
    <Box
      height={32}
      display={"flex"}
      justifyContent={"center"}
      maxWidth={370}
      width={"100%"}
      mx={showDesktopLayout ? 0 : "auto"}
    >
      <Box className={classes.bonusBalanceCard}>
        <BalanceIconGreen />
        <Typography
          color={"textSecondary"}
          className={classes.bonusBalanceText}
        >
          Bonus balance
        </Typography>
      </Box>
      <Box className={classes.availableBonusCard}>
        <Typography variant={"subtitle1"}>Available</Typography>&nbsp;
        <Typography
          variant={"subtitle1"}
          className={classes.availableBalanceText}
        >
          {balance} bonuses
        </Typography>
      </Box>
    </Box>
  );
}
export default StoreBalance;
