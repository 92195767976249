import React, { useContext, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import ButtonToggle from "./components/ButtonToggle";
import SubscriptionPlan from "./components/SubscriptionPlan";
import { palette } from "../../theme";
import CustomAccordion from "./components/CustomAccordion";
import useStripe from "../../utils/useStripe";
import { BaseContext } from "../../utils/baseProvider";
import withRequest from "../../utils/withRequest";
import { plans } from "../../constants/plans";
import { FAQ_TEXT } from "../../constants/FAQ";

function UnsubscribedContent({ postDataWithCallback, desktopMode }) {
  const baseContext = useContext(BaseContext);
  const [value, setValue] = useState("month");

  const stripe = useStripe({
    postDataWithCallback
  });

  function handleChange(event, value) {
    if (value) {
      setValue(value);
    }
  }

  function selectBasicPlan() {
    // select basic plan
  }

  const currentPlan = baseContext.currentUser && baseContext.currentUser.plan;

  return (
    <Box
      maxWidth={"100%"}
      mt={desktopMode ? 0 : 1}
      display={"flex"}
      flexDirection={"column"}
      alignItems={desktopMode ? "flex-start" : "initial"}
      pt={desktopMode ? 5 : 0}
      pl={desktopMode ? 8 : 3.125}
      pr={desktopMode ? 0 : 3.125}
    >
      <Typography variant={"h2"} align={"center"}>
        Choose your plan
      </Typography>
      <Box
        display={"flex"}
        justifyContent={"center"}
        mb={1}
        mt={desktopMode ? 2 : 0.75}
        alignItems={"center"}
      >
        <ButtonToggle
          onChange={handleChange}
          value={value}
          items={[
            { label: "Pay monthly", value: "month" },
            { label: "Pay yearly", value: "year" }
          ]}
        />

        {desktopMode && (
          <Box ml={5}>
            <Typography
              align={"center"}
              variant={"subtitle2"}
              color={"primary"}
            >
              Save around 34% when you pay yearly
            </Typography>
          </Box>
        )}
      </Box>

      {!desktopMode && (
        <Typography align={"center"} variant={"subtitle2"} color={"primary"}>
          Save around 34% when you pay yearly
        </Typography>
      )}

      <Box mt={desktopMode ? 3 : 4} mb={5}>
        <Box display={"flex"} justifyContent={"center"}>
          <SubscriptionPlan
            currentPlan={currentPlan}
            chipPlan={"basic"}
            price={plans["basic"].prices[value]}
            period={value}
            durationMin={plans["basic"].duration}
            onButtonClick={currentPlan !== "basic" ? selectBasicPlan : null}
          />
          <SubscriptionPlan
            currentPlan={currentPlan}
            chipPlan={"plus"}
            price={plans["plus"].prices[value]}
            period={value}
            durationMin={plans["plus"].duration}
            onButtonClick={stripe && value ? stripe[value] : stripe[value]}
          />
        </Box>
      </Box>

      <Box color={palette.text.grey}>
        <Box mb={0.5}>
          <Typography
            align={desktopMode ? "left" : "center"}
            variant={"subtitle2"}
          >
            <sup
              style={{
                lineHeight: 0
              }}
            >
              1
            </sup>
            &nbsp;Each next minute costs ${plans.pricePerMinute}
          </Typography>
        </Box>
        <Box mb={0.5}>
          <Typography
            align={desktopMode ? "left" : "center"}
            variant={"subtitle2"}
          >
            <sup
              style={{
                lineHeight: 0
              }}
            >
              2
            </sup>
            &nbsp;Phone calls are paid according to the tariff.
          </Typography>
        </Box>
        <Typography
          align={desktopMode ? "left" : "center"}
          variant={"subtitle2"}
        >
          All prices are in U.S. dollars
        </Typography>
      </Box>

      <Box mt={desktopMode ? 1.25 : 5}>
        {FAQ_TEXT.map((item, index) => {
          return !desktopMode ? (
            <CustomAccordion item={item} key={index} />
          ) : (
            <Box
              width={"100%"}
              maxWidth={700}
              my={3.75}
              color={palette.text.grey}
              key={index}
            >
              <Box mb={1}>
                <Typography variant={"subtitle1"} color={"textPrimary"}>
                  {item.question}
                </Typography>
              </Box>
              <Typography variant={"subtitle2"}>{item.answer}</Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

export default withRequest(UnsubscribedContent);
