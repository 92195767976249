import React from "react";
import { Box, Typography } from "@material-ui/core";
import BrokenLinkIcon from "./brokenLink.svg";
import { makeStyles } from "@material-ui/core/styles";
import withBottomBar from "../../components/BottomNavBar/withBottomBar";
import BasePage from "../BasePage/BasePage";
import useAppBarHeight from "../../utils/useAppBarHeight";
import { appStore } from "../../utils/stores/AppStore";
import { observer } from "mobx-react";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    flex: 1
  },
  emptyImg: {
    alignSelf: "center",
    maxWidth: 200,
    [theme.breakpoints.up("lg")]: {
      maxWidth: 292
    }
  }
}));

function BrokenLinkPage() {
  const appBarHeight = useAppBarHeight();
  const classes = useStyles({ appBarHeight });

  let boxStyle = {};
  if (appStore.authorized) {
    boxStyle = {
      height: `calc(100% - ${2 * appBarHeight}px)`
    };
  }

  return (
    <BasePage>
      <Box {...boxStyle} className={classes.container}>
        <Box m={"auto"}>
          <img
            className={classes.emptyImg}
            src={BrokenLinkIcon}
            alt={"Oops, link is not active"}
          />
          <Box mt={5}>
            <Typography variant={"h2"}>Oops, link is not active</Typography>
          </Box>
        </Box>
      </Box>
    </BasePage>
  );
}

export default withBottomBar(observer(BrokenLinkPage));
