import React, { useCallback, useState } from "react";
import { Box, Typography } from "@material-ui/core";
import useBreakpoints from "../../../../utils/useBreakpoints";
import { makeStyles } from "@material-ui/core/styles";
import { figmaFonts } from "../../../../theme";
import StyledLink from "../../../../components/StyledLink";
import ChatMenuModal from "../ChatMenu/ChatMenuModal";

const useStyles = makeStyles(theme => ({
  container: {
    background: theme.palette.background.callGrey,
    height: 69,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  text: {
    ...figmaFonts.textRegular16,
    color: theme.palette.text.grey,
    marginRight: theme.spacing(2)
  },
  button: {
    ...figmaFonts.desktopLink
  }
}));

const ChatRemoteBanPlaceHolder = ({ chat }) => {
  const classes = useStyles();
  const { isMobile } = useBreakpoints();
  const [modalType, setModalType] = useState();

  const onUnblock = useCallback(() => {
    setModalType("block");
  }, []);

  return (
    <Box className={classes.container}>
      {!isMobile && (
        <Typography className={classes.text}>
          Can't send a message to blocked contact {chat.title}
        </Typography>
      )}

      <StyledLink className={classes.button} onClick={onUnblock}>
        Unblock
      </StyledLink>

      <ChatMenuModal
        chat={chat}
        onClose={() => setModalType(undefined)}
        modalType={modalType}
      />
    </Box>
  );
};

export default ChatRemoteBanPlaceHolder;
