import React from "react";
import { Box, Typography } from "@material-ui/core";
import BillingPlaceholderIcon from "../../../assets/emptyPageIcon.png";
import { makeStyles } from "@material-ui/core/styles";
import { figmaFonts } from "../../../theme";

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: "center",
    position: "absolute",
    inset: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column"
  },
  title: {
    ...figmaFonts.textMedium18,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(3),
    color: theme.palette.text.grey
  },
  subtitle: {
    ...figmaFonts.textRegular16,
    color: theme.palette.text.grey
  }
}));

const EmptySearch = ({ name, store }) => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <img src={BillingPlaceholderIcon} alt={"empty"} />
      <Typography className={classes.title}>No results</Typography>
      <Typography className={classes.subtitle}>
        There were no results for “{name}”.
      </Typography>
      <Typography className={classes.subtitle}>Try a new search</Typography>
    </Box>
  );
};

export default EmptySearch;
