import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography
} from "@material-ui/core";
import { ReactComponent as CloseIcon } from "./closeIcon.svg";
import { makeStyles } from "@material-ui/core/styles";
import { figmaFonts } from "../../theme";

const useStyles = makeStyles(theme => ({
  dialog: {
    // zIndex: 1301 + " !important"
  },
  dialogContent: {
    minWidth: 100,
    minHeight: 100,
    padding: 0,
    paddingTop: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      paddingTop: theme.spacing(5)
    }
  },
  iconButton: {
    zIndex: 1302,
    position: "absolute",
    width: 40,
    height: 40,
    top: 0,
    right: 0,
    [theme.breakpoints.up("md")]: {
      top: 4,
      right: 4
    }
  },
  title: {
    position: "absolute",
    top: 24,
    left: 24,
    ...figmaFonts.desktopCapsBtn14,
    [theme.breakpoints.up("md")]: {
      position: "relative",
      top: 0,
      left: 0,
      ...figmaFonts.desktopH2,
      textTransform: "initial"
    }
  }
}));

function ModalDialog({
  handleClose,
  open,
  children,
  title,
  transitionDuration,
  hideCloseButton
}) {
  const classes = useStyles();

  return (
    <Dialog
      className={classes.dialog}
      maxWidth={"lg"}
      onClose={handleClose}
      open={open}
      BackdropProps={{ className: classes.dialog }}
      PaperProps={{ className: classes.dialog }}
      transitionDuration={transitionDuration}
    >
      {title && (
        <Box textAlign={"center"} whiteSpace={"pre"} mt={5}>
          <Typography className={classes.title}>{title}</Typography>
        </Box>
      )}

      {!hideCloseButton && (
        <IconButton onClick={handleClose} className={classes.iconButton}>
          <CloseIcon />
        </IconButton>
      )}

      <DialogContent className={!hideCloseButton ? classes.dialogContent : ""}>
        {children}
      </DialogContent>
    </Dialog>
  );
}

export default ModalDialog;
