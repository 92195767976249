import React from "react";
import { Box, Button, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import RewardCard from "./components/RewardCard/RewardCard";
import { ReactComponent as CoinIconOne } from "./assets/coinOne.svg";
import { ReactComponent as CoinIconThree } from "./assets/coinThree.svg";
import { ReactComponent as CoinIconFive } from "./assets/coinsFive.svg";
import withBottomBar from "../../../../components/BottomNavBar/withBottomBar";
import { ReactComponent as CoinIconSmall } from "../../../../assets/bonuses/coinIconSmall.svg";
import IconBadge from "../../../../components/IconBadge";
import { ReactComponent as WarnIcon } from "../../../SubscriptionPage/icons/warnIcon.svg";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import img from "./assets/getTheLink.svg";
import img_1 from "./assets/inviteFriendsImage.svg";
import img_2 from "./assets/WelcomeImage.png";
import BottomBlock from "./components/BottomBlock/BottomBlock";
import theme, { figmaFonts, palette } from "../../../../theme";
import useBreakpoints from "../../../../utils/useBreakpoints";

const useStyles = makeStyles(theme => ({
  cardsMobile: {
    maxWidth: "100vw",
    overflowX: "auto",
    display: "flex",
    WebkitOverflowScrolling: "touch",
    justifyContent: "center",
    [theme.breakpoints.up("lg")]: {
      justifyContent: "flex-start"
    },
    "&>div": {
      marginRight: theme.spacing(2),
      flexShrink: 0,
      [theme.breakpoints.up("lg")]: {
        marginRight: theme.spacing(6.875)
      },
      "&:last-of-type": {
        marginRight: 0
      }
    }
  },
  cards: props => ({
    width: 950,
    maxWidth: "100vw",

    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    [props.rewardCardBreakpoint]: {
      flexDirection: "row"
    }
  }),
  cardImg: {
    width: "100%"
  },
  subtitle: {
    color: theme.palette.text.grey
  },
  bottomCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    textAlign: "center",
    whiteSpace: "pre",
    backgroundColor: theme.palette.background.lightGrey,
    borderRadius: 5,
    width: 215,
    height: 250,
    [theme.breakpoints.up("md")]: {
      width: 280,
      height: 285
    }
  },
  bottomCardButton: {
    ...figmaFonts.desktopCapsBtn14,
    width: 124,
    height: 36,
    [theme.breakpoints.up("md")]: {
      width: 162,
      height: 48
    }
  },
  noteText: {
    ...figmaFonts.mobileSmallTextMedium,
    [theme.breakpoints.up("md")]: {
      ...figmaFonts.desktopSmallTextMedium
    }
  },
  noteTextLink: {
    cursor: "pointer"
  }
}));

function DetailedRules({ showDesktopLayout }) {
  const rewardCardBreakpoint = "@media (min-width:850px)";
  const classes = useStyles({ rewardCardBreakpoint });
  const rewardCardCollapse = useMediaQuery(rewardCardBreakpoint);
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  const history = useHistory();
  const { isMobile } = useBreakpoints();

  function openInvitePage() {
    history.push("/bonus?tab=3", { tab: 3 });
  }

  const textAlign = showDesktopLayout ? "left" : "center";
  return (
    <Box pb={4}>
      {!showDesktopLayout && (
        <Box textAlign={"center"} mt={isDesktop ? 5 : 2} mb={2}>
          <Typography variant={"h1"}>Detailed rules</Typography>
        </Box>
      )}

      <Box
        mb={isDesktop ? 5 : 3}
        textAlign={textAlign}
        whiteSpace={"pre"}
        display={"flex"}
      >
        <Typography variant={"h2"}>
          How do I get bonuses?{isDesktop ? " " : "\n"}We award bonuses for:
        </Typography>
        {showDesktopLayout && (
          <Box
            display={"flex"}
            alignItems={"center"}
            ml={"auto"}
            justifyContent={"center"}
            mb={2}
            px={1}
            py={0.5}
            borderRadius={5}
            style={{ background: palette.background.input }}
          >
            <Typography style={{ ...figmaFonts.desktopSmallTextMedium }}>
              1 bonus = 1
            </Typography>
            <Box width={23} height={23} ml={1}>
              <CoinIconSmall />
            </Box>
          </Box>
        )}
      </Box>

      <Box
        mx={showDesktopLayout ? 0 : "auto"}
        mb={rewardCardCollapse ? 2 : 0}
        px={showDesktopLayout ? 0 : 25 / 8}
        className={classes.cards}
      >
        <RewardCard
          bonus={1}
          icon={<CoinIconOne />}
          text={"Users who followed your\nreferral link and registered"}
        />
        <RewardCard
          bonus={100}
          icon={<CoinIconThree />}
          text={"Invited users who bought\na subscription Plus (monthly)"}
        />
        <RewardCard
          bonus={500}
          icon={<CoinIconFive />}
          text={"Invited users who bought\na subscription Plus (yearly)"}
        />
      </Box>

      {!showDesktopLayout && (
        <Box
          display={"flex"}
          alignItems={"center"}
          mx={"auto"}
          justifyContent={"center"}
          mb={2}
        >
          <Typography variant={"h2"}>1 bonus = 1</Typography>
          <Box width={24} height={24} ml={1}>
            <CoinIconSmall />
          </Box>
        </Box>
      )}

      <Box mx={isMobile ? 3.125 : 0}>
        <Typography
          align={textAlign}
          className={classes.subtitle}
          variant={"subtitle2"}
        >
          * the accrual of bonuses for the subscription is made in 30 days from
          the moment of connection.
        </Typography>

        <Box mt={2} width={"fit-content"} mx={showDesktopLayout ? 0 : "auto"}>
          <IconBadge icon={<WarnIcon />}>
            <Typography display={"inline"} className={classes.noteText}>
              The bonus is credited&nbsp;
              <Typography
                component={"span"}
                display={"inline"}
                className={clsx(classes.noteText, classes.noteTextLink)}
                color={"primary"}
              >
                only for the first month / year&nbsp;
              </Typography>
              of the purchased subscription.
            </Typography>
          </IconBadge>
        </Box>
      </Box>

      <Box mt={isDesktop ? 10 : 8}>
        <Box mb={5} textAlign={textAlign} whiteSpace={"pre"}>
          <Typography variant={"h2"}>
            How to participate{isDesktop ? " " : "\n"}in the referral program?
          </Typography>
        </Box>
      </Box>

      <Box
        px={showDesktopLayout ? 0 : 25 / 8}
        mb={isDesktop ? 2 : 0}
        className={classes.cardsMobile}
      >
        <Box onClick={openInvitePage} className={classes.bottomCard}>
          <Box position={"relative"} mb={3}>
            <img className={classes.cardImg} src={img} alt={"card"} />
            <Box position={"absolute"} bottom={5} left={0} right={0}>
              <Button
                variant="contained"
                color="primary"
                onClick={openInvitePage}
                className={classes.bottomCardButton}
              >
                Get the link
              </Button>
            </Box>
          </Box>
          <Typography variant={"subtitle1"}>
            Go to&nbsp;
            <Typography
              component={"span"}
              display={"inline"}
              color={"primary"}
              onClick={openInvitePage}
              variant={"subtitle1"}
            >
              Get the link&nbsp;
            </Typography>
            {"section,\ncopy the link and share it\nwith your friends"}
          </Typography>
        </Box>
        <Box className={classes.bottomCard}>
          <Box mb={3}>
            <img className={classes.cardImg} src={img_1} alt={"card"} />
          </Box>
          <Typography variant={"subtitle1"}>
            {"You can invite \n" +
              "any number of friends \n" +
              "and get a bonus for each"}
          </Typography>
        </Box>
        <Box className={classes.bottomCard}>
          <Box mb={3}>
            <img className={classes.cardImg} src={img_2} alt={"card"} />
          </Box>
          <Typography variant={"subtitle1"}>
            {"The referral program \n" +
              "is unlimited, does not have \n" +
              "a limit on invitations and\nstarts working instantly"}
          </Typography>
        </Box>
      </Box>
      <BottomBlock showDesktopLayout={showDesktopLayout} />
    </Box>
  );
}
export default withBottomBar(DetailedRules);
