import React from "react";
import PlanChip from "../../../components/chips/PlanChip";
import { Box, Button, Card, CardContent, Typography } from "@material-ui/core";
import TextChip from "../../../components/chips/TextChip";
import useBreakpoints from "../../../utils/useBreakpoints";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Check } from "../icons/check.svg";
import { ReactComponent as VideoCamera } from "../icons/videoCamera.svg";
import { ReactComponent as Messages } from "../icons/messages.svg";
import { ReactComponent as Phone } from "../icons/phone.svg";
import { figmaFonts, palette } from "../../../theme";

const useStyles = makeStyles(theme => ({
  card: {
    maxWidth: "50%",
    width: 294,
    borderRadius: 10,
    border: `1px solid ${theme.palette.secondary.main}`,
    marginRight: props => (props.isPlus || !props.isDesktop ? "0" : "32px"),

    boxShadow: props =>
      props.isPlus && !props.isDesktop
        ? "0px 10px 20px rgba(0, 0, 0, 0.07)"
        : "none",
    backgroundColor: props =>
      props.isPlus ? theme.palette.background.default : "initial",
    "&:last-child": {
      borderLeftStyle: props => (!props.isDesktop ? "none" : "solid")
    }
  },
  content: {
    height: "100%",
    padding: 0,
    display: "flex",
    flexDirection: "column",
    "&:last-child": {
      padding: 0
    }
  },
  price: {
    [theme.breakpoints.down("xs")]: {
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(22),
      lineHeight: theme.typography.pxToRem(30)
    }
  },
  pricePeriod: {
    ...figmaFonts.mobileSmallTextRegular,
    [theme.breakpoints.up("lg")]: {
      ...figmaFonts.textRegular16
    }
  },
  smallBadge: {
    ...figmaFonts.mobileSmallTextMedium,
    color: theme.palette.background.green
  },
  minutes: {
    ...figmaFonts.textMedium14,
    [theme.breakpoints.up("lg")]: {
      ...figmaFonts.textMedium18
    }
  },
  button: {
    paddingLeft: 11,
    paddingRight: 11,
    ...figmaFonts.mobileSmallTextMedium,
    fontWeight: "bold",
    height: 36,
    [theme.breakpoints.up("lg")]: {
      ...figmaFonts.desktopCapsBtn14,
      height: 48
    }
  },
  sup: {
    color: theme.palette.text.primary,
    fontSize: 7,
    lineHeight: "10px",
    [theme.breakpoints.up("lg")]: {
      fontSize: 10,
      lineHeight: "13px"
    }
  },
  stripeText: {
    ...figmaFonts.mobileSmallTextMedium,
    [theme.breakpoints.up("lg")]: {
      ...figmaFonts.desktopSmallTextMedium
    }
  }
}));

function SubscriptionPlan({
  chipPlan,
  currentPlan,
  period,
  durationMin,
  price,
  onButtonClick
}) {
  const isCurrentPlan = chipPlan === currentPlan;
  const isPlus = chipPlan === "plus";
  const isYear = period === "year";
  const { isDesktop } = useBreakpoints();
  const classes = useStyles({ isPlus, isDesktop });

  const renderStripe = (icon, text) => {
    return (
      <Box
        mx={isDesktop ? -3 : 0}
        px={isDesktop ? 3 : 1}
        py={0.5}
        mb={1}
        display={"flex"}
        style={{ backgroundColor: palette.secondary.main }}
        alignItems={"center"}
        width={isDesktop ? "initial" : "fit-content"}
        borderRadius={isDesktop ? 0 : 4}
      >
        <Box mr={1} height={14}>
          {icon}
        </Box>
        <Typography className={classes.stripeText} display={"inline"}>
          {text}
        </Typography>
      </Box>
    );
  };

  const renderBulletPoint = text => {
    return (
      <Box mb={0.5} display={"flex"} color={palette.text.grey}>
        <Box mr={1}>
          <Check />
        </Box>
        <Typography variant={"subtitle2"} display={"inline"}>
          {text}
        </Typography>
      </Box>
    );
  };

  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        <Box
          px={isDesktop ? 3 : 3}
          pb={isDesktop ? 3 : 2}
          pt={isDesktop ? 3 : 2}
          borderBottom={`1px solid ${palette.secondary.main}`}
          style={{
            boxShadow: isPlus
              ? `${palette.background.green} 0px 4px 0px 0px inset`
              : "initial"
          }}
        >
          <Box mb={1} display={"flex"} alignItems={"center"}>
            <Box mr={1}>
              <PlanChip plan={chipPlan} />
            </Box>
            {isPlus &&
              isYear &&
              (isDesktop ? (
                <TextChip text={"Save 60$ a year"} />
              ) : (
                <Typography className={classes.smallBadge}>
                  Save&nbsp;$60
                </Typography>
              ))}
          </Box>

          <Box>
            <Typography
              className={classes.price}
              display={"inline"}
              variant={"h1"}
            >
              ${price}
            </Typography>
            <Box color={palette.text.grey} ml={0.5} display={"inline"}>
              <Typography className={classes.pricePeriod} display={"inline"}>
                /{period}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box
          display={"flex"}
          flex={1}
          flexDirection={"column"}
          py={isDesktop ? 3 : 2}
          px={isDesktop ? 3 : 2.75}
        >
          <Box mb={0.75}>
            <Typography className={classes.minutes} display={"inline"}>
              {durationMin.toString()} min
            </Typography>
            {isYear && <>&nbsp;</>}
            <Box color={palette.text.grey} display={"inline"}>
              <Typography variant={"subtitle2"} display={"inline"}>
                {isYear && "/ month"}
                {isPlus && (
                  <Box
                    display={"inline"}
                    style={{ verticalAlign: "text-bottom" }}
                  >
                    &nbsp;
                  </Box>
                )}
              </Typography>
              {isPlus && <sup className={classes.sup}>1</sup>}
              <Box color={palette.text.grey} mt={0.5}>
                <Typography variant={"subtitle2"}>
                  Meetings, calls & chats
                  <br />
                  with AI-based translator
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box mt={1.25}>
            {renderStripe(
              <VideoCamera />,
              isDesktop ? "Audio/video meeting" : "Meetings"
            )}
            {renderBulletPoint("Voice translator")}
            {renderBulletPoint("Meetings transcription")}
            {renderBulletPoint("Translating chat")}
          </Box>

          <Box mt={1.25}>
            {renderStripe(<Messages />, "Messaging")}
            {renderBulletPoint("Translating messages")}
          </Box>

          <Box mt={1.25} mb={isPlus ? 3 : "auto"}>
            {isPlus && (
              <Box mt={1.5}>
                {renderStripe(
                  <Phone />,
                  <>
                    Phone calls&nbsp;<sup className={classes.sup}>2</sup>
                  </>
                )}
                {renderBulletPoint("Voice translator")}
                {renderBulletPoint("Calls transcription")}
              </Box>
            )}
          </Box>
          {currentPlan && (
            <Button
              className={classes.button}
              variant={"contained"}
              color={isCurrentPlan ? "default" : "primary"}
              disabled={isCurrentPlan}
              fullWidth
              onClick={onButtonClick}
            >
              {isCurrentPlan ? <>Current&nbsp;plan</> : "Buy now"}
            </Button>
          )}
        </Box>
      </CardContent>
    </Card>
  );
}

export default SubscriptionPlan;
