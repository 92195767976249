import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";
import { palette } from "../../theme";

const useStyles = makeStyles(theme => ({
  chip: {
    padding: theme.spacing(0.5, 1),
    borderRadius: "5px",
    height: "auto",
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.07)"
  },
  chipLabel: {
    textTransform: "initial"
  },
  chipIcon: {
    margin: 0,
    marginRight: 5
  }
}));
function TextChip({
  text,
  color = palette.background.green,
  backgroundColor = palette.background.default
}) {
  const classes = useStyles();

  return (
    <Chip
      classes={{
        root: classes.chip,
        label: classes.chipLabel,
        icon: classes.chipIcon
      }}
      style={{
        color,
        backgroundColor
      }}
      label={text}
    />
  );
}

export default TextChip;
