import React, { useEffect, useRef } from "react";
import BasePage from "./BasePage/BasePage";
import NotFoundPage from "./NotFoundPage";
import withBottomBar from "../components/BottomNavBar/withBottomBar";
import { appStore } from "../utils/stores/AppStore";
import { observer } from "mobx-react";

function PlugPage() {
  const showTopBar = useRef(false);

  useEffect(() => {
    if (appStore.authorized === undefined) {
      showTopBar.current = false;
    } else {
      showTopBar.current = appStore.authorized;
    }
  }, []);

  return (
    <BasePage showTopBar={showTopBar.current}>
      <NotFoundPage />
    </BasePage>
  );
}

export default withBottomBar(observer(PlugPage));
