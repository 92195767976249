import React, { useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  accordion: {
    border: "none",
    borderRadius: 0,
    borderTop: `1px solid ${theme.palette.background.input}`,
    boxShadow: "none",
    margin: 0,
    padding: theme.spacing(2, 0, 1, 0),
    "&:before": {
      backgroundColor: "transparent"
    },
    "&:last-child": {
      borderBottom: `1px solid ${theme.palette.background.input}`
    },
    "&.Mui-expanded": {
      margin: 0
    }
  },
  summary: {
    marginTop: 0,
    marginBottom: theme.spacing(1),
    padding: 0,
    minHeight: "auto",
    "&.Mui-expanded": {
      marginBottom: theme.spacing(1),
      minHeight: "auto"
    }
  },
  summaryContent: {
    margin: 0,
    "&.Mui-expanded": {
      margin: 0
    }
  },
  details: {
    padding: theme.spacing(0, 0, 1, 0),
    color: theme.palette.text.grey
  }
}));

function CustomAccordion({ item }) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleChange = () => {
    setExpanded(!expanded);
  };
  return (
    <Accordion
      className={classes.accordion}
      expanded={expanded}
      onChange={handleChange}
    >
      <AccordionSummary
        className={classes.summary}
        classes={{ content: classes.summaryContent }}
        expandIcon={null}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant={"subtitle1"}>{item.question}</Typography>
        <Box marginLeft={"auto"}>{expanded ? "-" : "+"}</Box>
      </AccordionSummary>

      <AccordionDetails className={classes.details}>
        <Typography variant={"subtitle2"}>{item.answer}</Typography>
      </AccordionDetails>
    </Accordion>
  );
}
export default CustomAccordion;
