import React, { useCallback, useContext, useEffect, useState } from "react";
import BasePage from "../BasePage/BasePage";
import { useHistory, useParams } from "react-router-dom";
import withRequest from "../../utils/withRequest";
import { BaseContext } from "../../utils/baseProvider";
import { makeStyles } from "@material-ui/core/styles";
import SignInForm from "../../components/SignInForm";
import GuestNameForm from "./GuestNameForm";
import { Box, Button } from "@material-ui/core";
import WaitForConnectionTitle from "../LocalCallPage/WaitForConnectionTitle";
import withBottomBar from "../../components/BottomNavBar/withBottomBar";
import useBreakpoints from "../../utils/useBreakpoints";
import JoinLocalCallPageAuthorized from "./JoinLocalCallPageAuthorized";
import { palette } from "../../theme";
import { appStore } from "../../utils/stores/AppStore";
import { observer } from "mobx-react";
import JoinLocalCallPageCallData from "./components/JoinLocalCallPageCallData";
import { FullHeightContainerWithCenteredContent } from "../../components/layouts";

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: "calc(100vw - 50px)",
    width: "375px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    [theme.breakpoints.up("lg")]: {
      width: "434px"
    }
  },
  linkButton: {
    width: "100%",
    padding: 0,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      marginBottom: theme.spacing(0)
    }
  }
}));

function JoinlocalcallPage({ postDataWithCallback }) {
  const classes = useStyles();
  const baseContext = useContext(BaseContext);
  const history = useHistory();
  const [callerData, setCallerData] = useState();
  const [isGuestMode, setIsGuestMode] = useState(false);
  const { remoteId } = useParams();
  const { isDesktop, isMobile } = useBreakpoints();
  const [callSettings, setCallSettings] = useState({
    from: null,
    to: "",
    toGender: null,
    fromGender: null,
    name: null,
    gender: null
  });

  useEffect(() => {
    baseContext.setShowWaitingForConnection(true);
    baseContext.setSign("in");

    return () => {
      baseContext.setShowWaitingForConnection(false);
    };
    // eslint-disable-next-line
  }, []);

  const getLangTo = useCallback(
    (callerLangTo, callerLangFrom, userDefaultLang = null) => {
      if (userDefaultLang) {
        return userDefaultLang === callerLangFrom
          ? callerLangTo
          : userDefaultLang;
      }

      return callerLangTo || "";
    },
    []
  );

  const loadCaller = useCallback(() => {
    if (remoteId) {
      postDataWithCallback(
        "/api/sfuinfo",
        { id: remoteId },
        data => {
          setCallerData(data);
          const callerLangTo = (data && data.to && data.to.Key) || "";
          const callerLangFrom = (data && data.from && data.from.Key) || null;
          setCallSettings(prevState => ({
            ...prevState,
            to: getLangTo(
              callerLangTo,
              callerLangFrom,
              baseContext.currentUser && baseContext.currentUser.fromLang
            ),
            from: (data && data.from && data.from.Key) || null,
            toGender: data.toGender || null,
            fromGender: data.fromGender || null
          }));
        },
        () => {
          history.push({ pathname: "/invalidUrl" });
        },
        false
      );
    }
  }, [
    baseContext.currentUser,
    getLangTo,
    history,
    postDataWithCallback,
    remoteId
  ]);

  useEffect(() => {
    if (appStore.authorized !== undefined && !callerData) {
      loadCaller();
    }
    // eslint-disable-next-line
  }, [appStore.authorized, callerData]);

  const handleClickJoin = useCallback(() => {
    const state = {
      ...callSettings,
      remoteId
    };

    history.push({ pathname: "/localcall", state: state });
  }, [callSettings, history, remoteId]);

  const onFieldChange = useCallback((key, value) => {
    setCallSettings(prevState => ({
      ...prevState,
      [key]: value
    }));
  }, []);

  if (appStore.authorized === undefined) {
    return <BasePage />; // HACK: to load user initially
  }

  if (appStore.authorized) {
    return <JoinLocalCallPageAuthorized />;
  }

  return (
    <BasePage>
      {!callerData ? null : (
        <FullHeightContainerWithCenteredContent
          disableCentering={isMobile}
          pt={isMobile ? 1 : 0}
        >
          <Box className={classes.root}>
            {!isDesktop && (
              <Box
                mb={3}
                style={{
                  background: palette.background.default
                }}
              >
                <WaitForConnectionTitle forCallee />
              </Box>
            )}
            <Box
              alignSelf={"flex-start"}
              display="flex"
              flexDirection="column"
              width={"100%"}
              mb={isDesktop ? 4 : 0}
            >
              <JoinLocalCallPageCallData
                callerData={callerData}
                callSettings={callSettings}
              />
            </Box>

            <Box mb={3} width={"100%"}>
              {isGuestMode ? (
                <GuestNameForm
                  callSettings={callSettings}
                  onFieldChange={onFieldChange}
                  onSubmit={handleClickJoin}
                />
              ) : (
                <SignInForm onSignInSuccess={handleClickJoin} />
              )}
            </Box>

            <Button
              onClick={() => setIsGuestMode(!isGuestMode)}
              variant="text"
              color="primary"
              className={classes.linkButton}
            >
              {isGuestMode ? "Sign In" : "Continue as Guest"}
            </Button>
          </Box>
        </FullHeightContainerWithCenteredContent>
      )}
    </BasePage>
  );
}

export default withBottomBar(withRequest(observer(JoinlocalcallPage)), false);
