import PlanChip from "./PlanChip";
import { ReactComponent as PlusIcon } from "../../assets/icons/plusIconMain.svg";
import BalanceEmptyModal from "../BalanceEmptyModal/BalanceEmptyModal";
import React, { useContext, useState } from "react";
import { BaseContext } from "../../utils/baseProvider";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { figmaFonts } from "../../theme";

const useStyles = makeStyles(theme => ({
  balanceChip: {
    display: "flex",
    alignItems: "center"
  },
  primaryText: {
    color: theme.palette.primary.main
  },
  plusIcon: {
    cursor: "pointer"
  },
  text: {
    ...figmaFonts.textMedium16
  },
  chip: {
    height: 40,
    marginRight: "-32px !important",
    paddingRight: 40,
    borderRadius: 10,
    "& svg": {
      width: 22,
      height: 22
    }
  }
}));

const UsdBalanceChip = ({ large = false }) => {
  const classes = useStyles();

  const baseContext = useContext(BaseContext);
  const [balanceEmptyModalOpen, setBalanceEmptyModalOpen] = useState(false);
  return (
    <div className={clsx(classes.balanceChip)}>
      <PlanChip
        className={large ? classes.chip : ""}
        plan={"save"}
        text={
          <span className={large ? classes.text : ""}>
            Balance:{" "}
            <span className={classes.primaryText}>
              ${baseContext.centsBalance / 100}
            </span>
          </span>
        }
      />
      <PlusIcon
        onClick={() => setBalanceEmptyModalOpen(true)}
        className={classes.plusIcon}
      />

      <BalanceEmptyModal
        type={"topUpUsdBalance"}
        open={balanceEmptyModalOpen}
        handleClose={() => setBalanceEmptyModalOpen(false)}
      />
    </div>
  );
};

export default UsdBalanceChip;
