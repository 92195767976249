import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { BaseContext } from "../../utils/baseProvider";
import { Box } from "@material-ui/core";
import ChipWithBottomText from "../ChipWithBottomText";
import PlanChip from "../chips/PlanChip";
import { useHistory } from "react-router-dom";
import { getFormattedDuration } from "../../utils/time";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column",
    cursor: "pointer"
  },
  chip: {
    padding: theme.spacing(0.5, 1),
    borderRadius: "5px",
    marginBottom: "5px",
    height: "auto",
    backgroundColor: theme.palette.background.input
  },
  chipLabel: {
    color: theme.palette.primary.main,
    textTransform: "capitalize"
  },
  chipIcon: {
    margin: 0,
    marginRight: 5
  },
  text: {
    color: theme.palette.text.grey,
    textAlign: "left"
  }
}));

function Plan() {
  const history = useHistory();
  const classes = useStyles();

  const baseContext = useContext(BaseContext);

  if (
    baseContext.secondsBalance === undefined ||
    !baseContext.currentUser.until
  ) {
    return null;
  }

  const formattedUntil = new Date(
    baseContext.currentUser.until * 1000
  ).toLocaleDateString("en-US");

  function planOnClick() {
    history.push("/subscription");
  }

  return (
    <Box onClick={planOnClick} className={classes.root}>
      <ChipWithBottomText
        text={`until ${formattedUntil}`}
        chip={
          <PlanChip
            text={`(${getFormattedDuration(baseContext.secondsBalance)})`}
            plan={baseContext.currentUser.plan}
          />
        }
      />
    </Box>
  );
}

export default Plan;
