import React, { useCallback, useEffect, useState } from "react";
import { appEmitter } from "../../utils/events/appEmitter";
import { Box, Typography } from "@material-ui/core";
import ModalDialog from "../ModalDialog/ModalDialog";
import useBreakpoints from "../../utils/useBreakpoints";

const NotificationModal = () => {
  const [open, setOpen] = useState(false);
  const [text, setText] = useState();
  const { isDesktop } = useBreakpoints();

  const onFullScreenNotification = useCallback(params => {
    setText(params.text);
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 2000);
  }, []);

  useEffect(() => {
    const cleaner = appEmitter.on(
      "onFullScreenNotification",
      onFullScreenNotification
    );
    return () => {
      cleaner();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <ModalDialog hideCloseButton open={open} handleClose={() => setOpen(false)}>
      <Box px={3} pb={1} mt={-0.5}>
        <Typography
          style={{ whiteSpace: isDesktop ? "initial" : "break-spaces" }}
          align={"center"}
          variant={"h2"}
        >
          {text}
        </Typography>
      </Box>
    </ModalDialog>
  );
};

export default NotificationModal;
