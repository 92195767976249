import { withStyles } from "@material-ui/core/styles";
import { figmaFonts } from "../../theme";
import { TableCell } from "@material-ui/core";

const StyledTableHeadCell = withStyles(theme => ({
  root: {
    padding: theme.spacing(0, 1, 1.125, 0),
    border: "none",
    color: theme.palette.text.grey,
    ...figmaFonts.mobileSmallTextRegular,
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 3.75, 1.125, 0),
      ...figmaFonts.desktopSmallTextRegular
    }
  }
}))(TableCell);

export default StyledTableHeadCell;
