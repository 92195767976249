import React, { useState } from "react";
import One from "./images/1.png";
import Two from "./images/2.png";
import Three from "./images/3.png";
import Four from "./images/4.png";
import Five from "./images/5.png";
import OneActive from "./images/1-active.png";
import TwoActive from "./images/2-active.png";
import ThreeActive from "./images/3-active.png";
import FourActive from "./images/4-active.png";
import FiveActive from "./images/5-active.png";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  imgBox: {
    width: 48,
    height: 48,
    borderRadius: "50%",
    background: theme.palette.secondary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: theme.spacing(2),
    cursor: "pointer"
  },
  img: {
    width: 32,
    height: 32
  }
}));

function Rating(props) {
  const classes = useStyles();
  const [rating, setRating] = useState(props.default);

  function onRatingClick(rating) {
    setRating(rating);
    props.onChange(rating);
  }

  const butttons = [
    {
      inactive: One,
      active: OneActive
    },
    {
      inactive: Two,
      active: TwoActive
    },
    {
      inactive: Three,
      active: ThreeActive
    },
    {
      inactive: Four,
      active: FourActive
    },
    {
      inactive: Five,
      active: FiveActive
    }
  ];

  return (
    <Box display={"flex"}>
      {butttons.map((btn, index) => {
        return (
          <Box
            onClick={() => onRatingClick(index + 1)}
            key={index}
            className={classes.imgBox}
          >
            <img
              className={classes.img}
              src={rating === index + 1 ? btn.active : btn.inactive}
              alt={"rating"}
            />
          </Box>
        );
      })}
    </Box>
  );
}

export default Rating;
