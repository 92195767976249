import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Box, Typography } from "@material-ui/core";
import moment from "moment";
import { formatTimeForChatMessages } from "../../../../utils/time";
import { makeStyles } from "@material-ui/core/styles";
import { observer } from "mobx-react";
import ChatMessage from "./ChatMessage";
import ChatCallMessage from "./ChatCallMessage";

const useStyles = makeStyles(theme => ({
  container: {
    position: "absolute",
    inset: 0,
    overflowY: "auto",
    padding: theme.spacing(0, 3.125)
  },
  date: {
    color: theme.palette.text.grey,
    fontSize: 11,
    fontWeight: 500,
    lineHeight: "13px",
    textAlign: "center",
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5)
  }
}));

const ChatMessages = ({
  messages,
  chat,
  onReply,
  showAvatars = false,
  store
}) => {
  const classes = useStyles();
  const listEl = useRef();

  const scrollBottom = useCallback(event => {
    const { currentTarget: target } = event;
    target.scroll({ top: target.scrollHeight, behavior: "smooth" });
  }, []);

  useEffect(() => {
    const listRef = listEl.current;
    scrollBottom({ currentTarget: listRef });
    listRef.addEventListener("DOMNodeInserted", scrollBottom);

    return () => {
      listRef.removeEventListener("DOMNodeInserted", scrollBottom);
    };
  }, [scrollBottom]);

  const messagesGroups = {};
  (messages || []).forEach(msg => {
    const ts = moment(msg.date)
      .startOf("day")
      .valueOf();

    if (!messagesGroups[ts]) {
      messagesGroups[ts] = [];
    }
    messagesGroups[ts].push({ ...msg, ts: moment(msg.date).valueOf() });
  });

  const dateGroups = useMemo(() => {
    return Object.keys(messagesGroups)
      .map(key => parseInt(key))
      .sort((a, b) => a - b);
  }, [messagesGroups]);

  return (
    <Box ref={listEl} className={classes.container}>
      {dateGroups.map(ts => {
        return (
          <Box key={ts}>
            <Typography className={classes.date}>
              {formatTimeForChatMessages(ts)}
            </Typography>
            {messagesGroups[ts]
              .sort((a, b) => a.ts - b.ts)
              .map((msg, index) => {
                let sameNext = false;
                let samePrev = false;
                if (messagesGroups[ts][index + 1]) {
                  sameNext =
                    messagesGroups[ts][index + 1].senderId === msg.senderId;
                }
                if (messagesGroups[ts][index - 1]) {
                  samePrev =
                    messagesGroups[ts][index - 1].senderId === msg.senderId;
                }
                if (msg.type === "callMessage") {
                  return (
                    <ChatCallMessage
                      sameNext={sameNext}
                      samePrev={samePrev}
                      showAvatar={showAvatars && !samePrev}
                      showAvatars={showAvatars}
                      chat={chat}
                      message={msg}
                      key={msg.id}
                    />
                  );
                }
                return (
                  <ChatMessage
                    sameNext={sameNext}
                    samePrev={samePrev}
                    showAvatar={showAvatars && !samePrev}
                    showAvatars={showAvatars}
                    chat={chat}
                    message={msg}
                    key={msg.id}
                    onReply={onReply}
                    store={store}
                    showMenu
                  />
                );
              })}
          </Box>
        );
      })}
    </Box>
  );
};

export default observer(ChatMessages);
