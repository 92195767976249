import React from "react";
import TranscriptionPlaceholderIcon from "../icons/TranscriptionPlaceholderIcon.svg";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import useBreakpoints from "../../../utils/useBreakpoints";

const useStyles = makeStyles(theme => ({
  boxContainer: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.text.grey,
    position: "relative",
    [theme.breakpoints.up("lg")]: {
      flexDirection: "column",
      backgroundColor: theme.palette.background.input,
      textAlign: "center"
    }
  },
  title: {
    position: "absolute",
    left: 40,
    top: 24,
    color: theme.palette.text.default
  },
  imgTitle: {
    [theme.breakpoints.up("lg")]: {
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(1)
    }
  },
  img: {
    width: "100%",
    maxWidth: 80,
    [theme.breakpoints.up("lg")]: {
      maxWidth: 200
    }
  }
}));

function TranscriptionPlaceholder({ isPhoneCall }) {
  const classes = useStyles();
  const { isDesktop } = useBreakpoints();
  return (
    <Box mb={isDesktop ? 0 : 3} className={classes.boxContainer}>
      {isDesktop && (
        <>
          <Typography className={classes.title} variant="body1">
            Transcription{isPhoneCall ? "" : " & Chat"}
          </Typography>

          <img
            className={classes.img}
            src={TranscriptionPlaceholderIcon}
            alt={"There is nothing here yet..."}
          />
        </>
      )}

      <Box
        textAlign={"center"}
        display="flex"
        flexDirection="column"
        height={79}
        justifyContent={"center"}
      >
        <Typography className={classes.imgTitle} variant="body1">
          Hello, Let’s Chat!
        </Typography>
        <Typography variant="caption">There is nothing here yet...</Typography>
      </Box>
    </Box>
  );
}
export default TranscriptionPlaceholder;
