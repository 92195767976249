import { Box, Typography } from "@material-ui/core";
import AvatarWithFallback from "../../../components/Avatar/AvatarWithFallback";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { figmaFonts } from "../../../theme";

const useStyles = makeStyles(theme => ({
  name: {
    ...figmaFonts.textMedium14
  },
  username: {
    ...figmaFonts.textRegular14,
    color: theme.palette.primary.main
  }
}));

const SearchUser = ({ user }) => {
  const classes = useStyles();
  return (
    <Box display={"flex"} alignItems={"center"}>
      <Box mr={1.25}>
        <AvatarWithFallback src={user.avatar} width={56} height={56} />
      </Box>
      <Box>
        <Typography className={classes.name}>{user.name}</Typography>
        <Typography className={classes.username}>@{user.username}</Typography>
      </Box>
    </Box>
  );
};

export default SearchUser;
