import React, { useEffect, useRef, useState } from "react";
import IOsSwitch from "./IOsSwitch";
import { ReactComponent as InfoIcon } from "./icons/infoIcon.svg";
import { makeStyles } from "@material-ui/core/styles";
import ChatList from "./ChatList";
import TextInput from "./TextInput";
import { Box, Paper, Popover, Typography } from "@material-ui/core";
import useBreakpoints from "../../../utils/useBreakpoints";

const useStyles = makeStyles(theme => ({
  chatContainer: {
    height: "100%",
    boxShadow: "none",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    backgroundColor: "transparent",
    borderRadius: 0,
    [theme.breakpoints.up("lg")]: {
      backgroundColor: theme.palette.background.input
    }
  },
  popover: {
    marginTop: theme.spacing(1)
  },
  popoverPaper: {
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.07)",
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      marginLeft: theme.spacing(5.75)
    }
  },
  typography: {
    fontWeight: "normal",
    color: theme.palette.text.grey,
    padding: theme.spacing(2),
    maxWidth: 279,
    [theme.breakpoints.up("lg")]: {
      maxWidth: 330,
      padding: theme.spacing(1.5, 2)
    }
  }
}));

function Transcription({
  calleeVideoEnabled,
  currentUserIsCallee,
  sendMessageToDC = null,
  messages,
  setMessageRead,
  translationEnabled,
  isPhoneCall
}) {
  const [popOverOpen, setPopOverOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [showTranslated, setShowTranslated] = useState(true);
  const { isDesktop } = useBreakpoints();

  useEffect(() => {
    if (translationEnabled) {
      setShowTranslated(true);
    }
  }, [translationEnabled]);

  function handleMessageChange(text) {
    setMessage(text);
  }

  function sendMessage() {
    let sendMsg = message.trim();
    if (sendMsg !== "" && sendMessageToDC) {
      sendMessageToDC("message", sendMsg);
    }
    setMessage("");
  }

  const infoIconRef = useRef();
  const classes = useStyles();

  const togglePopOver = () => {
    setPopOverOpen(!popOverOpen);
  };

  const handleSwitchChange = event => {
    if (!event.target.checked && !isDesktop) {
      setPopOverOpen(true);
    }
    setShowTranslated(event.target.checked);
  };

  return (
    <Paper className={classes.chatContainer}>
      <Box
        mx={isDesktop ? 5 : 3.125}
        mt={isDesktop ? 3 : 0}
        mb={isDesktop ? 3 : 2.375}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography
          color={
            isDesktop || (!isDesktop && !calleeVideoEnabled)
              ? "textPrimary"
              : "textSecondary"
          }
          variant="body1"
        >
          Transcription{isPhoneCall ? "" : " & Chat"}
        </Typography>
        <Box display="flex">
          <Box display={"flex"} onMouseEnter={() => setPopOverOpen(true)}>
            <InfoIcon
              ref={infoIconRef}
              style={{ visibility: isDesktop ? "hidden" : "visible" }}
            />
            <IOsSwitch
              disabled={!translationEnabled}
              checked={translationEnabled && showTranslated}
              onChange={handleSwitchChange}
            />
          </Box>
          <Popover
            open={popOverOpen}
            onClick={togglePopOver}
            anchorEl={infoIconRef.current}
            className={classes.popover}
            PaperProps={{
              className: classes.popoverPaper
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
          >
            <Typography
              onMouseLeave={() => setPopOverOpen(false)}
              variant="subtitle2"
              className={classes.typography}
            >
              {
                "When you turn off the switch, the interlocutor's\ntext will be shown without translation."
              }
            </Typography>
          </Popover>
        </Box>
      </Box>

      <ChatList
        currentUserSide={currentUserIsCallee ? "B" : "A"}
        messages={messages}
        calleeVideoEnabled={calleeVideoEnabled}
        original={translationEnabled && showTranslated}
        setMessageRead={setMessageRead}
      />

      {sendMessageToDC && (
        <Box marginTop="auto">
          <TextInput
            message={message}
            onChange={handleMessageChange}
            sendMessage={sendMessage}
          />
        </Box>
      )}
    </Paper>
  );
}
export default Transcription;
