import React from "react";
import { Box, Typography } from "@material-ui/core";
import { ReactComponent as Icon } from "./topUpBalanceIcon.svg";
import { ReactComponent as IconSmall } from "./topUpBalanceIconSmall.svg";
import { ReactComponent as PlusIcon } from "./iconPlus.svg";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    backgroundColor: theme.palette.background.default,
    borderTop: `4px solid ${theme.palette.primary.main}`,
    borderRadius: 5,
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2.75),
    height: 128
  },
  containerDesktop: {
    height: 228,
    flexDirection: "column",
    boxSizing: "content-box"
  },
  subtitle: {
    color: theme.palette.text.grey,
    width: "100%"
  },
  subtitleDesktop: {
    textAlign: "center"
  }
}));

function TopUpBalanceCard({ isDesktop }) {
  const classes = useStyles();
  const containerClassName = clsx(classes.container, {
    [classes.containerDesktop]: isDesktop
  });
  const textClassName = clsx(classes.subtitle, {
    [classes.subtitleDesktop]: isDesktop
  });
  return (
    <Box className={containerClassName}>
      <Box mb={isDesktop ? 2 : 0} mr={isDesktop ? 0 : 2}>
        {isDesktop ? <Icon /> : <IconSmall />}
      </Box>
      <Box textAlign={isDesktop ? "center" : "initial"}>
        <Typography className={textClassName} variant={"subtitle1"}>
          Balance
        </Typography>
        <Box mt={0.5} display={"flex"} alignItems={"center"}>
          <Box mr={0.5}>
            <PlusIcon />
          </Box>
          <Typography variant={"h1"}>$10</Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default TopUpBalanceCard;
