import React from "react";
import { Box } from "@material-ui/core";
import { palette } from "../theme";

function IconBadge({ children, icon }) {
  return (
    <Box
      style={{ backgroundColor: palette.background.input }}
      py={1}
      px={2}
      display={"flex"}
      width={"100%"}
      justifyContent={"center"}
      borderRadius={4}
      boxSizing={"border-box"}
    >
      <Box display={"flex"} alignItems={"flex-start"} mr={0.9}>
        {icon}
      </Box>
      {children}
    </Box>
  );
}

export default IconBadge;
