import React from "react";
import { ReactComponent as ErrorCross } from "./icons/errorCross.svg";

const ErrorWithIcon = ({ text }) => {
  return (
    <span style={{ display: "flex", alignItems: "center" }}>
      <ErrorCross />
      &nbsp;{text}
    </span>
  );
};

export default ErrorWithIcon;
