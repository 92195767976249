import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import {
  Box,
  Grid,
  ListItem,
  ListItemText,
  Popover,
  Typography
} from "@material-ui/core";
import { observer } from "mobx-react";
import { figmaFonts } from "../../../../theme";
import useIsVisible from "../../../../utils/hooks/useIsVisible";
import { appStore } from "../../../../utils/stores/AppStore";
import { chatsStore } from "../../../../utils/stores/ChatsStore";
import useBreakpoints from "../../../../utils/useBreakpoints";
import AvatarWithFallback from "../../../../components/Avatar/AvatarWithFallback";
import ChatMenuButton from "../ChatMenuButton/ChatMenuButton";
import { ReactComponent as Reply } from "../icons/reply.svg";
import { ReactComponent as Copy } from "../icons/copy.svg";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import {
  MessageDeliveredIcon,
  MessageReadIcon,
  MessageSentIcon
} from "../../../../assets/icons";

const useStyles = makeStyles(theme => ({
  listItem: {
    textAlign: props => (props.showAvatars ? "left" : "right"),
    padding: 0
  },
  listItemB: {
    textAlign: "left !important"
  },
  message: {
    flexDirection: "column",
    whiteSpace: "break-spaces",
    wordBreak: "break-word",
    padding: theme.spacing(0.75, 1.5),
    borderRadius: "10px 10px 0px 10px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.secondary,
    display: "flex",
    ...figmaFonts.textRegular14,
    textAlign: "left",
    maxWidth: 600
  },
  messageB: {
    borderRadius: "10px 10px 10px 0px",
    background: props =>
      props.isDesktop
        ? theme.palette.background.default
        : theme.palette.background.input,
    color: theme.palette.text.default
  },
  listItemText: {
    display: "inline-block",
    margin: 0,
    position: "relative"
  },
  popover: {
    marginTop: theme.spacing(1)
  },
  popoverPaper: {
    padding: theme.spacing(2),
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.07)"
  },
  replyBox: {
    borderRadius: 5,
    background: "rgba(0, 0, 0, 0.15)",
    borderLeft: "2px solid",
    borderColor: theme.palette.background.green,
    padding: theme.spacing(0.75, 1.25),
    marginBottom: theme.spacing(0.75),
    width: "fit-content"
  },
  replyBoxB: {
    background: theme.palette.background.callGrey,
    borderColor: theme.palette.primary.main,
    [theme.breakpoints.up("lg")]: {
      background: theme.palette.background.input
    }
  },
  replyName: {
    ...figmaFonts.textMedium16,
    color: theme.palette.background.green
  },
  replyNameB: {
    color: theme.palette.primary.main
  },
  replyText: {
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 2,
    overflow: "hidden",
    color: theme.palette.background.default,
    ...figmaFonts.mobileSmallTextRegular,
    [theme.breakpoints.up("lg")]: {
      ...figmaFonts.desktopSmallTextRegular
    }
  },
  replyTextB: {
    color: theme.palette.text.default
  }
}));

function ChatMessage({
  message,
  chat,
  sameNext,
  showAvatar = false,
  showAvatars = false,
  showMenu,
  onReply,
  store
}) {
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const { isDesktop } = useBreakpoints();
  const isBSide = useMemo(() => {
    return appStore.currentUser?.id !== message.senderId;
  }, [message.senderId]);
  const classes = useStyles({ isDesktop, showAvatars });

  const ref = useRef();
  const messageRef = useRef();
  const isVisible = useIsVisible(ref);

  useEffect(() => {
    if (isVisible) {
      chatsStore.setRead(message.chatId, message.id);
    }
  }, [isVisible, message, message.chatId, message.id]);

  const renderMessageTime = (
    timestamp,
    placeholder = false,
    messageStateIcon
  ) => {
    const hours = new Date(timestamp).getHours();
    let minutes = new Date(timestamp).getMinutes();
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    return (
      <>
        <span
          style={{
            unicodeBidi: "embed",
            position: !placeholder ? "absolute" : "initital",
            right: 12,
            bottom: 6,
            marginLeft: 8,
            textAlignLast: "right",
            fontWeight: 500,
            fontSize: "11px",
            lineHeight: "24px",
            color: isBSide ? "#9595B1" : "#B1BDFD",
            visibility: placeholder ? "hidden" : "visible"
          }}
        >
          {hours + ":" + minutes}
          {messageStateIcon}
        </span>
      </>
    );
  };

  const messageText = useMemo(() => {
    if (isBSide) {
      return chat.translationEnabled
        ? message.translatedText
        : message.originalText;
    } else {
      return message.originalText;
    }
  }, [
    chat.translationEnabled,
    isBSide,
    message.originalText,
    message.translatedText
  ]);

  const popoverOnClose = useCallback(event => {
    event.stopPropagation();
    setIsOpenMenu(false);
  }, []);

  const onCopy = useCallback(() => {
    setIsOpenMenu(false);
  }, []);

  const _onReply = useCallback(
    event => {
      event.stopPropagation();
      onReply(message.id);
      setIsOpenMenu(false);
    },
    [message.id, onReply]
  );

  const renderReplyMessage = useMemo(() => {
    if (!message.replyFor || !store) return null;
    const replyMessage = store.getMessageById(message.replyFor);
    if (!replyMessage) return null;

    const isReplyBSide = appStore.currentUser?.id !== replyMessage.senderId;
    const name = isReplyBSide ? chat.title : appStore.currentUser?.name;
    let text = replyMessage.originalText;
    if (isReplyBSide) {
      text = chat.translationEnabled
        ? replyMessage.translatedText
        : replyMessage.originalText;
    }

    return (
      <Box className={clsx(classes.replyBox, { [classes.replyBoxB]: isBSide })}>
        <Typography
          className={clsx(classes.replyName, { [classes.replyNameB]: isBSide })}
        >
          {name}
        </Typography>
        <Typography
          className={clsx(classes.replyText, { [classes.replyTextB]: isBSide })}
        >
          {text}
        </Typography>
      </Box>
    );
  }, [
    chat.title,
    chat.translationEnabled,
    classes.replyBox,
    classes.replyBoxB,
    classes.replyName,
    classes.replyNameB,
    classes.replyText,
    classes.replyTextB,
    isBSide,
    message.replyFor,
    store
  ]);

  const messageStateIcon = useMemo(() => {
    if (isBSide) return;
    const style = {
      verticalAlign: "text-bottom",
      marginLeft: "4px"
    };
    if (message.id <= chat.lastReadRemote) {
      return <MessageReadIcon style={style} />;
    }
    if (message.id <= chat.lastRequestedRemote) {
      return <MessageDeliveredIcon style={style} />;
    }
    return <MessageSentIcon style={style} />;
  }, [chat, isBSide, message.id]);

  return (
    <ListItem
      ref={ref}
      className={clsx(classes.listItem, isBSide ? classes.listItemB : "")}
      style={{
        marginBottom: sameNext ? "4px" : "8px"
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Box position={"relative"}>
            {showAvatars && (
              <Box
                width={40}
                display={"inline-block"}
                mr={1}
                style={{ verticalAlign: "bottom" }}
              >
                {showAvatar && (
                  <AvatarWithFallback
                    src={isBSide ? chat.avatar : appStore.currentUser.avatar}
                    width={40}
                    height={40}
                  />
                )}
              </Box>
            )}

            <ListItemText
              onClick={showMenu ? () => setIsOpenMenu(true) : undefined}
              className={classes.listItemText}
              ref={messageRef}
              primary={
                <>
                  {renderReplyMessage}
                  <span
                    style={{ unicodeBidi: "bidi-override", display: "block" }}
                  >
                    {messageText}
                    {renderMessageTime(message.ts, true, messageStateIcon)}
                    {renderMessageTime(message.ts, false, messageStateIcon)}
                  </span>
                </>
              }
              primaryTypographyProps={{
                className: clsx(
                  classes.message,
                  isBSide ? classes.messageB : ""
                )
              }}
              style={{
                cursor: showMenu ? "pointer" : "initial"
              }}
            />
          </Box>
        </Grid>
      </Grid>
      <Popover
        open={isOpenMenu}
        onClose={popoverOnClose}
        anchorEl={messageRef.current}
        className={classes.popover}
        PaperProps={{
          className: classes.popoverPaper
        }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
      >
        <ChatMenuButton onClick={_onReply} text={"Reply"} icon={<Reply />} />
        <CopyToClipboard onCopy={onCopy} text={messageText}>
          <ChatMenuButton text={"Copy"} icon={<Copy />} />
        </CopyToClipboard>
      </Popover>
    </ListItem>
  );
}

export default observer(ChatMessage);
