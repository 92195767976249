import React, { useContext, useEffect, useState } from "react";
import BasePage from "./BasePage/BasePage";
import { makeStyles } from "@material-ui/core/styles";
import { BaseContext } from "../utils/baseProvider";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import withRequest from "../utils/withRequest";
import InputGroup from "../components/InputGroup/InputGroup";
import { Box, Button, Typography } from "@material-ui/core";
import useBreakpoints from "../utils/useBreakpoints";
import useAppBarHeight from "../utils/useAppBarHeight";
import { appStore } from "../utils/stores/AppStore";
import { observer } from "mobx-react";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%"
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "384px",
    maxWidth: "100%",
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(5)
    }
  },
  heading: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("lg")]: {
      marginBottom: theme.spacing(3)
    }
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(4)
  }
}));

function PasswordverifyPage(props) {
  let location = useLocation();
  const prevFormData = location.state;
  const appBarHeight = useAppBarHeight();
  const classes = useStyles({ appBarHeight });
  const baseContext = useContext(BaseContext);
  const history = useHistory();
  const [formData, setFormData] = useState({ password: "", confirm: "" });
  const [disabled, setDisabled] = useState(true);
  const { isDesktop, isMobile } = useBreakpoints();

  useEffect(() => {
    checkDisabled();
    // eslint-disable-next-line
  }, [formData]);

  function onChange(event) {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  }

  function checkDisabled() {
    if (
      formData.password.length < 8 ||
      formData.password !== formData.confirm
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }

  function send(event) {
    event.stopPropagation();
    event.preventDefault();

    if (formData.password !== formData.confirm) {
      baseContext.setErrorTitle("Password mismatch");
      return;
    }

    let params = {
      email: prevFormData.email,
      code: parseInt(prevFormData.code, 10),
      password: formData.password
    };

    props.postDataWithCallback("/api/passwordverifyedit", params, data => {
      if (data === "ok") {
        history.push({ pathname: "/signin", state: { fromChange: true } });
      }
    });
  }

  if (appStore.authorized === true) {
    return <Redirect to="/" />;
  }

  return (
    <BasePage centeredContent={isDesktop}>
      <Box
        px={isMobile ? 3.125 : 0}
        my={isMobile ? appBarHeight / 8 + 5 : "auto"}
        className={classes.container}
      >
        <div className={classes.paper}>
          <Typography className={classes.heading} variant="h1">
            New password
          </Typography>
          <Typography variant="h2">You can now make new password</Typography>
          <form className={classes.form} noValidate onSubmit={send}>
            <InputGroup
              label="New password"
              required
              id="password"
              placeholder="At least 8 characters"
              name="password"
              type="password"
              autoComplete="password"
              onChange={onChange}
            />
            <InputGroup
              label="Confirm password"
              required
              id="confirm"
              placeholder="At least 8 characters"
              name="confirm"
              type="password"
              autoComplete="password"
              onChange={onChange}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              disabled={disabled}
              className={classes.submit}
            >
              CONFIRM
            </Button>
          </form>
        </div>
      </Box>
    </BasePage>
  );
}

export default withRequest(observer(PasswordverifyPage));
