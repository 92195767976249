export const callStatuses = {
  calling: "calling",
  connected: "connected",
  timeouted: "timeouted",
  noanswer: "noanswer",
  callended: "callended",
  hangup: "hangup"
};

export const getElapsedText = callStatus => {
  switch (callStatus) {
    case callStatuses.calling:
      return "Calling...";
    case callStatuses.noanswer:
      return "No answer";
    default:
      return null;
  }
};
