import React from "react";
import { Box, Typography } from "@material-ui/core";
import { ReactComponent as EmptyAvatar } from "./icons/avatar.svg";
import { formatElapsed } from "../../utils/time";
import LanguagesBadge, {
  LanguagesBadgeIcons
} from "../../components/LanguagesBadge/LanguagesBadge";
import useLanguages from "../../utils/hooks/useLanguages";
import { makeStyles } from "@material-ui/core/styles";
import theme, { figmaFonts } from "../../theme";

const useStyle = makeStyles(() => ({
  container: {
    border: `1px solid ${theme.palette.menuLine}`,
    borderRadius: 10,
    display: "flex",
    padding: theme.spacing(0, 2),
    alignItems: "center",
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(2)
    }
  },
  blockTitle: {
    color: theme.palette.text.grey,
    ...figmaFonts.desktopSmallTextMedium,
    marginBottom: 3
  },
  blockText: {
    ...figmaFonts.textMedium14
  },
  verticalDivider: {
    margin: theme.spacing(0, 2),
    width: 1,
    background: theme.palette.menuLine
  }
}));
const RateMeetingStats = ({
  title,
  elapsed,
  to,
  from,
  photo,
  cost,
  desktopMode
}) => {
  const classes = useStyle();
  const { getLanguageByKey } = useLanguages();

  return (
    <Box className={classes.container}>
      {desktopMode && (
        <Box width={120} height={120} mr={3}>
          {photo ? (
            <img
              width={120}
              height={120}
              style={{
                borderRadius: "50%"
              }}
              src={photo}
              alt={"user"}
            />
          ) : (
            <EmptyAvatar />
          )}
        </Box>
      )}
      <Box>
        {desktopMode && <Typography variant={"h2"}>{title}</Typography>}
        <Box mt={desktopMode ? 1.5 : 0} display={"flex"}>
          <Box py={desktopMode ? 0 : 2}>
            <Typography className={classes.blockTitle}>Spent min.</Typography>
            <Typography className={classes.blockText}>
              {formatElapsed(elapsed)}
            </Typography>
          </Box>

          <Box className={classes.verticalDivider} />

          <Box py={desktopMode ? 0 : 2}>
            <Typography className={classes.blockTitle}>Languages</Typography>
            <LanguagesBadge
              size={16}
              theme={"dark"}
              firstCountry={getLanguageByKey(from).Country}
              secondCountry={getLanguageByKey(to).Country}
              iconMargin={0.5}
              icon={LanguagesBadgeIcons.arrowsMid}
              iconSize={8}
            />
          </Box>

          <Box className={classes.verticalDivider} />

          <Box py={desktopMode ? 0 : 2}>
            <Typography className={classes.blockTitle}>Cost</Typography>
            <Typography className={classes.blockText}>
              ${cost.toString().substring(0, 6)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default RateMeetingStats;
