import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react";
import { appEmitter } from "../../utils/events/appEmitter";
import { Box, Button, Typography } from "@material-ui/core";
import ModalDialog from "../ModalDialog/ModalDialog";
import useRequest from "../../utils/hooks/useRequest";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { CallActive, CancelWhite } from "../../assets/icons";
import { makeStyles } from "@material-ui/core/styles";
import { figmaFonts } from "../../theme";
import AnimatedAvatar from "./AnimatedAvatar";
import { BaseContext } from "../../utils/baseProvider";

const useStyles = makeStyles(theme => ({
  callText: {
    ...figmaFonts.textRegular16,
    color: theme.palette.text.grey
  },
  button: {
    position: "relative",
    width: 56,
    height: 56,
    minWidth: 56,
    borderRadius: "50%",
    backgroundColor: theme.palette.background.darkGrey,
    [theme.breakpoints.up("md")]: {
      width: 64,
      height: 64
    },
    "&:hover": {
      backgroundColor: theme.palette.background.darkGrey
    },
    "&  svg": {
      width: 28,
      height: 28,
      [theme.breakpoints.up("md")]: {
        width: 32,
        height: 32
      }
    }
  },
  buttonError: {
    backgroundColor: theme.palette.background.red,
    "&:hover": {
      backgroundColor: theme.palette.background.red
    }
  },
  buttonAccept: {
    backgroundColor: theme.palette.background.green,
    "&:hover": {
      backgroundColor: theme.palette.background.green
    }
  },
  buttonText: {
    marginTop: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.grey,
    ...figmaFonts.mobileSmallTextMedium
  }
}));

const DirectCallModal = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({});
  const { postDataWithCallback } = useRequest();
  const { push } = useHistory();
  const [callId, setCallId] = useState(null);
  const baseContext = useContext(BaseContext);
  const [callSettings, setCallSettings] = useState({
    to: "",
    toGender: null
  });

  const ringtoneRef = useRef(new Audio("/callSound.mp3"));

  useEffect(() => {
    if (open) {
      ringtoneRef.current.play();
    } else {
      ringtoneRef.current.pause();
    }
  }, [open]);

  useEffect(() => {
    setCallSettings(prevState => ({
      ...prevState,
      to: (baseContext.currentUser && baseContext.currentUser.fromLang) || null,
      toGender:
        (baseContext.currentUser && baseContext.currentUser.gender) || null
    }));
  }, [baseContext.currentUser]);

  const loadUser = useCallback(
    async params => {
      setCallId(params.call_id);
      postDataWithCallback(
        "/api/usernameinfo",
        { username: params.username },
        data => {
          setUser(data);
          setOpen(true);
        }
      );
    },
    [postDataWithCallback]
  );

  const onDirectCall = useCallback(
    params => {
      setOpen(false);
      void loadUser(params);
    },
    [loadUser]
  );

  const onDirectCallEnd = useCallback(
    params => {
      if (params.call_id === callId) {
        setOpen(false);
        setUser({});
      }
    },
    [callId]
  );

  useEffect(() => {
    const cleaner = appEmitter.on("onDirectCall", onDirectCall);
    const cleaner1 = appEmitter.on("onDirectCallEnd", onDirectCallEnd);
    return () => {
      cleaner();
      cleaner1();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [callId]);

  const acceptCall = useCallback(() => {
    push({
      pathname: "/incoming-direct-call",
      state: { remoteId: callId, ...callSettings }
    });
    setOpen(false);
  }, [callId, callSettings, push]);

  const declineCall = useCallback(() => {
    postDataWithCallback("/api/sfudecline", {
      id: callId
    });
    setOpen(false);
  }, [callId, postDataWithCallback]);

  return (
    <ModalDialog hideCloseButton open={open} handleClose={onDirectCallEnd}>
      <Box px={5} pt={1.5} pb={3} textAlign={"center"}>
        <Typography variant={"h1"} className={classes.userName}>
          {user.name}
        </Typography>

        <Typography className={classes.callText}>Incoming call</Typography>

        <AnimatedAvatar avatar={user.avatar} />

        <Box display={"flex"}>
          <Box display={"flex"} flexDirection={"column"} mr={9}>
            <Button
              onClick={declineCall}
              className={clsx(classes.button, classes.buttonError)}
            >
              <CancelWhite />
            </Button>
            <Typography className={classes.buttonText}>Decline</Typography>
          </Box>

          <Box display={"flex"} flexDirection={"column"}>
            <Button
              onClick={acceptCall}
              className={clsx(classes.button, classes.buttonAccept)}
            >
              <CallActive />
            </Button>
            <Typography className={classes.buttonText}>Accept</Typography>
          </Box>
        </Box>
      </Box>
    </ModalDialog>
  );
};

export default DirectCallModal;
