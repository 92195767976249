import React, { useCallback, useContext } from "react";
import { LocalCallContext } from "../localCallProvider";
import TranscriptionContainer from "./TranscriptionContainer";

function LocalCallTranscriptionWrapper({ Bconnected, sendMessageToDC }) {
  const localCallContext = useContext(LocalCallContext);

  const setMessageRead = useCallback(
    message => {
      localCallContext.setMessages(messages => {
        return [...messages].map(msg => {
          if (msg.id === message.id) {
            return {
              ...msg,
              read: true
            };
          }
          return msg;
        });
      });
    },
    [localCallContext]
  );

  return (
    <TranscriptionContainer
      Bconnected={Bconnected}
      sendMessageToDC={sendMessageToDC}
      calleeVideoEnabled={localCallContext.calleeVideoEnabled}
      toggleTranscription={localCallContext.toggleTranscription}
      showTranscription={localCallContext.showTranscription}
      currentUserIsCallee={localCallContext.currentUserIsCallee}
      messages={localCallContext.messages}
      setMessageRead={setMessageRead}
      translationEnabled={localCallContext.translationEnabled}
    />
  );
}

export default LocalCallTranscriptionWrapper;
