import React, { useEffect, useState } from "react";
import BasePage from "./BasePage/BasePage";
import { makeStyles } from "@material-ui/core/styles";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import withRequest from "../utils/withRequest";
import CodeInput from "../components/CodeInput";
import StyledLink from "../components/StyledLink";
import { Box, Button, Typography } from "@material-ui/core";
import useAppBarHeight from "../utils/useAppBarHeight";
import useBreakpoints from "../utils/useBreakpoints";
import { appStore } from "../utils/stores/AppStore";
import { observer } from "mobx-react";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    width: "100%"
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "384px",
    maxWidth: "100%",
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(5)
    }
  },
  heading: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("lg")]: {
      marginBottom: theme.spacing(3)
    }
  },
  form: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(5),
    [theme.breakpoints.up("lg")]: {
      marginTop: theme.spacing(4)
    },
    marginBottom: theme.spacing(3)
  },
  link: {
    marginBottom: theme.spacing(3)
  },
  bottomText: {
    color: theme.palette.text.grey,
    textAlign: "center"
  }
}));

function SignupverifyPage(props) {
  const location = useLocation();
  const prevFormData = location.state;
  const appBarHeight = useAppBarHeight();
  const classes = useStyles({ appBarHeight });
  const history = useHistory();
  const [formData, setFormData] = useState({
    code: ""
  });
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState(false);
  const { isMobile } = useBreakpoints();

  useEffect(() => {
    checkDisabled();
    // eslint-disable-next-line
  }, [formData]);

  function getCode(value) {
    setError(false);
    setFormData({
      ...formData,
      code: value
    });
  }

  function checkDisabled() {
    if (formData.code.length < 4) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }

  function send(event) {
    event.stopPropagation();
    event.preventDefault();

    let params = {
      email: prevFormData.email,
      code: parseInt(formData.code, 10)
    };

    props.postDataWithCallback(
      "/api/signupverify",
      params,
      data => {
        if (data === "ok") {
          history.push({
            pathname: "/signupverifyedit",
            state: { email: prevFormData.email, code: formData.code }
          });
        } else {
          setError(true);
        }
      },
      () => setError(true)
    );
  }

  if (appStore.authorized === true) {
    return <Redirect to="/" />;
  }

  return (
    <BasePage>
      <Box
        px={isMobile ? 3.125 : 0}
        my={isMobile ? appBarHeight / 8 + 5 : "auto"}
        className={classes.container}
      >
        <div className={classes.paper}>
          <Typography className={classes.heading} variant="h1">
            Verify email
          </Typography>
          <Typography align="center" variant="h2">
            We just sent the code
          </Typography>
          <Typography align="center" variant="h2">
            to&nbsp;
            <Typography color={"primary"} component="span" variant="h2">
              {`${prevFormData.email}`}
            </Typography>
          </Typography>
          <form className={classes.form} noValidate onSubmit={send}>
            <CodeInput error={error} getCode={getCode} />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              disabled={disabled}
              className={classes.submit}
            >
              VERIFY
            </Button>
          </form>
          <StyledLink className={classes.link} to="/signup">
            Back to Sign Up
          </StyledLink>
          <Typography className={classes.bottomText} variant="subtitle1">
            If no email is received within ten minutes, check the submitted
            address is correct
          </Typography>
        </div>
      </Box>
    </BasePage>
  );
}

export default withRequest(observer(SignupverifyPage));
