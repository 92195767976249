import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Card, Typography } from "@material-ui/core";
import { ReactComponent as BalanceIconMain } from "./assets/balanceIconMain.svg";
import { ReactComponent as BalanceIconYellow } from "./assets/balanceiconYellow.svg";
import { ReactComponent as BalanceIconGreen } from "./assets/balanceIconGreen.svg";
import { ReactComponent as CoinIcon } from "../../../../../../assets/bonuses/coinIcon.svg";
import { figmaFonts, palette } from "../../../../../../theme";
import useBreakpoints from "../../../../../../utils/useBreakpoints";

const useStyles = makeStyles(theme => ({
  card: {
    boxShadow: "none",
    backgroundColor: props => props.secondaryColor,
    width: 215,
    height: 136,
    [theme.breakpoints.up("md")]: {
      height: 165
    },
    borderRadius: 5
  },
  textBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center"
  },
  countText: {
    color: props => props.mainColor,
    fontWeight: 500,
    fontSize: 34,
    lineHeight: "41px",
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("md")]: {
      fontSize: 44,
      lineHeight: "53px"
    }
  },
  headerBox: {
    display: "flex",
    backgroundColor: props => props.mainColor,
    height: 32,
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(1, 0)
  },
  headerTitle: {
    marginLeft: theme.spacing(0.5),
    ...figmaFonts.desktopSmallTextMedium
  },
  coinIcon: {
    fill: props => props.mainColor
  },
  asteriskText: {
    color: theme.palette.text.grey,
    whiteSpace: "break-spaces",
    textAlign: "center"
  },
  count: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
}));

function BonusBalanceCard({ count = 0, title, color, asterisk = false }) {
  const { isTablet } = useBreakpoints();
  let mainColor, secondaryColor, balanceIcon;
  if (color === "green") {
    mainColor = palette.background.green;
    secondaryColor = palette.background.lightGreen;
    balanceIcon = <BalanceIconGreen />;
  } else if (color === "yellow") {
    mainColor = palette.background.yellow;
    secondaryColor = palette.background.lightestYellow;
    balanceIcon = <BalanceIconYellow />;
  } else {
    mainColor = palette.primary.main;
    secondaryColor = palette.background.lightMain;
    balanceIcon = <BalanceIconMain />;
  }

  const classes = useStyles({ mainColor, secondaryColor });
  return (
    <Box>
      <Card className={classes.card}>
        <Box className={classes.headerBox}>
          {balanceIcon}
          <Typography className={classes.headerTitle} color={"secondary"}>
            Bonus balance
          </Typography>
        </Box>
        <Box
          pt={isTablet ? 3 : 2.5}
          pb={isTablet ? 4 : 2.5}
          className={classes.textBox}
        >
          <Typography variant={"subtitle1"}>
            {title}
            {asterisk && "*"}
          </Typography>

          <Box mt={0.5} className={classes.count}>
            <Typography className={classes.countText} color={"secondary"}>
              {count}
            </Typography>
            <CoinIcon className={classes.coinIcon} />
          </Box>
        </Box>
      </Card>
      {asterisk && (
        <Box mt={2}>
          <Typography variant={"subtitle2"} className={classes.asteriskText}>
            {asterisk}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default BonusBalanceCard;
