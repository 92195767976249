import React, { memo } from "react";
import WaitForConnectionTitle from "../../WaitForConnectionTitle";
import CallDataDesktop from "../CallData/CallDataDesktop";

const LocalCallAppBarLeftContent = ({ Bconnected, isCallee }) => {
  if (!Bconnected) {
    return <WaitForConnectionTitle />;
  }

  return (
    <CallDataDesktop isCallee={isCallee} showLanguagesBadge={Bconnected} />
  );
};

export default memo(LocalCallAppBarLeftContent);
