import React from "react";
import "./ripple-animation.scss";
import clsx from "clsx";

const RippleAnimation = ({ animated }) => {
  const disabled = "disabled";
  return (
    <div className="pulse-container">
      <div className="pulse-box">
        <div className={clsx("pulse-css", { [disabled]: !animated })} />
      </div>
    </div>
  );
};

export default RippleAnimation;
