import React, { memo, useContext } from "react";
import { Box } from "@material-ui/core";
import LanguagesBadge from "../../../../components/LanguagesBadge/LanguagesBadge";
import WaitForConnectionTitle from "../../WaitForConnectionTitle";
import useBreakpoints from "../../../../utils/useBreakpoints";
import { LocalCallContext } from "../../localCallProvider";
import { makeStyles } from "@material-ui/core/styles";
import SettingsButton from "../../../../components/CallSettings/SettingsButton";
import { AppContext } from "../../../../utils/AppContext";
import useLanguages from "../../../../utils/hooks/useLanguages";
import { palette } from "../../../../theme";

const useStyles = makeStyles(theme => ({
  adaptiveLeftPadding: {
    left: 7,
    paddingLeft: theme.spacing(3.125),
    [theme.breakpoints.up("md")]: {
      paddingLeft: theme.spacing(10)
    }
  }
}));

const LocalcallPageHeader = ({ isCallee }) => {
  const classes = useStyles();
  const { isDesktop } = useBreakpoints();
  const localCallContext = useContext(LocalCallContext);
  const { localCallUIStore, localCallStore } = useContext(AppContext);
  const { getLanguageByKey } = useLanguages();

  const firstCountry = getLanguageByKey(
    isCallee ? localCallStore.callSettings.to : localCallStore.callSettings.from
  ).Country;
  const secondCountry = getLanguageByKey(
    isCallee ? localCallStore.callSettings.from : localCallStore.callSettings.to
  ).Country;

  return (
    <>
      {isDesktop ? (
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          position={"absolute"}
          top={24}
          left={30}
          zIndex={1}
        >
          <Box display={"flex"} alignItems={"center"}>
            <SettingsButton
              onClick={localCallUIStore.toggleSettingsModalShow}
              disabled={true}
              background={palette.background.default}
            />

            {localCallContext.translationEnabled && (
              <Box ml={3}>
                <LanguagesBadge
                  firstCountry={firstCountry}
                  secondCountry={secondCountry}
                />
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <Box
          className={classes.adaptiveLeftPadding}
          position="absolute"
          bottom={104}
          zIndex={1}
        >
          <WaitForConnectionTitle small />
        </Box>
      )}
    </>
  );
};

export default memo(LocalcallPageHeader);
