export const plans = {
  basic: {
    prices: {
      month: 0,
      year: 0
    },
    duration: 5
  },
  plus: {
    prices: {
      month: 14.99,
      year: 119.88
    },
    duration: 60
  },
  pricePerMinute: 0.25
};
