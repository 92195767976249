import React, { useContext, useEffect } from "react";
import { BaseContext } from "../../utils/baseProvider";

const withBottomBar = (WrappedComponent, showCreateCall = true) => {
  return function HOC(props) {
    const baseContext = useContext(BaseContext);
    useEffect(() => {
      baseContext.setShowBottomBar(true);
      baseContext.setShowCreateCall(showCreateCall);

      return () => {
        baseContext.setShowBottomBar(false);
        baseContext.setShowCreateCall(false);
      };
      // eslint-disable-next-line
    }, []);
    return <WrappedComponent {...props} />;
  };
};

export default withBottomBar;
