import { Chip } from "@material-ui/core";
import { ReactComponent as BalanceIcon } from "../../assets/icons/balanceIcon.svg";
import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { BaseContext } from "../../utils/baseProvider";

const useStyles = makeStyles(theme => ({
  chip: {
    width: props => props.width || 128,
    padding: theme.spacing(0.5, 0.5, 0.5, 1),
    borderRadius: "5px",
    height: "auto"
  },
  chipIcon: {
    margin: 0,
    marginRight: 5
  },
  text: {
    color: theme.palette.text.grey,
    textAlign: "left"
  },
  label: {
    width: 95,
    [theme.breakpoints.up("lg")]: {
      width: 102
    }
  }
}));

function BalanceChip({ text, width, deleteIcon, onDelete }) {
  const classes = useStyles({ width });
  const baseContext = useContext(BaseContext);
  const formattedBalance =
    baseContext.secondsBalance < 3600
      ? new Date(baseContext.secondsBalance * 1000).toISOString().substr(14, 5)
      : "60:00";
  const labelText = `Balance: ${formattedBalance}`;

  return (
    <Chip
      classes={{
        root: classes.chip,
        icon: classes.chipIcon,
        label: width ? "" : classes.label
      }}
      icon={<BalanceIcon />}
      color="primary"
      label={text || labelText}
      deleteIcon={deleteIcon}
      onDelete={onDelete}
    />
  );
}
export default BalanceChip;
