import React from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as RadioEnabled } from "./icons/radioEnabled.svg";
import { ReactComponent as RadioDisabled } from "./icons/radioDisabled.svg";
import { figmaFonts } from "../../theme";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  formControl: {
    width: "100%",
    boxSizing: "border-box"
  },
  inputLabel: {
    color: theme.palette.text.default,
    "&.Mui-focused": {
      color: theme.palette.text.default
    }
  },
  radioGroup: {
    display: "flex",
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
    "&:checked": {
      color: "red"
    }
  },
  radioItem: {
    width: "100%",
    background: theme.palette.background.input,
    padding: theme.spacing(0.875, 0.875),
    borderRadius: 10,
    margin: theme.spacing(0),
    textTransform: "capitalize"
  },
  label: {
    ...figmaFonts.textRegular14,
    [theme.breakpoints.up("lg")]: {
      ...figmaFonts.textMedium16
    },
    color: theme.palette.text.grey
  },
  labelChecked: { color: theme.palette.text.default }
}));

function RadioSelectGroup({
  value,
  onChange,
  items,
  label,
  name,
  itemsMargin = 3
}) {
  const classes = useStyles();

  function handleChange(event) {
    onChange(event);
  }

  return (
    <FormControl className={classes.formControl} component="fieldset">
      <FormLabel className={classes.inputLabel} component="legend">
        {label}
      </FormLabel>
      <RadioGroup
        className={classes.radioGroup}
        aria-label={name}
        name={name}
        value={value}
        onChange={handleChange}
        row
      >
        {items.map((item, index) => (
          <Box
            key={item}
            width={`calc(${100 / items.length}% - ${(itemsMargin * 8) /
              items.length}px)`}
            mr={index + 1 < items.length ? itemsMargin : 0}
          >
            <FormControlLabel
              className={classes.radioItem}
              value={item}
              control={
                <Radio
                  disableRipple
                  checkedIcon={<RadioEnabled />}
                  icon={<RadioDisabled />}
                />
              }
              label={item}
              classes={{
                label:
                  value === item
                    ? clsx(classes.label, classes.labelChecked)
                    : classes.label
              }}
            />
          </Box>
        ))}
      </RadioGroup>
    </FormControl>
  );
}

export default RadioSelectGroup;
