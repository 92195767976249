import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import AvatarWithFallback from "../../components/Avatar/AvatarWithFallback";
import withBottomBar from "../../components/BottomNavBar/withBottomBar";
import { Box, Button, Typography } from "@material-ui/core";
import { figmaFonts } from "../../theme";
import StyledLink from "../../components/StyledLink";
import InputGroup from "../../components/InputGroup/InputGroup";
import FakeInputGroup from "./FakeInputGroup";
import RadioSelectGroup from "../../components/RadioSelectGroup/RadioSelectGroup";
import useBreakpoints from "../../utils/useBreakpoints";
import ModalDialog from "../../components/ModalDialog/ModalDialog";
import AvatarEdit from "./AvatarEdit";
import LanguageSelect from "../CreatelocalcallPage/components/LanguageSelect";
import useLanguages from "../../utils/hooks/useLanguages";
import PlaceholderStartAdornment from "../../components/InputGroup/components/PlaceholderStartAdornment";
import ErrorWithIcon from "../../components/InputGroup/components/ErrorWithIcon";
import SuccessWithIcon from "../../components/InputGroup/components/SuccessWithIcon";
import CopyButton from "../../components/CopyButton/CopyButton";
import useAppBarLeftContent from "../../utils/hooks/useAppBarLeftContent";
import { observer } from "mobx-react";

const useStyles = makeStyles(theme => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: 325,
    margin: "0 auto",
    padding: theme.spacing(0, 3.125),
    boxSizing: "content-box",
    [theme.breakpoints.up("lg")]: {
      maxWidth: 384
    }
  },
  heading: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.up("lg")]: {
      marginBottom: theme.spacing(5)
    }
  },
  deleteButton: {
    color: theme.palette.text.grey,
    ...figmaFonts.mobileSmallTextRegular,
    [theme.breakpoints.up("lg")]: {
      ...figmaFonts.desktopLink
    }
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(4.375),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(3, 0)
    }
  }
}));

const ProfileEditPageMobile = ({
  usernameLink,
  send,
  saveButtonContent,
  handleDeleteAvatar,
  closeEdit,
  onOpenEdit,
  onAvatarSave,
  onChange,
  errors,
  loaded,
  disabled,
  formData,
  avatarEditModalOpen,
  usernameAvailable
}) => {
  const classes = useStyles();
  useAppBarLeftContent({ title: "Settings" });
  const history = useHistory();
  const { isDesktop } = useBreakpoints();
  const { languagesFrom } = useLanguages();

  return (
    <>
      <div className={classes.paper}>
        {loaded && (
          <Fragment>
            <Typography className={classes.heading} variant="h1">
              Edit profile
            </Typography>
            <Box onClick={onOpenEdit} mb={isDesktop ? 2 : 3}>
              <AvatarWithFallback
                height={96}
                width={96}
                src={formData.avatar}
                editMode
              />
            </Box>
            <StyledLink
              className={classes.deleteButton}
              onClick={handleDeleteAvatar}
            >
              Delete photo
            </StyledLink>
            <form className={classes.form} noValidate onSubmit={send}>
              <InputGroup
                id="name"
                label="Name"
                type="text"
                name="name"
                autoComplete="name"
                value={formData.name}
                onChange={onChange}
              />

              <InputGroup
                id="username"
                label="Username"
                type="text"
                name="username"
                error={
                  errors["username"] ||
                  (usernameAvailable === false && (
                    <ErrorWithIcon text={"Not available"} />
                  ))
                }
                value={formData.username}
                helperText={
                  usernameAvailable ? (
                    <SuccessWithIcon text={"Available"} />
                  ) : null
                }
                onChange={onChange}
                startAdornment={
                  <PlaceholderStartAdornment
                    enabled={formData.username}
                    symbol={"@"}
                  />
                }
                endAdornment={
                  usernameLink ? <CopyButton textToCopy={usernameLink} /> : null
                }
              />

              <LanguageSelect
                onChange={onChange}
                value={formData.fromLang}
                languages={languagesFrom}
                label="Your native language"
                name={"fromLang"}
              />
              <RadioSelectGroup
                label={"Your voice"}
                onChange={onChange}
                name={"gender"}
                value={formData["gender"]}
                items={["Male", "Female"]}
              />
              <InputGroup
                id="email"
                label="Email"
                type="email"
                name="email"
                disabled
                onChange={onChange}
                defaultValue={formData.email}
              />
              <FakeInputGroup
                onClick={() => {
                  history.push("/profilepasswordverifyedit");
                }}
              />
              <Button
                type="submit"
                fullWidth
                size={"large"}
                color="primary"
                variant={"contained"}
                disabled={disabled}
              >
                {saveButtonContent}
              </Button>
            </form>
            {/*<StyledLink className={classes.deleteButton} to={"/profiledelete"}>*/}
            {/*  Delete my account*/}
            {/*</StyledLink>*/}
          </Fragment>
        )}
      </div>

      <ModalDialog open={avatarEditModalOpen} handleClose={closeEdit}>
        <AvatarEdit
          saveButtonContent={saveButtonContent}
          onClose={closeEdit}
          onSave={onAvatarSave}
        />
      </ModalDialog>
    </>
  );
};

export default withBottomBar(observer(ProfileEditPageMobile));
