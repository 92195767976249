import React, { useEffect } from "react";
import MainPage from "./pages/MainPage/MainPage";
import NotFoundPage from "./pages/NotFoundPage";
import LogoutPage from "./pages/LogoutPage";
import LoginPage from "./pages/LoginPage";
import SignupPage from "./pages/SignupPage";
import SubscriptionPage from "./pages/SubscriptionPage/SubscriptionPage";
import PasswordResetPage from "./pages/PasswordResetPage";
import SignupverifyPage from "./pages/SignupverifyPage";
import SignupverifyeditPage from "./pages/SignupverifyeditPage";
import PasswordverifyPage from "./pages/PasswordverifyPage";
import PasswordverifyeditPage from "./pages/PasswordverifyeditPage";
import ProfilePasswordverifyPage from "./pages/ProfilePasswordverifyPage";
import ProfilePasswordeditPage from "./pages/ProfilePasswordeditPage";
import ProfileEditPage from "./pages/ProfileEditPage/ProfileEditPage";
import SigninPage from "./pages/SigninPage";
import CreatelocalcallPage from "./pages/CreatelocalcallPage/CreatelocalcallPage";
import JoinlocalcallPage from "./pages/JoinLocalCallPage/JoinlocalcallPage";
import LocalcallPage from "./pages/LocalCallPage/LocalcallPage";
import PhonecallPage from "./pages/PhoneCall/PhoneCallPage/PhoneCallPage";
import SnackbarComponent from "./components/SnackbarComponent";
import Loader from "./components/Loader";
import { Route, Switch, useLocation } from "react-router-dom";
import LocalCallProvider from "./pages/LocalCallPage/localCallProvider";
import PlugPage from "./pages/PlugPage";
import Invite from "./pages/Invite";
import BillingPage from "./pages/BillingPage/BillingPage";
import HistoryPage from "./pages/History/HistoryPage";
import BrokenLinkPage from "./pages/BrokenLinkPage/BrokenLinkPage";
import RateMeetingPage from "./pages/RateMeetingPage/RateMeetingPage";
import PaySuccessPage from "./pages/PaymentPages/PaySuccessPage";
import PayCancelledPage from "./pages/PaymentPages/PayCancelledPage";
import LinkTimeoutPage from "./pages/LinkTimeoutPage/LinkTimeoutPage";
import querystring from "query-string";
import { setUtmSource } from "./utils/localStorage";
import CookiesModal from "./components/CookiesModal/CookiesModal";
import ReactGA from "react-ga4";
import BonusPage from "./pages/BonusPage/BonusPage";
import PhoneCallCreatePage from "./pages/PhoneCall/PhoneCallCreatePage/PhoneCallCreatePage";
import UserPage from "./pages/UserPage/UserPage";
import MessagesPage from "./pages/MessagesPage/MessagesPage";
import NotificationModal from "./components/NotificationModal/NotificationModal";
import DirectCallPage from "./pages/DirectCallPage/DirectCallPage";
import CheckAuthorization from "./components/CheckAuthorization";
import DirectCallModal from "./components/DirectCallModal/DirectCallModal";

export default function App() {
  const location = useLocation();
  const params = querystring.parse(location.search);

  useEffect(() => {
    if (params.utm_source) {
      setUtmSource(params.utm_source);
    }
    ReactGA.initialize("G-KW57BPRQEC");
    ReactGA.pageview(window.location.pathname + window.location.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LocalCallProvider>
      <Switch>
        <Route exact path="/">
          <MainPage />
        </Route>
        <Route path="/signup">
          <SignupPage />
        </Route>
        <Route path="/signupverify">
          <SignupverifyPage />
        </Route>
        <Route path="/signupverifyedit">
          <SignupverifyeditPage />
        </Route>
        <Route path="/signin">
          <SigninPage />
        </Route>
        <Route path="/login">
          <LoginPage />
        </Route>
        <Route path="/logout">
          <LogoutPage />
        </Route>
        <Route path="/passwordreset">
          <PasswordResetPage />
        </Route>
        <Route path="/passwordverify">
          <PasswordverifyPage />
        </Route>
        <Route path="/passwordverifyedit">
          <PasswordverifyeditPage />
        </Route>
        <Route path="/profilepasswordverifyedit">
          <ProfilePasswordverifyPage />
        </Route>
        <Route path="/profilepasswordedit">
          <ProfilePasswordeditPage />
        </Route>
        <Route path="/profileedit">
          <ProfileEditPage />
        </Route>
        <Route path="/createlocalcall">
          <CreatelocalcallPage />
        </Route>
        <Route path="/join/:remoteId">
          <JoinlocalcallPage />
        </Route>
        <Route path="/localcall">
          <LocalcallPage />
        </Route>
        <Route exact path="/phonecall">
          <PhonecallPage />
        </Route>
        <Route path="/phonecallcreate">
          <PhoneCallCreatePage />
        </Route>
        <Route path="/history">
          <HistoryPage />
        </Route>
        <Route exact path="/subscription">
          <SubscriptionPage />
        </Route>
        <Route exact path="/billing">
          <BillingPage />
        </Route>
        <Route path="/billing/paysuccess">
          <PaySuccessPage />
        </Route>
        <Route path="/billing/paycanceled">
          <PayCancelledPage />
        </Route>
        {/*<Route path="/inviteFriends">*/}
        {/*  <InviteFriendsPage />*/}
        {/*</Route>*/}
        <Route path="/bonus">
          <BonusPage />
        </Route>
        <Route path="/invite/:invitedBy">
          <Invite />
        </Route>
        <Route path="/profiledelete">
          <PlugPage />
        </Route>
        <Route path="/ratecall">
          <RateMeetingPage />
        </Route>
        <Route path="/invalidUrl">
          <BrokenLinkPage />
        </Route>
        <Route exact path="/linkTimeout">
          <LinkTimeoutPage />
        </Route>
        <Route exact path="/u/:username">
          <UserPage />
        </Route>
        <Route exact path="/messages">
          <MessagesPage />
        </Route>

        <Route exact path={"/directcall/:remoteId"}>
          <CheckAuthorization>
            <DirectCallPage />
          </CheckAuthorization>
        </Route>

        <Route path="/incoming-direct-call">
          <CheckAuthorization>
            <LocalcallPage isDirectCall={true} />
          </CheckAuthorization>
        </Route>

        <Route path="*">
          <NotFoundPage />
        </Route>
      </Switch>
      <SnackbarComponent />
      <Loader />
      <CookiesModal />
      <NotificationModal />
      <DirectCallModal />
    </LocalCallProvider>
  );
}
