import { makeAutoObservable } from "mobx";

const defaultState = {
  to: null,
  from: null,
  toGender: null,
  fromGender: null
};

export default class LocalCallStore {
  constructor() {
    this.resetState();
    makeAutoObservable(this);
  }

  resetState = () => {
    Object.keys(defaultState).forEach(key => {
      this[key] = defaultState[key];
    });
  };

  initCallState = callSettings => {
    this.resetState();
    this.setCallSettings(callSettings);
  };

  setCallSettings = callSettings => {
    if (callSettings.to) {
      this.to = callSettings.to;
    }
    if (callSettings.from) {
      this.from = callSettings.from;
    }
    if (callSettings.toGender) {
      this.toGender = callSettings.toGender;
    }
    if (callSettings.fromGender) {
      this.fromGender = callSettings.fromGender;
    }
  };

  get callSettings() {
    return {
      to: this.to,
      from: this.from,
      toGender: this.toGender,
      fromGender: this.fromGender
    };
  }
}
