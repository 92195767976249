import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Grid } from "@material-ui/core";
import BalanceEmptyModal from "../../../components/BalanceEmptyModal/BalanceEmptyModal";
import BasePage from "../../BasePage/BasePage";
import { BaseContext } from "../../../utils/baseProvider";
import { TopNavigationItems } from "../../../components/AppBarTop/TopNavigation/TopNavigation";
import withTopNavigation from "../../../components/AppBarTop/TopNavigation/withTopNavigation";
import withBottomBar from "../../../components/BottomNavBar/withBottomBar";
import withRequest from "../../../utils/withRequest";
import PhoneCallTopBar from "../components/PhoneCallTopBar/PhoneCallTopBar";
import { getCountryCodeByKey } from "../../../utils/countriesHelper";
import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneCallDialer from "../components/PhoneCallDialer/PhoneCallDialer";
import useLanguages from "../../../utils/hooks/useLanguages";
import SettingsModal from "../../../components/CallSettings/SettingsModal";
import { isDesktop } from "react-device-detect";
import PhoneCallCreateSettingsBadge from "./PhoneCallCreateSettingsBadge/PhoneCallCreateSettingsBadge";
import UsdBalanceChipDark from "../../../components/chips/UsdBalanceChipDark";

const useStyles = makeStyles(theme => ({
  container: { width: "100%", flex: 1 },
  gridContainer: {
    height: "100%",
    width: "100%",
    margin: 0
  },
  controlsContainer: {
    backgroundColor: theme.palette.primary.main,
    height: 64,
    paddingTop: theme.spacing(2),
    paddingLeft: theme.spacing(3.125),
    paddingRight: theme.spacing(3.125),
    display: "flex",
    justifyContent: "space-between"
  },
  formBox: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "384px"
  },
  heading: {
    textAlign: "center",
    margin: theme.spacing(3, 0),
    [theme.breakpoints.up("lg")]: {
      margin: theme.spacing(5, 0)
    }
  }
}));

function PhoneCallCreatePageMobile({ postDataWithCallback }) {
  const classes = useStyles();
  const history = useHistory();
  const baseContext = useContext(BaseContext);
  const [balanceEmptyModalOpen, setBalanceEmptyModalOpen] = useState(false);
  const [showModalType, setShowModalType] = useState();
  const [rate, setRate] = useState("");
  const {
    languagesFrom,
    languagesTo,
    selectedFrom,
    selectedTo,
    getLanguageByKey
  } = useLanguages();
  const [settingsModalOpen, setSettingsModalOpen] = useState(false);

  const initialData = history.location.state;
  const [callSettings, setCallSettings] = useState({
    from: (initialData && initialData.from) || "",
    to: (initialData && initialData.to) || "",
    fromGender: baseContext.currentUser?.gender || "Male",
    toGender: (initialData && initialData.gender) || "Male"
  });

  const [formData, setFormData] = useState({
    phoneNumber: (initialData && initialData.phoneNumber) || "",
    audioEnabled: true
  });

  useEffect(() => {
    if (initialData) {
      onPhoneChange(history.location.state.phoneNumber);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!callSettings.from && selectedFrom) {
      setCallSettings(prevState => ({
        ...prevState,
        from: selectedFrom
      }));
    }
    if (!callSettings.to && selectedTo) {
      setCallSettings(prevState => ({
        ...prevState,
        to: selectedTo
      }));
    }
  }, [callSettings.from, callSettings.to, selectedFrom, selectedTo]);

  const onSettingsSave = useCallback(
    data => {
      setCallSettings({ ...callSettings, ...data });
      setSettingsModalOpen(false);
    },
    [callSettings]
  );

  function formDataOnChange(key, value) {
    setFormData(prevData => ({ ...prevData, [key]: value }));
  }

  const onPhoneChange = phone => {
    phone = phone.replace(/[^0-9+*#]/g, "");
    if (phone && phone[0] !== "+") {
      phone = "+" + phone;
    }
    formDataOnChange("phoneNumber", phone);
    // TODO: remove fake number
    if (isValidPhoneNumber(phone) || phone === "+111") {
      // TODO: remove fake number
      if (phone === "+111") {
        setRate("0.1111");
      } else {
        postDataWithCallback("/api/call/price", { number: phone }, data => {
          setRate(data.price);
        });
      }
    } else {
      setRate("");
    }
  };

  const onDialerSymbolClick = symbol => {
    if (symbol !== "#" && symbol !== "*") {
      onPhoneChange(`${formData.phoneNumber}${symbol}`);
    } else {
      // TODO: symbols # and *
    }
  };

  const onCallClick = () => {
    if (!baseContext.currentUser) {
      return;
    }

    if (baseContext.currentUser.plan === "basic") {
      setShowModalType("onlyPlus");
      setBalanceEmptyModalOpen(true);
      return;
    }

    // TODO: less than call price?
    if (baseContext.centsBalance <= 0) {
      setShowModalType("emptyUsdBalance");
      setBalanceEmptyModalOpen(true);
      return;
    }

    const fromCode = getCountryCodeByKey(callSettings.from, languagesFrom);
    const toCode = getCountryCodeByKey(callSettings.to, languagesTo);

    history.push({
      pathname: "/phonecall",
      state: {
        ...formData,
        ...callSettings,
        fromCode,
        toCode
      }
    });
  };

  const langTo = useMemo(() => {
    return getLanguageByKey(callSettings.to);
  }, [callSettings.to, getLanguageByKey]);

  return (
    <BasePage>
      <Box pt={isDesktop ? 3 : 0} pb={3} className={classes.container}>
        <Grid container spacing={0} className={classes.gridContainer}>
          <Grid item xs={12} lg={8}>
            <Box className={classes.controlsContainer}>
              <UsdBalanceChipDark />
              <PhoneCallCreateSettingsBadge
                onClick={() => setSettingsModalOpen(true)}
                toCode={langTo.Country}
                toName={langTo.DisplayLang}
                backgroundColor={"#484ECA"}
                height={24}
                mx={0}
              />
            </Box>

            <PhoneCallTopBar
              title={null}
              onBack={history.goBack}
              onInputChange={onPhoneChange}
              value={formData.phoneNumber}
              statusText={rate ? `$${rate} / min` : null}
              isMobileMode={true}
            />
            <Box px={3.125} mx={"auto"} className={classes.formBox}>
              <Box mt={3}>
                <PhoneCallDialer
                  onButtonClick={onDialerSymbolClick}
                  onCallClick={onCallClick}
                  callActive={rate}
                  audioEnabled={formData.audioEnabled}
                  onMicToggle={() =>
                    formDataOnChange("audioEnabled", !formData.audioEnabled)
                  }
                  onBackspace={() =>
                    onPhoneChange(formData.phoneNumber.slice(0, -1).trim())
                  }
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>

      <SettingsModal
        open={settingsModalOpen}
        onClose={() => setSettingsModalOpen(false)}
        onSettingsSave={onSettingsSave}
        isCaller={true}
        callSettings={callSettings}
      />

      <BalanceEmptyModal
        type={showModalType}
        open={balanceEmptyModalOpen}
        handleClose={() => {
          setBalanceEmptyModalOpen(false);
        }}
      />
    </BasePage>
  );
}

export default withTopNavigation(
  withBottomBar(withRequest(PhoneCallCreatePageMobile), false),
  {
    left: TopNavigationItems.backButton,
    right: TopNavigationItems.usdBalance
  }
);
