import React, { Fragment, useContext, useEffect, useState } from "react";
import BasePage from "./BasePage/BasePage";
import withRequest from "../utils/withRequest";
import CodeInput from "../components/CodeInput";
import { BaseContext } from "../utils/baseProvider";
import { makeStyles } from "@material-ui/core/styles";
import { Redirect, useHistory } from "react-router-dom";
import { Button, Typography } from "@material-ui/core";
import useAppBarLeftContent from "../utils/hooks/useAppBarLeftContent";
import useBreakpoints from "../utils/useBreakpoints";
import { appStore } from "../utils/stores/AppStore";
import { observer } from "mobx-react";

const useStyles = makeStyles(theme => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    width: "384px",
    maxWidth: "100%",
    marginTop: theme.spacing(5),
    marginLeft: theme.spacing(8)
  },
  heading: {
    marginBottom: theme.spacing(3)
  },
  form: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3)
  }
}));

function ProfilePasswordverifyPage(props) {
  const classes = useStyles();
  const history = useHistory();
  const baseContext = useContext(BaseContext);
  const [formData, setFormData] = useState({ code: "" });
  const [disabled, setDisabled] = useState(true);
  const [ready, setReady] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState(false);
  const { isDesktop } = useBreakpoints();
  useAppBarLeftContent({ title: "Enter code", showBackButton: true });

  useEffect(() => {
    checkDisabled();
    // eslint-disable-next-line
  }, [formData]);

  useEffect(() => {
    if (baseContext.currentUser !== undefined) {
      setReady(true);
    }
    // eslint-disable-next-line
  }, [baseContext.currentUser]);

  useEffect(() => {
    if (ready === true) {
      props.postDataWithCallback("/api/userpasswordverify", {}, data => {
        if (data === "ok") {
          setSent(true);
        }
      });
    }
    // eslint-disable-next-line
  }, [ready]);

  function getCode(value) {
    setError(false);
    setFormData({
      ...formData,
      code: value
    });
  }

  function checkDisabled() {
    if (formData.code.length < 4) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }

  function send(event) {
    event.stopPropagation();
    event.preventDefault();

    const params = {
      code: parseInt(formData.code),
      email: baseContext.currentUser.email
    };

    props.postDataWithCallback(
      "/api/passwordverify",
      params,
      data => {
        if (data === "ok") {
          history.push({
            pathname: "/profilepasswordedit",
            state: { code: formData.code }
          });
        } else {
          setError(true);
        }
      },
      () => setError(true)
    );
  }

  if (appStore.authorized === false) {
    return <Redirect to="/" />;
  }

  return (
    <BasePage>
      <Fragment>
        <div className={classes.paper}>
          {sent === true && (
            <Fragment>
              <Typography align={isDesktop ? "inherit" : "center"} variant="h2">
                We just sent it to&nbsp;
                <Typography color="primary" component="span" variant="h2">
                  {`${baseContext.currentUser.email}`}
                </Typography>
              </Typography>
              <form className={classes.form} noValidate onSubmit={send}>
                <CodeInput error={error} getCode={getCode} />
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  size="large"
                  disabled={disabled}
                >
                  VERIFY
                </Button>
              </form>
              {/*<StyledLink to="/profileedit">Back to Profile</StyledLink>*/}
            </Fragment>
          )}
        </div>
      </Fragment>
    </BasePage>
  );
}

export default withRequest(observer(ProfilePasswordverifyPage));
