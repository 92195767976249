import React, { useEffect, useState } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import useBreakpoints from "../../../../utils/useBreakpoints";
import EmptyPage from "../../../EmptyPage/EmptyPage";
import { useHistory } from "react-router-dom";
import withRequest from "../../../../utils/withRequest";
import TotalInvitedCountCard from "./components/TotalInvitedCountCard/TotalInvitedCountCard";
import BonusBalanceCard from "./components/BonusBalanceCard/BonusBalanceCard";
import { makeStyles } from "@material-ui/core/styles";
import BonusesTable from "./components/BonusesTable/BonusesTable";
import withBottomBar from "../../../../components/BottomNavBar/withBottomBar";
import { figmaFonts, palette } from "../../../../theme";
import { appStore } from "../../../../utils/stores/AppStore";
import { observer } from "mobx-react";

const useStyles = makeStyles(theme => ({
  cards: {
    maxWidth: "95vw",
    overflowX: "auto",
    display: "flex",
    [theme.breakpoints.up("lg")]: {
      justifyContent: "center"
    },
    WebkitOverflowScrolling: "touch",
    "&>div": {
      marginRight: theme.spacing(2),
      flexShrink: 0,
      [theme.breakpoints.up("lg")]: {
        marginRight: theme.spacing(3.75)
      },
      "&:last-of-type": {
        marginRight: 0
      }
    }
  },
  button: {
    ...figmaFonts.desktopCapsBtn14
  },
  empty: {
    ...figmaFonts.textRegular16,
    color: theme.palette.text.grey
  }
}));

function Dashboard({ getDataWithCallback, showDesktopLayout }) {
  const classes = useStyles();
  const { isDesktop } = useBreakpoints();
  const history = useHistory();
  const [data, setData] = useState({});

  useEffect(() => {
    if (appStore.authorized) {
      getDataWithCallback("/api/userbonus", {}, data => {
        setData(data);
      });
    }
    // eslint-disable-next-line
  }, [appStore.authorized]);

  const openDetailedRules = () => {
    history.push("/bonus?tab=1", { tab: 1 });
  };

  return (
    <Box alignSelf={showDesktopLayout ? "flex-start" : "center"}>
      {!showDesktopLayout && (
        <Box textAlign={"center"} mt={isDesktop ? 5 : 2} mb={5}>
          <Typography variant={"h1"}>Dashboard</Typography>
        </Box>
      )}

      <Box px={showDesktopLayout ? 0 : 3.125} mb={2} className={classes.cards}>
        <TotalInvitedCountCard count={data.friends} />
        <BonusBalanceCard title={"Total"} count={data.totalBonus} />
        <BonusBalanceCard
          title={"Available"}
          count={data.availableBonus}
          backgroundColor={palette.background.green}
          color={"green"}
        />
        <BonusBalanceCard
          title={"On the way"}
          count={data.pendingBonus}
          color={"yellow"}
          asterisk={"* received bonuses, \nbut not available for use"}
        />
      </Box>

      <Box mx={showDesktopLayout ? 0 : "auto"} width={216}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          className={classes.button}
          onClick={openDetailedRules}
        >
          Detailed rules
        </Button>
      </Box>

      <Box
        textAlign={showDesktopLayout ? "left" : "center"}
        mt={showDesktopLayout ? 8 : 6.25}
      >
        <Typography variant={"h2"}>History bonuses</Typography>
      </Box>
      {data.bonuses && (
        <Box>
          {data.bonuses.length === 0 ? (
            <Box mt={showDesktopLayout ? 1 : 2}>
              {showDesktopLayout ? (
                <Typography className={classes.empty}>
                  There is nothing here yet
                </Typography>
              ) : (
                <EmptyPage />
              )}
            </Box>
          ) : (
            <Box
              mt={5}
              mb={5}
              maxWidth={950}
              mx={showDesktopLayout ? 0 : "auto"}
            >
              <BonusesTable
                showDesktopLayout={showDesktopLayout}
                data={data.bonuses}
              />
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}

export default withRequest(withBottomBar(observer(Dashboard)));
