import { makeAutoObservable, runInAction } from "mobx";

import { api } from "../api/api";
import apiRoutes from "../api/apiRoutes";
import { appStore } from "./AppStore";
import { appEmitter } from "../events/appEmitter";

class ChatsStore {
  preloadedChats = new Set();
  chatsLoaded = false;

  constructor() {
    makeAutoObservable(this);
  }

  isLoadingChats = true;

  isRefreshing = false;

  chats = [];

  typings = {};

  openedChat = null;

  openChat = chat => {
    this.openedChat = chat;
  };

  closeChat = () => {
    this.openedChat = undefined;
  };

  resetState() {
    this.chats = [];
  }

  get shouldShowEmptyState() {
    console.log(
      "[ChatsStore.shouldShowEmptyState]",
      this.chatsLoaded,
      this.isRefreshing,
      this.chats.length
    );
    if (this.chats.length === 0 && this.isRefreshing) {
      return true;
    }
    return this.chatsLoaded && this.chats.length === 0;
  }

  get totalUnreadMessages() {
    let sum = 0;
    // eslint-disable-next-line no-unused-vars
    for (let chat of this.chats) {
      sum += chat.unreadCounter;
    }
    return sum;
  }

  get unreadMessages() {
    const sum = this.totalUnreadMessages;
    return sum > 0 ? (sum > 99 ? "99+" : sum.toString()) : undefined;
  }

  findExistingOrCreateNew(user) {
    let chat = this.chats.find(c => c.remoteId === user.id);
    if (!chat) {
      chat = {
        avatar: user.avatar,
        title: user.name,
        type: "p2p",
        remoteId: user.id,
        unreadCounter: 0,
        lastMessageAt: "",
        messages: [],
        text: "",
        lastRead: 0,
        remoteBan: false,
        translationEnabled: true,
        lastOnline: undefined,
        isOnline: false
      };
    }
    console.log("[ChatsStore.findExistingOrCreateNew]", user.id, chat);
    return chat;
  }

  isMessageFromOpenedChat(message) {
    if (!this.openedChat) return false;
    return (
      this.openedChat?.remoteId === message.remoteId ||
      this.openedChat?.remoteId === message.senderId
    );
  }

  findById(chatId) {
    if (process.env.NODE_ENV === "development") {
      console.log("[ChatsStore.findById]", chatId, this.chats.map(c => c.id));
    }

    return this.chats.findIndex(c => c.id === chatId);
  }

  replaceChat(chat) {
    this.preloadedChats.delete(chat.id);
    const index = this.findById(chat.id);
    console.log("[ChatsStore.replaceChat]", index, chat);
    if (index === -1) {
      this.chats.unshift(chat);
    } else {
      this.chats = this.chats.map(c => {
        if (c.id === chat.id)
          return { ...chat, translationEnabled: c.translationEnabled };
        return { ...c, translationEnabled: c.translationEnabled };
      });
    }
    this.preloadedChats.add(chat.id);
  }

  deleteChat = async chat => {
    try {
      appStore.setLoading(true);
      if (chat.id) {
        await api.postDataWithCallback(apiRoutes.deleteChat, {
          id: chat.id
        });
      }
      runInAction(() => {
        this.chats = this.chats.filter(c => c.id !== chat.id);
        this.preloadedChats.delete(chat.id);
      });
      if (this.openedChat && this.openedChat.id === chat.id) {
        this.closeChat();
      }
      appEmitter.emit("onFullScreenNotification", {
        text: "Chat removed successfully"
      });
    } finally {
      appStore.setLoading(false);
    }
  };

  reportChat = async chat => {
    try {
      appStore.setLoading(true);
      await api.postDataWithCallback(apiRoutes.userComplain, {
        id: chat.remoteId
      });
      appEmitter.emit("onFullScreenNotification", {
        text: "Your complaint\nhas been submitted"
      });
    } finally {
      appStore.setLoading(false);
    }
  };

  toggleBlock = async chat => {
    try {
      appStore.setLoading(true);
      await api.postDataWithCallback(
        chat.remoteBan ? apiRoutes.userUnban : apiRoutes.userBan,
        { id: chat.remoteId }
      );
      appEmitter.emit("onFullScreenNotification", {
        text: chat.remoteBan
          ? "User unblocked successfully"
          : "User blocked successfully"
      });
      return runInAction(() => {
        chat.remoteBan = !chat.remoteBan;
        const c = this.chats.find(c => c.id === chat.id);
        if (c) c.remoteBan = chat.remoteBan;
        return true;
      });
    } finally {
      appStore.setLoading(false);
    }
  };

  typing = (chatId, isTyping) => {
    const index = this.chats.findIndex(c => c.id === chatId);
    if (index === -1) return;
    this.chats[index].typing = isTyping;
    if (this.typings[chatId]) clearTimeout(this.typings[chatId]);

    if (isTyping) {
      this.typings[chatId] = setTimeout(() => {
        console.log("[ChatsStore.timeout]");
        this.typing(chatId, false);
      }, 3000);
    }

    appEmitter.emit("typing", { chatId, isTyping });
  };

  updateChatByRemoteId = async (remoteId, data) => {
    const chat = this.chats.find(chat => chat.remoteId === remoteId);
    if (chat && chat.id) {
      this.updateChatById(chat.id, data);
    }
  };

  updateChatById = async (chatId, data) => {
    if (!this.preloadedChats.has(chatId)) {
      const chat = await api.postDataWithCallback(apiRoutes.getMessages, {
        id: chatId
      });
      if (chat) {
        runInAction(() => {
          if (!this.preloadedChats.has(chatId)) {
            this.chats.unshift(chat);
            this.preloadedChats.add(chat.id);
          } else {
            const index = this.findById(chatId);
            this.chats.splice(index, 1, {
              ...this.chats[index],
              ...data
            });
          }
        });
      }
    } else {
      runInAction(() => {
        const index = this.findById(chatId);
        this.chats.splice(index, 1, {
          ...this.chats[index],
          ...data
        });
      });
    }
    // update opened chat
    runInAction(() => {
      const index = this.findById(chatId);
      if (this.openedChat?.id === chatId && this.chats[index]) {
        this.openedChat = this.chats[index];
      }
    });
  };

  updateOpenedChat = message => {
    if (!this.openedChat) return;

    // got info on newly opened chat - update id
    if (
      !this.openedChat.id &&
      message.chatId &&
      message.remoteId === this.openedChat.remoteId
    ) {
      this.openedChat.id = message.chatId;
    }
  };

  onNewMessage = async message => {
    console.log(
      "[ChatsStore.onNewMessage]",
      message.chatId,
      message.originalText,
      this.chats.length
    );

    this.updateOpenedChat(message);

    if (message.type === "messageRead") {
      const { chatId, messageId } = message;
      await this.updateChatById(chatId, {
        lastReadRemote: messageId
      });
    }

    if (message.type === "messageRequested") {
      const { chatId, messageId } = message;
      await this.updateChatById(chatId, {
        lastRequestedRemote: messageId
      });
    }

    if (message.type === "online") {
      const { isOnline, lastOnline, id } = message;
      await this.updateChatByRemoteId(id, { lastOnline, isOnline });
      return;
    }

    if (message.type === "typing") {
      this.typing(message.chatId, true);
      return;
    }

    if (message.type !== "text" && message.type !== "callMessage") {
      return;
    }

    const typingId = this.typings[message.chatId];
    if (typingId) clearTimeout(typingId);

    const isOpenedChat = this.isMessageFromOpenedChat(message);
    let isSelf = message.senderId === appStore.currentUser?.id;
    if (!this.preloadedChats.has(message.chatId)) {
      let chat = await api.postDataWithCallback(apiRoutes.getMessages, {
        id: message.chatId
      });
      if (chat) {
        runInAction(() => {
          if (!this.preloadedChats.has(message.chatId)) {
            this.chats.unshift(chat);
            this.preloadedChats.add(chat.id);
          } else {
            console.log(
              "[ChatsStore.onNewMessage]",
              "replace chat",
              message.chatId
            );
            const index = this.findById(message.chatId);
            this.chats.splice(index, 1, {
              ...this.chats[index],
              unreadCounter: isOpenedChat ? 0 : this.chats[index].unreadCounter,
              lastMessage: message,
              lastMessageId: message.id
            });
            chat = this.chats[index];
          }
          if (isOpenedChat) {
            appEmitter.emit("newChatMessage", { message, chat });
          }
        });
      }
      return;
    }
    runInAction(() => {
      const index = this.findById(message.chatId);
      console.log(
        "[ChatsStore]",
        this.chats[index]?.remoteId,
        appStore.currentUser?.id,
        this.openedChat?.id
      );
      const chat = {
        ...this.chats[index],
        lastMessageAt: message.date,
        lastMessage: message,
        lastMessageId: message.id,
        text: isSelf ? message.originalText : message.translatedText,
        unreadCounter:
          isSelf || isOpenedChat ? 0 : this.chats[index].unreadCounter + 1,
        typing: false
      };
      this.chats.splice(index, 1, chat);
      if (isOpenedChat) {
        this.openedChat = chat;
        appEmitter.emit("newChatMessage", { message, chat: this.chats[index] });
      }
    });
  };

  refresh = () => {
    this.isRefreshing = true;
    void this.fetchChats();
  };

  async fetchChats() {
    try {
      this.chatsLoaded = false;
      this.isLoadingChats = true;
      const response = await api.getDataWithCallback(apiRoutes.fetchChats);

      runInAction(() => {
        this.preloadedChats = new Set();
        this.chats = response.map(chat => ({
          ...chat,
          translationEnabled: true
        }));
        response.forEach(c => this.preloadedChats.add(c.id));
        this.chatsLoaded = true;
        appEmitter.emit("chatsLoaded", { chats: this.chats });
      });
    } finally {
      runInAction(() => {
        this.isLoadingChats = false;
        this.isRefreshing = false;
      });
    }
  }

  setRead = (chatId, toMessageId) => {
    const index = this.chats.findIndex(c => c.id === chatId);
    if (index === -1) return;
    const chat = this.chats[index];
    const lastReadId = chat.lastRead;

    if (lastReadId && lastReadId >= toMessageId) {
      return;
    }

    this.chats.splice(index, 1, {
      ...chat,
      lastRead: toMessageId,
      unreadCounter: 0
    });
    void api.postDataWithCallback(apiRoutes.setRead, { id: toMessageId });
  };

  toggleTranslation = chatId => {
    const index = this.chats.findIndex(c => c.id === chatId);
    if (index === -1) return;
    let chat = this.chats[index];
    chat = {
      ...chat,
      translationEnabled: !chat.translationEnabled
    };

    this.chats.splice(index, 1, chat);
    if (chatId === this.openedChat?.id) {
      this.openedChat = chat;
    }
  };
}
export const chatsStore = new ChatsStore();
