import { Box, Tooltip, Typography } from "@material-ui/core";
import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { figmaFonts } from "../../../../../theme";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  button: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    cursor: "pointer",
    color: theme.palette.text.grey,
    "&:hover": {
      color: theme.palette.primary.main
    }
  },
  buttonActive: {
    background: props =>
      props.expanded ? theme.palette.primary.main : "transparent",
    borderRadius: 5,
    paddingLeft: props => (props.expanded ? theme.spacing(1) : 0),
    border: `1px solid transparent`,
    borderColor: props =>
      props.expanded ? "transparent" : theme.palette.primary.main
  },
  title: {
    marginLeft: theme.spacing(1),
    ...figmaFonts.textMedium16
  },
  titleActiveButton: {
    marginLeft: theme.spacing(0),
    color: theme.palette.background.default
  },
  titleActive: {
    color: theme.palette.primary.main
  },
  iconBox: {
    position: "relative",
    width: 40,
    height: 40
  },
  iconCounter: {
    position: "absolute",
    background: theme.palette.background.red,
    top: 6,
    right: 6,
    minWidth: 14,
    height: 14,
    color: theme.palette.text.secondary,
    borderRadius: 7,
    fontWeight: "bold",
    fontSize: 9,
    lineHeight: "11px",
    paddingTop: "1px",
    paddingLeft: 2,
    paddingRight: 2,
    textAlign: "center"
  },
  iconBoxActive: {
    backgroundColor: theme.palette.background.input,
    borderRadius: 5,
    color: theme.palette.primary.main
  },
  tooltipRoot: {
    background: theme.palette.background.default,
    color: theme.palette.primary.main,
    height: 40,
    borderRadius: 5,
    boxShadow: " 0px 10px 20px rgba(0, 0, 0, 0.07)",
    ...figmaFonts.textMedium16,
    paddingTop: 11,
    paddingLeft: 24,
    paddingRight: 24
  }
}));

const BasePageDrawerMenuButton = ({ item, expanded }) => {
  const classes = useStyles({ expanded });
  const history = useHistory();

  const active =
    item.path === history.location.pathname ||
    item.additionalPath === history.location.pathname;

  const buttonClassName = clsx(classes.button, {
    [classes.buttonActive]: item.active
  });

  const titleClassName = clsx(classes.title, {
    [classes.titleActiveButton]: item.active,
    [classes.titleActive]: !item.active && active
  });

  const menuItemOnClick = useCallback(() => {
    history.push(item.path);
  }, [history, item.path]);

  const iconBoxClassName = clsx(classes.iconBox, {
    [classes.iconBoxActive]: active && !item.active
  });

  const iconComponent = (
    <Box className={iconBoxClassName}>
      {item.counter && item.counter > 0 ? (
        <Box className={classes.iconCounter}>{item.counter}</Box>
      ) : null}
      {item.icon}
    </Box>
  );

  return (
    <div onClick={item.onClick || menuItemOnClick} className={buttonClassName}>
      {expanded ? (
        iconComponent
      ) : (
        <Tooltip
          classes={{ tooltip: classes.tooltipRoot }}
          title={item.title}
          placement="right"
        >
          {iconComponent}
        </Tooltip>
      )}

      {expanded && (
        <Typography className={titleClassName}>{item.title}</Typography>
      )}
    </div>
  );
};

export default BasePageDrawerMenuButton;
