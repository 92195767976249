import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AvatarWithFallback from "../../Avatar/AvatarWithFallback";
import { BaseContext } from "../../../utils/baseProvider";
import { Box } from "@material-ui/core";
import useAppBarHeight from "../../../utils/useAppBarHeight";
import { useHistory } from "react-router-dom";
import { appStore } from "../../../utils/stores/AppStore";
import { observer } from "mobx-react";
import useLanguages from "../../../utils/hooks/useLanguages";

const useStyles = makeStyles(theme => ({
  avatarBox: {
    cursor: "pointer",
    position: "relative",
    marginLeft: "auto"
  }
}));

function MobileAppBarContent() {
  const appBarHeight = useAppBarHeight();
  const classes = useStyles({ appBarHeight });
  const baseContext = useContext(BaseContext);
  const history = useHistory();
  const { getLanguageByKey } = useLanguages();

  const onAvatarClick = () => {
    history.push("/");
  };

  const lang = getLanguageByKey(appStore.currentUser?.fromLang).Country || "";

  return (
    <Box className={classes.avatarBox} onClick={onAvatarClick}>
      <AvatarWithFallback
        width={44}
        height={44}
        src={baseContext.currentUser.avatar}
        langCode={lang.toLowerCase()}
      />
    </Box>
  );
}
export default observer(MobileAppBarContent);
