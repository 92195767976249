import React, { useCallback, useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import withBottomBar from "../../components/BottomNavBar/withBottomBar";
import useRequest from "../../utils/hooks/useRequest";
import BasePage from "../BasePage/BasePage";
import useLanguages from "../../utils/hooks/useLanguages";
import AvatarWithFallback from "../../components/Avatar/AvatarWithFallback";
import { Box, Button, Card, CardContent, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Flag from "../../components/CustomFlag/Flag";
import { BaseContext } from "../../utils/baseProvider";
import useBreakpoints from "../../utils/useBreakpoints";
import useAppBarHeight from "../../utils/useAppBarHeight";
import { appStore } from "../../utils/stores/AppStore";
import { observer } from "mobx-react";
import useAppBarLeftContent from "../../utils/hooks/useAppBarLeftContent";
import { chatsStore } from "../../utils/stores/ChatsStore";
import { FullHeightContainerWithCenteredContent } from "../../components/layouts";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    width: "100%"
  },
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "auto"
  },
  card: {
    width: props => (props.isMobile ? 325 : 384),
    marginTop: props => (props.isMobile ? -60 : -80),
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.07)",
    borderRadius: 10
  },
  cardContent: {
    borderTop: `4px solid ${theme.palette.primary.main}`,
    paddingTop: props => (props.isMobile ? 84 : 104)
  },
  name: {
    wordBreak: "break-word"
  },
  greyText: {
    color: theme.palette.text.grey,
    textAlign: "center"
  },
  languageBox: {
    display: "flex",
    borderRadius: 10,
    background: theme.palette.background.input,
    justifyContent: "center",
    alignItems: "center",
    padding: 10
  },
  flag: { marginLeft: theme.spacing(2), marginRight: theme.spacing(1) }
}));

const UserPage = () => {
  const appBarHeight = useAppBarHeight();
  const { isMobile, isDesktop } = useBreakpoints();
  const classes = useStyles({ isMobile, appBarHeight });
  const baseContext = useContext(BaseContext);
  const { username } = useParams();
  const { postDataWithCallback } = useRequest();
  const history = useHistory();
  const { languagesFrom } = useLanguages();
  const [user, setUser] = useState();
  useAppBarLeftContent({ title: appStore.authorized ? "Contact" : "" });

  useEffect(() => {
    if (appStore.authorized) {
      chatsStore.fetchChats();
    }
  }, []);
  const loadUser = useCallback(async () => {
    postDataWithCallback(
      "/api/usernameinfo",
      { username },
      data => {
        setUser(data);
      },
      () => {
        history.push("/");
      }
    );
  }, [history, postDataWithCallback, username]);

  useEffect(() => {
    void loadUser();
    baseContext.setSign("in");
    // eslint-disable-next-line
  }, []);

  function handleClickSignIn() {
    history.push({ pathname: "/signin" });
  }

  const handleClickMessage = () => {
    history.push(`/messages?toId=${user.id}`);
  };

  const userLanguage =
    languagesFrom &&
    user &&
    languagesFrom.find(lang => lang.Key === user.fromLang);

  return (
    <BasePage>
      {user && (
        <FullHeightContainerWithCenteredContent
          disableCentering={isMobile}
          pt={isMobile ? 5 : 0}
        >
          {appStore.authorized === false && (
            <Box mb={5}>
              <Typography align={"center"} variant={"h2"}>
                Don’t have “YOUS” yet?
              </Typography>
              <Typography align={"center"} variant={"h2"}>
                Try it now free!
              </Typography>
            </Box>
          )}

          {appStore.authorized === true && !isDesktop && (
            <Box mb={5}>
              <Typography align={"center"} variant={"h1"}>
                Contact
              </Typography>
            </Box>
          )}

          <Box mx={"auto"} width={"fit-content"}>
            <AvatarWithFallback
              height={isMobile ? 120 : 160}
              width={isMobile ? 120 : 160}
              src={user.avatar}
            />
          </Box>

          <Card className={classes.card}>
            <CardContent className={classes.cardContent}>
              <Typography
                className={classes.name}
                variant={"h1"}
                align={"center"}
              >
                {user.name}
              </Typography>

              <Box mt={isMobile ? 0 : 0.5}>
                <Typography
                  variant={"body2"}
                  align={"center"}
                  className={classes.greyText}
                >
                  @{username}
                </Typography>
              </Box>

              {userLanguage && (
                <Box mt={isMobile ? 3 : 4} className={classes.languageBox}>
                  <Typography variant={"body2"} className={classes.greyText}>
                    Native language:
                  </Typography>

                  <Flag
                    className={classes.flag}
                    code={userLanguage.Country.toLowerCase()}
                  />

                  <Typography variant={"body2"}>
                    {userLanguage.DisplayLang}
                  </Typography>
                </Box>
              )}
            </CardContent>
          </Card>

          <Box mt={4} width={"100%"}>
            {appStore.authorized === false ? (
              <Button
                variant={"contained"}
                color={"primary"}
                size={"large"}
                onClick={handleClickSignIn}
                fullWidth
              >
                LOGIN
              </Button>
            ) : (
              <Button
                variant={"contained"}
                color={"primary"}
                size={"large"}
                onClick={handleClickMessage}
                fullWidth
                disabled={
                  baseContext.currentUser &&
                  user.id === baseContext.currentUser.id
                }
              >
                Send message
              </Button>
            )}
          </Box>
        </FullHeightContainerWithCenteredContent>
      )}
    </BasePage>
  );
};

export default withBottomBar(observer(UserPage));
