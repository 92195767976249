import { makeAutoObservable } from "mobx";

const defaultState = {
  settingsModalShow: false,
  showTranscription: false
};

export default class LocalCallUIStore {
  constructor() {
    this.resetState();
    makeAutoObservable(this);
  }

  resetState = () => {
    Object.keys(defaultState).forEach(key => {
      this[key] = defaultState[key];
    });
  };

  toggleSettingsModalShow = () => {
    this.settingsModalShow = !this.settingsModalShow;
  };
}
