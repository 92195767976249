import React from "react";
import BasePage from "./BasePage/BasePage";
import icon404icon from "../assets/404.svg";
import { Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import useBreakpoints from "../utils/useBreakpoints";
import { useHistory } from "react-router-dom";
import withBottomBar from "../components/BottomNavBar/withBottomBar";
import { observer } from "mobx-react";
import { FullHeightContainerWithCenteredContent } from "../components/layouts";

const useStyles = makeStyles(theme => ({
  logo: {
    maxWidth: "100%",
    boxSizing: "border-box",
    width: "636px",
    textAlign: "center"
  }
}));

function NotFoundPage() {
  const classes = useStyles();
  const history = useHistory();
  const { isDesktop, isHd } = useBreakpoints();
  const adaptive = !isDesktop && !isHd;

  return (
    <BasePage>
      <FullHeightContainerWithCenteredContent>
        <img className={classes.logo} src={icon404icon} alt={404} />
        <Box mt={adaptive ? 5 : 8} mb={adaptive ? 3 : 5}>
          <Typography align={"center"} variant={adaptive ? "h2" : "h1"}>
            Sorry, the page you're looking
            <br />
            for cannot be found
          </Typography>
        </Box>
        <Box
          width={"100%"}
          maxWidth={adaptive ? 202 : 278}
          display={"flex"}
          mx={"auto"}
        >
          <Button
            fullWidth
            size={adaptive ? "medium" : "large"}
            variant={"contained"}
            color={"primary"}
            onClick={() => history.push("/")}
          >
            visit our homepage
          </Button>
        </Box>
      </FullHeightContainerWithCenteredContent>
    </BasePage>
  );
}

export default withBottomBar(observer(NotFoundPage));
