import React from "react";
import { ReactComponent as ChevronLeft } from "./icons/chevronLeft.svg";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { figmaFonts } from "../../../theme";
import { Button, SvgIcon } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    ...figmaFonts.mobileSmallTextMedium,
    color: theme.palette.text.default,
    padding: 0,
    height: 24,
    minWidth: 50
  },
  icon: {
    width: 16,
    height: 16
  }
}));

function BackButton() {
  const classes = useStyles();
  const history = useHistory();
  return (
    <Button
      startIcon={
        <SvgIcon className={classes.icon} viewBox={"0 0 16 16"}>
          <ChevronLeft />
        </SvgIcon>
      }
      onClick={history.goBack}
      className={classes.root}
    >
      Back
    </Button>
  );
}

export default BackButton;
