import { makeAutoObservable } from "mobx";
import { getTokenValue, removeTokenValue } from "../localStorage";
import { websockets } from "./Websocket";
import { chatsStore } from "./ChatsStore";

class AppStore {
  authorized = undefined;
  errorTitle = undefined;
  loading = undefined;
  currentUser = undefined;

  constructor() {
    makeAutoObservable(this);
  }

  setAuthorized = authorized => {
    if (authorized === false) {
      removeTokenValue();
      chatsStore.resetState();
    }

    if (authorized === true) {
      websockets.onAppStateChange(authorized, getTokenValue());
    }

    this.authorized = authorized;
  };

  setErrorTitle = errorTitle => {
    if (this.errorTitle) {
      this.errorTitle = errorTitle;
    } else {
      this.errorTitle = null;
      this.errorTitle = errorTitle;
    }
  };

  setLoading = loading => {
    this.loading = loading;
  };

  setCurrentUser = currentUser => {
    this.currentUser = currentUser;
  };
}

export const appStore = new AppStore();
