import React, { memo, useEffect, useMemo, useState } from "react";
import { Box } from "@material-ui/core";
import withRequest from "../../utils/withRequest";
import ModalDialog from "../ModalDialog/ModalDialog";
import SwitchToPlusModal from "./components/SwitchToPlusModal";
import TopUpUsdBalance from "./components/TopUpUsdBalance";
import NotEnoughMoney from "./components/NotEnoughMoney";
import NotEnoughMinutes from "./components/NotEnoughMinutes";

function BalanceEmptyModal({ open, handleClose, type, postDataWithCallback }) {
  const [currentPlan, setCurrentPlan] = useState();

  useEffect(() => {
    setCurrentPlan(type);
  }, [type]);

  const onClose = () => {
    handleClose();
    setCurrentPlan(type);
  };

  const onTopUpClick = () => {
    setCurrentPlan(null);
    setTimeout(() => {
      setCurrentPlan("topUpUsdBalance");
    }, 100);
  };

  const content = useMemo(() => {
    switch (currentPlan) {
      case "switchToPlusMinutes": {
        return (
          <SwitchToPlusModal
            text={"You don't have enough\nminutes to create a meeting"}
          />
        );
      }
      case "notEnoughMinutes": {
        return <NotEnoughMinutes onTopUpClick={onTopUpClick} />;
      }
      case "emptyUsdBalance": {
        return <NotEnoughMoney onTopUpClick={onTopUpClick} />;
      }
      case "topUpUsdBalance": {
        return <TopUpUsdBalance />;
      }
      case "onlyPlus": {
        return (
          <SwitchToPlusModal
            text={"You can make phone calls\nonly on the Plus plan"}
          />
        );
      }
      default: {
        return null;
      }
    }
  }, [currentPlan]);

  if (!currentPlan) return null;

  return (
    <ModalDialog open={open} handleClose={onClose}>
      <Box pb={5} px={4}>
        {content}
      </Box>
    </ModalDialog>
  );
}

export default withRequest(memo(BalanceEmptyModal));
