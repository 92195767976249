import React, { useContext, useState } from "react";
import { BaseContext } from "../../utils/baseProvider";
import withBottomBar from "../../components/BottomNavBar/withBottomBar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core/styles";
import {
  BalanceIcon,
  ButtonPlusIcon,
  ButtonUpgradeIcon,
  EditProfileIcon,
  InviteFriendsIcon,
  LogoutIcon,
  PlanIcon,
  RightArrowIcon,
  TimeBalanceIcon
} from "./icons";
import { Button } from "@material-ui/core";
import { figmaFonts } from "../../theme";
import { getFormattedBalance } from "../../utils/time";
import BalanceEmptyModal from "../../components/BalanceEmptyModal/BalanceEmptyModal";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  container: {},
  dataBox: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.background.default,
    padding: theme.spacing(3),
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20
  },
  cardContainer: {
    backgroundColor: theme.palette.background.input,
    padding: theme.spacing(2),
    borderRadius: 10,
    width: 154,
    color: theme.palette.text.default
  },
  primaryText: {
    ...figmaFonts.mobileSmallTextMedium,
    color: theme.palette.primary.main
  },
  text: {
    ...figmaFonts.textMedium16,
    textTransform: "capitalize"
  },
  textUntil: {
    ...figmaFonts.mobileSmallTextMedium,
    color: theme.palette.text.grey
  },
  button: {
    height: 32
  },
  balanceChip: {
    height: 22,
    width: 67,
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0.5),
    backgroundColor: theme.palette.background.darkestGrey,
    ...figmaFonts.mobileSmallTextMedium,
    borderRadius: 5
  },
  buttonGrey: {
    backgroundColor: theme.palette.background.input,
    height: 52,
    display: "flex",
    borderRadius: 10,
    alignItems: "center",
    padding: theme.spacing(1.25),
    marginBottom: theme.spacing(1),
    position: "relative",
    cursor: "pointer"
  },
  buttonGreyTitle: {
    ...figmaFonts.textMedium14
  },
  buttonGreySubtitle: {
    ...figmaFonts.mobileSmallTextRegular,
    color: theme.palette.text.grey
  },
  buttonGreyArrow: {
    position: "absolute",
    right: 16
  }
}));

function MainPageAuthorizedFragmentMobile() {
  const classes = useStyles();
  const history = useHistory();
  const baseContext = useContext(BaseContext);
  const [topUpBalanceOpen, setTopUpBalanceOpen] = useState(false);

  const renderButton = ({ title, subtitle, icon, onClick }) => {
    return (
      <Box onClick={onClick} className={classes.buttonGrey}>
        <Box mr={1.25} height={32}>
          {icon}
        </Box>
        <Box display={"flex"} flexDirection={"column"}>
          <Typography className={classes.buttonGreyTitle}>{title}</Typography>
          {subtitle && (
            <Typography className={classes.buttonGreySubtitle}>
              {subtitle}
            </Typography>
          )}
        </Box>

        {subtitle && <RightArrowIcon className={classes.buttonGreyArrow} />}
      </Box>
    );
  };

  const renderTopUpCard = () => {
    return (
      <Box mr={2} className={classes.cardContainer}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <BalanceIcon />
        </Box>

        <Box mt={1.5} mb={1.5}>
          <Typography className={classes.primaryText}>Balance:</Typography>
          <Typography className={classes.text}>
            ${baseContext.centsBalance / 100}
          </Typography>
        </Box>
        <Button
          variant={"contained"}
          color={"primary"}
          size={"small"}
          fullWidth
          className={classes.button}
          onClick={() => setTopUpBalanceOpen(true)}
        >
          <ButtonPlusIcon style={{ marginRight: "5px" }} />
          Top-up
        </Button>
      </Box>
    );
  };

  const renderPlanCard = () => {
    const formattedUntil = new Date(
      baseContext.currentUser.until * 1000
    ).toLocaleDateString("en-US");

    const formattedBalance = getFormattedBalance(baseContext.secondsBalance);

    return (
      <Box className={classes.cardContainer}>
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <PlanIcon />
          {baseContext.currentUser.until && (
            <Box>
              <Typography className={classes.textUntil}>until</Typography>
              <Typography className={classes.textUntil}>
                {formattedUntil}
              </Typography>
            </Box>
          )}
        </Box>

        <Box
          mt={1.5}
          mb={1.5}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"flex-end"}
        >
          <Box>
            <Typography className={classes.primaryText}>Plan:</Typography>
            <Typography className={classes.text}>
              {baseContext.currentUser.plan}
            </Typography>
          </Box>
          <Box className={classes.balanceChip}>
            <TimeBalanceIcon style={{ marginRight: "5px" }} />
            {formattedBalance}
          </Box>
        </Box>
        <Button
          variant={"contained"}
          color={"primary"}
          size={"small"}
          fullWidth
          className={classes.button}
          onClick={() => history.push("/subscription")}
        >
          <ButtonUpgradeIcon style={{ marginRight: "5px" }} />
          Upgrade
        </Button>
      </Box>
    );
  };

  return (
    <>
      <Box className={classes.dataBox}>
        <Typography variant={"h1"}>{baseContext.currentUser.name}</Typography>
        {baseContext.currentUser.username && (
          <Typography variant={"h2"}>
            @{baseContext.currentUser.username}
          </Typography>
        )}

        <Box mt={2} display={"flex"}>
          {renderTopUpCard()}
          {renderPlanCard()}
        </Box>
      </Box>

      <Box px={3.125} mt={3} mb={3}>
        {renderButton({
          title: "Edit Profile",
          icon: <EditProfileIcon />,
          subtitle: "Update your name, language and more",
          onClick: () => history.push("/profileedit")
        })}
        {renderButton({
          title: "Invite friends",
          icon: <InviteFriendsIcon />,
          subtitle: "Share with friends and get bonuses",
          onClick: () => history.push("/bonus")
        })}
        {renderButton({
          title: "Log out",
          icon: <LogoutIcon />,
          onClick: () => history.push("/logout")
        })}
      </Box>

      <BalanceEmptyModal
        handleClose={() => setTopUpBalanceOpen(false)}
        open={topUpBalanceOpen}
        type={"topUpUsdBalance"}
      />
    </>
  );
}

export default withBottomBar(MainPageAuthorizedFragmentMobile);
