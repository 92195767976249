import React, { useEffect, useState } from "react";
import BasePage from "./BasePage/BasePage";
import { makeStyles } from "@material-ui/core/styles";
import { Redirect, useHistory } from "react-router-dom";
import withRequest from "../utils/withRequest";
import InputGroup from "../components/InputGroup/InputGroup";
import StyledLink from "../components/StyledLink";
import { Box, Button, Typography } from "@material-ui/core";
import * as EmailValidator from "email-validator";
import useAppBarHeight from "../utils/useAppBarHeight";
import useBreakpoints from "../utils/useBreakpoints";
import { appStore } from "../utils/stores/AppStore";
import { observer } from "mobx-react";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: `calc(100%)`,
    width: "100%"
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "384px",
    maxWidth: "100%",
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(5)
    }
  },
  heading: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("lg")]: {
      marginBottom: theme.spacing(3)
    }
  },
  form: {
    width: "100%",
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(5),
    [theme.breakpoints.up("lg")]: {
      marginTop: theme.spacing(4)
    }
  }
}));

function PasswordResetPage(props) {
  const appBarHeight = useAppBarHeight();
  const classes = useStyles({ appBarHeight });
  const history = useHistory();
  const [formData, setFormData] = useState({ email: "" });
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState(undefined);
  const { isMobile } = useBreakpoints();

  useEffect(() => {
    checkDisabled();
    // eslint-disable-next-line
  }, [formData]);

  function onChange(event) {
    setError(undefined);
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  }

  function checkDisabled() {
    if (formData.email.length < 5) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }

  function send(event) {
    event.stopPropagation();
    event.preventDefault();

    if (!EmailValidator.validate(formData.email)) {
      setError("Please enter correct email");
      return;
    }

    props.postDataWithCallback(
      "/api/passwordreset",
      formData,
      data => {
        if (data === "ok") {
          history.push({ pathname: "/passwordverify", state: formData });
        }
      },
      err => {
        setError("Email not found");
      }
    );
  }

  if (appStore.authorized === true) {
    return <Redirect to="/" />;
  }

  return (
    <BasePage>
      <Box
        px={isMobile ? 3.125 : 0}
        my={isMobile ? appBarHeight / 8 + 5 : "auto"}
        className={classes.container}
      >
        <div className={classes.paper}>
          {}
          <Typography className={classes.heading} variant="h1">
            Password recovery
          </Typography>
          <Typography variant="h2">Enter the email you’re using</Typography>
          <Typography variant="h2">for your account</Typography>
          <form className={classes.form} noValidate onSubmit={send}>
            <InputGroup
              label="Email"
              required
              id="email"
              placeholder="name@domain.com"
              name="email"
              autoComplete="email"
              onChange={onChange}
              error={error}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              size="large"
              disabled={disabled}
            >
              CONTINUE
            </Button>
          </form>
          <StyledLink to="/signin">Back to Sign In</StyledLink>
        </div>
      </Box>
    </BasePage>
  );
}

export default withRequest(observer(PasswordResetPage));
