import { useEffect, useState } from "react";

function useBreakpoints() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function onResize() {
      setWidth(window.innerWidth);
    }

    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
    // eslint-disable-next-line
  }, []);

  // <600
  const isMobile = width < 600;
  // >= 600 && <900
  const isSmallTablet = width >= 600 && width < 900;
  // >= 900 && < 1280
  const isTablet = width >= 900 && width < 1280;
  // > 1280
  const isDesktop = width >= 1280;
  // > 1366
  const isHd = width > 1366;

  return { isMobile, isSmallTablet, isTablet, isDesktop, isHd };
}

export default useBreakpoints;
