import { Box, Button } from "@material-ui/core";
import React, { memo, useCallback, useEffect, useState } from "react";
import ModalDialog from "../ModalDialog/ModalDialog";
import LanguageSelect from "../../pages/CreatelocalcallPage/components/LanguageSelect";
import useLanguages from "../../utils/hooks/useLanguages";
import RadioSelectGroup from "../RadioSelectGroup/RadioSelectGroup";
import useBreakpoints from "../../utils/useBreakpoints";

const SettingsModal = ({
  open,
  onClose,
  isCaller,
  onSettingsSave,
  callSettings,
  onlyCurrentUserSettings = false
}) => {
  const { languagesFrom, languagesTo } = useLanguages();
  const { isDesktop } = useBreakpoints();

  const [formData, setFormData] = useState();

  useEffect(() => {
    setFormData(callSettings);
    // eslint-disable-next-line
  }, [open]);

  const onFieldChange = useCallback(
    (key, value) => {
      setFormData({
        ...formData,
        [key]: value
      });
    },
    [formData]
  );

  const onSettingsSaveClick = useCallback(() => {
    onSettingsSave(formData);
  }, [formData, onSettingsSave]);

  if (!formData) return null;

  return (
    <ModalDialog
      title={"Translation settings"}
      open={open}
      handleClose={onClose}
    >
      <Box
        maxWidth={isDesktop ? 400 : 320}
        px={isDesktop ? 5 : 3}
        pb={isDesktop ? 5 : 3}
      >
        <LanguageSelect
          onChange={event => {
            onFieldChange(isCaller ? "from" : "to", event.target.value);
            if (isCaller && event.target.value === formData.to) {
              onFieldChange("to", "");
            }
          }}
          value={isCaller ? formData.from : formData.to}
          languages={isCaller ? languagesFrom : languagesTo}
          label={"Your native language " + isCaller ? "from" : "to"}
        />

        {!onlyCurrentUserSettings && isCaller && (
          <LanguageSelect
            onChange={event => onFieldChange("to", event.target.value)}
            value={formData.to}
            languages={languagesTo}
            label="Target language"
          />
        )}

        <RadioSelectGroup
          onChange={event =>
            onFieldChange(
              isCaller ? "fromGender" : "toGender",
              event.target.value
            )
          }
          value={isCaller ? formData.fromGender : formData.toGender}
          label={"Your voice"}
          items={["Male", "Female"]}
          itemsMargin={isDesktop ? 3 : 1}
        />

        {!onlyCurrentUserSettings && isCaller && (
          <RadioSelectGroup
            onChange={event => onFieldChange("toGender", event.target.value)}
            value={formData.toGender}
            label={"The voice of the interlocutor"}
            items={["Male", "Female"]}
            itemsMargin={isDesktop ? 3 : 1}
          />
        )}

        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={onSettingsSaveClick}
          disabled={
            !formData.from ||
            !formData.to ||
            !formData.toGender ||
            !formData.fromGender
          }
        >
          Save
        </Button>
      </Box>
    </ModalDialog>
  );
};

export default memo(SettingsModal);
