import Flag from "../../../../components/CustomFlag/Flag";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { ReactComponent as Icon } from "./icon.svg";
import { figmaFonts } from "../../../../theme";

const useStyles = makeStyles(
  theme => ({
    badge: {
      height: props => props.height,
      backgroundColor: props =>
        props.backgroundColor || theme.palette.background.input,
      borderRadius: 5,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      padding: theme.spacing(0, 1),
      cursor: "pointer",
      color: props =>
        props.backgroundColor ? theme.palette.text.secondary : "initial"
    },
    flag: {
      width: 16,
      height: 16
    },
    toName: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(1.125),
      ...figmaFonts.desktopSmallTextMedium
    }
  }),
  []
);
const PhoneCallCreateSettingsBadge = ({
  onClick,
  toCode,
  toName,
  backgroundColor,
  height = 32,
  mx = "auto"
}) => {
  const classes = useStyles({ backgroundColor, height, mx });

  return (
    <Box mx={mx} className={classes.badge} onClick={onClick}>
      {toCode && <Flag className={classes.flag} code={toCode.toLowerCase()} />}
      {toName && <Typography className={classes.toName}>{toName}</Typography>}
      <Icon />
    </Box>
  );
};

export default PhoneCallCreateSettingsBadge;
