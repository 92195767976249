import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import LanguagesBadge, {
  LanguagesBadgeIcons
} from "../../components/LanguagesBadge/LanguagesBadge";
import { LocalCallContext } from "./localCallProvider";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import useBreakpoints from "../../utils/useBreakpoints";
import { formatElapsed } from "../../utils/time";
import { AppContext } from "../../utils/AppContext";
import useLanguages from "../../utils/hooks/useLanguages";
import SettingsButton from "../../components/CallSettings/SettingsButton";
import { figmaFonts } from "../../theme";
import Balance from "../../components/AppBarTop/TopNavigation/Balance";
import { ReactComponent as MicOff } from "./icons/micOff.svg";
import { ReactComponent as MicOffDark } from "./icons/micOffDark.svg";
import { observer } from "mobx-react";

const useStyles = makeStyles(theme => ({
  callInfo: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "top",
    position: "absolute",
    zIndex: 1,
    top: 24,
    left: 0,
    padding: theme.spacing(0, 3.125),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(0, 10)
    },
    [theme.breakpoints.up("lg")]: {
      top: 40
    }
  },
  firstRowBox: {
    display: "flex",
    alignItems: "baseline"
  },
  name: {
    maxWidth: 200,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("md")]: {
      maxWidth: 240,
      fontSize: theme.typography.pxToRem(22),
      lineHeight: theme.typography.pxToRem(27)
    }
  },
  time: {
    fontWeight: "normal",
    color: theme.palette.text.secondary,
    ...figmaFonts.desktopSmallTextRegular,
    [theme.breakpoints.down("md")]: {
      ...figmaFonts.textRegular14
    }
  },
  timeDark: {
    color: theme.palette.text.grey
  }
}));

function CallData({
  Bconnected,
  isDirectCall,
  elapsedText,
  isCallee,
  showLanguagesBadge = true
}) {
  const classes = useStyles();
  const localCallContext = useContext(LocalCallContext);
  const { isDesktop, isMobile } = useBreakpoints();
  const { localCallUIStore, localCallStore } = useContext(AppContext);
  const { getLanguageByKey } = useLanguages();

  const mobileWithDisabledVideo = false;

  const firstCountry = getLanguageByKey(
    isCallee ? localCallStore.callSettings.to : localCallStore.callSettings.from
  ).Country;
  const secondCountry = getLanguageByKey(
    isCallee ? localCallStore.callSettings.from : localCallStore.callSettings.to
  ).Country;

  return (
    <Box className={classes.callInfo}>
      <Box display={"flex"}>
        <SettingsButton
          small
          onClick={localCallUIStore.toggleSettingsModalShow}
          size={isDesktop ? 30 : 24}
          disabled={!Bconnected}
        />

        <Box ml={isMobile ? 1 : 2} display={"flex"} flexDirection={"column"}>
          {(isDirectCall || Bconnected) && (
            <Box mb={isMobile ? 0.5 : 1}>
              <Box display={"flex"} alignItems={"center"}>
                <Typography
                  className={classes.name}
                  color={mobileWithDisabledVideo ? "initial" : "secondary"}
                  variant="h2"
                >
                  {localCallContext.remoteName}
                </Typography>
                {!localCallContext.calleeAudioEnabled && (
                  <Box display="flex" alignItems={"center"} ml={1}>
                    {mobileWithDisabledVideo ? <MicOffDark /> : <MicOff />}
                  </Box>
                )}
              </Box>
            </Box>
          )}

          <Box display={"flex"} alignItems={"center"}>
            {(elapsedText || Bconnected) && (
              <Box mr={1}>
                <Typography
                  className={clsx(
                    classes.time,
                    mobileWithDisabledVideo ? classes.timeDark : ""
                  )}
                  variant="subtitle2"
                >
                  {elapsedText
                    ? elapsedText
                    : formatElapsed(localCallContext.elapsed)}
                </Typography>
              </Box>
            )}

            {showLanguagesBadge && (
              <Box mt={Bconnected ? 0 : 0.5} alignSelf={"center"}>
                {localCallContext.translationEnabled && (
                  <LanguagesBadge
                    icon={
                      Bconnected
                        ? LanguagesBadgeIcons.arrowsSmall
                        : LanguagesBadgeIcons.arrowsBlack
                    }
                    iconMargin={0}
                    size={16}
                    firstCountry={firstCountry}
                    secondCountry={secondCountry}
                  />
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>

      <Box>
        <Balance
          showIcon={false}
          mode={Bconnected || isDirectCall ? "dark" : "light"}
        />
      </Box>
    </Box>
  );
}
export default observer(CallData);
