import React, { useEffect, useState } from "react";
import Transcription from "./Transcription";
import TranscriptionPlaceholder from "./TranscriptionPlaceholder";
import { Box, SwipeableDrawer } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DragStrip from "../../../components/DragStrip";
import clsx from "clsx";
import useBreakpoints from "../../../utils/useBreakpoints";

const useStyles = makeStyles(theme => ({
  drawerBackdrop: {
    opacity: "0 !important"
  },
  drawerContent: {
    backgroundColor: "rgba(245, 245, 251, 0.4)",
    backdropFilter: "blur(50px)",
    borderRadius: "20px 20px 0px 0px",
    paddingTop: theme.spacing(4.5),
    boxShadow: "none",
    transition: "opacity 225ms linear !important",
    opacity: 0
  },
  drawerContentOpen: {
    opacity: 1
  }
}));

function TranscriptionContainer({
  showTranscription,
  toggleTranscription,
  Bconnected,
  messages,
  sendMessageToDC = null,
  calleeVideoEnabled = false,
  currentUserIsCallee = false,
  setMessageRead,
  translationEnabled,
  isPhoneCall
}) {
  const classes = useStyles();
  const { isDesktop } = useBreakpoints();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(showTranscription);
  }, [showTranscription]);

  const transcriptionEl = Bconnected ? (
    <Transcription
      calleeVideoEnabled={calleeVideoEnabled}
      currentUserIsCallee={currentUserIsCallee}
      messages={messages}
      sendMessageToDC={sendMessageToDC}
      setMessageRead={setMessageRead}
      translationEnabled={translationEnabled}
      isPhoneCall={isPhoneCall}
    />
  ) : (
    <TranscriptionPlaceholder isPhoneCall={isPhoneCall} />
  );

  return isDesktop ? (
    <Box position={"absolute"} top={0} bottom={0} left={0} right={0}>
      {open && transcriptionEl}
    </Box>
  ) : (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={toggleTranscription}
      onOpen={toggleTranscription}
      keepMounted={true}
      PaperProps={{
        className: clsx(
          classes.drawerContent,
          open ? classes.drawerContentOpen : ""
        )
      }}
      BackdropProps={{
        className: classes.drawerBackdrop
      }}
    >
      <DragStrip white={Bconnected && calleeVideoEnabled} bottom={false} />
      {transcriptionEl}
    </SwipeableDrawer>
  );
}

export default TranscriptionContainer;
