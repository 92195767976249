import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Switch } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    width: 38,
    height: 22,
    padding: 0,
    marginLeft: theme.spacing(1)
  },
  switchBase: {
    padding: 3,
    "&$checked": {
      transform: "translateX(16px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#52d869",
        opacity: 1,
        border: "none"
      }
    },
    "&$focusVisible $thumb": {
      color: "#52d869",
      border: "6px solid #fff"
    }
  },
  thumb: {
    width: 16,
    height: 16
  },
  track: {
    borderRadius: 50,
    backgroundColor: theme.palette.text.grey,
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"])
  },
  checked: {},
  focusVisible: {}
}));

function IOSSwitch({ checked = false, onChange, disabled = false }) {
  const classes = useStyles();
  return (
    <Switch
      onChange={onChange}
      checked={checked}
      focusVisibleClassName={classes.focusVisible}
      disabled={disabled}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked
      }}
    />
  );
}

export default IOSSwitch;
