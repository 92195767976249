const getCallMessageText = (callStatus, isBSide) => {
  switch (callStatus) {
    case "No answer":
      return "Missed Call";
    case "Canceled":
      return isBSide ? "Missed Call" : "Canceled Call";
    case "Answered":
      return isBSide ? "Incoming Call" : "Outgoing Call";
    default:
      return "";
  }
};

export default getCallMessageText;
