import React from "react";
import { Box, Typography } from "@material-ui/core";
import PlanChip from "../../../../components/chips/PlanChip";
import { makeStyles } from "@material-ui/core/styles";
import { figmaFonts } from "../../../../theme";

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: theme.palette.background.input,
    color: theme.palette.text.grey,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    textAlign: "center"
  },
  headerActive: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.secondary,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    textAlign: "center"
  },
  headerRotated: {
    textOrientation: "mixed",
    writingMode: "vertical-lr",
    transform: "rotate(180deg)",
    borderRadius: 0,
    borderBottomRightRadius: 4,
    borderTopRightRadius: 4
  },
  content: {
    border: `1px solid ${theme.palette.background.input}`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  contentHorizontal: {
    border: `1px solid ${theme.palette.background.input}`,
    display: "flex"
  },
  period: {
    ...figmaFonts.textRegular16,
    color: theme.palette.text.grey,
    marginLeft: 4
  }
}));

function PlanCard({ price, period, currentPlanPeriod, horizontal }) {
  const classes = useStyles();
  const title = period === currentPlanPeriod ? "Current" : "Updated";
  const text = period === "month" ? "(monthly)" : "(yearly)";

  let titleClass = classes.header;
  if (period === currentPlanPeriod) {
    titleClass += " " + classes.headerActive;
  }
  if (horizontal) {
    titleClass += " " + classes.headerRotated;
  }

  return (
    <Box display={"flex"} flexDirection={horizontal ? "row" : "column"}>
      <Box className={titleClass} py={0.5} px={0.5}>
        {title}
      </Box>
      <Box
        className={horizontal ? classes.contentHorizontal : classes.content}
        py={horizontal ? 3 : 2}
        pl={3}
        px={horizontal ? 4 : 3}
      >
        <Box>
          <Box mb={horizontal ? 0.5 : 1}>
            <PlanChip plan={"plus"} text={text} />
          </Box>
          <Box>
            <Typography display={"inline"} variant={"h1"}>
              ${price}
            </Typography>
            <Typography display={"inline"} className={classes.period}>
              /{period}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default PlanCard;
