import React, { useCallback, useMemo } from "react";
import ModalDialog from "../../../../components/ModalDialog/ModalDialog";
import { Box, Button, Typography } from "@material-ui/core";
import { chatsStore } from "../../../../utils/stores/ChatsStore";
import { makeStyles } from "@material-ui/core/styles";
import { figmaFonts } from "../../../../theme";
import useBreakpoints from "../../../../utils/useBreakpoints";

const useStyles = makeStyles(theme => ({
  modalTitle: {},
  modalText: {
    ...figmaFonts.desktopSmallTextRegular,
    whiteSpace: "break-spaces",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    minWidth: 248,
    [theme.breakpoints.up("sm")]: {
      minWidth: 305
    }
  },
  cancelButton: {
    backgroundColor: theme.palette.background.input,
    borderColor: "transparent",
    marginRight: theme.spacing(1),
    ...figmaFonts.desktopCapsBtn14,
    height: 40,
    [theme.breakpoints.up("sm")]: {
      height: 48
    },
    width: "50%"
  },
  submitButton: {
    width: "50%",
    marginLeft: theme.spacing(1),
    ...figmaFonts.desktopCapsBtn14,
    height: 40,
    [theme.breakpoints.up("sm")]: {
      height: 48
    }
  }
}));
const ChatMenuModal = ({ modalType, onClose, chat }) => {
  const classes = useStyles();
  const { isDesktop } = useBreakpoints();

  const modalText = useMemo(() => {
    switch (modalType) {
      case "report":
        return {
          title: "Report " + chat.title,
          text:
            "If something doesn't feel right, report it\n" +
            "so we can help keep you safe.",
          button: "Report"
        };
      case "delete":
        return {
          title: "Delete " + chat.title,
          text:
            "Are you sure you want to delete\n" +
            chat.title +
            " from your contact list?",
          button: "Delete"
        };
      case "block":
        return {
          title: (chat.remoteBan ? "Unblock " : "Block ") + chat.title,
          text:
            "Do you want to " +
            (chat.remoteBan ? "unblock " : "block ") +
            chat.title +
            " from\n" +
            "messaging and calling you to YOUS?",
          button: chat.remoteBan ? "Unblock" : "Block"
        };
      default:
        return {};
    }
  }, [chat.remoteBan, chat.title, modalType]);

  const onModalClick = useCallback(async () => {
    switch (modalType) {
      case "report":
        await chatsStore.reportChat(chat);
        break;
      case "delete":
        await chatsStore.deleteChat(chat);
        break;
      case "block":
        await chatsStore.toggleBlock(chat);
        break;
      default:
        return;
    }

    onClose();
  }, [chat, modalType, onClose]);
  return (
    <ModalDialog
      open={!!modalType}
      handleClose={onClose}
      transitionDuration={50}
    >
      <Box textAlign={"center"} pt={isDesktop ? 0 : 2} px={3.625} pb={5}>
        <Typography variant={"h2"} className={classes.modalTitle}>
          {modalText.title}
        </Typography>
        <Typography className={classes.modalText}>{modalText.text}</Typography>
        <Box display={"flex"}>
          <Button
            variant={"outlined"}
            color={"primary"}
            onClick={onClose}
            className={classes.cancelButton}
            size="small"
          >
            Cancel
          </Button>
          <Button
            onClick={onModalClick}
            type="submit"
            variant="contained"
            size="small"
            color="primary"
            className={classes.submitButton}
          >
            {modalText.button}
          </Button>
        </Box>
      </Box>
    </ModalDialog>
  );
};

export default ChatMenuModal;
