import React, { useEffect, useState } from "react";
import BasePage from "../BasePage/BasePage";
import { makeStyles } from "@material-ui/core/styles";
import withRequest from "../../utils/withRequest";
import { Box } from "@material-ui/core";
import withBottomBar from "../../components/BottomNavBar/withBottomBar";
import TabPanel from "../../components/TabPanel";
import { Redirect, useLocation } from "react-router-dom";
import useBreakpoints from "../../utils/useBreakpoints";
import InviteFriendsPage from "../InviteFriendsPage/InviteFriendsPage";
import Dashboard from "./components/Dashboard/Dashboard";
import DetailedRules from "./components/DetailedRules/DetailedRules";
import Store from "./components/Store/Store";
import { appStore } from "../../utils/stores/AppStore";
import { observer } from "mobx-react";
import DesktopAppBar from "./components/DesktopAppBar";
import MobileAppBar from "./components/MobileAppBar";
import useAppBarLeftContent from "../../utils/hooks/useAppBarLeftContent";

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
    marginTop: theme.spacing(2)
  },
  tabsRoot: {
    minHeight: "auto"
  },
  indicator: {
    display: "none"
  },
  flexContainer: {
    justifyContent: "center"
  },
  tab: {
    minHeight: "auto",
    fontSize: theme.typography.pxToRem(10),
    lineHeight: theme.typography.pxToRem(12),
    fontWeight: 400,
    color: theme.palette.text.grey,
    padding: theme.spacing(1),
    opacity: 1,
    textTransform: "none",
    "&.Mui-selected": {
      color: theme.palette.text.default
    }
  }
}));

function BonusPage({ getDataWithCallback }) {
  const classes = useStyles();
  const { isDesktop, isHd } = useBreakpoints();
  const location = useLocation();
  const prevState = location.state || { tab: 0 };
  const [tab, setTab] = useState(prevState.tab);
  const [data, setData] = useState({});
  useAppBarLeftContent({ title: "Invite friends" });

  useEffect(() => {
    if (appStore.authorized) {
      getDataWithCallback("/api/userbonus", {}, data => {
        setData(data);
      });
    }
    // eslint-disable-next-line
  }, [appStore.authorized]);

  useEffect(() => {
    if (location.state && location.state.tab !== undefined) {
      setTab(4);
      setTimeout(() => {
        setTab(location.state.tab);
      }, 0);
    }
  }, [location.state]);

  const showDesktopLayout = isDesktop || isHd;

  if (appStore.authorized === false) {
    return <Redirect to={"/"} />;
  }

  return (
    <BasePage>
      <Box pt={showDesktopLayout ? 5 : 2} ml={showDesktopLayout ? 8 : 0}>
        <Box mb={showDesktopLayout ? 5 : 2.5}>
          {showDesktopLayout ? (
            <DesktopAppBar setTab={setTab} tab={tab} />
          ) : (
            <MobileAppBar setTab={setTab} tab={tab} />
          )}
        </Box>

        <TabPanel value={tab} index={0}>
          <Dashboard showDesktopLayout={showDesktopLayout} />
        </TabPanel>
        <TabPanel style={{ width: "fit-content" }} value={tab} index={1}>
          <DetailedRules showDesktopLayout={showDesktopLayout} />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={tab} index={2}>
          <Store
            showDesktopLayout={showDesktopLayout}
            balance={data.totalBonus}
          />
        </TabPanel>
        <TabPanel className={classes.tabPanel} value={tab} index={3}>
          <InviteFriendsPage showDesktopLayout={showDesktopLayout} />
        </TabPanel>
      </Box>
    </BasePage>
  );
}

export default withBottomBar(withRequest(observer(BonusPage)));
