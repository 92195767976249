import React from "react";
import { Redirect } from "react-router-dom";
import { observer } from "mobx-react";
import { appStore } from "../utils/stores/AppStore";
import Loader from "./Loader";
import BasePage from "../pages/BasePage/BasePage";

const CheckAuthorization = ({ children }) => {
  if (appStore.authorized === undefined) {
    return (
      <BasePage>
        <Loader />
      </BasePage>
    );
  }

  if (appStore.authorized === false) {
    return <Redirect to="/" />;
  }

  return children;
};

export default observer(CheckAuthorization);
