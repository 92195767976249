import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Box } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  dragStrip: {
    height: 4,
    width: 115,
    backgroundColor: theme.palette.text.grey,
    position: "absolute",
    left: 0,
    right: 0,
    margin: "0 auto",
    borderRadius: 100
  },
  dragStripWhite: {
    backgroundColor: theme.palette.background.default
  },
  dragStripTop: {
    top: 16
  },
  dragStripBottom: {
    bottom: 8
  }
}));
function DragStrip({ bottom = true, white = false }) {
  const classes = useStyles();

  return (
    <Box
      className={clsx(
        classes.dragStrip,
        bottom ? classes.dragStripBottom : classes.dragStripTop,
        white ? classes.dragStripWhite : ""
      )}
    />
  );
}

export default DragStrip;
