import { ReactComponent as BasicPlanIcon } from "../../assets/icons/basicPlanIcon.svg";
import { ReactComponent as PlusPlanIcon } from "../../assets/icons/plusPlanIcon.svg";
import { ReactComponent as SaveIcon } from "../../assets/icons/saveIcon.svg";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";
import { palette } from "../../theme";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  chip: {
    padding: theme.spacing(0.5, 1),
    borderRadius: "5px",
    height: "auto",
    cursor: "inherit"
  },
  chipLabel: {
    textTransform: "capitalize"
  },
  chipIcon: {
    margin: 0,
    marginRight: 5
  }
}));
function PlanChip({ plan, text, marginRight = "auto", className, background }) {
  const classes = useStyles();

  let icon, labelText, color, backgroundColor;
  backgroundColor = background || palette.background.input;
  switch (plan) {
    case "save":
      icon = <SaveIcon />;
      labelText = text;
      break;
    case "basic":
      icon = <BasicPlanIcon />;
      labelText = "basic" + (text ? ` ${text}` : "");
      color = palette.primary.main;
      backgroundColor = palette.background.input;
      break;
    case "plus":
      icon = <PlusPlanIcon />;
      color = palette.text.secondary;
      backgroundColor = palette.background.green;
      labelText = "plus" + (text ? ` ${text}` : "");
      break;
    default:
      break;
  }
  const chipRootClassName = clsx(classes.chip, className);
  return (
    <Chip
      classes={{
        root: chipRootClassName,
        label: classes.chipLabel,
        icon: classes.chipIcon
      }}
      style={{
        color,
        backgroundColor,
        marginRight: marginRight
      }}
      icon={icon}
      label={labelText}
    />
  );
}

export default PlanChip;
