import React from "react";
import { IncomingCallIcon, MissedCallIcon } from "../../assets/icons";

const getCallMessageIcon = (callStatus, isBSide) => {
  const style = {
    transform: `rotate(${isBSide ? 0 : 180}deg)`,
    marginRight: "4px"
  };

  switch (callStatus) {
    case "No answer":
    case "Canceled":
      return <MissedCallIcon style={style} />;
    case "Answered":
      return <IncomingCallIcon style={style} />;
    default:
      return null;
  }
};

export default getCallMessageIcon;
