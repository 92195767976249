import { Box, SvgIcon, Typography } from "@material-ui/core";
import { ReactComponent as Facebook } from "./icons/facebook.svg";
import { ReactComponent as Twitter } from "./icons/twitter.svg";
import { ReactComponent as Linkedin } from "./icons/linkedin.svg";
import { ReactComponent as Instagram } from "./icons/instagram.svg";
import React from "react";
import { palette } from "../../theme";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  icon: {
    width: 48,
    height: 48,
    margin: theme.spacing(0, 0.5),
    cursor: "pointer"
  }
}));

function Social() {
  const classes = useStyles();

  function openLink(url) {
    const anchor = document.createElement("a");

    Object.assign(anchor, {
      target: "_blank",
      href: url,
      rel: "noopener noreferrer"
    }).click();
  }

  function onIconClick(name) {
    switch (name) {
      case "facebook":
        openLink("http://facebook.com/yousaiofficial/");
        break;
      case "twitter":
        openLink("https://twitter.com/yousaiofficial");
        break;
      case "linkedin":
        openLink("https://www.linkedin.com/company/yousai");
        break;
      case "instagram":
        openLink("https://www.instagram.com/youshq");
        break;
      default:
        break;
    }
  }

  return (
    <Box color={palette.text.grey}>
      <Typography color={"inherit"} align={"center"} variant={"subtitle1"}>
        Join us in social networks
      </Typography>
      <Box mt={1.375} display={"flex"} justifyContent={"center"}>
        <SvgIcon
          onClick={onIconClick.bind(null, "facebook")}
          className={classes.icon}
          viewBox={"0 0 48 48"}
        >
          <Facebook />
        </SvgIcon>
        <SvgIcon
          onClick={onIconClick.bind(null, "twitter")}
          className={classes.icon}
          viewBox={"0 0 48 48"}
        >
          <Twitter />
        </SvgIcon>
        <SvgIcon
          onClick={onIconClick.bind(null, "linkedin")}
          className={classes.icon}
          viewBox={"0 0 48 48"}
        >
          <Linkedin />
        </SvgIcon>
        <SvgIcon
          onClick={onIconClick.bind(null, "instagram")}
          className={classes.icon}
          viewBox={"0 0 48 48"}
        >
          <Instagram />
        </SvgIcon>
      </Box>
    </Box>
  );
}

export default Social;
