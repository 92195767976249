import React, { Fragment, useCallback, useContext, useMemo } from "react";
import { BaseContext } from "../../utils/baseProvider";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import { ReactComponent as LogoSvg } from "../../assets/logo.svg";
import WaitForConnectionTitle from "../../pages/LocalCallPage/WaitForConnectionTitle";
import { AppBar, Box, Button, Toolbar } from "@material-ui/core";
import TopNavigation from "./TopNavigation/TopNavigation";
import useBreakpoints from "../../utils/useBreakpoints";
import useAppBarHeight from "../../utils/useAppBarHeight";

const useStyles = makeStyles(theme => {
  const appBarBottomBorderHeight = 1;

  return {
    root: {
      backgroundColor: theme.palette.background.default,
      borderBottomWidth: appBarBottomBorderHeight,
      borderBottomStyle: "solid",
      borderColor: "transparent",
      zIndex: 1300, // to hide swipeable menu on mobile
      [theme.breakpoints.up("hd")]: {
        zIndex: 2
      },
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      // to prevent logo and content collision on smaller devices
      [theme.breakpoints.up(768)]: {
        backgroundColor: "transparent"
      }
    },
    toolbar: {
      padding: 0,
      minHeight: props => props.appBarHeight - appBarBottomBorderHeight
    },
    toolbarRightBox: {
      display: "flex",
      marginLeft: "auto"
    },
    logo: {
      width: 117,
      height: 28,
      marginTop: "auto",
      marginBottom: "auto",
      marginRight: theme.spacing(1),
      [theme.breakpoints.up("md")]: {
        width: 155,
        height: 37,
        marginRight: theme.spacing(2)
      }
    },
    appBar: {
      padding: theme.spacing(0, 3.125),
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(0, 3.75, 0, 3.75)
      }
    },
    waitForConnection: {
      height: props => props.appBarHeight - appBarBottomBorderHeight,
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      justifyContent: "center",
      display: "flex",
      background: theme.palette.background.default
    }
  };
});

function AppBarTopNonAuthorized() {
  const appBarHeight = useAppBarHeight();
  const classes = useStyles({ appBarHeight });
  const history = useHistory();
  const baseContext = useContext(BaseContext);
  const { isDesktop, isHd } = useBreakpoints();

  const isSignUp = useMemo(() => baseContext.sign === "up", [baseContext.sign]);

  function handleClickLogo() {
    history.push({ pathname: "/" });
  }

  const handleClick = useCallback(() => {
    if (isSignUp) {
      history.push({ pathname: "/signin" });
    } else {
      history.push({ pathname: "/signup" });
    }
  }, [history, isSignUp]);

  const authorized = baseContext.currentUser !== undefined;

  const showWaitingForConnection = useMemo(
    () => isDesktop && baseContext.showWaitingForConnection && !authorized,
    [authorized, baseContext.showWaitingForConnection, isDesktop]
  );

  const { title } = baseContext.appBarTopLeftItem || {};

  return (
    <Fragment>
      <div className={classes.root}>
        <AppBar
          className={classes.appBar}
          position="static"
          color="transparent"
          elevation={0}
        >
          {showWaitingForConnection && (
            <Box className={classes.waitForConnection}>
              <WaitForConnectionTitle />
            </Box>
          )}

          <Toolbar className={classes.toolbar}>
            <LogoSvg className={classes.logo} onClick={handleClickLogo} />

            {title && isDesktop && (
              <Box
                pl={3.75}
                borderLeft={"1px solid #EFEFEF"}
                height={appBarHeight}
                display={"flex"}
                alignItems={"center"}
              >
                {title}
              </Box>
            )}

            <Box className={classes.toolbarRightBox}>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                onClick={handleClick}
              >
                {isSignUp ? "Log in" : "Sign Up Free"}
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
      </div>
      {!isHd && !isDesktop && baseContext.topNavigationProps && (
        <TopNavigation {...baseContext.topNavigationProps} />
      )}
    </Fragment>
  );
}

export default AppBarTopNonAuthorized;
