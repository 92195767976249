import React, { useContext, useEffect, useState } from "react";
import BasePage from "./BasePage/BasePage";
import { makeStyles } from "@material-ui/core/styles";
import { BaseContext } from "../utils/baseProvider";
import { Redirect, useHistory } from "react-router-dom";
import withRequest from "../utils/withRequest";
import InputGroup from "../components/InputGroup/InputGroup";
import CustomCheckbox from "../components/CustomCheckbox/CustomCheckbox";
import { Box, Button, FormControlLabel, Typography } from "@material-ui/core";
import { getInvitedBy, getUtmSource } from "../utils/localStorage";
import RadioSelectGroup from "../components/RadioSelectGroup/RadioSelectGroup";
import useBreakpoints from "../utils/useBreakpoints";
import * as EmailValidator from "email-validator";
import useLanguages from "../utils/hooks/useLanguages";
import PlaceholderStartAdornment from "../components/InputGroup/components/PlaceholderStartAdornment";
import LanguageSelect from "./CreatelocalcallPage/components/LanguageSelect";
import { getUsernameError } from "../utils/validators/getUsernameError";
import useUsernameAvailable from "../utils/hooks/useUsernameAvailable";
import SuccessWithIcon from "../components/InputGroup/components/SuccessWithIcon";
import ErrorWithIcon from "../components/InputGroup/components/ErrorWithIcon";
import useAppBarHeight from "../utils/useAppBarHeight";
import { appStore } from "../utils/stores/AppStore";
import { observer } from "mobx-react";

const useStyles = makeStyles(theme => ({
  paper: {
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: "384px",
    paddingBottom: theme.spacing(2),
    paddingTop: props => `calc(${props.appBarHeight}px + 40px)`,
    [theme.breakpoints.up("lg")]: {
      paddingTop: props => props.appBarHeight + "px",
      paddingBottom: props => props.appBarHeight + "px"
    }
  },
  heading: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("lg")]: {
      marginBottom: theme.spacing(3)
    }
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.up("lg")]: {
      marginTop: theme.spacing(4)
    }
  },
  submit: {
    [theme.breakpoints.up("lg")]: {
      marginTop: 3
    }
  },
  checkboxLabel: {
    color: theme.palette.text.grey,
    marginLeft: theme.spacing(2),
    "&>a": {
      color: "inherit"
    }
  }
}));

function SignupPage(props) {
  const appBarHeight = useAppBarHeight();
  const classes = useStyles({ appBarHeight });
  const baseContext = useContext(BaseContext);
  const history = useHistory();
  const [formData, setFormData] = useState({
    email: "",
    name: "",
    gender: "",
    username: "",
    fromLang: ""
  });
  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [errors, setErrors] = useState({ username: "", email: "" });
  const { languagesFrom, selectedFrom } = useLanguages();
  const { usernameAvailable } = useUsernameAvailable(formData.username);
  const { isMobile } = useBreakpoints();

  useEffect(() => {
    if (selectedFrom && !formData.fromLang) {
      setFormData({ ...formData, fromLang: selectedFrom });
    }
    // eslint-disable-next-line
  }, [selectedFrom]);

  useEffect(() => {
    baseContext.setSign("up");
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    checkDisabled();
    // eslint-disable-next-line
  }, [formData, checked]);

  function onChange(event) {
    let value = event.target.value;

    if (event.target.name === "name") {
      value = value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, "");
    }

    if (event.target.name === "email") {
      setErrors({ ...errors, email: "" });
    }

    if (event.target.name === "username") {
      value = value.replace(/[^a-z0-9_]+/g, "");
      const usernameError = getUsernameError(value);
      setErrors({ ...errors, username: usernameError || "" });
    }

    setFormData({
      ...formData,
      [event.target.name]: value
    });
  }

  function checkDisabled() {
    if (
      formData.email.length < 5 ||
      formData.name.length < 1 ||
      !formData.gender ||
      checked === false
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }

  function handleCheckbox(event) {
    setChecked(event.target.checked);
  }

  function send(event) {
    event.stopPropagation();
    event.preventDefault();

    const usernameError = getUsernameError(formData.username);
    if (usernameError) {
      setErrors({ ...errors, username: usernameError });
      return;
    }

    if (usernameAvailable === false) {
      return;
    }

    if (!EmailValidator.validate(formData.email)) {
      setErrors({ ...errors, email: "Please enter correct email" });
      return;
    }

    const data = { ...formData };
    const invitedBy = getInvitedBy();

    if (invitedBy) {
      data["invitedBy"] = invitedBy;
    }

    const utmSource = getUtmSource();

    if (utmSource) {
      data["utmSource"] = utmSource;
    }

    props.postDataWithCallback(
      "/api/signup",
      data,
      data => {
        if (data === "ok") {
          history.push({ pathname: "/signupverify", state: formData });
        }
      },
      () => {
        setErrors({ ...errors, email: "This email already exists" });
      }
    );
  }

  if (appStore.authorized === true) {
    return <Redirect to="/" />;
  }

  return (
    <BasePage>
      <Box px={isMobile ? 3.125 : 0} className={classes.paper}>
        <Typography className={classes.heading} variant="h1">
          Sign Up
        </Typography>
        <Typography align={"center"} variant="h2">
          Enter your name and email to&nbsp;register
        </Typography>
        <form className={classes.form} noValidate onSubmit={send}>
          <InputGroup
            label="Your name"
            name="name"
            id="name"
            placeholder="Peter"
            autoComplete="name"
            value={formData.name}
            required
            onChange={onChange}
          />
          <InputGroup
            id="username"
            label="Username"
            type="text"
            name="username"
            error={
              errors["username"] ||
              (usernameAvailable === false && (
                <ErrorWithIcon text={"Not available"} />
              ))
            }
            value={formData.username}
            helperText={
              usernameAvailable ? <SuccessWithIcon text={"Available"} /> : null
            }
            onChange={onChange}
            startAdornment={
              <PlaceholderStartAdornment
                enabled={formData.username}
                symbol={"@"}
              />
            }
          />
          <LanguageSelect
            onChange={onChange}
            value={formData.fromLang}
            languages={languagesFrom}
            label="Your native language"
            name={"fromLang"}
          />
          <RadioSelectGroup
            name={"gender"}
            value={formData["gender"]}
            label={"Your voice"}
            items={["Male", "Female"]}
            onChange={onChange}
          />
          <InputGroup
            label="Email"
            name="email"
            id="email"
            placeholder="name@domain.com"
            autoComplete="email"
            type="email"
            error={errors["email"]}
            required
            onChange={onChange}
          />
          <Button
            type="submit"
            variant="contained"
            size="large"
            color="primary"
            fullWidth
            className={classes.submit}
            disabled={disabled}
          >
            REGISTER
          </Button>
        </form>
        <FormControlLabel
          control={
            <CustomCheckbox handleCheckbox={handleCheckbox} checked={checked} />
          }
          label={
            <Typography className={classes.checkboxLabel} variant="subtitle1">
              I agree with{" "}
              <a target={"_blank"} href={"https://yous.ai/terms"}>
                Terms
              </a>{" "}
              &{" "}
              <a target={"_blank"} href={"https://yous.ai/privacy"}>
                Privacy
              </a>
            </Typography>
          }
        />
      </Box>
    </BasePage>
  );
}

export default withRequest(observer(SignupPage));
