import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box } from "@material-ui/core";
import { ChatStore } from "../../../../utils/stores/ChatStore";
import ChatPageHeader from "./ChatPageHeader";
import { observer } from "mobx-react";
import ChatMessages from "./ChatMessages";
import { chatsStore } from "../../../../utils/stores/ChatsStore";
import { appEmitter } from "../../../../utils/events/appEmitter";
import useBreakpoints from "../../../../utils/useBreakpoints";
import ChatPageHeaderDesktop from "../../desktopComponents/ChatPageHeaderDesktop";
import NewChat from "./NewChat";
import TextInput from "../../../LocalCallPage/Transcription/TextInput";
import throttle from "lodash/throttle";
import useRequest from "../../../../utils/hooks/useRequest";
import apiRoutes from "../../../../utils/api/apiRoutes";
import ChatRemoteBanPlaceHolder from "./ChatRemoteBanPlaceHolder";

const ChatPage = ({ width = 0, onChatSelect }) => {
  const selectedChat = chatsStore.openedChat;
  const [store, setStore] = useState(() => new ChatStore(selectedChat));
  const { isDesktop } = useBreakpoints();
  const { postDataWithCallback } = useRequest();

  useEffect(() => {
    if (selectedChat.id !== store.chat.id) {
      setStore(new ChatStore(selectedChat));
    }
  }, [selectedChat, store.chat]);

  useEffect(() => {
    void store.fetchMessages();
    const cleaner = appEmitter.on("newChatMessage", store.onNewMessage);

    return () => {
      cleaner();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  const showAvatars = useMemo(() => width >= 900, [width]);

  const newChat =
    store.messages.length === 0 &&
    !store.isLoading &&
    !chatsStore.isLoadingChats;

  const sendTyping = useCallback(
    throttle(chatId => {
      postDataWithCallback(
        apiRoutes.sendTyping,
        { id: chatId },
        undefined,
        undefined,
        false
      );
    }, 3000),
    []
  );

  useEffect(() => {
    return sendTyping.cancel;
  }, [sendTyping.cancel]);

  const onTextInputChange = useCallback(
    text => {
      store.setMessage(text);
      if (selectedChat.id) {
        sendTyping(selectedChat.id);
      }
    },
    [selectedChat, sendTyping, store]
  );

  const onSend = useCallback(() => {
    store.sendMessage();
    sendTyping.cancel();
  }, [sendTyping, store]);

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      height={"100%"}
      width={isDesktop ? "100%" : "auto"}
      pb={isDesktop ? "env(safe-area-inset-bottom)" : 0}
    >
      {isDesktop ? (
        <ChatPageHeaderDesktop
          onClose={chatsStore.closeChat}
          chat={selectedChat}
          store={store}
        />
      ) : (
        <ChatPageHeader
          onClose={chatsStore.closeChat}
          chat={selectedChat}
          store={store}
        />
      )}

      {newChat ? (
        <NewChat
          chat={selectedChat}
          onChatSelect={onChatSelect}
          store={store}
        />
      ) : (
        <Box position={"relative"} flex={1} mx={isDesktop ? 0 : -3.125}>
          <ChatMessages
            showAvatars={showAvatars}
            chat={selectedChat}
            messages={store.messages}
            onReply={store.onReply}
            store={store}
          />
        </Box>
      )}
      <Box
        mx={isDesktop ? 0 : -3.125}
        pb={isDesktop ? "env(safe-area-inset-bottom)" : 0}
      >
        {selectedChat.remoteBan ? (
          <ChatRemoteBanPlaceHolder chat={selectedChat} />
        ) : (
          <TextInput
            sendMessage={onSend}
            message={store.message}
            onChange={onTextInputChange}
            replyForMessage={store.getMessageById(store.replyFor)}
            chat={selectedChat}
            clearReply={() => store.onReply()}
          />
        )}
      </Box>
    </Box>
  );
};

export default observer(ChatPage);
