import React, { useContext } from "react";
import QueryString from "query-string";
import { getTokenValue } from "./localStorage";
import { BaseContext } from "./baseProvider";
import { appStore } from "./stores/AppStore";

const withRequest = WrappedComponent => {
  return function HOC(props) {
    const baseContext = useContext(BaseContext);

    function requestDataWithCallback(
      url,
      params,
      onResponse,
      onError,
      showDefaultLoaderAndError,
      method
    ) {
      if (showDefaultLoaderAndError) {
        baseContext.setErrorTitle(null);
        baseContext.setLoading(true);
      }
      let requestParams = {
        credentials: "same-origin",
        method: method,
        headers: {}
      };

      if (method === "post") {
        requestParams.headers["Content-Type"] = "application/json";
        requestParams.body = JSON.stringify(params);
      }

      let response;
      let token = getTokenValue();
      let bearer = null;
      if (token) {
        bearer = `bearer ${token}`;
      }
      if (bearer) {
        requestParams.headers["Authorization"] = bearer;
      }

      fetch(url, requestParams)
        .then(result => {
          response = result.clone();
          return result.text();
        })
        .then(data => {
          if (showDefaultLoaderAndError) {
            baseContext.setLoading(false);
          }
          if (response.status === 401) {
            appStore.setAuthorized(false);
            return;
          }

          if (response.status !== 200) {
            try {
              data = JSON.parse(data);
            } catch (e) {}

            if (onError) {
              onError(data);
            } else {
              if (showDefaultLoaderAndError) {
                baseContext.setErrorTitle("Error");
              }
            }
            return;
          }

          try {
            data = JSON.parse(data);
          } catch (e) {
            console.log(e);
            if (onError) {
              onError();
            } else {
              if (showDefaultLoaderAndError) {
                baseContext.setErrorTitle("Error");
              }
            }
            return;
          }
          if (onResponse != null) {
            onResponse(data);
          }
        })
        .catch(e => {
          if (showDefaultLoaderAndError) {
            baseContext.setLoading(false);
          }
          console.log(e);
          if (response && response.status === 401) {
            appStore.setAuthorized(false);
            return;
          }
          if (onError) {
            onError();
          } else {
            if (showDefaultLoaderAndError) {
              baseContext.setErrorTitle("Error");
            }
          }
        });
    }

    function getDataWithCallback(
      url,
      params,
      onResponse = null,
      onError = null,
      showDefaultLoaderAndError = true
    ) {
      let query = QueryString.stringify(params);
      let queryUrl = url + (query ? "?" + query : "");
      requestDataWithCallback(
        queryUrl,
        params,
        onResponse,
        onError,
        showDefaultLoaderAndError,
        "get"
      );
    }

    function postDataWithCallback(
      url,
      params,
      onResponse = null,
      onError = null,
      showDefaultLoaderAndError = true
    ) {
      requestDataWithCallback(
        url,
        params,
        onResponse,
        onError,
        showDefaultLoaderAndError,
        "post"
      );
    }

    return (
      <WrappedComponent
        postDataWithCallback={postDataWithCallback}
        getDataWithCallback={getDataWithCallback}
        {...props}
      />
    );
  };
};

export default withRequest;
