import React, { useContext, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { BaseContext } from "../../utils/baseProvider";
import { Box } from "@material-ui/core";
import ChipWithBottomText from "../ChipWithBottomText";
import BalanceChip from "./BalanceChip";
import { ReactComponent as TopUpPlus } from "./icons/topUpPlus.svg";
import BalanceEmptyModal from "../BalanceEmptyModal/BalanceEmptyModal";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  chip: {
    padding: theme.spacing(0.5, 1),
    borderRadius: "5px",
    marginBottom: "5px",
    height: "auto"
  },
  chipIcon: {
    margin: 0,
    marginRight: 5
  },
  text: {
    color: theme.palette.text.grey,
    textAlign: "left"
  },
  label: {
    width: 95,
    [theme.breakpoints.up("lg")]: {
      width: 102
    }
  }
}));

function UsdBalanceChipWithTopUp() {
  const classes = useStyles();
  const baseContext = useContext(BaseContext);
  const [balanceEmptyModalOpen, setBalanceEmptyModalOpen] = useState(false);

  if (
    baseContext.secondsBalance === undefined ||
    !baseContext.currentUser.until
  ) {
    return null;
  }

  return (
    <Box className={classes.root}>
      <ChipWithBottomText
        chip={
          <BalanceChip
            width={"auto"}
            text={`$${baseContext.centsBalance / 100}`}
            deleteIcon={<TopUpPlus />}
            onDelete={() => setBalanceEmptyModalOpen(true)}
          />
        }
      />

      <BalanceEmptyModal
        type={"topUpUsdBalance"}
        open={balanceEmptyModalOpen}
        handleClose={() => setBalanceEmptyModalOpen(false)}
      />
    </Box>
  );
}

export default UsdBalanceChipWithTopUp;
