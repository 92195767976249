import { makeAutoObservable, runInAction } from "mobx";

import { api } from "../api/api";
import apiRoutes from "../api/apiRoutes";

export class PeopleSearchStore {
  searchValue = "";
  users = [];
  error = undefined;
  isLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  get isEmpty() {
    return !this.isLoading && this.users.length === 0;
  }

  get isSearchValueEmpty() {
    return (this.searchValue?.length ?? 0) === 0;
  }

  clearSearch = () => {
    this.searchValue = "";
    this.users = [];
  };

  setSearchValue = value => {
    this.searchValue = value;
  };

  setIsLoading = () => {
    this.isLoading = true;
  };

  search = async value => {
    if (value.length < 4) {
      this.isLoading = false;
      this.users = [];
      return;
    }
    this.isLoading = true;
    const startSearch = this.searchValue;
    const response = await api.postDataWithCallback(apiRoutes.userSearch, {
      name: this.searchValue
    });
    if (startSearch !== this.searchValue) return;
    runInAction(() => {
      this.users = response;
      this.isLoading = false;
    });
  };
}

export const peopleSearchStore = new PeopleSearchStore();
