import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  chip: {
    padding: theme.spacing(0.5, 1),
    borderRadius: "5px",
    height: "auto"
  },
  chipIcon: {
    margin: 0,
    marginRight: 5
  },
  text: {
    marginTop: "5px",
    color: theme.palette.text.grey,
    textAlign: "left",
    whiteSpace: "nowrap"
  },
  label: {
    width: 95,
    [theme.breakpoints.up("lg")]: {
      width: 102
    }
  }
}));

function ChipWithBottomText({ chip, text }) {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      {chip}
      <Typography className={classes.text} variant="subtitle2">
        {text}
      </Typography>
    </Box>
  );
}

export default ChipWithBottomText;
