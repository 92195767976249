import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  adornment: {
    marginLeft: "10px"
  },
  adornmentDisabled: {
    color: theme.palette.text.grey
  }
}));
const PlaceholderStartAdornment = ({ symbol, enabled }) => {
  const classes = useStyles();
  return (
    <span
      className={clsx(classes.adornment, {
        [classes.adornmentDisabled]: !enabled
      })}
    >
      {symbol}
    </span>
  );
};

export default PlaceholderStartAdornment;
