const apiRoutes = {
  fetchChats: "/api/chat/all",
  sendMessage: "/api/chat/send",
  setRead: "/api/chat/read",
  getMessages: "/api/chat/messages",
  deleteChat: "/api/chat/delete",
  userComplain: "/api/usercomplain",
  userBan: "/api/userban",
  userUnban: "/api/userunban",
  userSearch: "/api/usersearch",
  sendTyping: "/api/chat/typing"
};

export default apiRoutes;

// api/usercomplain -d "{\"id\": 241}"; -  шлет сообщение в телегу в stat
// api/userban -d "{\"id\": 241}"; - от юзера 241 не будет приходить новых сообщений
// api/userunban -d "{\"id\": 241}";
// api/usersearch -d "{\"name\": \"user\"}"; - поиск юзеро
// api/chat/send -d "{\"toId\": 129, \"text\": \"bonjour\"}"; - отправить сообщение (создает чаты, если нужно)
// api/chat/all - все чаты для списка чатов
// api/chat/read -d "{\"id\": 52}"; - 52 сообщение прочитано
// api/chat/messages -d "{\"id\": 87}" - все сообщение одного чата
// api/chat/delete -d "{\"id\": 73}"; - удалить чат (удаляется только у того кто удаляет)
