import React, { useCallback } from "react";
import TextFieldWithFocusListeners from "../../../components/TextFieldWithFocusListeners";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as SearchIcon } from "../icons/search.svg";
import { ReactComponent as ClearIcon } from "../icons/clearIcon.svg";
import { Box } from "@material-ui/core";
import { observer } from "mobx-react";
import { peopleSearchStore } from "../../../utils/stores/PeopleSearchStore";
import debounce from "lodash.debounce";

const useStyles = makeStyles(theme => ({
  textField: {
    backgroundColor: theme.palette.background.input,
    borderRadius: "10px",
    margin: 0,
    padding: 0,
    paddingLeft: 40,
    [theme.breakpoints.up("lg")]: {
      paddingLeft: 40
    },
    height: 44
  },
  inputRoot: {
    "&:hover:not(.Mui-error):not(.Mui-focused) $notchedOutline": {
      borderColor: "transparent"
    },
    "& .Mui-focused $notchedOutline": {
      borderWidth: "1px"
    },
    "&.Mui-focused:not(.Mui-error) $notchedOutline": {
      borderColor: "transparent"
    },
    "&>textarea::-webkit-scrollbar": {
      display: "none"
    }
  },
  notchedOutline: {
    borderColor: "transparent"
  },
  input: {
    padding: "12px 12px"
  },
  clearButton: {
    position: "absolute",
    right: 0,
    top: 0,
    width: 44,
    zIndex: 1,
    height: 44,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer"
  }
}));

const SearchInput = () => {
  const classes = useStyles();

  const onChange = useCallback(
    debounce(text => {
      void peopleSearchStore.search(text);
    }, 500),
    // eslint-disable-next-line
    []
  );

  const onTextInputChange = useCallback(
    event => {
      peopleSearchStore.setIsLoading();
      peopleSearchStore.setSearchValue(event.target.value);
      onChange(event.target.value);
    },
    [onChange]
  );

  return (
    <Box position={"relative"}>
      <SearchIcon
        style={{
          position: "absolute",
          left: 16,
          top: 10,
          zIndex: 1
        }}
      />
      <TextFieldWithFocusListeners
        variant="outlined"
        margin="normal"
        fullWidth
        placeholder={"Search people"}
        className={classes.textField}
        value={peopleSearchStore.searchValue}
        onChange={onTextInputChange}
        InputProps={{
          classes: {
            root: classes.inputRoot,
            notchedOutline: classes.notchedOutline,
            input: classes.input
          }
        }}
      />
      {peopleSearchStore.searchValue && (
        <Box
          className={classes.clearButton}
          onClick={peopleSearchStore.clearSearch}
        >
          <ClearIcon />
        </Box>
      )}
    </Box>
  );
};

export default observer(SearchInput);
