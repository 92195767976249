import React from "react";
import Avatar from "react-avatar-edit";

class AvatarModified extends Avatar {
  initCanvas() {
    const Konva = window.Konva;
    const stage = this.initStage();
    const background = this.initBackground();
    const shading = this.initShading();
    const crop = this.initCrop();
    const cropStroke = this.initCropStroke();
    const resize = this.initResize();
    const resizeIcon = this.initResizeIcon();

    const layer = new Konva.Layer();

    layer.add(background);
    layer.add(shading);
    layer.add(cropStroke);
    layer.add(crop);

    layer.add(resizeIcon);

    layer.add(resize);

    stage.add(layer);

    const scaledRadius = (scale = 0) => crop.radius() - scale;
    const isLeftCorner = scale => crop.x() - scaledRadius(scale) < 0;
    const calcLeft = () => crop.radius() + 1;
    const isTopCorner = scale => crop.y() - scaledRadius(scale) < 0;
    const calcTop = () => crop.radius() + 1;
    const isRightCorner = scale =>
      crop.x() + scaledRadius(scale) > stage.width();
    const calcRight = () => stage.width() - crop.radius() - 1;
    const isBottomCorner = scale =>
      crop.y() + scaledRadius(scale) > stage.height();
    const calcBottom = () => stage.height() - crop.radius() - 1;
    const isNotOutOfScale = scale =>
      !isLeftCorner(scale) &&
      !isRightCorner(scale) &&
      !isBottomCorner(scale) &&
      !isTopCorner(scale);
    const calcScaleRadius = scale =>
      scaledRadius(scale) >= this.minCropRadius
        ? scale
        : crop.radius() - this.minCropRadius;
    const calcResizerX = x => x + crop.radius() * 0.86;
    const calcResizerY = y => y - crop.radius() * 0.5;
    const moveResizer = (x, y) => {
      resize.x(calcResizerX(x) - 8);
      resize.y(calcResizerY(y) - 8);
      resizeIcon.x(calcResizerX(x) - 8);
      resizeIcon.y(calcResizerY(y) - 10);
    };

    const getPreview = () => {
      if (this.props.exportAsSquare) {
        const fullSizeImage = new Konva.Image({ image: this.image });
        const xScale = fullSizeImage.width() / background.width();
        const yScale = fullSizeImage.height() / background.height();

        const width = crop.radius() * 2 * xScale;
        const height = crop.radius() * 2 * yScale;
        const pixelRatio = this.props.exportSize
          ? this.props.exportSize / width
          : undefined;

        return fullSizeImage.toDataURL({
          x: (crop.x() - crop.radius()) * xScale,
          y: (crop.y() - crop.radius()) * yScale,
          width,
          height,
          pixelRatio,
          mimeType: this.props.exportMimeType,
          quality: this.props.exportQuality
        });
      } else {
        const width = crop.radius() * 2;
        const height = crop.radius() * 2;
        const pixelRatio = this.props.exportSize
          ? this.props.exportSize / width
          : undefined;

        return crop.toDataURL({
          x: crop.x() - crop.radius(),
          y: crop.y() - crop.radius(),
          width,
          height,
          pixelRatio,
          mimeType: this.props.exportMimeType,
          quality: this.props.exportQuality
        });
      }
    };

    const onScaleCallback = scaleY => {
      const scale = scaleY > 0 || isNotOutOfScale(scaleY) ? scaleY : 0;
      cropStroke.radius(cropStroke.radius() - calcScaleRadius(scale));
      crop.radius(crop.radius() - calcScaleRadius(scale));
      resize.fire("resize");
    };

    this.onCropCallback(getPreview());

    crop.on("dragmove", () => crop.fire("resize"));
    crop.on("dragend", () => this.onCropCallback(getPreview()));

    crop.on("resize", () => {
      const x = isLeftCorner()
        ? calcLeft()
        : isRightCorner()
        ? calcRight()
        : crop.x();
      const y = isTopCorner()
        ? calcTop()
        : isBottomCorner()
        ? calcBottom()
        : crop.y();
      moveResizer(x, y);
      crop.setFillPatternOffset({ x: x / this.scale, y: y / this.scale });
      crop.x(x);
      cropStroke.x(x);
      crop.y(y);
      cropStroke.y(y);
    });

    crop.on("mouseenter", () => (stage.container().style.cursor = "move"));
    crop.on("mouseleave", () => (stage.container().style.cursor = "default"));
    crop.on("dragstart", () => (stage.container().style.cursor = "move"));
    crop.on("dragend", () => (stage.container().style.cursor = "default"));

    resize.on("touchstart", evt => {
      resize.on("dragmove", dragEvt => {
        if (dragEvt.evt.type !== "touchmove") return;
        const scaleY =
          dragEvt.evt.changedTouches["0"].pageY -
            evt.evt.changedTouches["0"].pageY || 0;
        onScaleCallback(scaleY * this.mobileScaleSpeed);
      });
    });

    resize.on("dragmove", evt => {
      if (evt.evt.type === "touchmove") return;
      const newMouseY = evt.evt.y;
      const ieScaleFactor = newMouseY
        ? newMouseY - this.state.lastMouseY
        : undefined;
      const scaleY = evt.evt.movementY || ieScaleFactor || 0;
      this.setState({
        lastMouseY: newMouseY
      });
      onScaleCallback(scaleY);
    });
    resize.on("dragend", () => this.onCropCallback(getPreview()));

    resize.on("resize", () => moveResizer(crop.x(), crop.y()));

    resize.on(
      "mouseenter",
      () => (stage.container().style.cursor = "nesw-resize")
    );
    resize.on("mouseleave", () => (stage.container().style.cursor = "default"));
    resize.on("dragstart", evt => {
      this.setState({
        lastMouseY: evt.evt.y
      });
      stage.container().style.cursor = "nesw-resize";
    });
    resize.on("dragend", () => (stage.container().style.cursor = "default"));
  }

  initResizeIcon() {
    const Konva = window.Konva;
    let group = new Konva.Group({
      zIndex: 0,
      x: this.halfWidth + this.cropRadius * 0.86 - 8,
      y: this.halfHeight + this.cropRadius * -0.5 - 10
    });
    group.add(
      new Konva.Path({
        x: 0,
        y: 0,
        data:
          "M32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32C24.8366 32 32 24.8366 32 16Z",
        fill: this.cropColor,
        stroke: this.cropColor
      })
    );
    group.add(
      new Konva.Path({
        x: 8,
        y: 8,
        data:
          "M13.272 1.6H12C11.7878 1.6 11.5843 1.51571 11.4343 1.36569C11.2843 1.21566 11.2 1.01217 11.2 0.8C11.2 0.587827 11.2843 0.384344 11.4343 0.234315C11.5843 0.0842854 11.7878 0 12 0H15.2C15.3045 0.00126399 15.4078 0.0230072 15.504 0.0639999C15.6995 0.14518 15.8548 0.30052 15.936 0.496C15.977 0.592178 15.9987 0.695458 16 0.8V4C16 4.21217 15.9157 4.41566 15.7657 4.56569C15.6157 4.71571 15.4122 4.8 15.2 4.8C14.9878 4.8 14.7843 4.71571 14.6343 4.56569C14.4843 4.41566 14.4 4.21217 14.4 4V2.728L9.768 7.368C9.69363 7.44298 9.60515 7.5025 9.50766 7.54311C9.41017 7.58373 9.30561 7.60464 9.2 7.60464C9.09439 7.60464 8.98983 7.58373 8.89234 7.54311C8.79485 7.5025 8.70637 7.44298 8.632 7.368C8.55702 7.29363 8.4975 7.20515 8.45689 7.10766C8.41627 7.01017 8.39536 6.90561 8.39536 6.8C8.39536 6.69439 8.41627 6.58983 8.45689 6.49234C8.4975 6.39485 8.55702 6.30637 8.632 6.232L13.272 1.6ZM0.8 11.2C1.01217 11.2 1.21566 11.2843 1.36569 11.4343C1.51571 11.5843 1.6 11.7878 1.6 12V13.272L6.232 8.632C6.38264 8.48136 6.58696 8.39673 6.8 8.39673C7.01304 8.39673 7.21736 8.48136 7.368 8.632C7.51864 8.78264 7.60327 8.98696 7.60327 9.2C7.60327 9.41304 7.51864 9.61736 7.368 9.768L2.728 14.4H4C4.21217 14.4 4.41566 14.4843 4.56569 14.6343C4.71572 14.7843 4.8 14.9878 4.8 15.2C4.8 15.4122 4.71572 15.6157 4.56569 15.7657C4.41566 15.9157 4.21217 16 4 16H0.8C0.695458 15.9987 0.592178 15.977 0.496 15.936C0.300521 15.8548 0.14518 15.6995 0.0640001 15.504C0.0230074 15.4078 0.00126362 15.3045 0 15.2V12C0 11.7878 0.0842848 11.5843 0.234314 11.4343C0.384343 11.2843 0.587827 11.2 0.8 11.2Z",
        fill: "white"
      })
    );

    return group;
  }

  initResize() {
    const Konva = window.Konva;
    return new Konva.Rect({
      zIndex: 1,
      x: this.halfWidth + this.cropRadius * 0.86 - 8,
      y: this.halfHeight + this.cropRadius * -0.5 - 8,
      width: 32,
      height: 32,
      draggable: true,
      dragBoundFunc: function(pos) {
        return {
          x: this.getAbsolutePosition().x,
          y: pos.y
        };
      }
    });
  }

  render() {
    const { width, height } = this.props;

    const style = {
      display: "flex",
      justifyContent: "center",
      backgroundColor: this.backgroundColor,
      width: width || this.width,
      position: "relative"
    };

    const inputStyle = {
      width: 0.1,
      height: 0.1,
      opacity: 0,
      overflow: "hidden",
      position: "absolute",
      zIndex: -1
    };

    const label = this.props.label;

    const labelStyle = {
      ...this.props.labelStyle,
      ...{ lineHeight: (height || 200) + "px" }
    };

    const borderStyle = {
      ...this.props.borderStyle,
      ...{
        width: width || 200,
        height: height || 200
      }
    };

    const closeBtnStyle = {
      position: "absolute",
      zIndex: 999,
      cursor: "pointer",
      left: "10px",
      top: "10px"
    };

    return (
      <div>
        {this.state.showLoader ? (
          <div style={borderStyle}>
            <input
              onChange={e => this.onFileLoad(e)}
              name={this.loaderId}
              type="file"
              id={this.loaderId}
              style={inputStyle}
              accept={this.mimeTypes}
            />
            <label htmlFor={this.loaderId} style={labelStyle}>
              {label}
            </label>
          </div>
        ) : (
          <div style={style}>
            <svg
              style={closeBtnStyle}
              onClick={this.onCloseClick}
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M24.3337 12.0334C24.3343 11.9238 24.3133 11.815 24.2718 11.7135C24.2303 11.612 24.1692 11.5196 24.092 11.4418L20.5587 7.90843C20.4808 7.83119 20.3885 7.77009 20.2869 7.72862C20.1854 7.68715 20.0767 7.66613 19.967 7.66676C19.8573 7.66613 19.7486 7.68715 19.6471 7.72862C19.5456 7.77009 19.4532 7.83119 19.3753 7.90843L17.017 10.2668L7.90867 19.3751C7.83144 19.453 7.77033 19.5453 7.72886 19.6468C7.68739 19.7484 7.66637 19.8571 7.66701 19.9668V23.5001C7.66701 23.7211 7.7548 23.9331 7.91108 24.0893C8.06736 24.2456 8.27933 24.3334 8.50034 24.3334H12.0337C12.1503 24.3398 12.2669 24.3215 12.376 24.2799C12.4851 24.2383 12.5843 24.1742 12.667 24.0918L21.7253 14.9834L24.092 12.6668C24.1681 12.586 24.23 12.493 24.2753 12.3918C24.2834 12.3253 24.2834 12.2582 24.2753 12.1918C24.2792 12.153 24.2792 12.1139 24.2753 12.0751L24.3337 12.0334ZM11.692 22.6668H9.33367V20.3084L17.6087 12.0334L19.967 14.3918L11.692 22.6668ZM21.142 13.2168L18.7837 10.8584L19.967 9.68343L22.317 12.0334L21.142 13.2168Z"
                fill="white"
              />
            </svg>

            <div id={this.containerId} />
          </div>
        )}
      </div>
    );
  }
}

export default AvatarModified;
