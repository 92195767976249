import React, { useEffect, useState } from "react";
import { Box, Button, Paper, Typography } from "@material-ui/core";
import { ReactComponent as CookiesIcon } from "./cookiesIcon.svg";
import { makeStyles } from "@material-ui/core/styles";
import { figmaFonts } from "../../theme";
import useBreakpoints from "../../utils/useBreakpoints";
import { getCookieState, setCookieState } from "../../utils/localStorage";

const useStyles = makeStyles(theme => ({
  paper: {
    padding: theme.spacing(2),
    borderRadius: 10
  },
  heading: {
    ...figmaFonts.mobileTextSemibold,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.5)
  },
  text: {
    marginBottom: theme.spacing(1.5),
    fontSize: "12px",
    lineHeight: "18px"
  },
  declineButton: {
    backgroundColor: theme.palette.background.lightGrey,
    color: theme.palette.primary.main,
    marginRight: theme.spacing(1)
  }
}));

function CookiesModal() {
  const classes = useStyles();
  const { isMobile, isSmallTablet } = useBreakpoints();
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    const cookieState = getCookieState();
    setHidden(!!cookieState);
  }, []);

  function acceptCookie() {
    setCookieState("accepted");
    setHidden(true);
  }

  function declineCookie() {
    setCookieState("declined");
    setHidden(true);
  }

  if (hidden) return null;

  const isAdaptive = isMobile || isSmallTablet;

  return (
    <Box
      position={"fixed"}
      bottom={isAdaptive ? 25 : 40}
      right={isAdaptive ? 25 : 40}
      zIndex={1301}
    >
      <Paper className={classes.paper}>
        <CookiesIcon />
        <Typography className={classes.heading}>We use cookies</Typography>
        <Typography className={classes.text}>
          Cookies help us deliver the best
          <br />
          experience on our website
        </Typography>
        <Box display={"flex"}>
          <Button
            className={classes.declineButton}
            size={"small"}
            color={"secondary"}
            variant={"contained"}
            onClick={declineCookie}
          >
            Decline
          </Button>
          <Button
            onClick={acceptCookie}
            color={"primary"}
            size={"small"}
            variant={"contained"}
          >
            Allow
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}

export default CookiesModal;
