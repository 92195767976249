import { useCallback, useEffect, useState } from "react";
import useRequest from "./useRequest";
import debounce from "lodash.debounce";
import { getUsernameError } from "../validators/getUsernameError";

const useUsernameAvailable = (username, currentUsername = null) => {
  const { postDataWithCallback } = useRequest();
  const [usernameAvailable, setUsernameAvailable] = useState();

  const debouncedFetchData = useCallback(
    debounce((username, callback) => {
      postDataWithCallback(
        "/api/usernameinfo",
        { username },
        () => {
          callback(false);
        },
        () => {
          // username not in use
          callback(true);
        }
      );
    }, 500),
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    if (currentUsername && username === currentUsername) {
      setUsernameAvailable(undefined);
    } else if (!getUsernameError(username)) {
      debouncedFetchData(username, setUsernameAvailable);
    } else {
      setUsernameAvailable(undefined);
    }
    // eslint-disable-next-line
  }, [username, currentUsername]);

  return {
    usernameAvailable
  };
};

export default useUsernameAvailable;
