import { useCallback, useContext, useEffect } from "react";
import useRequest from "./useRequest";
import { AppContext } from "../AppContext";

const useLanguages = () => {
  const { getDataWithCallback } = useRequest();
  const { languagesStore } = useContext(AppContext);

  const getLanguages = useCallback(
    from => {
      getDataWithCallback(
        from ? "/api/sfulanguagesfrom" : "/api/sfulanguagesto",
        {},
        data => {
          if (from) {
            languagesStore.setLanguagesFrom(data.languages, data.selected);
          } else {
            languagesStore.setLanguagesTo(data.languages, data.selected);
          }
        }
      );
    },
    [getDataWithCallback, languagesStore]
  );

  const getLangBy = useCallback(
    (key, value, from = false) => {
      return (from
        ? languagesStore.languagesFrom
        : languagesStore.languagesTo
      ).find(lang => lang[key] === value);
    },
    [languagesStore.languagesFrom, languagesStore.languagesTo]
  );

  useEffect(() => {
    if (languagesStore.languagesFrom.length === 0) {
      getLanguages(true);
    }
    if (languagesStore.languagesTo.length === 0) {
      getLanguages(false);
    }

    // eslint-disable-next-line
  }, []);

  const getLanguageByKey = useCallback(
    key => {
      return (
        [...languagesStore.languagesFrom, ...languagesStore.languagesTo].find(
          lang => lang.Key === key
        ) || {}
      );
    },
    [languagesStore.languagesFrom, languagesStore.languagesTo]
  );

  return {
    languagesFrom: languagesStore.languagesFrom,
    languagesTo: languagesStore.languagesTo,
    selectedFrom: languagesStore.selectedFrom,
    selectedTo: languagesStore.selectedTo,
    setSelectedFormLanguageKey: languagesStore.setSelectedFormLanguageKey,
    getLangBy,
    getLanguageByKey
  };
};

export default useLanguages;
