import React from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { ReactComponent as LogoSvg } from "../../assets/logo.svg";
import {
  Box,
  Button,
  MobileStepper,
  Paper,
  Typography
} from "@material-ui/core";
import Main1 from "../../assets/main1.svg";
import Main2 from "../../assets/main2.svg";
import Main3 from "../../assets/main3.svg";
import Main4 from "../../assets/main4.svg";
import Main5 from "../../assets/main5.svg";
import useBreakpoints from "../../utils/useBreakpoints";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const mainSteps = [
  {
    label1: "Audio/video meetings",
    label2: "with built-in AI-translator"
  },
  {
    label1: "Chat and messages",
    label2: "with the built-in AI-translator"
  },
  {
    label1: "Phone calls",
    label2: "with built-in AI-translator"
  },
  {
    label1: "Transcription of speech",
    label2: "in text chat format"
  },

  {
    label1: "Meetings, phone calls,",
    label2: "and messages are protected"
  }
];

const useStyles = makeStyles(theme => ({
  containerBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    paddingTop: theme.spacing(5),
    [theme.breakpoints.up("lg")]: {
      minHeight: "100%",
      paddingTop: theme.spacing(2)
    }
  },
  logo: {
    cursor: "pointer",
    width: 185,
    height: 44,
    marginBottom: theme.spacing(9.375),
    [theme.breakpoints.up("lg")]: {
      width: 190,
      height: 45
    }
  },
  slideImage: {
    width: 325,
    height: 201,
    maxWidth: "100%",
    [theme.breakpoints.up("lg")]: {
      width: 472,
      height: 292
    }
  },
  labelContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.up("lg")]: {
      marginTop: theme.spacing(4)
    }
  },
  root: {
    width: "100%",
    maxWidth: 325,
    [theme.breakpoints.up("lg")]: {
      maxWidth: 472
    }
  },
  slideContainer: {
    display: "flex"
  },
  stepper: {
    padding: 0,
    justifyContent: "center"
  },
  dot: {
    width: 12,
    height: 12,
    margin: theme.spacing(0, 1),
    backgroundColor: theme.palette.background.input,
    cursor: "pointer"
  },
  dotActive: {
    backgroundColor: theme.palette.primary.main
  },
  buttonContainer: {
    display: "flex",
    maxWidth: 207,
    width: "100%",
    marginTop: theme.spacing(8.375),
    [theme.breakpoints.up("lg")]: {
      maxWidth: 390,
      marginTop: theme.spacing(7.125)
    }
  }
}));

function MainPageNonAuthorizedFragment(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = mainSteps.length;
  const history = useHistory();
  const { isDesktop } = useBreakpoints();

  function handleStepChange(step) {
    setActiveStep(step);
  }

  function handleClickSignIn() {
    history.push({ pathname: "/signin" });
  }
  function handleClickSignUp() {
    history.push({ pathname: "/signup" });
  }

  function renderSwipe(index) {
    switch (index) {
      case 0:
        return <img src={Main1} className={classes.slideImage} alt={"slide"} />;
      case 1:
        return <img src={Main3} className={classes.slideImage} alt={"slide"} />;
      case 2:
        return <img src={Main5} className={classes.slideImage} alt={"slide"} />;
      case 3:
        return <img src={Main2} className={classes.slideImage} alt={"slide"} />;
      case 4:
        return <img src={Main4} className={classes.slideImage} alt={"slide"} />;
      default:
        return null;
    }
  }

  function dotOnClick(event) {
    const dot = event.target;
    const dotsContainer = dot.parentNode;
    setActiveStep(Array.prototype.indexOf.call(dotsContainer.children, dot));
  }

  function openMainPage() {
    window.open("https://yous.ai", "_self");
  }

  return (
    <Box pb={2} className={classes.containerBox}>
      <LogoSvg onClick={openMainPage} className={classes.logo} />
      <div className={classes.root}>
        <AutoPlaySwipeableViews
          axis={"x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {mainSteps.map((step, index) => (
            <div className={classes.slideContainer} key={index}>
              {renderSwipe(index)}
            </div>
          ))}
        </AutoPlaySwipeableViews>

        <Paper className={classes.labelContainer} elevation={0}>
          <Typography variant="h2" align="center">
            {mainSteps[activeStep].label1}
          </Typography>
          <Typography variant="h2" align="center">
            {mainSteps[activeStep].label2}
          </Typography>
        </Paper>

        <MobileStepper
          classes={{
            root: classes.stepper,
            dot: classes.dot,
            dotActive: classes.dotActive
          }}
          steps={maxSteps}
          position="static"
          variant="dots"
          activeStep={activeStep}
          onClick={dotOnClick}
        />
      </div>
      <Box
        className={classes.buttonContainer}
        flexDirection={isDesktop ? "row-reverse" : "column"}
      >
        <Box width="100%" mb={isDesktop ? 0 : 2}>
          <Button
            variant="contained"
            color="primary"
            size={isDesktop ? "large" : "medium"}
            onClick={handleClickSignUp}
            fullWidth
          >
            SIGN UP FREE
          </Button>
        </Box>
        <Box width="100%" mr={isDesktop ? 3.75 : 0}>
          <Button
            variant={isDesktop ? "contained" : "outlined"}
            color={isDesktop ? "default" : "primary"}
            size={isDesktop ? "large" : "medium"}
            onClick={handleClickSignIn}
            fullWidth
          >
            LOGIN
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default MainPageNonAuthorizedFragment;
