import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { Prompt, useHistory, useLocation, useParams } from "react-router-dom";
import BasePage from "../BasePage/BasePage";
import { observer } from "mobx-react";
import useRequest from "../../utils/hooks/useRequest";
import CallDataDesktop from "../LocalCallPage/components/CallData/CallDataDesktop";
import useAppBarLeftContent from "../../utils/hooks/useAppBarLeftContent";
import { LocalCallContext } from "../LocalCallPage/localCallProvider";
import { callStatuses, getElapsedText } from "./helpers";
import { appStore } from "../../utils/stores/AppStore";
import { Box, CardMedia, Grid } from "@material-ui/core";
import clsx from "clsx";
import CallData from "../LocalCallPage/CallData";
import AvatarWithFallback from "../../components/Avatar/AvatarWithFallback";
import CallControls from "../LocalCallPage/components/CallControls/CallControls";
import { makeStyles } from "@material-ui/core/styles";
import useBreakpoints from "../../utils/useBreakpoints";
import SettingsModal from "../../components/CallSettings/SettingsModal";
import { AppContext } from "../../utils/AppContext";
import { BaseContext } from "../../utils/baseProvider";
import useAppBarHeight from "../../utils/useAppBarHeight";
import CallBackControls from "../PhoneCall/PhoneCallPage/components/CallBackControlls/CallBackControlls";
import LocalCallTranscriptionWrapper from "../LocalCallPage/Transcription/TranscriptionWrapper";
import { Tone } from "../PhoneCall/PhoneCallPage/Tone";
import { getInitialReadMessageStatus } from "../../utils/calls/getInitialReadMessageStatus";

const useStyles = makeStyles(theme => ({
  grid: {
    height: "100%",
    maxHeight: "100%",
    padding: 0,
    margin: theme.spacing(0),
    flex: 1,
    width: "100%"
  },
  gridItem: {
    maxHeight: "100%",
    display: "flex",
    flexDirection: "column",
    position: "relative"
  },
  videoContainer: {
    flexGrow: 1,
    height: "100%",
    position: "relative",
    backgroundColor: theme.palette.background.callGrey,
    overflow: "hidden"
  },
  videoContainerDisabledVideo: {
    background:
      "linear-gradient(0deg, rgba(78, 78, 137, 0) 64.12%, rgba(11, 11, 70, 0.21) 82.35%, rgba(11, 11, 33, 0.455) 100%)"
  },
  remoteVideo: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    objectFit: "cover",
    height: "100%",
    width: "100%"
  },
  localVideo: {
    position: "absolute",
    right: 25,
    maxHeight: 120,
    maxWidth: 92,
    bottom: 104,
    objectFit: "cover",
    zIndex: 2,
    [theme.breakpoints.up("lg")]: {
      maxHeight: 140,
      maxWidth: 210,
      right: 24,
      top: 24,
      bottom: "initial"
    }
  },
  videoHidden: {
    display: "none"
  },
  centeredBox: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    padding: theme.spacing(0, 3.125),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center"
  },
  callControls: {
    position: "absolute",
    bottom: "24px",
    left: 0,
    right: 0,
    marginBottom: "env(safe-area-inset-bottom)"
  }
}));

const DirectCallPage = () => {
  const classes = useStyles();
  const { postDataWithCallback } = useRequest();
  const { remoteId } = useParams();
  const [callStatus, setCallStatus] = useState(callStatuses.calling);
  const baseContext = useContext(BaseContext);
  const localCallContext = useContext(LocalCallContext);
  const location = useLocation();
  const [ice, setIce] = useState(false);
  const initData = location.state || {};
  const [ready, setReady] = useState(false);
  const { localCallUIStore, localCallStore } = useContext(AppContext);
  const { isDesktop, isMobile } = useBreakpoints();
  const [id, setId] = useState(null);
  const history = useHistory();
  const appBarHeight = useAppBarHeight();
  const [Bconnection, setBconnection] = useState("");

  const Bconnected = useMemo(() => Bconnection === "connected", [Bconnection]);

  const ringtoneRef = useRef(new Tone(400, 450));

  const appBarLeftComponent = useMemo(
    () => (
      <CallDataDesktop
        showLanguagesBadge={callStatus === callStatuses.connected}
        elapsedText={getElapsedText(callStatus)}
        isCallee={false}
      />
    ),
    [callStatus]
  );

  useAppBarLeftContent({
    memoizedComponent: appBarLeftComponent
  });

  const localStreamRef = useRef();
  const dcRef = useRef();
  const pcRef = useRef();
  const videoLocalRef = useRef();
  const videoRemoteRef = useRef();

  useEffect(() => {
    return () => {
      handleDisconnect();
      localCallContext.resetToDefaultState();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (appStore.authorized !== undefined && baseContext.currentUser) {
      startCall();
    }
    // eslint-disable-next-line
  }, [appStore.authorized]);

  function startCall() {
    postDataWithCallback("/api/userinfo", { id: parseInt(remoteId) }, data => {
      initWebrtc();

      localCallContext.setRemoteAvatar(data.avatar);
      localCallContext.setRemoteName(data.name);
      localCallStore.initCallState({
        from: baseContext.currentUser
          ? baseContext.currentUser.fromLang
          : undefined,
        fromGender: baseContext.currentUser
          ? baseContext.currentUser.gender
          : undefined,
        to: data.fromLang,
        toGender: data.gender || "Male"
      });
    });
  }

  useEffect(() => {
    if (ready) {
      connect();
    }
    // eslint-disable-next-line
  }, [ready]);

  useEffect(() => {
    if (ice && id !== null) {
      sendAnswer();
    }
    // eslint-disable-next-line
  }, [ice, id]);

  useEffect(() => {
    if (Bconnected) {
      sendMediaState(
        localCallContext.myAudioEnabled,
        localCallContext.myVideoEnabled
      );
    }
    // eslint-disable-next-line
  }, [
    Bconnected,
    localCallContext.myAudioEnabled,
    localCallContext.myVideoEnabled
  ]);

  // hide top bar for full screen during call
  useEffect(() => {
    if (Bconnected && !isDesktop) {
      baseContext.setShowTopBar(false);
    } else {
      baseContext.setShowTopBar(true);
    }
    return () => {
      baseContext.setShowTopBar(true);
    };
    // eslint-disable-next-line
  }, [Bconnected, isMobile]);

  useEffect(() => {
    const openRateCall = () => {
      history.push("/ratecall", {
        remoteid: (initData && initData.remoteId) || id,
        side: localCallContext.currentUserIsCallee ? "B" : "A",
        type: "meeting",
        elapsed: localCallContext.elapsed,
        name: localCallContext.remoteName,
        photo: localCallContext.remoteAvatar,
        to: localCallStore.callSettings.to,
        from: localCallStore.callSettings.from
      });
    };

    if (callStatus === callStatuses.calling) {
      ringtoneRef.current.startRinging();
    } else {
      ringtoneRef.current.stopRinging();
    }

    switch (callStatus) {
      case callStatuses.callended:
        if (Bconnected) {
          openRateCall();
        } else {
          setCallStatus(callStatuses.noanswer);
        }
        break;
      case callStatuses.timeouted:
        history.push("/");
        break;
      case callStatuses.hangup:
        if (Bconnected) {
          openRateCall();
        } else {
          history.push("/");
        }
        break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, [callStatus, Bconnected]);

  function initWebrtc() {
    let localPc = new RTCPeerConnection({
      iceServers: [
        {
          urls: "stun:web.yous.ai:33478"
        }
      ]
    });

    localPc.onsignalingstatechange = e => {
      console.log(`signalling: ${localPc.signalingState}`);
    };

    localPc.onicegatheringstatechange = () => {
      console.log(`onicegatheringstatechange: ${localPc.iceGatheringState}`);
    };

    localPc.oniceconnectionstatechange = e => {
      console.log(`connection: ${localPc.iceConnectionState}`);
      if (
        localPc.iceConnectionState === "disconnected" ||
        localPc.iceConnectionState === "closed"
      ) {
        setCallStatus(callStatuses.callended);
      }
    };

    localPc.ondatachannel = e => {
      let dc = e.channel;
      dcRef.current = dc;
      dc.onclose = () => {
        console.log("dc has closed");
        setCallStatus(callStatuses.callended);
      };
      dc.onopen = () => {
        console.log("dc has opened");
        if (remoteId) {
          sendMessageToDC("translationConfig", localCallStore.callSettings);
        }
      };
      dc.onmessage = e => {
        handleDCMessage(e.data);
      };
    };

    localPc.ontrack = function(event) {
      if (event.track.kind === "video") {
        videoRemoteRef.current.srcObject = event.streams[0];
      }
    };

    navigator.mediaDevices
      .getUserMedia({
        video: true,
        audio: true
      })
      .then(stream => {
        stream.getTracks().forEach(track => {
          localPc.addTrack(track, stream);
        });
        localStreamRef.current = stream;
        videoLocalRef.current.srcObject = stream;
        setReady(true);
      })
      .catch(console.log);

    pcRef.current = localPc;
  }

  function connect() {
    let localId = "";

    let off = {};
    if (initData !== undefined) {
      if (initData.remoteId !== undefined) {
        off.remoteid = initData.remoteId;
        localCallContext.setCurrentUserIsCallee(true);
      }
      if (initData.name) {
        off.name = initData.name;
      }
      if (initData.toGender) {
        off.gender = initData.toGender;
      }
      if (initData.from !== undefined) {
        off.from = initData.from;
      }
      if (initData.to !== undefined) {
        off.to = initData.to;
      }
    }

    pcRef.current.onicecandidate = event => {
      console.log("candidate: ", event.candidate);
      setIce(true);
    };

    postDataWithCallback("/api/sfuoffer", off, data => {
      pcRef.current
        .setRemoteDescription(new RTCSessionDescription(data.sdp))
        .catch(console.log);
      pcRef.current
        .createAnswer()
        .then(d => pcRef.current.setLocalDescription(d))
        .catch(console.log);
      localId = data.id;
      setId(localId);
    });
  }

  function sendAnswer() {
    let ans = {};
    ans.sdp = pcRef.current.localDescription;
    ans.id = id;
    ans.toId = parseInt(remoteId);
    postDataWithCallback("/api/sfuanswer", ans, data => {
      console.log(data);
    });
  }

  function sendMessageToDC(type, payload) {
    if (dcRef.current && dcRef.current.readyState === "open") {
      let message = payload;
      if (type !== "message") {
        message = JSON.stringify({
          type: type,
          payload
        });
      }
      dcRef.current.send(message);
    }
  }

  function handleDCMessage(data) {
    const ob = JSON.parse(data);

    switch (ob.type) {
      case "elapsed":
        localCallContext.setElapsed(ob.text);
        break;
      case "elapsedSecondsBalance":
        baseContext.updateUserBalance(ob.text);
        break;
      case "translatedText":
      case "originalText":
      case "translation":
      case "original":
        // here we assume that consecutive messages ( original and translated )
        // are received one after another
        localCallContext.setMessages(oldArray => [
          ...oldArray,
          {
            ...ob,
            id:
              oldArray.length % 2 === 1 ? oldArray.length - 1 : oldArray.length,
            read: getInitialReadMessageStatus(false, ob),
            timestamp: new Date().getTime()
          }
        ]);
        break;
      case "control":
        if (ob.text === "timeout") {
          setCallStatus(callStatuses.timeouted);
        } else if (ob.side === "B") {
          if (ob.text === "connected") {
            setCallStatus(callStatuses.connected);
            setBconnection(ob.text);
          }
        }
        break;
      case "remoteName":
        localCallContext.setRemoteName(ob.text);
        break;
      case "clientControl":
        if (ob.payload) {
          localCallContext.setCalleeAudioEnabled(ob.payload.audio);
          localCallContext.setCalleeVideoEnabled(ob.payload.video);
        }
        break;
      case "remoteId":
        localCallContext.setRemoteId(ob.text);
        break;
      case "translationEnabled":
        localCallContext.setTranslationEnabled(ob.text === "enabled");
        break;
      case "translationConfig":
        localCallStore.setCallSettings(ob.payload);
        break;
      default:
        console.log(ob);
    }
  }

  function handleDisconnect() {
    if (pcRef.current) {
      pcRef.current.close();
    }

    if (localStreamRef.current) {
      localStreamRef.current.getTracks().forEach(track => {
        track.stop();
      });
    }

    setCallStatus(callStatuses.hangup);
  }

  function sendMediaState(audio, video) {
    sendMessageToDC("clientControl", {
      audio,
      video
    });
  }

  function toggleVideo() {
    if (localStreamRef.current) {
      localStreamRef.current.getVideoTracks()[0].enabled = !localCallContext.myVideoEnabled;
      localCallContext.setMyVideoEnabled(!localCallContext.myVideoEnabled);
    }
  }

  function toggleAudio() {
    if (localStreamRef.current) {
      localStreamRef.current.getAudioTracks()[0].enabled = !localCallContext.myAudioEnabled;
      localCallContext.setMyAudioEnabled(!localCallContext.myAudioEnabled);
    }
  }

  function toggleTranslation() {
    localCallContext.setTranslationEnabled(
      !localCallContext.translationEnabled
    );
    sendMessageToDC("translationEnabled", {
      enabled: !localCallContext.translationEnabled
    });
  }

  const onSettingsSave = useCallback(
    formData => {
      localCallStore.setCallSettings({
        ...localCallStore.callSettings,
        ...formData
      });
      localCallUIStore.toggleSettingsModalShow();
      sendMessageToDC("translationConfig", formData);
    },
    [localCallStore, localCallUIStore]
  );

  const containerStyles = isDesktop
    ? {
        height:
          appStore.authorized && !(!isDesktop && Bconnected) ? `100%` : "auto",
        flex: 1,
        paddingTop: appStore.authorized ? 0 : appBarHeight + "px",
        width: "100%"
      }
    : {
        position: "absolute",
        top: baseContext.showTopBar ? appBarHeight : 0,
        left: 0,
        right: 0,
        bottom: 0,
        width: "100%",
        backgroundColor: "#F5F5FB"
      };

  const recall = () => {
    setCallStatus(callStatuses.calling);
    setReady(false);
    setIce(false);
    dcRef.current = null;
    if (pcRef.current) {
      pcRef.current.close();
    }
    pcRef.current = null;
    initWebrtc();
  };

  return (
    <BasePage noPaddingBottom>
      <Prompt
        when={
          ![
            callStatuses.callended,
            callStatuses.timeouted,
            callStatuses.noanswer,
            callStatuses.hangup
          ].includes(callStatus)
        }
        message={`Are you sure you want to end call?`}
      />
      <Box {...containerStyles}>
        <Grid
          className={classes.grid}
          container
          spacing={0}
          justifyContent="center"
        >
          <Grid
            className={classes.gridItem}
            item
            xs={isDesktop && localCallContext.showTranscription ? 8 : 12}
          >
            <Box
              className={clsx(
                classes.videoContainer,
                !localCallContext.calleeVideoEnabled || !Bconnected
                  ? classes.videoContainerDisabledVideo
                  : ""
              )}
            >
              {!isDesktop && (
                <CallData
                  Bconnected={Bconnected}
                  elapsedText={getElapsedText(callStatus)}
                  isDirectCall
                  showLanguagesBadge={callStatus === callStatuses.connected}
                  isCallee={false}
                />
              )}

              <CardMedia
                className={clsx(
                  classes.remoteVideo,
                  !localCallContext.calleeVideoEnabled
                    ? classes.videoHidden
                    : ""
                )}
                component="video"
                ref={videoRemoteRef}
                autoPlay={true}
                playsInline={true}
              />

              <CardMedia
                className={clsx(
                  classes.localVideo,
                  !localCallContext.myVideoEnabled ? classes.videoHidden : ""
                )}
                component="video"
                ref={videoLocalRef}
                autoPlay={true}
                playsInline={true}
                volume={0}
                muted={true}
              />

              <Box className={classes.centeredBox}>
                {(!localCallContext.calleeVideoEnabled || !Bconnected) && (
                  <AvatarWithFallback
                    src={localCallContext.remoteAvatar}
                    width={isDesktop ? 190 : 136}
                    height={isDesktop ? 190 : 136}
                  />
                )}
              </Box>

              <Box className={classes.callControls}>
                {callStatus === callStatuses.noanswer ? (
                  <CallBackControls recall={recall} cancel={handleDisconnect} />
                ) : (
                  <CallControls
                    Bconnected={Bconnected}
                    toggleVideo={toggleVideo}
                    toggleAudio={toggleAudio}
                    handleDisconnect={handleDisconnect}
                    toggleTranslation={toggleTranslation}
                  />
                )}
              </Box>
            </Box>
          </Grid>

          {localCallContext.showTranscription &&
            (isDesktop ? (
              <Grid className={classes.gridItem} item xs={4}>
                <LocalCallTranscriptionWrapper
                  Bconnected={Bconnected}
                  sendMessageToDC={sendMessageToDC}
                />
              </Grid>
            ) : (
              <LocalCallTranscriptionWrapper
                Bconnected={Bconnected}
                sendMessageToDC={sendMessageToDC}
              />
            ))}
        </Grid>
      </Box>
      <SettingsModal
        open={localCallUIStore.settingsModalShow}
        onClose={localCallUIStore.toggleSettingsModalShow}
        onSettingsSave={onSettingsSave}
        isCaller={!localCallContext.currentUserIsCallee}
        fromCountryCode={localCallStore.callSettings.from}
        toCountryCode={localCallStore.callSettings.to}
        callSettings={localCallStore.callSettings}
        onlyCurrentUserSettings
      />
    </BasePage>
  );
};

export default observer(DirectCallPage);
