import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { figmaFonts, palette } from "../../../../theme";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  switchButton: {
    ...figmaFonts.desktopCapsBtn14
  }
}));
function RenewCancelBox({ desktopMode, onClick, currentUser }) {
  const classes = useStyles();
  let formattedDate = new Date(currentUser.until * 1000);
  formattedDate = `${formattedDate.toLocaleString("en-US", {
    month: "long"
  })} ${formattedDate.getDate()}, ${formattedDate.getFullYear()}`;
  return (
    <Box
      border={"1px solid " + palette.background.input}
      mt={desktopMode ? 4 : 3}
      py={3}
      px={5}
      width={"100%"}
      boxSizing={"border-box"}
      display={"flex"}
      flexDirection={desktopMode ? "row" : "column"}
      alignItems={"center"}
    >
      <Box mr={desktopMode ? "auto" : "initial"}>
        <Box mb={1} color={"secondary.text"} textAlign={"center"}>
          <Typography variant={"subtitle1"}>Date renew:</Typography>
        </Box>
        <Typography variant={"body1"}>{formattedDate}</Typography>
      </Box>
      <Box
        display={"flex"}
        alignItems={"center"}
        mt={desktopMode ? 0 : 3}
        width={215}
      >
        <Button
          className={classes.switchButton}
          variant={"contained"}
          color={"secondary"}
          onClick={onClick}
          fullWidth
        >
          Cancel subscription
        </Button>
      </Box>
    </Box>
  );
}
export default RenewCancelBox;
