import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import BasePage from "../BasePage/BasePage";
import { BaseContext } from "../../utils/baseProvider";
import { Box, Button, Typography } from "@material-ui/core";
import InputGroup from "../../components/InputGroup/InputGroup";
import withBottomBar from "../../components/BottomNavBar/withBottomBar";
import { makeStyles } from "@material-ui/core/styles";
import Image from "./icons/meetingOver.svg";
import Rating from "./Rating";
import Social from "./Social";
import withRequest from "../../utils/withRequest";
import { useLocation } from "react-router-dom";
import useBreakpoints from "../../utils/useBreakpoints";
import RateMeetingStats from "./RateMeetingStats";
import { figmaFonts } from "../../theme";
import useAppBarLeftContent from "../../utils/hooks/useAppBarLeftContent";
import useAppBarHeight from "../../utils/useAppBarHeight";
import { ReactComponent as EmptyAvatar } from "./icons/avatar.svg";
import { appStore } from "../../utils/stores/AppStore";
import { observer } from "mobx-react";
import { FullHeightContainerWithCenteredContent } from "../../components/layouts";

const useStyles = makeStyles(theme => ({
  form: {
    width: "100%"
  },
  textField: { marginTop: theme.spacing(2) },
  submit: {},
  ratingText: {
    ...figmaFonts.desktopSmallTextMedium,
    color: theme.palette.text.grey,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      marginBottom: 0
    }
  },
  name: {
    textAlign: "center",
    ...figmaFonts.textMedium16,
    marginBottom: theme.spacing(2)
  },
  img: {
    margin: "0 auto"
  }
}));

function RateMeetingPage({ postDataWithCallback }) {
  const baseContext = useContext(BaseContext);
  const classes = useStyles();
  const location = useLocation();
  const {
    type,
    elapsed = "0",
    remoteid,
    phoneNumber,
    name,
    photo,
    to,
    from,
    side
  } = location.state || {};
  const [formData, setFormData] = useState({ comment: "", rating: 5 });
  const [formSent, setFormSent] = useState(false);
  const [rate, setRate] = useState();
  const { isDesktop } = useBreakpoints();
  const appBarHeight = useAppBarHeight();

  const title = type === "phone" ? "Call Summary" : "Meeting Summary";
  useAppBarLeftContent({ title });

  const isPhoneCall = useMemo(() => type === "phone", [type]);

  useEffect(() => {
    baseContext.setSign("up");
    void getCost();
    // eslint-disable-next-line
  }, []);

  const getCost = useCallback(async () => {
    if (!phoneNumber) return;
    postDataWithCallback(
      "/api/call/price",
      {
        number: phoneNumber
      },
      data => {
        setRate(data.price);
      }
    );
  }, [phoneNumber, postDataWithCallback]);

  function onChange(event) {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  }

  function submit(event) {
    event.stopPropagation();
    event.preventDefault();

    const url = isPhoneCall ? "/api/call/phonerating" : "/api/call/rating";

    let data = {
      remoteid,
      rating: formData.rating,
      text: formData.comment
    };

    if (!isPhoneCall) {
      data = {
        ...data,
        side
      };
    }

    postDataWithCallback(url, data, () => {
      setFormSent(true);
    });
  }

  function ratingOnChange(rating) {
    onChange({
      target: {
        name: "rating",
        value: rating
      }
    });
  }

  const desktopLayout = isDesktop && appStore.authorized;
  const desktopMode = isDesktop;

  return (
    <BasePage>
      {!formSent ? (
        <Box
          pl={desktopLayout ? 8 : 3.125}
          pr={desktopLayout ? 0 : 3.125}
          pt={
            desktopLayout ? (appStore.authorized ? 5 : appBarHeight / 8 + 5) : 0
          }
          mt={desktopLayout ? 0 : "auto"}
          mb={desktopLayout ? 3 : "auto"}
          mx={desktopLayout ? 0 : "auto"}
          maxWidth={450}
          boxSizing={"content-box"}
          key={"form"}
        >
          {!desktopLayout && desktopMode && (
            <Box mb={3}>
              <Typography align={"center"} variant={"h1"}>
                Call Summary
              </Typography>
            </Box>
          )}

          {!desktopMode && (
            <>
              <Box mb={3}>
                <Typography align={"center"} variant={"h1"}>
                  Call Summary
                </Typography>
              </Box>
              <Box mx={"auto"} width={120} height={120} mb={2}>
                {photo ? (
                  <img
                    width={120}
                    height={120}
                    style={{
                      borderRadius: "50%"
                    }}
                    src={photo}
                    alt={"user"}
                  />
                ) : (
                  <EmptyAvatar />
                )}
              </Box>
              {!desktopMode && (
                <Typography className={classes.name}>
                  {isPhoneCall ? phoneNumber : name}
                </Typography>
              )}
            </>
          )}
          <Box mb={3}>
            <RateMeetingStats
              desktopMode={desktopMode}
              title={isPhoneCall ? phoneNumber : name}
              elapsed={elapsed}
              to={side === "B" ? from : to}
              from={side === "B" ? to : from}
              photo={photo}
              cost={isPhoneCall ? (elapsed / 60) * rate : 0}
            />
          </Box>
          <Box mb={2}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              flexDirection={desktopMode ? "row" : "column"}
            >
              <Typography className={classes.ratingText}>
                Rate your
                {desktopMode ? <br /> : " "}
                conversation:
              </Typography>
              <Rating default={formData.rating} onChange={ratingOnChange} />
            </Box>
          </Box>

          <form className={classes.form} onSubmit={submit}>
            <Box width={"100%"} mb={desktopMode ? 3 : 2}>
              <InputGroup
                onChange={onChange}
                placeholder={"Comment"}
                name={"comment"}
                type={"text"}
                multiline
                minRows={5}
                maxRows={5}
                style={{ borderRadius: 5, marginBottom: 0 }}
              />
            </Box>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="primary"
              fullWidth
              className={classes.submit}
            >
              Send
            </Button>
          </form>
        </Box>
      ) : (
        <FullHeightContainerWithCenteredContent>
          {(!desktopMode || !appStore.authorized) && (
            <>
              <Box mb={5}>
                <Typography align={"center"} variant={"h1"}>
                  Meeting Summary
                </Typography>
              </Box>
            </>
          )}
          <img
            src={Image}
            className={classes.img}
            alt={"Thank you for your feedback!"}
          />
          <Box mt={desktopMode ? 5 : 3}>
            <Typography align={"center"} variant={"h2"}>
              Thank you
              {desktopMode ? <br /> : " "}
              for your feedback!
            </Typography>
          </Box>
          <Box mt={8.625} mb={desktopMode ? 8 : 4}>
            <Social />
          </Box>
        </FullHeightContainerWithCenteredContent>
      )}
    </BasePage>
  );
}

export default withBottomBar(withRequest(observer(RateMeetingPage)));
