import React from "react";
import { ReactComponent as DefaultAvatarIcon } from "./DefaultAvatarIcon.svg";
import { makeStyles } from "@material-ui/core/styles";
import { Avatar, Box, SvgIcon } from "@material-ui/core";
import { ReactComponent as PencilIcon } from "../../assets/icons/pencil.svg";
import useBreakpoints from "../../utils/useBreakpoints";
import Flag from "../CustomFlag/Flag";

const useStyles = makeStyles(theme => ({
  avatar: {
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.background.input
  },
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    opacity: 0,
    backgroundColor: theme.palette.primary.main,
    borderRadius: "50%",
    zIndex: 1,
    transition: "opacity 200ms linear",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&:hover": {
      opacity: 0.8
    }
  },
  iconMobile: {
    position: "absolute",
    bottom: -16,
    borderRadius: "50%",
    backgroundColor: theme.palette.primary.main,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1
  },
  flagBox: {
    width: 16,
    height: 16,
    backgroundColor: theme.palette.background.default,
    borderRadius: "50%",
    position: "absolute",
    bottom: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  flag: {
    width: "12px !important",
    height: 12,
    borderRadius: "50%"
  },
  onlineDot: {
    width: 6,
    height: 6,
    background: theme.palette.background.green,
    borderRadius: "50%"
  }
}));

function AvatarWithFallback({
  width,
  height,
  editMode,
  src,
  noIcon,
  langCode,
  isOnline
}) {
  const classes = useStyles();
  const { isDesktop } = useBreakpoints();

  return (
    <Box width={width} height={height} position={"relative"}>
      {editMode &&
        (isDesktop ? (
          <Box width={width} height={height} className={classes.overlay}>
            <SvgIcon width={20} height={20} viewBox={"0 0 10 10"}>
              <PencilIcon />
            </SvgIcon>
          </Box>
        ) : (
          <Box
            left={width / 2 - 16}
            width={32}
            height={32}
            className={classes.iconMobile}
          >
            <SvgIcon width={20} height={20} viewBox={"0 0 10 10"}>
              <PencilIcon />
            </SvgIcon>
          </Box>
        ))}

      <Avatar className={classes.avatar} src={src}>
        {!noIcon && (
          <DefaultAvatarIcon style={{ width: "100%", height: "100%" }} />
        )}
      </Avatar>
      {langCode && !editMode && (
        <Box className={classes.flagBox}>
          <Flag className={classes.flag} code={langCode} />
        </Box>
      )}
      {isOnline && (
        <Box className={classes.flagBox}>
          <Box className={classes.onlineDot} />
        </Box>
      )}
    </Box>
  );
}

export default AvatarWithFallback;
