import React from "react";
import { Box, Typography } from "@material-ui/core";
import SearchUser from "./SearchUser";
import { figmaFonts, palette } from "../../../theme";
import useBreakpoints from "../../../utils/useBreakpoints";
import { makeStyles } from "@material-ui/core/styles";
import { peopleSearchStore } from "../../../utils/stores/PeopleSearchStore";
import EmptySearch from "./EmptySearch";

const useStyles = makeStyles(theme => ({
  subtitle: {
    ...figmaFonts.textRegular16,
    color: theme.palette.text.grey,
    margin: theme.spacing(0, 3.75)
  },
  people: {
    background: theme.palette.background.input,
    color: theme.palette.text.grey,
    padding: theme.spacing(0.75, 1),
    borderRadius: 5,
    marginTop: theme.spacing(1.25),
    marginBottom: theme.spacing(0.25)
  },
  peopleText: {
    ...figmaFonts.mobileSmallTextMedium
  }
}));

const UserList = ({ users, onUserSelect }) => {
  const classes = useStyles();
  const { isDesktop } = useBreakpoints();
  const empty = peopleSearchStore.isEmpty;
  if (empty) {
    if (isDesktop) {
      return (
        <Typography className={classes.subtitle}>
          There were no results for “{peopleSearchStore.searchValue}”. Try a new
          search
        </Typography>
      );
    } else {
      return (
        <Box flex={1} position={"relative"} height={"calc(100% - 69px)"}>
          <EmptySearch name={peopleSearchStore.searchValue} />
        </Box>
      );
    }
  }
  return (
    <Box px={isDesktop ? 3.75 : 0} style={{ overflowY: "auto" }}>
      {isDesktop && users.length > 0 && (
        <Box className={classes.people}>
          <Typography className={classes.peopleText}>People</Typography>
        </Box>
      )}
      {users.map((user, index) => {
        return (
          <Box
            key={index}
            borderTop={index > 0 ? `1px solid ${palette.secondary.main}` : ""}
            py={1}
            onClick={() => onUserSelect(user)}
          >
            <SearchUser user={user} />
          </Box>
        );
      })}
    </Box>
  );
};

export default UserList;
