import React from "react";
import DialButton from "../../../components/PhoneCallDialer/components/DialButton";
import { Box, Typography } from "@material-ui/core";
import { Cancel, HangUp } from "../../../../../assets/icons";
import { makeStyles } from "@material-ui/core/styles";
import { figmaFonts } from "../../../../../theme";
import useBreakpoints from "../../../../../utils/useBreakpoints";

const useStyles = makeStyles(theme => ({
  text: {
    color: theme.palette.text.grey,
    ...figmaFonts.mobileSmallTextMedium,
    textAlign: "center"
  },
  callIcon: {
    transform: "rotate(-135deg)"
  }
}));

const CallBackControls = ({ cancel, recall }) => {
  const classes = useStyles();
  const { isDesktop } = useBreakpoints();

  return (
    <Box
      mt={isDesktop ? 10 : 0}
      mb={3}
      maxWidth={250}
      mx={"auto"}
      display={"flex"}
      justifyContent={"space-between"}
    >
      <Box>
        <DialButton content={<Cancel />} onClick={cancel} />
        <Box mt={2}>
          <Typography className={classes.text}>Cancel</Typography>
        </Box>
      </Box>

      <Box>
        <DialButton
          content={<HangUp className={classes.callIcon} />}
          onClick={recall}
          color={"green"}
        />
        <Box mt={2}>
          <Typography className={classes.text}>Call Back</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CallBackControls;
