import { Box, Typography } from "@material-ui/core";
import UsdBalanceChipWithTopUp from "../chips/UsdBalanceChipWithTopUp";
import Plan from "./Plan";
import AvatarWithFallback from "../Avatar/AvatarWithFallback";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { BaseContext } from "../../utils/baseProvider";
import { makeStyles } from "@material-ui/core/styles";
import { appStore } from "../../utils/stores/AppStore";
import useLanguages from "../../utils/hooks/useLanguages";

const useStyles = makeStyles(theme => {
  return {
    chipBox: {
      marginRight: theme.spacing(2)
    },
    avatarBox: {
      marginRight: theme.spacing(1),
      cursor: "pointer"
    },
    userNameBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      flexShrink: 0
    },
    userName: {
      maxWidth: 96,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap"
    },
    userNameBoxEmail: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      color: theme.palette.text.grey,
      width: 100
    }
  };
});

const DesktopAppBarRight = () => {
  const classes = useStyles();
  const history = useHistory();
  const baseContext = useContext(BaseContext);
  const { getLanguageByKey } = useLanguages();

  function handleClickAvatar() {
    history.push({ pathname: "/profileedit" });
  }

  const lang = getLanguageByKey(appStore.currentUser?.fromLang).Country || "";

  return (
    <>
      <Box mb={"auto"} className={classes.chipBox}>
        <UsdBalanceChipWithTopUp />
      </Box>

      <Box className={classes.chipBox}>
        <Plan />
      </Box>

      <Box className={classes.avatarBox} onClick={handleClickAvatar}>
        <AvatarWithFallback
          width={45}
          height={45}
          src={baseContext.currentUser?.avatar}
          langCode={lang.toLowerCase()}
        />
      </Box>

      <Box className={classes.userNameBox}>
        <Typography className={classes.userName} variant="body2">
          {baseContext.currentUser?.name}
        </Typography>
        <Typography className={classes.userNameBoxEmail} variant="subtitle2">
          {baseContext.currentUser?.email}
        </Typography>
      </Box>
    </>
  );
};

export default DesktopAppBarRight;
