import React, { useContext } from "react";
import { TextField } from "@material-ui/core";
import { BaseContext } from "../utils/baseProvider";

function TextFieldWithFocusListeners(props) {
  const baseContext = useContext(BaseContext);

  function onFocus() {
    baseContext.setKeyboardOpen(true);
  }

  function onBlur() {
    baseContext.setKeyboardOpen(false);
  }

  return <TextField onFocus={onFocus} onBlur={onBlur} {...props} />;
}

export default TextFieldWithFocusListeners;
