import React, { useContext, useEffect } from "react";
import { BaseContext } from "../../utils/baseProvider";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import useBreakpoints from "../../utils/useBreakpoints";
import BasePageDesktopLayout from "./components/BasePageDesktopLayout/BasePageDesktopLayout";
import BasePageMobileLayout from "./components/BasePageMobileLayout";
import useRequest from "../../utils/hooks/useRequest";
import { appStore } from "../../utils/stores/AppStore";

const useStyles = makeStyles(theme => ({
  appContainer: {
    display: "flex",
    alignItems: "stretch",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    [theme.breakpoints.up("lg")]: {
      minHeight: "100vh",
      overflow: "hidden"
    },
    "@media not all and (min-resolution:.001dpcm)": {
      "@supports (-webkit-appearance: none)": {
        height: "-webkit-fill-available",
        minHeight: "-webkit-fill-available",
        overflowY: "auto"
      }
    }
  }
}));

function BasePage({ children, showTopBar, noPaddingBottom }) {
  const baseContext = useContext(BaseContext);
  const { isDesktop } = useBreakpoints();
  const { getDataWithCallback } = useRequest();

  const classes = useStyles();

  useEffect(() => {
    getAccountInfo();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (showTopBar !== undefined) {
      baseContext.setShowTopBar(showTopBar);
    } else {
      baseContext.setShowTopBar(true);
    }
    // eslint-disable-next-line
  }, [showTopBar]);

  function getAccountInfo() {
    let url = "/api/usercurrent";

    getDataWithCallback(
      url,
      {},
      data => {
        if (data.id !== undefined) {
          if (data.version && data.version !== window.VERSION) {
            window.location.reload(true);
          }
          baseContext.setCurrentUser(data);
          appStore.setAuthorized(true);
          baseContext.setUserSecondsBalance(data.secondsBalance);
          baseContext.setUserCentsBalance(data.centsBalance);
          baseContext.setStripe({ ...data });
        } else {
          appStore.setAuthorized(false);
          baseContext.setCurrentUser(undefined);
          baseContext.setUserSecondsBalance(undefined);
          baseContext.setUserCentsBalance(undefined);
        }
      },
      err => {
        appStore.setAuthorized(false);
        baseContext.setCurrentUser(undefined);
        baseContext.setUserSecondsBalance(undefined);
        baseContext.setUserCentsBalance(undefined);
      }
    );
  }

  return (
    <Box className={classes.appContainer}>
      {isDesktop ? (
        <BasePageDesktopLayout>{children}</BasePageDesktopLayout>
      ) : (
        <BasePageMobileLayout noPaddingBottom={noPaddingBottom}>
          {children}
        </BasePageMobileLayout>
      )}
    </Box>
  );
}

export default BasePage;
