import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { figmaFonts } from "../../../theme";

const useStyles = makeStyles(theme => ({
  group: {
    padding: "7px 5px",
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.07)",
    borderRadius: 10,
    background: theme.palette.background.default
  },
  groupSmall: {
    padding: 4,
    borderRadius: 5
  },
  button: {
    height: 34,
    color: theme.palette.text.default,
    background: theme.palette.background.default,
    border: "none !important",
    borderBottomLeftRadius: "10px !important",
    borderBottomRightRadius: "10px !important",
    borderTopLeftRadius: "10px !important",
    borderTopRightRadius: "10px !important",
    textTransform: "initial",
    "&.Mui-selected": {
      background: theme.palette.primary.main,
      color: theme.palette.text.secondary,
      "&:hover": {
        background: theme.palette.buttons.purpleHover
      }
    },
    "&:not(:last-child)": {
      marginRight: 2.5
    }
  },
  buttonSmall: {
    height: 24,
    borderBottomLeftRadius: "5px !important",
    borderBottomRightRadius: "5px !important",
    borderTopLeftRadius: "5px !important",
    borderTopRightRadius: "5px !important",
    padding: theme.spacing(0.5, 1),
    ...figmaFonts.desktopSmallTextMedium
  }
}));

function ButtonToggle({ value, items, onChange, small }) {
  const classes = useStyles();
  const groupClassName = clsx(classes.group, { [classes.groupSmall]: small });
  const buttonClassName = clsx(classes.button, {
    [classes.buttonSmall]: small
  });
  return (
    <ToggleButtonGroup
      className={groupClassName}
      value={value}
      exclusive
      onChange={onChange}
    >
      {items.map(item => {
        return (
          <ToggleButton
            className={buttonClassName}
            key={item.value}
            value={item.value}
          >
            {item.label}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}

export default ButtonToggle;
