import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as SendMessageIcon } from "./icons/SendMessageIcon.svg";
import TextFieldWithFocusListeners from "../../../components/TextFieldWithFocusListeners";
import { Box, Button, SvgIcon, Typography } from "@material-ui/core";
import { appStore } from "../../../utils/stores/AppStore";
import { ReactComponent as Reply } from "./icons/reply.svg";
import { ReactComponent as ReplyMobile } from "./icons/replyMobile.svg";
import { ReactComponent as Clear } from "./icons/btnClear.svg";
import { ReactComponent as ClearMobile } from "./icons/btnClearMobile.svg";
import clsx from "clsx";
import useBreakpoints from "../../../utils/useBreakpoints";
import { figmaFonts } from "../../../theme";

const useStyles = makeStyles(theme => ({
  box: {
    padding: theme.spacing(1.875, 3.125),
    backgroundColor: theme.palette.background.input,
    [theme.breakpoints.up("lg")]: {
      backgroundColor: "transparent",
      padding: theme.spacing(2, 2)
    }
  },
  form: {
    position: "relative"
  },
  sendMessageButton: {
    position: "absolute",
    bottom: 0,
    right: 0,
    width: 38,
    height: 38,
    minWidth: 38,
    margin: 0,
    [theme.breakpoints.up("lg")]: {
      bottom: 4,
      right: 4,
      width: 48,
      height: 48
    }
  },
  sendMessageButtonIcon: {
    width: 18,
    height: 18,
    [theme.breakpoints.up("lg")]: {
      width: 22,
      height: 22
    },
    opacity: props => (props.disabled ? 0.5 : 1)
  },
  textField: {
    borderRadius: "10px",
    margin: 0,
    padding: 0,
    paddingRight: 54,
    backgroundColor: props =>
      props.disabled
        ? theme.palette.background.disabledInput
        : theme.palette.background.default,
    [theme.breakpoints.up("lg")]: {
      paddingRight: 64
    }
  },
  textFieldReply: {
    [theme.breakpoints.up("lg")]: {
      borderTopLeftRadius: 0,
      borderTopRightRadius: 0
    }
  },
  inputRoot: {
    minHeight: 38,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    [theme.breakpoints.up("lg")]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(2)
    },
    "&:hover:not(.Mui-error):not(.Mui-focused) $notchedOutline": {
      borderColor: "transparent"
    },
    "& .Mui-focused $notchedOutline": {
      borderWidth: "1px"
    },
    "&.Mui-focused:not(.Mui-error) $notchedOutline": {
      borderColor: "transparent"
    },
    "&>textarea::-webkit-scrollbar": {
      display: "none"
    }
  },
  notchedOutline: {
    borderColor: "transparent"
  },
  replyBox: {
    display: "flex",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    padding: theme.spacing(1.25),
    paddingTop: 0,
    marginTop: "-5px",
    [theme.breakpoints.up("lg")]: {
      marginTop: 0,
      paddingTop: theme.spacing(1.25),
      background: theme.palette.background.default,
      borderBottom: "1px solid #EFEFEF"
    }
  },
  replyName: {
    ...figmaFonts.textMedium14,
    [theme.breakpoints.up("lg")]: {
      ...figmaFonts.textMedium16
    }
  },
  replyText: {
    ...figmaFonts.mobileSmallTextRegular,
    [theme.breakpoints.up("lg")]: {
      ...figmaFonts.textRegular15
    },
    display: "-webkit-box",
    "-webkit-box-orient": "vertical",
    "-webkit-line-clamp": 2,
    overflow: "hidden"
  }
}));

function TextInput({
  sendMessage,
  message,
  onChange,
  disabled,
  onBlur,
  replyForMessage,
  chat,
  clearReply
}) {
  const classes = useStyles({ disabled });
  const { isDesktop } = useBreakpoints();

  const onSubmit = event => {
    event.stopPropagation();
    event.preventDefault();
    sendMessage();
  };

  function onKeyPress(e) {
    if (e.keyCode === 13 && (e.ctrlKey || e.shiftKey)) {
      e.preventDefault();
      onChange(message + "\n");
    } else if (e.keyCode === 13) {
      e.preventDefault();
      sendMessage();
    }
  }

  function _onChange(e) {
    onChange(e.target.value);
  }

  const isBSide = useMemo(() => {
    return (
      replyForMessage && appStore.currentUser?.id !== replyForMessage.senderId
    );
  }, [replyForMessage]);

  const messageText = useMemo(() => {
    if (!replyForMessage || !chat) return;

    if (isBSide) {
      return chat.translationEnabled
        ? replyForMessage.translatedText
        : replyForMessage.originalText;
    } else {
      return replyForMessage.originalText;
    }
  }, [chat, isBSide, replyForMessage]);

  return (
    <Box className={classes.box}>
      {replyForMessage && (
        <Box className={classes.replyBox}>
          <Box>{isDesktop ? <Reply /> : <ReplyMobile />}</Box>
          <Box
            px={1.25}
            width={"100%"}
            display={"flex"}
            flexDirection={"column"}
          >
            <Typography className={classes.replyName} color={"primary"}>
              {isBSide ? chat.title : appStore.currentUser.name}
            </Typography>

            <Typography className={classes.replyText}>{messageText}</Typography>
          </Box>
          <Box style={{ cursor: "pointer" }} onClick={clearReply}>
            {isDesktop ? <Clear /> : <ClearMobile />}
          </Box>
        </Box>
      )}
      <form className={classes.form}>
        <TextFieldWithFocusListeners
          onKeyDown={onKeyPress}
          className={clsx(classes.textField, {
            [classes.textFieldReply]: !!replyForMessage
          })}
          variant="outlined"
          margin="normal"
          value={message}
          fullWidth
          disabled={disabled}
          placeholder="Type a something..."
          onChange={_onChange}
          multiline
          minRows={1}
          onBlur={onBlur}
          maxRows={3}
          InputProps={{
            classes: {
              root: classes.inputRoot,
              notchedOutline: classes.notchedOutline,
              input: classes.input
            }
          }}
        />
        {/*<EmojiIcon className={classes.emojiIcon} />*/}
        <Button
          disabled={disabled}
          onClick={onSubmit}
          className={classes.sendMessageButton}
        >
          <SvgIcon className={classes.sendMessageButtonIcon}>
            <SendMessageIcon />
          </SvgIcon>
        </Button>
      </form>
    </Box>
  );
}
export default TextInput;
