import React, { Fragment, useContext, useEffect } from "react";
import BasePage from "./BasePage/BasePage";
import { makeStyles } from "@material-ui/core/styles";
import { BaseContext } from "../utils/baseProvider";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import StyledLink from "../components/StyledLink";
import SignInForm from "../components/SignInForm";
import { Box, Typography } from "@material-ui/core";
import useBreakpoints from "../utils/useBreakpoints";
import useAppBarHeight from "../utils/useAppBarHeight";
import { appStore } from "../utils/stores/AppStore";
import { observer } from "mobx-react";

const useStyles = makeStyles(theme => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginRight: "auto",
    marginLeft: "auto"
  },
  fromChangeTextBox: {
    marginTop: theme.spacing(1),
    [theme.breakpoints.up("lg")]: {
      marginTop: theme.spacing(3)
    }
  },
  formBox: {
    width: "100%",
    marginTop: theme.spacing(5),
    [theme.breakpoints.up("lg")]: {
      marginTop: theme.spacing(4)
    }
  },
  boldText: {
    fontWeight: 500
  }
}));

function SigninPage(props) {
  const classes = useStyles();
  let location = useLocation();
  const prevState = location.state || {};
  const baseContext = useContext(BaseContext);
  const history = useHistory();
  const { isMobile } = useBreakpoints();
  const appBarHeight = useAppBarHeight();

  useEffect(() => {
    baseContext.setSign("in");
    // eslint-disable-next-line
  }, []);

  function onSignInSuccess() {
    history.push({ pathname: "/login" });
  }

  if (appStore.authorized === true) {
    return <Redirect to="/" />;
  }

  return (
    <BasePage>
      <Fragment>
        <Box
          px={isMobile ? 3.125 : 0}
          my={isMobile ? appBarHeight / 8 + 5 : "auto"}
          className={classes.paper}
        >
          <Typography variant="h1">Log In</Typography>
          {prevState.fromChange !== undefined ? (
            <Box className={classes.fromChangeTextBox} align="center">
              <Typography
                className={classes.boldText}
                color="primary"
                variant="h2"
              >
                Congratulations!
              </Typography>
              <Typography variant="h2">
                Password changed successfully.
              </Typography>
            </Box>
          ) : null}

          <Box mb={3} className={classes.formBox}>
            <SignInForm onSignInSuccess={onSignInSuccess} />
          </Box>

          <StyledLink to="/passwordreset">Forgot password?</StyledLink>
        </Box>
      </Fragment>
    </BasePage>
  );
}

export default observer(SigninPage);
