import { withStyles } from "@material-ui/core/styles";
import { TableCell } from "@material-ui/core";
import { figmaFonts } from "../../theme";

const StyledTableCell = withStyles(theme => ({
  root: {
    padding: theme.spacing(2, 1, 2, 0),
    border: "none",
    wordBreak: "break-all",
    verticalAlign: "top",
    ...figmaFonts.textRegular14,
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(2, 3.75, 2, 0),
      ...figmaFonts.textRegular16
    }
  }
}))(TableCell);

export default StyledTableCell;
