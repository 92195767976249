import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import { BaseContext } from "../utils/baseProvider";
import { IconButton, Snackbar } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  close: {
    padding: theme.spacing(0.5)
  }
}));

export default function SnackbarComponent() {
  const baseContext = useContext(BaseContext);
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  function handleClose(event, reason) {
    if (reason === "clickaway") {
      return;
    }
    baseContext.setErrorTitle(null);
    setOpen(false);
  }

  useEffect(() => {
    if (baseContext.errorTitle !== null) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [baseContext.errorTitle]);

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left"
        }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        ContentProps={{
          "aria-describedby": "message-id"
        }}
        message={<span id="message-id">{baseContext.errorTitle}</span>}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    </div>
  );
}
