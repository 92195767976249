import React, { Fragment } from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// import { BaseContext } from "../../utils/baseProvider";

const useStyles = makeStyles(theme => {
  return {
    navbarContainer: {
      height: 75,
      zIndex: 4,
      width: "100%",
      marginBottom: "env(safe-area-inset-bottom)",
      backgroundColor: theme.palette.background.default,
      boxShadow: "0px -5px 40px rgba(0, 0, 0, 0.07)",
      alignSelf: "flex-end"
    },
    navbarContainerWithCreateCallButton: {
      height: 75,
      backgroundPosition: "bottom",
      backgroundColor: "transparent",
      boxShadow: "none",
      backgroundRepeat: "no-repeat"
    },
    navbar: {
      height: 75,
      "@supports (margin-bottom: env(safe-area-inset-bottom))": {
        height: "calc(env(safe-area-inset-bottom) + 75px)"
      },
      paddingBottom: "env(safe-area-inset-bottom)",
      background: "linear-gradient( transparent 50%, white 50%)"
    },
    navbarActionRoot: {
      maxWidth: "none",
      minWidth: 52,
      padding: 0,
      "&.Mui-selected": {
        paddingTop: 0
      },
      "& svg": {
        fill: theme.palette.text.grey,
        marginBottom: 7,
        color: theme.palette.text.grey
      },
      "&.Mui-selected svg": {
        fill: theme.palette.primary.main,
        color: theme.palette.primary.main
      }
    },
    navbarActionRootWithMargin: {
      marginRight: 86
    },
    iconCounter: {
      position: "absolute",
      background: theme.palette.background.red,
      top: 8,
      right: "calc(50% - 18px)",
      minWidth: 16,
      height: 16,
      color: theme.palette.text.secondary,
      borderRadius: 8,
      fontWeight: "bold",
      fontSize: 11,
      lineHeight: "12px",
      paddingTop: 2,
      paddingLeft: 2,
      paddingRight: 2,
      textAlign: "center",
      zIndex: 1
    }
  };
});

function MobileBottomTabBar({ value, navigationItems, navigationOnChange }) {
  const classes = useStyles();
  // const baseContext = useContext(BaseContext);

  return (
    <Fragment>
      <Box
        // display={baseContext.keyboardOpen ? "none" : "initial"}
        className={classes.navbarContainer}
      >
        <BottomNavigation
          value={value}
          className={classes.navbar}
          onChange={navigationOnChange}
          showLabels
        >
          {navigationItems.map((item, index) => {
            return (
              <BottomNavigationAction
                key={index}
                classes={{
                  root: classes.navbarActionRoot
                }}
                label={item.label}
                icon={
                  <Box>
                    {item.icon}
                    {item.counter && item.counter > 0 ? (
                      <Box className={classes.iconCounter}>{item.counter}</Box>
                    ) : null}
                  </Box>
                }
                value={item.path}
              />
            );
          })}
        </BottomNavigation>
      </Box>
    </Fragment>
  );
}

export default MobileBottomTabBar;
