import React, { memo, useCallback, useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as CopyButtonSuccess } from "../../assets/copySuccess.svg";
import { ReactComponent as CopyButtonIcon } from "../../assets/CopyButtonIcon.svg";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";

const useStyles = makeStyles(theme => ({
  icon: {
    cursor: "pointer",
    top: 16,
    right: 16,
    width: 32,
    height: 32
  }
}));
const CopyButton = ({ textToCopy }) => {
  const classes = useStyles();
  const [copied, setCopied] = useState(false);
  const timer = useRef();

  const onCopy = useCallback(() => {
    if (timer.current) {
      clearTimeout(timer.current);
    }
    setCopied(true);
    timer.current = setTimeout(() => setCopied(false), 2000);
  }, []);

  return (
    <CopyToClipboard onCopy={onCopy} text={textToCopy}>
      <SvgIcon onClick={onCopy} className={classes.icon} viewBox={"0 0 32 32"}>
        {copied ? <CopyButtonSuccess /> : <CopyButtonIcon />}
      </SvgIcon>
    </CopyToClipboard>
  );
};

export default memo(CopyButton);
