import React, { useContext, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { BaseContext } from "../../utils/baseProvider";
import { Box, Typography } from "@material-ui/core";
import useAppBarHeight from "../../utils/useAppBarHeight";
import { ReactComponent as BackIcon } from "./icons/backButton.svg";
import DesktopAppBarRight from "./DesktopAppBarRight";

const useStyles = makeStyles(theme => {
  return {
    container: {
      display: "flex",
      paddingRight: theme.spacing(3.75),
      borderBottom: `1px solid ${theme.palette.menuLine}`
    },
    toolbarLeftBox: {
      display: "flex",
      alignItems: "center"
    },
    appBarTitle: {
      fontWeight: 600,
      fontSize: "26px",
      lineHeight: "31px"
    },
    toolbarRightBox: {
      display: "flex",
      marginLeft: "auto",
      alignItems: "center",
      paddingTop: theme.spacing(2.75),
      paddingBottom: theme.spacing(2.75)
    }
  };
});

function DesktopAppBarContent() {
  const classes = useStyles();
  const history = useHistory();
  const baseContext = useContext(BaseContext);
  const appBarHeight = useAppBarHeight();

  const { showBackButton, title } = baseContext.appBarTopLeftItem || {};

  const titleComponent = useMemo(() => {
    if (!title) return null;

    if (typeof title === "string") {
      return <Typography className={classes.appBarTitle}>{title}</Typography>;
    }

    return title;
  }, [classes.appBarTitle, title]);

  return (
    <Box pl={8} className={classes.container} height={appBarHeight}>
      {baseContext.appBarTopLeftItem && (
        <Box className={classes.toolbarLeftBox}>
          {showBackButton && (
            <Box cursor={"pointer"} onClick={history.goBack} height={32} mr={3}>
              <BackIcon />
            </Box>
          )}

          {titleComponent}
        </Box>
      )}

      <Box className={classes.toolbarRightBox}>
        <DesktopAppBarRight />
      </Box>
    </Box>
  );
}

export default DesktopAppBarContent;
