import React from "react";
import {
  Box,
  IconButton,
  InputAdornment,
  InputLabel,
  TextField
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as PencilIcon } from "./icons/pencilGrey.svg";

const useStyles = makeStyles(theme => ({
  box: {
    cursor: "pointer"
  },
  labelBox: {
    display: "flex",
    justifyContent: "space-between"
  },
  inputLabel: {
    color: theme.palette.text.default,
    cursor: "pointer"
  },
  textField: {
    cursor: "pointer",
    background: theme.palette.background.input,
    borderRadius: "10px",
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(1),
    padding: 0,
    "& .Mui-disabled": {
      color: theme.palette.text.default,
      opacity: 1
    }
  },
  input: {
    cursor: "pointer"
  }
}));

function FakeInputGroup(props) {
  const classes = useStyles();
  return (
    <Box onClick={props.onClick} className={classes.box}>
      <Box className={classes.labelBox}>
        <InputLabel className={classes.inputLabel}>Password</InputLabel>
      </Box>

      <TextField
        className={classes.textField}
        value={"••••••••••"}
        variant="outlined"
        margin="normal"
        fullWidth
        disabled
        autoComplete="off"
        InputProps={{
          classes: { input: classes.input },
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <PencilIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    </Box>
  );
}

export default FakeInputGroup;
