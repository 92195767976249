import { Box, Button, Typography } from "@material-ui/core";
import IconBadge from "../../IconBadge";
import { ReactComponent as SaveIcon } from "../../../assets/icons/saveIcon.svg";
import React, { memo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { figmaFonts } from "../../../theme";

const useStyles = makeStyles(theme => ({
  balance: {
    ...figmaFonts.desktopSmallTextMedium
  },

  button: {
    ...figmaFonts.desktopCapsBtn14
  },

  text: {
    whiteSpace: "pre"
  }
}));

const NotEnoughMoney = ({ onTopUpClick }) => {
  const classes = useStyles();
  return (
    <>
      <Box width={"min-content"} mx={"auto"}>
        <IconBadge icon={<SaveIcon />}>
          <Typography className={classes.balance}>
            Balance:&nbsp;
            <Typography
              className={classes.balance}
              component={"span"}
              color={"primary"}
            >
              0$
            </Typography>
          </Typography>
        </IconBadge>
      </Box>
      <Box textAlign={"center"} mt={2} mb={1.25}>
        <Typography variant={"h2"} className={classes.text}>
          {"You don't have enough\nmoney to create a phone call"}
        </Typography>
      </Box>

      <Box mt={3} mx={"auto"} width={177}>
        <Button
          onClick={onTopUpClick}
          variant={"contained"}
          color={"primary"}
          fullWidth
          className={classes.button}
        >
          Top up balance
        </Button>
      </Box>
    </>
  );
};

export default memo(NotEnoughMoney);
