import React, { useCallback, useEffect, useState } from "react";
import withRequest from "../../utils/withRequest";

export const LocalCallContext = React.createContext();

function LocalCallProvider({ children, postDataWithCallback }) {
  const [messages, setMessages] = useState([]);
  const [showTranscription, setShowTranscription] = useState(false);
  const [currentUserIsCallee, setCurrentUserIsCallee] = useState(false);
  const [remoteName, setRemoteName] = useState("");
  const [remoteAvatar, setRemoteAvatar] = useState("");
  const [myVideoEnabled, setMyVideoEnabled] = useState(false);
  const [myAudioEnabled, setMyAudioEnabled] = useState(true);
  const [calleeVideoEnabled, setCalleeVideoEnabled] = useState(false);
  const [calleeAudioEnabled, setCalleeAudioEnabled] = useState(true);
  const [languageFrom, setLanguageFrom] = useState();
  const [languageTo, setLanguageTo] = useState();
  const [elapsed, setElapsed] = useState(0);
  const [remoteId, setRemoteId] = useState();
  const [translationEnabled, setTranslationEnabled] = useState(true);

  const loadAvatar = useCallback(() => {
    postDataWithCallback("/api/userinfo", { id: parseInt(remoteId) }, data => {
      setRemoteAvatar(data.avatar);
      setRemoteName(data.name);
    });
    // eslint-disable-next-line
  }, [remoteId]);

  useEffect(() => {
    if (remoteId && remoteId !== "0" && !remoteAvatar) {
      loadAvatar();
    }
    // eslint-disable-next-line
  }, [remoteId]);

  function toggleTranscription() {
    setShowTranscription(prevState => !prevState);
  }

  function resetToDefaultState() {
    setMessages([]);
    setShowTranscription(false);
    setCurrentUserIsCallee(false);
    setRemoteName("");
    setRemoteAvatar("");
    setMyVideoEnabled(false);
    setMyAudioEnabled(true);
    setCalleeVideoEnabled(false);
    setCalleeAudioEnabled(false);
    setLanguageFrom(undefined);
    setLanguageTo(undefined);
    setElapsed(0);
    setRemoteId(undefined);
    setTranslationEnabled(true);
  }

  return (
    <LocalCallContext.Provider
      value={{
        messages,
        setMessages,
        showTranscription,
        toggleTranscription,
        currentUserIsCallee,
        setCurrentUserIsCallee,
        remoteName,
        setRemoteName,
        setRemoteAvatar,
        remoteAvatar,
        calleeAudioEnabled,
        setCalleeAudioEnabled,
        myAudioEnabled,
        setMyAudioEnabled,
        calleeVideoEnabled,
        setCalleeVideoEnabled,
        myVideoEnabled,
        setMyVideoEnabled,
        setLanguageFrom,
        languageFrom,
        setLanguageTo,
        languageTo,
        elapsed,
        setElapsed,
        resetToDefaultState,
        setRemoteId,
        translationEnabled,
        setTranslationEnabled
      }}
    >
      {children}
    </LocalCallContext.Provider>
  );
}

export const LocalCallConsumer = LocalCallContext.Consumer;

export default withRequest(LocalCallProvider);
