import React, { memo, useCallback, useEffect, useState } from "react";
import InputGroup from "../../components/InputGroup/InputGroup";
import { Button } from "@material-ui/core";
import RadioSelectGroup from "../../components/RadioSelectGroup/RadioSelectGroup";
import LanguageSelect from "../CreatelocalcallPage/components/LanguageSelect";
import useLanguages from "../../utils/hooks/useLanguages";

function GuestNameForm({ onSubmit, onFieldChange, callSettings }) {
  const [disabled, setDisabled] = useState(true);
  const { languagesTo } = useLanguages();

  useEffect(() => {
    checkDisabled();
    // eslint-disable-next-line
  }, [callSettings]);

  const checkDisabled = useCallback(() => {
    if (
      !callSettings.name ||
      callSettings.name.length < 1 ||
      !callSettings.toGender
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [callSettings.name, callSettings.toGender]);

  const send = useCallback(
    event => {
      event.stopPropagation();
      event.preventDefault();

      onSubmit();
    },
    [onSubmit]
  );

  return (
    <form noValidate onSubmit={send}>
      <InputGroup
        label="Your name"
        required
        id="name"
        placeholder="Peter"
        name="name"
        autoComplete="name"
        onChange={event => onFieldChange("name", event.target.value)}
      />

      <LanguageSelect
        onChange={event => onFieldChange("to", event.target.value)}
        value={callSettings.to}
        languages={languagesTo}
        label="Your native language"
      />

      <RadioSelectGroup
        onChange={event => onFieldChange("toGender", event.target.value)}
        value={callSettings.toGender}
        label={"Your voice"}
        items={["Male", "Female"]}
      />

      <Button
        type="submit"
        fullWidth
        variant="contained"
        size="large"
        color="primary"
        disabled={disabled}
      >
        JOIN
      </Button>
    </form>
  );
}

export default memo(GuestNameForm);
