import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import { figmaFonts } from "../../../../theme";

const useStyles = makeStyles(theme => ({
  button: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    color: theme.palette.text.grey,
    marginBottom: theme.spacing(0.5),
    "&:hover": {
      color: theme.palette.primary.main
    },
    "&:last-child": {
      marginBottom: 0
    }
  },
  buttonIcon: {
    width: 32,
    height: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.input,
    borderRadius: 5,
    marginRight: theme.spacing(1),
    [theme.breakpoints.up("lg")]: {
      width: 40,
      height: 40
    },
    "&>svg": {
      width: 20,
      height: 20,
      [theme.breakpoints.up("lg")]: {
        width: 24,
        height: 24
      }
    }
  },
  text: {
    ...figmaFonts.textMedium14,
    [theme.breakpoints.up("lg")]: {
      ...figmaFonts.textMedium16
    }
  }
}));
const ChatMenuButton = ({ onClick, icon, text }) => {
  const classes = useStyles();
  return (
    <Box onClick={onClick} className={classes.button}>
      {icon && <Box className={classes.buttonIcon}>{icon}</Box>}
      <Typography className={classes.text}>{text}</Typography>
    </Box>
  );
};

export default ChatMenuButton;
