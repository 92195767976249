import React from "react";
import { Box, Chip, Typography } from "@material-ui/core";
import DialButton from "../../../components/PhoneCallDialer/components/DialButton";
import {
  Dialer,
  HangUp,
  MicOff,
  MicOn,
  TranscriptionOff,
  TranscriptionOn
} from "../../../../../assets/icons";
import { makeStyles } from "@material-ui/core/styles";
import useBreakpoints from "../../../../../utils/useBreakpoints";
import { ReactComponent as TranslationOn } from "../../../../LocalCallPage/components/CallControls/icons/TranslationOn.svg";
import { ReactComponent as TranslationOff } from "../../../../LocalCallPage/components/CallControls/icons/TranslationOff.svg";
import { figmaFonts } from "../../../../../theme";
import useAppBarHeight from "../../../../../utils/useAppBarHeight";

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  containerMobile: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    width: 248,
    height: props => `calc(100% - ${props.appBarHeight}px - 64px - 75px)`,
    justifyContent: "center",
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(3)
  },
  icon: {
    "& path": {
      fill: theme.palette.text.default
    }
  },
  chip: {
    borderRadius: 5,
    backgroundColor: theme.palette.background.darkGrey,
    height: 24
  },
  chipLabel: {
    padding: theme.spacing(0.5, 1),
    color: theme.palette.text.secondary
  },
  buttonText: {
    ...figmaFonts.mobileSmallTextRegular,
    marginTop: theme.spacing(2),
    width: 60,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  }
}));

const PhoneCallControls = ({
  onHangUp,
  audioEnabled,
  onMicToggle,
  onDialerOpen,
  transcriptionEnabled,
  toggleTranscription,
  dialerOpened = false,
  toggleTranslation,
  translationEnabled
}) => {
  const appBarHeight = useAppBarHeight();
  const classes = useStyles({ appBarHeight });
  const { isDesktop } = useBreakpoints();

  const buttons = [
    {
      content: <Dialer />,
      onClick: onDialerOpen,
      disabled: !onDialerOpen,
      order: 0,
      text: "Keypad"
    },
    {
      content: audioEnabled ? (
        <MicOn className={classes.icon} />
      ) : (
        <MicOff className={classes.icon} />
      ),
      onClick: onMicToggle,
      order: 1,
      text: "Mute"
    },
    {
      content: transcriptionEnabled ? (
        <TranscriptionOn className={classes.icon} />
      ) : (
        <TranscriptionOff className={classes.icon} />
      ),
      onClick: toggleTranscription,
      order: 3,
      text: "Transcription"
    },
    {
      content: translationEnabled ? (
        <TranslationOn className={classes.icon} />
      ) : (
        <TranslationOff className={classes.icon} />
      ),
      onClick: toggleTranslation,
      order: 4,
      text: "Translation"
    }
  ];
  const hangUpButton = {
    content: <HangUp />,
    color: "red",
    onClick: onHangUp,
    order: 2
  };

  if (onHangUp && !dialerOpened && isDesktop) {
    buttons.push(hangUpButton);
  }

  const arrayOfArrays = [];
  if (isDesktop) {
    for (let i = 0; i < buttons.length; i += 4) {
      arrayOfArrays.push(buttons.slice(i, i + 4));
    }
  } else {
    for (let i = 0; i < buttons.length; i += 3) {
      arrayOfArrays.push(buttons.slice(i, i + 3));
    }
  }

  if (isDesktop) {
    return (
      <Box className={classes.container}>
        {arrayOfArrays.map((arr, index) => {
          return (
            <div key={"b" + index}>
              {arr.map((button, index) => {
                return (
                  <Box
                    key={index}
                    px={1.5}
                    py={1}
                    display={"inline-block"}
                    order={button.order}
                  >
                    <DialButton
                      content={button.content}
                      disabled={button.disabled}
                      onClick={button.onClick}
                      color={button.color}
                    />
                  </Box>
                );
              })}
            </div>
          );
        })}

        <Box
          textAlign={"center"}
          mt={!audioEnabled || !translationEnabled ? 2 : 0}
        >
          {!audioEnabled && (
            <Box>
              <Chip
                classes={{ root: classes.chip, label: classes.chipLabel }}
                label={"Microphone is muted"}
              />
            </Box>
          )}

          {!translationEnabled && (
            <Box mt={audioEnabled ? 0 : 1}>
              <Chip
                classes={{ root: classes.chip, label: classes.chipLabel }}
                label={"Translation is disabled"}
              />
            </Box>
          )}
        </Box>
      </Box>
    );
  } else {
    return (
      <>
        {!dialerOpened && (
          <div className={classes.containerMobile}>
            {arrayOfArrays.map((arr, index) => {
              return (
                <div key={"b" + index}>
                  {arr.map((button, index) => {
                    return (
                      <Box
                        key={index}
                        px={1.25}
                        py={1}
                        display={"inline-block"}
                        order={button.order}
                      >
                        <DialButton
                          content={button.content}
                          disabled={button.disabled}
                          onClick={button.onClick}
                          color={button.color}
                        />
                        <Typography className={classes.buttonText}>
                          {button.text}
                        </Typography>
                      </Box>
                    );
                  })}
                </div>
              );
            })}
            <Box mt={4} mx={"auto"} display={"inline-block"}>
              <DialButton
                content={hangUpButton.content}
                disabled={hangUpButton.disabled}
                onClick={hangUpButton.onClick}
                color={hangUpButton.color}
              />
            </Box>
          </div>
        )}
        <Box textAlign={"center"} mt={"auto"} mb={3}>
          {!audioEnabled && (
            <Box>
              <Chip
                classes={{ root: classes.chip, label: classes.chipLabel }}
                label={"Microphone is muted"}
              />
            </Box>
          )}

          {!translationEnabled && (
            <Box mt={audioEnabled ? 0 : 1}>
              <Chip
                classes={{ root: classes.chip, label: classes.chipLabel }}
                label={"Translation is disabled"}
              />
            </Box>
          )}
        </Box>
      </>
    );
  }
};

export default PhoneCallControls;
