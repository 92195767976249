import React, { useContext, useEffect, useMemo, useState } from "react";
import BasePage from "../BasePage/BasePage";
import { BaseContext } from "../../utils/baseProvider";
import { Redirect } from "react-router-dom";
import withRequest from "../../utils/withRequest";
import withBottomBar from "../../components/BottomNavBar/withBottomBar";
import { Box } from "@material-ui/core";
import useBreakpoints from "../../utils/useBreakpoints";
import CircleAnimation from "./CircleAnimation";
import { ReactComponent as SavedIcon } from "./icons/savedIcon.svg";
import { getUsernameError } from "../../utils/validators/getUsernameError";
import ErrorWithIcon from "../../components/InputGroup/components/ErrorWithIcon";
import useUsernameAvailable from "../../utils/hooks/useUsernameAvailable";
import useAppBarLeftContent from "../../utils/hooks/useAppBarLeftContent";
import ProfileEditPageDesktop from "./ProfileEditPageDesktop";
import ProfileEditPageMobile from "./ProfileEditPageMobile";
import { appStore } from "../../utils/stores/AppStore";
import { observer } from "mobx-react";

function ProfileEditPage({ postDataWithCallback }) {
  const baseContext = useContext(BaseContext);
  useAppBarLeftContent({ title: "Settings" });
  const [formData, setFormData] = useState({
    name: "",
    avatar: "",
    gender: "",
    username: "",
    fromLang: ""
  });
  const [errors, setErrors] = useState({ username: "" });
  const [loaded, setLoaded] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [avatarEditModalOpen, setAvatarEditModalOpen] = useState(false);
  const [saveState, setSaveState] = useState("initial");
  const { isDesktop } = useBreakpoints();
  const { usernameAvailable } = useUsernameAvailable(
    formData.username,
    baseContext.currentUser && baseContext.currentUser.username
  );

  useEffect(() => {
    checkDisabled();
    // eslint-disable-next-line
  }, [formData]);

  useEffect(() => {
    if (baseContext.currentUser !== undefined) {
      setFormData({
        name: baseContext.currentUser.name,
        avatar: baseContext.currentUser.avatar,
        email: baseContext.currentUser.email,
        gender: baseContext.currentUser.gender,
        fromLang: baseContext.currentUser.fromLang,
        username: baseContext.currentUser.username
      });
      setLoaded(true);
    }
  }, [baseContext.currentUser]);

  function onChange(event) {
    let value = event.target.value;
    if (event.target.name === "name") {
      value = value.replace(/[`~!@#$%^&*()_|+\-=?;:'",.<>{}[\]\\/]/gi, "");
    }

    if (event.target.name === "username") {
      value = value.replace(/[^a-z0-9_]+/g, "");
      const usernameError = getUsernameError(value);
      setErrors({ ...errors, username: usernameError || "" });
    }

    setFormData({
      ...formData,
      [event.target.name]: value
    });
    checkDisabled();
  }

  function checkDisabled() {
    if (formData.name.length < 1) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }

  function send(event) {
    event.stopPropagation();
    event.preventDefault();

    const usernameError = getUsernameError(formData.username);
    if (usernameError) {
      setErrors({ username: usernameError });
      return;
    }

    if (usernameAvailable === false) {
      return;
    }

    setSaveState("saving");
    postDataWithCallback(
      "/api/useredit",
      formData,
      data => {
        if (data === "ok") {
          baseContext.setCurrentUser({
            ...baseContext.currentUser,
            ...formData
          });
          setTimeout(() => {
            setSaveState("saved");
            setTimeout(() => {
              setSaveState("initial");
            }, 2000);
          }, 500);
        }
      },
      error => {
        if (error === "err") {
          setErrors({
            username: getUsernameError(formData.username) || (
              <ErrorWithIcon text={"Not available"} />
            )
          });
        }
        setSaveState("initial");
      },
      false
    );
  }

  async function onAvatarSave(newAvatar) {
    setFormData({
      ...formData,
      avatar: newAvatar
    });
    setSaveState("saving");
    await postDataWithCallback(
      "/api/useredit",
      {
        ...formData,
        avatar: newAvatar
      },
      data => {
        if (data === "ok") {
          baseContext.setCurrentUser({
            ...baseContext.currentUser,
            ...formData,
            avatar: newAvatar
          });
          setTimeout(() => {
            setSaveState("saved");
            setTimeout(() => {
              setSaveState("initial");
              closeEdit();
            }, 1500);
          }, 500);
        }
      },
      () => {
        setSaveState("initial");
      },
      false
    );
  }

  function onOpenEdit() {
    setAvatarEditModalOpen(true);
  }

  function closeEdit() {
    setAvatarEditModalOpen(false);
  }

  function handleDeleteAvatar() {
    setFormData({
      ...formData,
      avatar: ""
    });
  }

  const saveButtonContent = useMemo(() => {
    if (saveState === "initial") {
      return "Save changes";
    } else if (saveState === "saving") {
      return (
        <>
          Saving
          <CircleAnimation />
        </>
      );
    } else if (saveState === "saved") {
      return (
        <>
          Saved
          <Box alignItems={"center"} height={22} display={"flex"} ml={1.875}>
            <SavedIcon />
          </Box>
        </>
      );
    }
  }, [saveState]);

  const usernameLink = useMemo(() => {
    if (!baseContext.currentUser || !baseContext.currentUser.username) {
      return null;
    }
    return `${window.location.origin}/u/${baseContext.currentUser.username}`;
  }, [baseContext.currentUser]);

  if (appStore.authorized === false) {
    return <Redirect to="/" />;
  }

  const props = {
    usernameLink,
    send,
    saveButtonContent,
    handleDeleteAvatar,
    closeEdit,
    onOpenEdit,
    onAvatarSave,
    onChange,
    errors,
    loaded,
    disabled,
    formData,
    avatarEditModalOpen,
    usernameAvailable
  };

  return (
    <BasePage>
      {isDesktop ? (
        <ProfileEditPageDesktop {...props} />
      ) : (
        <ProfileEditPageMobile {...props} />
      )}
    </BasePage>
  );
}

export default withBottomBar(withRequest(observer(ProfileEditPage)));
