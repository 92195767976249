import React from "react";
import BasePage from "../BasePage/BasePage";
import { Redirect } from "react-router-dom";
import { appStore } from "../../utils/stores/AppStore";
import { observer } from "mobx-react";
import useBreakpoints from "../../utils/useBreakpoints";
import MessagesPageMobile from "./MessagesPageMobile";
import withBottomBar from "../../components/BottomNavBar/withBottomBar";
import MessagesPageDesktop from "./MessagePageDesktop";

const MessagesPage = () => {
  const { isDesktop } = useBreakpoints();

  if (appStore.authorized === false) {
    return <Redirect to="/" />;
  }

  return (
    <BasePage>
      {isDesktop ? <MessagesPageDesktop /> : <MessagesPageMobile />}
    </BasePage>
  );
};

export default withBottomBar(observer(MessagesPage));
