import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";
import { BaseContext } from "../../../utils/baseProvider";
import { appStore } from "../../../utils/stores/AppStore";
import useAppBarHeight from "../../../utils/useAppBarHeight";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    height: "100%",
    overflowY: "auto",
    margin: "auto"
  }
}));

export const FullHeightContainerWithCenteredContent = ({
  children,
  disableCentering = false,
  pt = 0
}) => {
  const baseContext = useContext(BaseContext);
  const appBarHeight = useAppBarHeight();
  const classes = useStyles();
  const py =
    baseContext.showTopBar && appStore.authorized === false
      ? appBarHeight / 8
      : 2;

  return (
    <Box px={2} pt={py || pt} pb={py} className={classes.container}>
      <Box m={disableCentering ? "initial" : "auto"}>{children}</Box>
    </Box>
  );
};
