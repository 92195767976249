import React from "react";
import FlagIconFactory from "react-flag-icon-css";
import { makeStyles } from "@material-ui/core/styles";

const context = require.context("./flags", true, /\.(svg)$/);
const files = {};

context.keys().forEach(filename => {
  const name = filename.slice(2, 4); // only works for 2 letter names
  files[name] = context(filename);
});

let globalFlagsStyles = {};
Object.keys(files).forEach(flagKey => {
  globalFlagsStyles[`.flag-icon-${flagKey}.flag-icon-squared`] = {
    backgroundImage: `url(${files[flagKey]})`,
    borderRadius: "initial" // all custom flags are round
  };
});

const useStyles = makeStyles({
  "@global": globalFlagsStyles
});

const Flag = FlagIconFactory(React, { useCssModules: false });

function CustomFlag(props) {
  useStyles();
  return <Flag squared {...props} />;
}

export default CustomFlag;
