import React, { memo, useCallback } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import PlanChip from "../../chips/PlanChip";
import AnimatedArrows from "../../AnimatedArrows/AnimatedArrows";
import { makeStyles } from "@material-ui/core/styles";
import { figmaFonts } from "../../../theme";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles(theme => ({
  button: {
    ...figmaFonts.desktopCapsBtn14
  },
  text: {
    whiteSpace: "pre"
  }
}));

const SwitchToPlusModal = ({ text }) => {
  const classes = useStyles();
  const history = useHistory();

  const changeTariffOnClick = useCallback(() => {
    history.push("/subscription");
  }, [history]);

  return (
    <>
      <Box>
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <PlanChip plan={"basic"} marginRight={0} />
          <Box mx={1}>
            <AnimatedArrows />
          </Box>
          <PlanChip plan={"plus"} marginRight={0} />
        </Box>
      </Box>
      <Box textAlign={"center"} m={3}>
        <Typography variant={"h2"} className={classes.text}>
          {text}
        </Typography>
      </Box>
      <Box mx={"auto"} width={177}>
        <Button
          onClick={changeTariffOnClick}
          variant={"contained"}
          color={"primary"}
          fullWidth
          className={classes.button}
        >
          Change tariff
        </Button>
      </Box>
    </>
  );
};

export default memo(SwitchToPlusModal);
