import { Box, Typography } from "@material-ui/core";
import React from "react";

export function SubscriptionCost({
  currentPlanPeriod,
  desktopMode,
  planPrice
}) {
  return (
    <>
      <Box
        mb={desktopMode ? 1 : 0.5}
        color={"text.grey"}
        textAlign={desktopMode ? "left" : "center"}
      >
        <Typography variant={"subtitle1"}>Subscription cost:</Typography>
      </Box>

      <Box
        display={"flex"}
        alignItems={"flex-end"}
        justifyContent={desktopMode ? "flex-start" : "center"}
      >
        <Typography variant={"h1"}>${planPrice}</Typography>
        <Box ml={0.5} color={"secondary.text"}>
          <Typography variant={"subtitle1"}>/{currentPlanPeriod}</Typography>
        </Box>
      </Box>
    </>
  );
}
