import React from "react";
import { Box } from "@material-ui/core";
import ItemBottom from "../ItemBottom/ItemBottom";
import { makeStyles } from "@material-ui/core/styles";
import ItemMiddle from "../ItemMiddle/ItemMiddle";

const useStyles = makeStyles(theme => ({
  card: {
    width: "100%",
    backgroundColor: theme.palette.background.input,
    borderRadius: 5
  }
}));

function SmallItem({ component, title, price, isDesktop }) {
  const classes = useStyles();
  return (
    <Box className={classes.card}>
      <Box p={3}>{component}</Box>
      <ItemMiddle isDesktop={isDesktop} title={title} />
      <ItemBottom isDesktop={isDesktop} price={price} />
    </Box>
  );
}
export default SmallItem;
