import useBreakpoints from "./useBreakpoints";

const APP_BAR_HEIGHT = 60;
const APP_BAR_HEIGHT_MD = 92;
const APP_BAR_HEIGHT_UP_LG = 92;

// returns current top bar size
function useAppBarHeight() {
  const {
    isMobile,
    isSmallTablet,
    isTablet,
    isDesktop,
    isHd
  } = useBreakpoints();
  let height = APP_BAR_HEIGHT;

  if (isMobile || isSmallTablet) {
    return APP_BAR_HEIGHT;
  }

  if (isTablet || isDesktop) {
    return APP_BAR_HEIGHT_MD;
  }

  if (isHd) {
    return APP_BAR_HEIGHT_UP_LG;
  }

  return height;
}

export default useAppBarHeight;
