import React, { useContext, useEffect, useState } from "react";
import { BaseContext } from "../../utils/baseProvider";
import BasePage from "../BasePage/BasePage";
import { makeStyles } from "@material-ui/core/styles";
import withRequest from "../../utils/withRequest";
import { AppBar, Box, Button, Tab, Tabs, Typography } from "@material-ui/core";
import withBottomBar from "../../components/BottomNavBar/withBottomBar";
import TabPanel from "../../components/TabPanel";
import { useLocation } from "react-router-dom";
import EmptyPage from "../EmptyPage/EmptyPage";
import useBreakpoints from "../../utils/useBreakpoints";
import { appStore } from "../../utils/stores/AppStore";
import { observer } from "mobx-react";

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
    marginTop: theme.spacing(2)
  },
  tabsRoot: {
    minHeight: "auto"
  },
  indicator: {
    display: "none"
  },
  flexContainer: {
    justifyContent: "center"
  },
  tab: {
    minHeight: "auto",
    fontSize: theme.typography.pxToRem(10),
    lineHeight: theme.typography.pxToRem(12),
    fontWeight: 400,
    color: theme.palette.text.grey,
    padding: theme.spacing(1),
    opacity: 1,
    textTransform: "none",
    "&.Mui-selected": {
      color: theme.palette.text.default
    }
  }
}));

function BillingPage(props) {
  const baseContext = useContext(BaseContext);
  const classes = useStyles();
  const { isDesktop } = useBreakpoints();
  const location = useLocation();
  const prevState = location.state || { tab: 3 };
  const [tab, setTab] = useState(prevState.tab);
  const [url, setUrl] = useState();

  useEffect(() => {
    if (url) {
      window.open(url, "_self", "noopener noreferrer");
    }
  }, [url]);

  useEffect(() => {
    if (prevState.tab !== undefined) {
      setTab(prevState.tab);
    }
  }, [prevState.tab]);

  useEffect(() => {
    if (baseContext.currentUser && baseContext.currentUser.plan === "plus") {
      props.postDataWithCallback("/api/stripe/portal", {}, data => {
        setUrl(data.url);
      });
    }
    // eslint-disable-next-line
  }, [baseContext.currentUser]);

  function changeTab(event, tabIndex) {
    setTab(tabIndex);
  }

  function tabProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  }

  function renderPortalButton() {
    return url ? (
      <Button
        color={"primary"}
        variant={"contained"}
        onClick={() => window.open(url, "_blank", "noopener noreferrer")}
      >
        Portal
      </Button>
    ) : null;
  }

  // temp hide menu
  const showBillingNav = false;

  return (
    <BasePage centeredContent={isDesktop && tab === 3}>
      {showBillingNav && !isDesktop ? (
        <AppBar className={classes.appBar} position="static">
          <Tabs
            classes={{
              root: classes.tabsRoot,
              flexContainer: classes.flexContainer,
              indicator: classes.indicator
            }}
            value={tab}
            onChange={changeTab}
          >
            <Tab
              className={classes.tab}
              label="Payment Methods"
              {...tabProps(0)}
            />
            <Tab className={classes.tab} label="Invoices" {...tabProps(1)} />
            <Tab className={classes.tab} label="Settings" {...tabProps(2)} />
            <Tab style={{ display: "none" }} label="Billing" {...tabProps(3)} />
          </Tabs>
        </AppBar>
      ) : null}

      <TabPanel value={tab} index={0}>
        <Box mt={2} mb={2}>
          <Typography variant={"h1"}>Payment Methods</Typography>
        </Box>
        {renderPortalButton()}
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <Box mt={2} mb={2}>
          <Typography variant={"h1"}>Invoices</Typography>
        </Box>
        {renderPortalButton()}
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <Box mt={2} mb={2}>
          <Typography variant={"h1"}>Settings</Typography>
        </Box>
        {renderPortalButton()}
      </TabPanel>
      {tab === 3 && appStore.authorized === true && <EmptyPage />}
    </BasePage>
  );
}

export default withBottomBar(withRequest(observer(BillingPage)));
