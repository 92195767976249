import React, { useCallback, useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import { figmaFonts, palette } from "../../../theme";
import AvatarWithFallback from "../../../components/Avatar/AvatarWithFallback";
import { makeStyles } from "@material-ui/core/styles";
import { formatTimeForChatList } from "../../../utils/time";
import { observer } from "mobx-react";
import { ReactComponent as TranslationDisabledIcon } from "../icons/disabledTranslation.svg";
import useBreakpoints from "../../../utils/useBreakpoints";
import { appStore } from "../../../utils/stores/AppStore";
import {
  MessageDeliveredIcon,
  MessageReadIcon,
  MessageSentIcon
} from "../../../assets/icons";
import getCallMessageIcon from "../../../utils/calls/getCallMessageIcon";
import getCallMessageText from "../../../utils/calls/getCallMessageText";

const useStyles = makeStyles(theme => ({
  name: {
    ...figmaFonts.textMedium14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginRight: theme.spacing(0.5)
  },
  time: {
    fontSize: 11,
    lineHeight: "13px",
    color: theme.palette.text.grey,
    marginLeft: "auto",
    flex: 0,
    whiteSpace: "nowrap"
  },
  text: {
    ...figmaFonts.textRegular14,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: theme.palette.text.grey,
    display: "flex",
    alignItems: "center"
  },
  unreadCounter: {
    height: 16,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.primary.main,
    color: theme.palette.text.secondary,
    borderRadius: 8,
    fontSize: 11,
    lineHeight: "13px",
    paddingTop: "1px",
    paddingLeft: "4px",
    paddingRight: "4px",
    marginLeft: "auto",
    minWidth: 16
  },
  emptyChats: {
    color: theme.palette.text.grey,
    ...figmaFonts.textRegular14
  }
}));

const ChatsList = ({ chats = [], onChatSelect, selectedChat, loaded }) => {
  const classes = useStyles();
  // TODO: selected chat is defined by remoteId
  // when new chat is opened chat id is not defined yet, until 1st message is sent
  // add functionality on first message sent to load chat and replace selected chat with one that has chat id
  // in parent component
  const selectedChatUserId = useMemo(
    () => selectedChat && selectedChat.remoteId,
    [selectedChat]
  );
  const { isDesktop } = useBreakpoints();

  const renderListItem = useCallback(
    (chat, showBorder) => {
      const isLastMessageBSide =
        appStore.currentUser?.id !== chat.lastMessage?.senderId;
      const style = {
        verticalAlign: "text-bottom",
        marginRight: "4px"
      };
      let messageStateIcon = <MessageSentIcon style={style} />;
      if (chat.lastMessageId <= chat.lastRequestedRemote) {
        messageStateIcon = <MessageDeliveredIcon style={style} />;
      }
      if (chat.lastMessageId <= chat.lastReadRemote) {
        messageStateIcon = <MessageReadIcon style={style} />;
      }

      const messageText =
        chat.lastMessage?.type === "callMessage" ? (
          <>
            {getCallMessageIcon(
              chat.lastMessage.callStatus,
              isLastMessageBSide
            )}
            {getCallMessageText(
              chat.lastMessage.callStatus,
              isLastMessageBSide
            )}
          </>
        ) : (
          chat.text
        );

      return (
        <Box
          borderBottom={showBorder ? `1px solid ${palette.secondary.main}` : ""}
          display={"flex"}
          alignItems={"center"}
          pb={1}
        >
          <Box mr={1.25}>
            <AvatarWithFallback
              isOnline={chat.isOnline}
              src={chat.avatar}
              width={56}
              height={56}
            />
          </Box>
          <Box width={`calc(100% - 56px - 10px)`}>
            <Box
              width={"100%"}
              display={"flex"}
              alignItems={"center"}
              mb={0.25}
            >
              <Typography className={classes.name}>{chat.title}</Typography>
              {!chat.translationEnabled && (
                <Box style={{ flex: 0, width: 16, height: 16 }}>
                  <TranslationDisabledIcon />
                </Box>
              )}
              <Typography className={classes.time}>
                {!isLastMessageBSide ? messageStateIcon : null}
                {formatTimeForChatList(chat.lastMessageAt)}
              </Typography>
            </Box>

            <Box width={"100%"} display={"flex"} alignItems={"center"}>
              {chat.typing ? (
                <Typography color={"primary"} className={classes.text}>
                  typing...
                </Typography>
              ) : (
                <Typography className={classes.text}>{messageText}</Typography>
              )}

              {chat.unreadCounter > 0 && (
                <Box className={classes.unreadCounter}>
                  <span>
                    {chat.unreadCounter > 99 ? "99+" : chat.unreadCounter}
                  </span>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      );
    },
    [classes.name, classes.text, classes.time, classes.unreadCounter]
  );

  if (loaded && chats.length === 0) {
    return (
      <Box mt={2} px={isDesktop ? 3.75 : 0}>
        <Typography className={classes.emptyChats}>
          Oops, there are no chats here yet
        </Typography>
      </Box>
    );
  }

  const showBorder = chats.length > 1;

  return (
    <Box style={{ overflowY: "auto" }}>
      {chats.map((chat, index) => {
        const isSelected = selectedChatUserId === chat.remoteId;
        return (
          <Box
            key={index}
            px={isDesktop ? 3.75 : 0}
            pt={1}
            onClick={() => onChatSelect(chat)}
            style={
              isDesktop && isSelected
                ? {
                    background: palette.background.input,
                    boxShadow: `3px 0px 0 ${palette.primary.main} inset`
                  }
                : {}
            }
          >
            {renderListItem(chat, showBorder)}
          </Box>
        );
      })}
    </Box>
  );
};

export default observer(ChatsList);
