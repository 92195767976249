import { AppBar, Tab, Tabs } from "@material-ui/core";
import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: theme.palette.background.input,
    border: "none",
    boxShadow: "none",
    padding: "7px 5px",
    width: "fit-content",
    borderRadius: 5,
    marginRight: "auto",
    fontWeight: 500,
    fontSize: 15,
    lineHeight: "18px",
    marginLeft: 0
  },
  tabsRoot: {
    minHeight: "auto"
  },
  indicator: {
    display: "none"
  },
  flexContainer: {
    justifyContent: "center"
  },
  tab: {
    minWidth: 80,
    minHeight: "auto",
    fontSize: theme.typography.pxToRem(15),
    lineHeight: theme.typography.pxToRem(18),
    fontWeight: 500,
    color: theme.palette.text.grey,
    padding: theme.spacing(1, 2),
    opacity: 1,
    textTransform: "none",

    borderRadius: 5
  },
  tabActive: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main
  },
  tabPanel: {
    height: "100%",
    width: "100%",
    maxWidth: 898
  }
}));
const DesktopAppBar = ({ setTab, tab }) => {
  const classes = useStyles();
  function changeTab(event, tabIndex) {
    setTab(tabIndex);
  }

  function tabProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className: clsx(classes.tab, { [classes.tabActive]: tab === index })
    };
  }

  return (
    <AppBar className={classes.appBar} position="static">
      <Tabs
        classes={{
          root: classes.tabsRoot,
          flexContainer: classes.flexContainer,
          indicator: classes.indicator
        }}
        value={tab}
        onChange={changeTab}
      >
        <Tab label="Dashboard" {...tabProps(0)} />
        <Tab label="Detailed rules" {...tabProps(1)} />
        <Tab label="Store" {...tabProps(2)} />
        <Tab label="Get the link" {...tabProps(3)} />
        {/*hack*/}
        <Tab style={{ display: "none" }} {...tabProps(4)} />
      </Tabs>
    </AppBar>
  );
};

export default DesktopAppBar;
