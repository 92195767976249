import { useEffect, useRef, useState } from "react";

export default function useIsVisible(ref) {
  const observerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    observerRef.current = new IntersectionObserver(([entry]) =>
      setIsVisible(entry.isIntersecting)
    );
  }, [observerRef]);

  useEffect(() => {
    observerRef.current.observe(ref.current);

    return () => {
      observerRef.current.disconnect();
    };
  }, [observerRef, ref]);

  return isVisible;
}
