import { AppBar, Tab, Tabs } from "@material-ui/core";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
    marginTop: theme.spacing(2)
  },
  tabsRoot: {
    minHeight: "auto"
  },
  indicator: {
    display: "none"
  },
  flexContainer: {
    justifyContent: "center"
  },
  tab: {
    minHeight: "auto",
    fontSize: theme.typography.pxToRem(10),
    lineHeight: theme.typography.pxToRem(12),
    fontWeight: 400,
    color: theme.palette.text.grey,
    padding: theme.spacing(1),
    opacity: 1,
    textTransform: "none",
    "&.Mui-selected": {
      color: theme.palette.text.default
    }
  }
}));
const MobileAppBar = ({ setTab, tab }) => {
  const classes = useStyles();
  function changeTab(event, tabIndex) {
    setTab(tabIndex);
  }

  function tabProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`
    };
  }
  return (
    <AppBar className={classes.appBar} position="static">
      <Tabs
        classes={{
          root: classes.tabsRoot,
          flexContainer: classes.flexContainer,
          indicator: classes.indicator
        }}
        value={tab}
        onChange={changeTab}
      >
        <Tab className={classes.tab} label="Dashboard" {...tabProps(0)} />
        <Tab className={classes.tab} label="Detailed rules" {...tabProps(1)} />
        <Tab className={classes.tab} label="Store" {...tabProps(2)} />
        <Tab className={classes.tab} label="Get the link" {...tabProps(3)} />
        {/*hack*/}
        <Tab style={{ display: "none" }} {...tabProps(4)} />
      </Tabs>
    </AppBar>
  );
};

export default MobileAppBar;
