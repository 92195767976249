import React, { useContext, useEffect } from "react";
import { BaseContext } from "../../../utils/baseProvider";

const withTopNavigation = (WrappedComponent, topNavigationProps) => {
  return function HOC(props) {
    const baseContext = useContext(BaseContext);
    useEffect(() => {
      baseContext.setTopNavigationProps(topNavigationProps);

      return () => {
        baseContext.setTopNavigationProps(null);
      };
      // eslint-disable-next-line
    }, []);
    return <WrappedComponent {...props} />;
  };
};

export default withTopNavigation;
