import React, { useCallback, useContext, useEffect } from "react";
import { Box, Fade, Typography } from "@material-ui/core";
import BillingPlaceholderIcon from "../../../../assets/emptyPageIcon.png";
import { makeStyles } from "@material-ui/core/styles";
import { figmaFonts } from "../../../../theme";
import { appEmitter } from "../../../../utils/events/appEmitter";
import { chatsStore } from "../../../../utils/stores/ChatsStore";
import { BaseContext } from "../../../../utils/baseProvider";

const useStyles = makeStyles(theme => ({
  container: {
    textAlign: "center"
  },
  title: {
    ...figmaFonts.textMedium18,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(5),
    color: theme.palette.text.grey
  },
  subtitle: {
    ...figmaFonts.textRegular16,
    color: theme.palette.text.grey
  },
  messageBox: {
    marginTop: theme.spacing(3)
  },
  message1: {
    padding: theme.spacing(1, 2),
    background: theme.palette.primary.main,
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
    display: "inline-block",
    borderRadius: 10,
    borderBottomRightRadius: 0,
    cursor: "pointer"
  },
  message2: {
    padding: theme.spacing(1, 2),
    background: theme.palette.primary.main,
    color: theme.palette.text.secondary,
    display: "inline-block",
    borderRadius: 10,
    borderBottomLeftRadius: 0,
    cursor: "pointer"
  },
  message3: {
    padding: theme.spacing(1, 2),
    marginTop: theme.spacing(2),
    background: theme.palette.primary.main,
    color: theme.palette.text.secondary,
    borderRadius: 10,
    borderBottomLeftRadius: 0,
    display: "inline-block",
    cursor: "pointer"
  }
}));

const messages = {
  "01": ["👋", "مرحبًا ، [N]!", "مرحبا انا سعيد للقائك!"], //arabic,
  "03": ["👋", "你好，[N]!", "你好，很高興遇到你!"], //chinese,
  "05": ["👋", "Hallo, [N]!", "Hallo leuk je te ontmoeten!"], //dutch,
  "07": ["👋", "Hello, [N]!", "Hey, nice to meet you!"], //english,
  "09": ["👋", "Bonjour, [N]!", "Salut, enchanté!"], //french,
  "11": ["👋", "Hallo, [N]!", "Hey, freut mich dich kennen zu lernen!"], //German,
  "13": ["👋", "हैलो, [N]!", "अहो, तुमसे मिलकर बहुत अच्छा लगा!"], //Hindi,
  "15": ["👋", "Ciao, [N]!", "Ehi piacere di conoscerti!"], //Italian,
  "17": ["👋", "こんにちは、[N]!", "こんにちは、はじめまして"], //Japanese,
  "19": ["👋", "안녕하십니까, [N]!", "이봐 만나서 반가워!"], //Korean,
  "21": ["👋", "Hallo, [N]!", "Hei hyggelig å møte deg!"], //Norwegian,
  "23": ["👋", "Cześć, [N]!", "Cześć, miło Cię poznać!"], //Polish,
  "25": ["👋", "Olá, [N]!", "Ei prazer em conhecê-lo!"], //Portuguese,
  "27": ["👋", "Привет, [N]!", "Привет, приятно познакомиться!"], //Russian,
  "29": ["👋", "Merhaba, [N]!", "Hey tanıştığımıza memnun oldum!"], //Turkish,
  "31": ["👋", "Xin chào, [N]!", "Này, rất vui được làm quen với bạn!"] //Vietnamese,
};
const NewChat = ({ chat, store, onChatSelect }) => {
  const classes = useStyles();
  const baseContext = useContext(BaseContext);
  useEffect(() => {
    const cleaner = appEmitter.on("chatsLoaded", onChatsLoaded);
    return () => {
      cleaner();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  const onChatsLoaded = useCallback(
    ({ chats }) => {
      const newChat = chats.find(chat => chat.remoteId === store.chat.remoteId);

      if (newChat) {
        onChatSelect(newChat);
      }
    },
    [onChatSelect, store.chat.remoteId]
  );

  const sendMessage = async text => {
    store.setMessage(text);
    await store.sendMessage();
    await chatsStore.fetchChats();
  };

  const msgs = baseContext.currentUser.fromLang
    ? messages[baseContext.currentUser.fromLang]
    : messages["07"];

  return (
    <Fade in={true}>
      <Box m={"auto"} className={classes.container}>
        <img src={BillingPlaceholderIcon} alt={"empty"} />
        <Typography className={classes.title}>
          No messages here yet...
        </Typography>
        <Typography className={classes.subtitle}>
          Start a conversation with {chat.title}
        </Typography>
        <Box className={classes.messageBox}>
          <Box>
            <Box
              className={classes.message1}
              onClick={() => sendMessage(msgs[0])}
            >
              <span role={"img"} aria-label={"emoji"}>
                {msgs[0]}
              </span>
            </Box>

            <Box
              className={classes.message2}
              onClick={() => sendMessage(msgs[1].replace("[N]", chat.title))}
            >
              {msgs[1].replace("[N]", chat.title)}
            </Box>
          </Box>

          <Box
            className={classes.message3}
            onClick={() => sendMessage(msgs[2])}
          >
            {msgs[2]}
          </Box>
        </Box>
      </Box>
    </Fade>
  );
};

export default NewChat;
