import React from "react";
import BasePage from "../BasePage/BasePage";
import { Box, Button, Typography } from "@material-ui/core";
import icon from "./icons/payCancelledIcon.svg";
import useBreakpoints from "../../utils/useBreakpoints";
import withBottomBar from "../../components/BottomNavBar/withBottomBar";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import useAppBarHeight from "../../utils/useAppBarHeight";
import useAppBarLeftContent from "../../utils/hooks/useAppBarLeftContent";
import { FullHeightContainerWithCenteredContent } from "../../components/layouts";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      height: props => `calc(100% - ${2 * props.appBarHeight}px)`
    }
  },
  button: {
    paddingLeft: 39,
    paddingRight: 39
  }
}));

function PayCancelledPage() {
  const appBarHeight = useAppBarHeight();
  const classes = useStyles({ appBarHeight });
  const history = useHistory();
  const { isDesktop, isMobile } = useBreakpoints();
  const title = "Status Payment";
  useAppBarLeftContent({ title: isDesktop && title });

  function onTryAgainClick() {
    history.push("/subscription");
  }
  return (
    <BasePage>
      <FullHeightContainerWithCenteredContent
        disableCentering={isMobile}
        pt={5}
      >
        {!isDesktop && (
          <Box mt={5} mb={5}>
            <Typography align={"center"} variant={"h1"}>
              {title}
            </Typography>
          </Box>
        )}

        <Box mx={"auto"} maxWidth={isDesktop ? 280 : 200}>
          <img
            style={{ width: "100%" }}
            src={icon}
            alt={"The payment was successful"}
          />
        </Box>

        <Box textAlign={"center"} mt={5} mb={3}>
          <Typography noWrap={false} variant={"h2"}>
            Oops, but something went wrong,
            <br />
            the payment didn't go through
          </Typography>
        </Box>

        <Box display={"flex"} justifyContent={"center"}>
          <Button
            className={classes.button}
            onClick={onTryAgainClick}
            color={"primary"}
            variant={"contained"}
            size={"large"}
          >
            Try again
          </Button>
        </Box>
      </FullHeightContainerWithCenteredContent>
    </BasePage>
  );
}

export default withBottomBar(PayCancelledPage);
