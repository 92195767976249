import QueryString from "query-string";
import { getTokenValue } from "../localStorage";
import { appStore } from "../stores/AppStore";

class Api {
  requestDataWithCallback(
    url,
    params,
    onResponse,
    onError,
    showDefaultLoaderAndError,
    method
  ) {
    if (showDefaultLoaderAndError) {
      appStore.setErrorTitle(null);
      appStore.setLoading(true);
    }
    let requestParams = {
      credentials: "same-origin",
      method: method,
      headers: {}
    };

    if (method === "post") {
      requestParams.headers["Content-Type"] = "application/json";
      requestParams.body = JSON.stringify(params);
    }

    let response;
    let token = getTokenValue();
    let bearer = null;
    if (token) {
      bearer = `bearer ${token}`;
    }
    if (bearer) {
      requestParams.headers["Authorization"] = bearer;
    }

    return fetch(url, requestParams)
      .then(result => {
        response = result.clone();
        return result.text();
      })
      .then(data => {
        if (showDefaultLoaderAndError) {
          appStore.setLoading(false);
        }
        if (response.status === 401) {
          appStore.setAuthorized(false);
          return;
        }
        if (response.status !== 200) {
          try {
            data = JSON.parse(data);
          } catch (e) {}

          if (onError) {
            onError(data);
          } else {
            if (showDefaultLoaderAndError) {
              appStore.setErrorTitle("Error");
            }
          }
          return;
        }

        try {
          data = JSON.parse(data);
        } catch (e) {
          console.log(e);
          if (onError) {
            onError();
          } else {
            if (showDefaultLoaderAndError) {
              appStore.setErrorTitle("Error");
            }
          }
          return;
        }

        if (onResponse != null) {
          onResponse(data);
          return;
        }

        return data;
      })
      .catch(e => {
        if (showDefaultLoaderAndError) {
          appStore.setLoading(false);
        }
        console.log(e);
        if (response && response.status === 401) {
          appStore.setAuthorized(false);
          return;
        }
        if (onError) {
          onError();
        } else {
          if (showDefaultLoaderAndError) {
            appStore.setErrorTitle("Error");
          }
        }
      });
  }

  getDataWithCallback(
    url,
    params,
    onResponse = null,
    onError = null,
    showDefaultLoaderAndError = true
  ) {
    let query = QueryString.stringify(params);
    let queryUrl = url + (query ? "?" + query : "");
    return this.requestDataWithCallback(
      queryUrl,
      params,
      onResponse,
      onError,
      showDefaultLoaderAndError,
      "get"
    );
  }

  postDataWithCallback(
    url,
    params,
    onResponse = null,
    onError = null,
    showDefaultLoaderAndError = true
  ) {
    return this.requestDataWithCallback(
      url,
      params,
      onResponse,
      onError,
      showDefaultLoaderAndError,
      "post"
    );
  }
}

export const api = new Api();
