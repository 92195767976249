import { ReactComponent as SelectDropDownIcon } from "../../../assets/SelectDropDownIcon.svg";
import React, { useRef, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from "@material-ui/core";
import Flag from "../../../components/CustomFlag/Flag";
import useBreakpoints from "../../../utils/useBreakpoints";

const useStyles = makeStyles(theme => ({
  inputLabel: {
    color: theme.palette.text.default,
    marginBottom: theme.spacing(1)
  },
  text: {
    width: "100%",
    padding: theme.spacing(2, 3),
    cursor: "pointer"
  },
  selectBox: {
    display: "flex",
    marginBottom: theme.spacing(3),
    borderRadius: "10px",
    backgroundColor: theme.palette.background.input
  },
  select: {
    position: "relative",
    zIndex: 1,
    width: "68px",
    height: "56px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.07)",
    backgroundColor: theme.palette.background.default,
    borderRadius: "10px 0px 0px 10px",
    "& .MuiSelect-select": {
      width: "68px",
      paddingLeft: theme.spacing(1.5)
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "inherit"
    },
    "& .MuiTypography-root": {
      display: "none"
    },
    "& .MuiSelect-icon": {
      top: "calc( 50% - 8px )",
      marginRight: "10px"
    },
    "& span.flag-icon": {
      width: "24px !important",
      height: "24px"
    }
  },
  flag: {
    width: "20px !important",
    height: "20px",
    borderRadius: "50%"
  },
  menu: {
    maxHeight: 242,
    borderRadius: 5,
    "& .MuiPaper-root": {
      maxHeight: 242,
      height: "100%",
      width: "100%",
      overflow: "hidden",
      padding: theme.spacing(3, 2, 3, 0),
      boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.07)"
    },
    "& .MuiList-root": {
      maxHeight: 242 - theme.spacing(6),
      padding: 0,
      width: "100%",
      borderRadius: 5,
      overflowY: "scroll"
    },
    // Scrollbar start
    "& .MuiList-root::-webkit-scrollbar": {
      width: 4
    },
    "& .MuiList-root::-webkit-scrollbar-track": {
      background: theme.palette.background.grey,
      borderRadius: 10
    },
    "& .MuiList-root::-webkit-scrollbar-thumb": {
      background: theme.palette.background.violet,
      borderRadius: 10
    },
    // Scrollbar end
    "& .MuiListItem-root": {
      padding: theme.spacing(1, 3),
      color: theme.palette.text.grey
    },
    "& .MuiListItem-root.Mui-selected": {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.main
    }
  },
  menuItemText: {
    marginLeft: theme.spacing(2)
  }
}));

function LanguageSelect({
  displayVoice,
  languages,
  label,
  value,
  name,
  onChange
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const selectRef = useRef();
  const { isDesktop } = useBreakpoints();

  const getDisplayLanguageByKey = (key, languages) => {
    if (languages) {
      let language = languages.filter(lang => lang["Key"] === key)[0];

      if (language) {
        return `${language.DisplayLang}${
          displayVoice ? ` (${language.DisplayVoice})` : ""
        }`;
      }
    }
    return "";
  };

  function renderItems() {
    if (languages === undefined) {
      return null;
    }

    return languages.map(function(language) {
      return (
        <MenuItem key={language.Key} value={language.Key}>
          <Flag
            className={classes.flag}
            code={language.Country.toLowerCase()}
          />
          <Typography variant="subtitle1" className={classes.menuItemText}>
            {getDisplayLanguageByKey(language.Key, languages)}
          </Typography>
        </MenuItem>
      );
    });
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <Box ref={selectRef}>
      <InputLabel className={classes.inputLabel}>{label}</InputLabel>
      <Box className={classes.selectBox}>
        <Select
          MenuProps={{
            getContentAnchorEl: null,
            className: classes.menu,
            anchorOrigin: { vertical: "bottom", horizontal: "left" },
            transformOrigin: {
              vertical: isDesktop ? 8 : 16,
              horizontal: "left"
            },
            anchorEl: selectRef.current,
            style: {
              width: selectRef.current && selectRef.current.clientWidth + 32
            }
          }}
          IconComponent={SelectDropDownIcon}
          className={classes.select}
          value={value}
          name={name}
          onChange={onChange}
          disableUnderline
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
        >
          {renderItems()}
        </Select>
        <Typography
          onClick={handleOpen}
          className={classes.text}
          variant="subtitle1"
        >
          {value ? getDisplayLanguageByKey(value, languages) : ""}
        </Typography>
      </Box>
    </Box>
  );
}

export default LanguageSelect;
