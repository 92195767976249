import React from "react";
import BasePage from "../BasePage/BasePage";
import MainPageNonAuthorizedFragment from "./MainPageNonAuthorizedFragment";
import useBreakpoints from "../../utils/useBreakpoints";
import MainPageAuthorizedFragmentMobile from "./MainPageAuthorizedFragmentMobile";
import { appStore } from "../../utils/stores/AppStore";
import { observer } from "mobx-react";
import MessagesPage from "../MessagesPage/MessagesPage";

function MainPage() {
  const { isDesktop } = useBreakpoints();

  if (appStore.authorized === undefined) {
    return <BasePage />;
  }

  if (appStore.authorized === true) {
    if (isDesktop) {
      return <MessagesPage />;
    }

    return (
      <BasePage showTopBar={appStore.authorized}>
        <MainPageAuthorizedFragmentMobile />
      </BasePage>
    );
  }

  return (
    <BasePage showTopBar={appStore.authorized}>
      <MainPageNonAuthorizedFragment />
    </BasePage>
  );
}

export default observer(MainPage);
