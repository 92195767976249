import React from "react";
import { Box, Button, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    backgroundColor: theme.palette.background.default,
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.07)",
    display: "flex"
  },
  containerDesktop: {
    boxShadow: "0px -5px 40px rgba(0, 0, 0, 0.07)",
    borderRadius: 5
  },
  priceText: {
    fontSize: 30,
    lineHeight: "30px",
    color: theme.palette.primary.main
  },
  bonusesText: {}
}));

function ItemBottom({ price, isDesktop }) {
  const classes = useStyles();
  const containerClassName = clsx(classes.container, {
    [classes.containerDesktop]: isDesktop
  });

  return (
    <Box pb={2} pt={isDesktop ? 2 : 0} px={3} className={containerClassName}>
      <Box mr={2}>
        <Typography className={classes.priceText}> {price}</Typography>
        <Typography variant={"subtitle2"}>bonuses</Typography>
      </Box>

      <Button
        fullWidth
        size={"medium"}
        variant={"contained"}
        color={"primary"}
        disabled={true}
      >
        Get
      </Button>
    </Box>
  );
}

export default ItemBottom;
