import React, { useCallback, useRef, useState } from "react";
import { ReactComponent as BackIcon } from "../../icons/backArrow.svg";
import { Box, Popover, Typography } from "@material-ui/core";
import AvatarWithFallback from "../../../../components/Avatar/AvatarWithFallback";
import { makeStyles } from "@material-ui/core/styles";
import { figmaFonts } from "../../../../theme";
import { observer } from "mobx-react";
import ChatPageHeaderOnlineStatus from "../ChatPageHeaderOnlineStatus";
import ChatMenu from "../ChatMenu/ChatMenu";
import { DirectCallIcon } from "../../../../assets/icons";
import { useHistory } from "react-router-dom";
// import IOSSwitch from "../../../LocalCallPage/Transcription/IOsSwitch";
// import { ReactComponent as InfoIcon } from "../../../LocalCallPage/Transcription/icons/infoIcon.svg";
// import { chatsStore } from "../../../../utils/stores/ChatsStore";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    padding: theme.spacing(1.5, 3.125),
    margin: theme.spacing(0, -3.125),
    background: theme.palette.background.input
  },
  name: {
    ...figmaFonts.textMedium14
  },
  popover: {
    marginTop: theme.spacing(1)
  },
  popoverPaper: {
    boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.07)",
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("lg")]: {
      marginLeft: theme.spacing(5.75)
    }
  },
  typography: {
    fontWeight: "normal",
    color: theme.palette.text.grey,
    padding: theme.spacing(2),
    maxWidth: 279,
    [theme.breakpoints.up("lg")]: {
      maxWidth: 330,
      padding: theme.spacing(1.5, 2)
    }
  },
  directCallButton: {
    cursor: "pointer",
    marginLeft: "auto",
    marginRight: theme.spacing(1),
    width: "32px",
    height: "32px"
  }
}));
const ChatPageHeader = ({ chat, onClose }) => {
  const classes = useStyles();
  const infoIconRef = useRef();
  const [popOverOpen, setPopOverOpen] = useState(false);
  const { push } = useHistory();

  const togglePopOver = () => {
    setPopOverOpen(!popOverOpen);
  };

  const goToDirectCallPage = useCallback(() => {
    if (chat.remoteId) {
      push({
        pathname: `/directcall/${chat.remoteId}`
      });
    }
  }, [chat.remoteId, push]);

  return (
    <Box className={classes.container}>
      <Box
        width={20}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"center"}
        mr={2}
        onClick={onClose}
      >
        <BackIcon />
      </Box>
      <Box width={"100%"} display={"flex"} alignItems={"center"}>
        <AvatarWithFallback width={40} height={40} src={chat.avatar} />

        <Box ml={1.25} mr={"auto"}>
          <Typography className={classes.name}>{chat.title}</Typography>
          <ChatPageHeaderOnlineStatus
            typing={chat.typing}
            online={chat.isOnline}
            lastOnline={chat.lastOnline}
          />
        </Box>

        <DirectCallIcon
          className={classes.directCallButton}
          onClick={goToDirectCallPage}
        />
        {/*<Box mr={1} ml={"auto"} display={"flex"} alignItems={"center"}>*/}
        {/*  <InfoIcon onClick={togglePopOver} ref={infoIconRef} />*/}
        {/*  <IOSSwitch*/}
        {/*    onChange={() => chatsStore.toggleTranslation(chat.id)}*/}
        {/*    checked={chat.translationEnabled}*/}
        {/*  />*/}
        {/*</Box>*/}
        <ChatMenu chat={chat} />
        <Popover
          open={popOverOpen}
          onClick={togglePopOver}
          anchorEl={infoIconRef.current}
          className={classes.popover}
          PaperProps={{
            className: classes.popoverPaper
          }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <Typography
            onMouseLeave={() => setPopOverOpen(false)}
            variant="subtitle2"
            className={classes.typography}
          >
            {
              "When you turn off the switch, the interlocutor's\ntext will be shown without translation."
            }
          </Typography>
        </Popover>
      </Box>
    </Box>
  );
};

export default observer(ChatPageHeader);
