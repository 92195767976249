import { useContext, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { BaseContext } from "../utils/baseProvider";
import {
  removeInvitedBy,
  removeTokenValue,
  removeUtmSource
} from "../utils/localStorage";
import { appStore } from "../utils/stores/AppStore";

function LogoutPage() {
  const baseContext = useContext(BaseContext);
  let location = useLocation();
  const history = useHistory();
  const prevState = location.state || {};

  useEffect(() => {
    baseContext.setSign("in");
    removeTokenValue();
    removeInvitedBy();
    removeUtmSource();
    appStore.setAuthorized(false);
    baseContext.setCurrentUser(undefined);
    baseContext.setUserSecondsBalance(undefined);
    baseContext.setUserCentsBalance(undefined);
    if (prevState.fromChange !== undefined) {
      history.push({ pathname: "/signin", state: { fromChange: true } });
    } else {
      history.push({ pathname: "/" });
    }
    // eslint-disable-next-line
  }, []);

  return null;
}

export default LogoutPage;
