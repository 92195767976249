import { Box, Typography } from "@material-ui/core";
import IconBadge from "../../../../components/IconBadge";
import { ReactComponent as SaveIcon } from "../../../../assets/icons/saveIcon.svg";
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { figmaFonts } from "../../../../theme";

const useStyles = makeStyles(theme => ({
  saveBadgeBox: {
    backgroundColor: theme.palette.background.input,
    display: "flex",
    borderRadius: 4,
    width: "fit-content"
  }
}));

function SavingsBadge({ desktopMode, children = null, mt = null }) {
  const classes = useStyles({ desktopMode });

  const style = desktopMode
    ? {
        whiteSpace: "pre",
        ...figmaFonts.desktopSmallTextMedium
      }
    : {
        ...figmaFonts.mobileSmallTextMedium
      };

  const topMargin = mt != null ? mt : desktopMode ? 4 : 3;
  return (
    <Box className={classes.saveBadgeBox} mt={topMargin}>
      <IconBadge icon={<SaveIcon />}>
        <Box whiteSpace={"nowrap"} style={style}>
          {children ? (
            <>{children}</>
          ) : (
            <Typography variant={"inherit"}>
              <Typography
                component={"span"}
                variant={"inherit"}
                color={"primary"}
                display={"inline"}
              >
                Save around 34%
              </Typography>
              {desktopMode ? "\n" : " "}
              when you pay yearly
            </Typography>
          )}
        </Box>
      </IconBadge>
    </Box>
  );
}

export default SavingsBadge;
