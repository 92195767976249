import React, { Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Box, Toolbar } from "@material-ui/core";
import MobileAppBarContent from "./MobileAppBarContent/MobileAppBarContent";
import clsx from "clsx";
import useBreakpoints from "../../utils/useBreakpoints";
import useAppBarHeight from "../../utils/useAppBarHeight";
import DesktopAppBarRight from "./DesktopAppBarRight";
import { LogoSvg } from "../../assets";

const useStyles = makeStyles(theme => {
  const appBarBottomBorderHeight = 1;

  return {
    root: {
      backgroundColor: theme.palette.background.default,
      borderBottomWidth: appBarBottomBorderHeight,
      borderBottomStyle: "solid",
      borderColor: "transparent",
      zIndex: 1300, // to hide swipeable menu on mobile
      [theme.breakpoints.up("hd")]: {
        zIndex: 2
      }
    },
    rootBorder: {
      borderColor: theme.palette.background.input
    },
    toolbar: {
      padding: 0,
      minHeight: props => props.appBarHeight - appBarBottomBorderHeight
    },
    appBar: {
      padding: theme.spacing(0, 3.125),
      [theme.breakpoints.up("md")]: {
        padding: theme.spacing(0, 3.75, 0, 3.75)
      }
    }
  };
});

function AppBarTop() {
  const appBarHeight = useAppBarHeight();
  const classes = useStyles({ appBarHeight });
  const { isMobile, isSmallTablet } = useBreakpoints();

  return (
    <Fragment>
      <div
        className={clsx(classes.root, classes.stickyBox, classes.rootBorder)}
      >
        <AppBar
          className={classes.appBar}
          position="static"
          color="transparent"
          elevation={0}
        >
          <Toolbar className={classes.toolbar}>
            <Box display={"flex"} width={"100%"}>
              <Box mr={"auto"}>
                <LogoSvg width={isMobile ? "117px" : "136px"} />
              </Box>
              {isSmallTablet || isMobile ? (
                <MobileAppBarContent />
              ) : (
                <DesktopAppBarRight />
              )}
            </Box>
          </Toolbar>
        </AppBar>
      </div>
    </Fragment>
  );
}

export default AppBarTop;
