import React from "react";
import "./dotAnimation.css";

function DotAnimation({ size = 15 }) {
  return (
    <div style={{ "--size": size + "px" }}>
      <div className="loader-wrap">
        <div className="loader-dot loader-dot-one">
          <div className="loader-dot-inner loader-1" />
        </div>
        <div className="loader-dot loader-dot-two">
          <div className="loader-dot-inner loader-2" />
        </div>
        <div className="loader-dot loader-dot-three">
          <div className="loader-dot-inner loader-3" />
        </div>
        <div className="loader-dot loader-dot-four">
          <div className="loader-dot-inner loader-4" />
        </div>
      </div>
    </div>
  );
}

export default DotAnimation;
