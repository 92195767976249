import React, { Fragment, useContext } from "react";
import Flag from "../CustomFlag/Flag";
import { Box, SvgIcon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ReactComponent as Arrows } from "./arrows.svg";
import { ReactComponent as ArrowsSmall } from "./arrowsWhite.svg";
import { ReactComponent as ArrowsMid } from "./arrowsMid.svg";
import { ReactComponent as ArrowsBlack } from "./arrowsBlack.svg";
import { LocalCallContext } from "../../pages/LocalCallPage/localCallProvider";

const useStyles = makeStyles(() => ({
  flag: {
    width: props => `${props.size}px !important`,
    height: props => `${props.size}px`,
    borderRadius: "50%"
  },
  icon: {
    boxShadow: props =>
      props.theme === "dark" ? "none" : "0px 10px 20px rgba(0, 0, 0, 0.07)",
    display: "flex"
  },
  svgIcon: {
    width: "100%",
    height: "100%"
  }
}));

function LanguagesBadge({
  firstCountry,
  secondCountry,
  theme,
  iconSize,
  size = 24,
  iconMargin = 0.75,
  icon = LanguagesBadgeIcons.arrows
}) {
  if (!iconSize) {
    iconSize = size;
  }
  const classes = useStyles({ size, theme });
  const localCallContext = useContext(LocalCallContext);

  let fromCode = firstCountry;
  let toCode = secondCountry;
  if (!fromCode) {
    fromCode = localCallContext.languageFrom;
  }
  if (!toCode) {
    toCode = localCallContext.languageTo;
  }

  return (
    <Fragment>
      {fromCode && toCode ? (
        <Box display="flex">
          <Flag className={classes.flag} code={fromCode.toLowerCase()} />
          <Box
            className={classes.icon}
            width={iconSize}
            height={size}
            marginRight={iconMargin}
            marginLeft={iconMargin}
          >
            {icon(classes)}
          </Box>
          <Flag className={classes.flag} code={toCode.toLowerCase()} />
        </Box>
      ) : null}
    </Fragment>
  );
}

export const LanguagesBadgeIcons = {
  arrows: classes => (
    <SvgIcon className={classes.svgIcon} viewBox={"20 10 24 24"}>
      <Arrows />
    </SvgIcon>
  ),
  arrowsMid: classes => (
    <SvgIcon className={classes.svgIcon} viewBox={"0 0 14 14"}>
      <ArrowsMid />
    </SvgIcon>
  ),
  arrowsSmall: classes => (
    <SvgIcon className={classes.svgIcon} viewBox={"0 0 16 16"}>
      <ArrowsSmall />
    </SvgIcon>
  ),
  arrowsBlack: classes => (
    <SvgIcon className={classes.svgIcon} viewBox={"-4 -4 16 16"}>
      <ArrowsBlack />
    </SvgIcon>
  )
};

export default LanguagesBadge;
