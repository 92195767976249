// `listeners` are unbounded -- don't use this in practice!
function eventEmitter() {
  const listeners = {};

  return {
    on(key, fn) {
      listeners[key] = (listeners[key] || []).concat(fn);
      return () => this.off(key, fn);
    },
    off(key, fn) {
      listeners[key] = (listeners[key] || []).filter(f => f !== fn);
    },
    emit(key, data) {
      (listeners[key] || []).forEach(function(fn) {
        fn(data);
      });
    }
  };
}

export const appEmitter = eventEmitter();

// export const showLoading = () => {
//   console.log('[EventEmitter.showLoading]')
//   appEmitter.emit('loading', true)
// }
// export const hideLoading = (delayed: boolean = true) => {
//   console.log('[EventEmitter.hideLoading]')
//   if (!delayed) return appEmitter.emit('loading', false)
//   setTimeout(() => appEmitter.emit('loading', false), 500)
// }
