import { Box, Typography } from "@material-ui/core";
import { ReactComponent as BigPlusPlanIcon } from "../../icons/bigPlusPlanIcon.svg";
import React from "react";

export function CurrentPlan(props) {
  return (
    <>
      <Box
        mb={1}
        color={"text.grey"}
        textAlign={props.desktopMode ? "inherit" : "center"}
      >
        <Typography variant={"subtitle1"}>Your plan:</Typography>
      </Box>

      <Box
        display={"flex"}
        alignItems={"flex-end"}
        justifyContent={props.desktopMode ? "flex-start" : "center"}
      >
        <Box
          width={props.desktopMode ? 39 : 32}
          height={props.desktopMode ? 39 : 32}
        >
          <BigPlusPlanIcon />
        </Box>

        <Box ml={1}>
          <Typography variant={"h1"}>Plus</Typography>
        </Box>
        <Box ml={0.5} color={"secondary.text"}>
          <Typography variant={"subtitle1"}>
            ({props.planPeriodText})
          </Typography>
        </Box>
      </Box>
    </>
  );
}
