import React, { useContext, useEffect, useState } from "react";
import { Box, Button, SvgIcon, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SendLink from "./SendLink";
import withBottomBar from "../../components/BottomNavBar/withBottomBar";
import { ReactComponent as FacebookIcon } from "./icons/facebook.svg";
import { ReactComponent as TwitterIcon } from "./icons/twitter.svg";
import { ReactComponent as LinkedinIcon } from "./icons/linkedin.svg";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { BaseContext } from "../../utils/baseProvider";
import useBreakpoints from "../../utils/useBreakpoints";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    maxWidth: 325,
    [theme.breakpoints.up("lg")]: {
      maxWidth: 384
    }
  },
  text: {
    whiteSpace: "pre-line",
    wordBreak: "break-word",
    backgroundColor: theme.palette.background.input,
    borderRadius: 10,
    padding: theme.spacing(2.375, 3),
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(2.5, 3)
    }
  },
  shareText: {
    color: theme.palette.text.grey
  },
  socialIcon: {
    width: 32,
    height: 32,
    cursor: "pointer"
  }
}));

function InviteFriendsPage({ showDesktopLayout }) {
  const classes = useStyles();
  const baseContext = useContext(BaseContext);
  const { isDesktop } = useBreakpoints();
  const [id, setId] = useState();

  useEffect(() => {
    if (baseContext.currentUser && baseContext.currentUser.invite) {
      setId(baseContext.currentUser.invite);
    }
  }, [baseContext.currentUser]);

  const text =
    "YOUS is audio/video meetings, phone calls, and messages with AI-based translator. The app works on iOS, Android, Web. YOUS unites people. Try for Free!\n\nInvite to join:";

  const link = `${window.location.protocol}//${window.location.host}/invite/${id}`;

  function openLink(url) {
    const a = document.createElement("a");
    a.setAttribute("href", url);
    a.setAttribute("target", "_blank");
    a.setAttribute("rel", "noopener noreferrer");
    a.click();
  }

  return (
    <Box mx={showDesktopLayout ? 0 : "auto"} className={classes.container}>
      {!showDesktopLayout && (
        <Box my={5}>
          <Typography variant={"h1"}>Get the link</Typography>
        </Box>
      )}

      <SendLink link={link} />

      <Box width={"100%"}>
        <Box mb={isDesktop ? 1.5 : 1.25}>
          <Typography variant={"body2"}>Write post</Typography>
        </Box>
        <Box className={classes.text}>
          <Typography variant={"subtitle1"}>{text}</Typography>
          <Typography variant={"subtitle1"} color={"primary"}>
            {link}
          </Typography>
        </Box>
      </Box>
      <Box
        width={"100%"}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"space-between"}
        mt={3}
      >
        <Box>
          <Typography className={classes.shareText} variant={"subtitle1"}>
            Share on
          </Typography>
          <Box
            mt={isDesktop ? 0.5 : 1}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"space-between"}
            width={112}
          >
            <SvgIcon
              onClick={() => openLink("https://facebook.com/yousaiofficial/")}
              className={classes.socialIcon}
              viewBox={"0 0 32 32"}
            >
              <FacebookIcon />
            </SvgIcon>
            <SvgIcon
              onClick={() => openLink("https://twitter.com/yousaiofficial")}
              className={classes.socialIcon}
              viewBox={"0 0 32 32"}
            >
              <TwitterIcon />
            </SvgIcon>
            <SvgIcon
              onClick={() =>
                openLink("https://www.linkedin.com/company/yousai")
              }
              className={classes.socialIcon}
              viewBox={"0 0 32 32"}
            >
              <LinkedinIcon />
            </SvgIcon>
          </Box>
        </Box>
        <Box maxWidth={172} width={"100%"}>
          <CopyToClipboard text={text + "\n" + link}>
            <Button
              fullWidth
              color={"primary"}
              size={"large"}
              variant={"contained"}
            >
              COPY
            </Button>
          </CopyToClipboard>
        </Box>
      </Box>
    </Box>
  );
}

export default withBottomBar(InviteFriendsPage);
