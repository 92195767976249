import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/styles";
import App from "./App";
import theme from "./theme";
import { BrowserRouter } from "react-router-dom";
import { CssBaseline } from "@material-ui/core";
import { version } from "../package.json";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import BaseProvider from "./utils/baseProvider";

Sentry.init({
  dsn:
    "https://23c06be2e1f6423c8676711da692ea2f@o960446.ingest.sentry.io/5908724",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0
});

window.VERSION = version;

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <BrowserRouter>
      <BaseProvider>
        <App />
      </BaseProvider>
    </BrowserRouter>
  </ThemeProvider>,
  document.querySelector("#root")
);
