import { useContext, useEffect } from "react";
import { BaseContext } from "../baseProvider";
import { appStore } from "../stores/AppStore";

const useAppBarLeftContent = ({
  showBackButton = false,
  showWaitingForConnection = false,
  title = null,
  memoizedComponent = null
}) => {
  const baseContext = useContext(BaseContext);

  useEffect(() => {
    return () => {
      baseContext.setAppBarTopLeftItem({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const _title = appStore.authorized
      ? title || memoizedComponent
      : memoizedComponent;
    baseContext.setAppBarTopLeftItem({
      showBackButton,
      showWaitingForConnection,
      title: _title
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title, memoizedComponent, appStore.authorized]);
};

export default useAppBarLeftContent;
