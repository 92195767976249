import { createContext } from "react";
import LanguagesStore from "./stores/LanguagesStore";
import LocalCallUIStore from "./stores/LocalCallUIStore";
import LocalCallStore from "./stores/LocalCallStore";

export function createStores() {
  return {
    languagesStore: new LanguagesStore(),
    localCallUIStore: new LocalCallUIStore(),
    localCallStore: new LocalCallStore()
  };
}

const stores = createStores();

export const AppContext = createContext(stores);
