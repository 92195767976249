import { Box, Button, InputAdornment, Typography } from "@material-ui/core";
import InputGroup from "../../InputGroup/InputGroup";
import React, { memo, useState } from "react";
import withRequest from "../../../utils/withRequest";
import { makeStyles } from "@material-ui/core/styles";
import { figmaFonts } from "../../../theme";

const useStyles = makeStyles(theme => ({
  button: {
    ...figmaFonts.desktopCapsBtn14
  },
  input: {
    textAlign: "center",
    paddingLeft: 0,
    height: 48
  },
  startAdornment: {
    color: theme.palette.text.grey
  },
  centerAdornment: { marginLeft: "calc(50% - 19px)", marginRight: 0 },
  error: {
    color: theme.palette.error.main,
    ...figmaFonts.desktopSmallTextRegular,
    textAlign: "center"
  }
}));

const TopUpUsdBalance = ({ postDataWithCallback }) => {
  const classes = useStyles();
  const [value, setValue] = useState("15");
  const [error, setError] = useState(false);

  const onChange = event => {
    setValue(event.target.value.replace(/[^0-9]/g, ""));
    setError(false);
  };

  const onPayClick = () => {
    const val = parseInt(value);
    if (!val || val < 10) {
      setError(true);
    } else {
      setError(false);

      postDataWithCallback(
        "/api/stripe/checkout",
        {
          price: (val * 100).toString()
        },
        data => {
          if (data.url) {
            window.open(data.url, "_self", "noopener noreferrer");
          }
        }
      );
    }
  };
  return (
    <>
      <Box textAlign={"center"} my={1.5} mx={4}>
        <Typography variant={"h2"}>Top-up balance</Typography>
      </Box>
      <Box width={177} mx={"auto"}>
        <InputGroup
          value={value}
          inputClassName={classes.input}
          onChange={onChange}
          error={error && " "}
          type="tel"
          autoComplete={"off"}
          startAdornment={
            <InputAdornment
              classes={{ positionStart: classes.centerAdornment }}
              position="start"
            >
              <Typography className={classes.startAdornment}>
                $&nbsp;
              </Typography>
            </InputAdornment>
          }
        />
        {error && (
          <Box mt={-1} mb={2}>
            <Typography className={classes.error}>
              Minimum amount $10
            </Typography>
          </Box>
        )}
      </Box>

      <Box mx={"auto"} width={177}>
        <Button
          onClick={onPayClick}
          variant={"contained"}
          color={"primary"}
          fullWidth
          className={classes.button}
        >
          Pay
        </Button>
      </Box>
    </>
  );
};

export default withRequest(memo(TopUpUsdBalance));
