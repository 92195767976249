import React from "react";
import { ReactComponent as SuccessTick } from "./icons/successTick.svg";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.background.green
  }
}));
const SuccessWithIcon = ({ text }) => {
  const classes = useStyles();
  return (
    <Typography
      component="span"
      classes={classes}
      variant={"subtitle2"}
      style={{ display: "flex", alignItems: "center" }}
    >
      <SuccessTick />
      &nbsp;{text}
    </Typography>
  );
};

export default SuccessWithIcon;
