import React from "react";
import { Box, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { figmaFonts } from "../../../../../../theme";

const useStyles = makeStyles(theme => ({
  box: props => ({
    border: `1px solid ${theme.palette.background.input}`,
    borderRadius: 5,
    display: "flex",
    alignItems: "center",
    maxWidth: "100%",
    [props.desktopBreakpoint]: {
      flexDirection: "column"
    }
  }),
  text: props => ({
    [props.desktopBreakpoint]: {
      whiteSpace: "pre",
      textAlign: "center"
    }
  }),
  rewardText: {
    color: theme.palette.text.grey
  },
  bonusesText: props => ({
    ...figmaFonts.textMedium16,
    [props.desktopBreakpoint]: {
      fontWeight: 500,
      fontSize: 28,
      lineHeight: "34px",
      textAlign: "center"
    }
  }),
  iconContainer: {
    "&>svg": {
      maxWidth: "100%"
    }
  }
}));

function RewardCard({ text, icon, bonus }) {
  const desktopBreakpoint = "@media (min-width:850px)";
  const classes = useStyles({ desktopBreakpoint });
  const isDesktop = useMediaQuery(desktopBreakpoint);
  return (
    <Box
      py={isDesktop ? 5 : 3.5}
      px={isDesktop ? 4 : 2}
      mb={2}
      className={classes.box}
    >
      <Box
        textAlign={"center"}
        width={isDesktop ? "initial" : 113}
        flexShrink={isDesktop ? "" : 0}
      >
        <Box className={classes.iconContainer} mb={isDesktop ? 2 : 1}>
          {icon}
        </Box>
        <Typography className={classes.rewardText} variant={"subtitle2"}>
          Reward
        </Typography>
        <Box mt={0.5}>
          <Typography color={"primary"} className={classes.bonusesText}>
            {bonus}
            {bonus === 1 ? " bonus" : " bonuses *"}
          </Typography>
        </Box>
      </Box>
      <Box mt={2} ml={isDesktop ? 0 : 2.5}>
        <Typography className={classes.text} variant={"subtitle1"}>
          {text}
        </Typography>
      </Box>
    </Box>
  );
}

export default RewardCard;
