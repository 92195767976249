import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Box, Grid, ListItem, ListItemText } from "@material-ui/core";
import { observer } from "mobx-react";
import { figmaFonts, palette } from "../../../../theme";
import useIsVisible from "../../../../utils/hooks/useIsVisible";
import { appStore } from "../../../../utils/stores/AppStore";
import { chatsStore } from "../../../../utils/stores/ChatsStore";
import useBreakpoints from "../../../../utils/useBreakpoints";
import AvatarWithFallback from "../../../../components/Avatar/AvatarWithFallback";
import { CallIconTransparent } from "../../../../assets/icons";
import { formatTimeForCallMessages } from "../../../../utils/time";
import { useHistory } from "react-router-dom";
import getCallMessageText from "../../../../utils/calls/getCallMessageText";
import getCallMessageIcon from "../../../../utils/calls/getCallMessageIcon";

const useStyles = makeStyles(theme => ({
  listItem: {
    textAlign: props => (props.showAvatars ? "left" : "right"),
    padding: 0
  },
  listItemB: {
    textAlign: "left !important"
  },
  message: {
    flexDirection: "column",
    whiteSpace: "break-spaces",
    wordBreak: "break-word",
    padding: theme.spacing(0.75, 1.5),
    borderRadius: "10px 10px 0px 10px",
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.text.secondary,
    display: "flex",
    ...figmaFonts.textRegular14,
    textAlign: "left",
    maxWidth: 600
  },
  messageB: {
    borderRadius: "10px 10px 10px 0px",
    background: props =>
      props.isDesktop
        ? theme.palette.background.default
        : theme.palette.background.input,
    color: theme.palette.text.default
  },
  listItemText: {
    display: "inline-block",
    margin: 0,
    position: "relative"
  }
}));

function ChatCallMessage({
  message,
  chat,
  sameNext,
  showAvatar = false,
  showAvatars = false
}) {
  const { push } = useHistory();
  const { isDesktop } = useBreakpoints();

  const isBSide = useMemo(() => {
    return appStore.currentUser?.id !== message.senderId;
  }, [message.senderId]);
  const classes = useStyles({ isDesktop, showAvatars });

  const ref = useRef();
  const messageRef = useRef();
  const isVisible = useIsVisible(ref);

  useEffect(() => {
    if (isVisible) {
      chatsStore.setRead(message.chatId, message.id);
    }
  }, [isVisible, message, message.chatId, message.id]);

  const renderMessageTime = (timestamp, placeholder = false) => {
    const hours = new Date(timestamp).getHours();
    let minutes = new Date(timestamp).getMinutes();
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }

    return (
      <Box display={"flex"} alignItems={"center"} height={"14px"}>
        {getCallMessageIcon(message.callStatus, isBSide)}
        <span
          style={{
            unicodeBidi: "embed",
            fontWeight: 500,
            fontSize: "11px",
            lineHeight: "13px",
            color: isBSide ? "#9595B1" : "#B1BDFD"
          }}
        >
          {hours + ":" + minutes}
          {message.callDuration
            ? `, ${formatTimeForCallMessages(parseInt(message.callDuration))}`
            : ""}
        </span>
      </Box>
    );
  };

  const onRecallClick = useCallback(() => {
    push(`/directcall/${isBSide ? message.senderId : message.remoteId}`);
  }, [isBSide, message.remoteId, message.senderId, push]);

  return (
    <ListItem
      ref={ref}
      className={clsx(classes.listItem, isBSide ? classes.listItemB : "")}
      style={{
        marginBottom: sameNext ? "4px" : "8px"
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Box position={"relative"}>
            {showAvatars && (
              <Box
                width={40}
                display={"inline-block"}
                mr={1}
                style={{ verticalAlign: "bottom" }}
              >
                {showAvatar && (
                  <AvatarWithFallback
                    src={isBSide ? chat.avatar : appStore.currentUser.avatar}
                    width={40}
                    height={40}
                  />
                )}
              </Box>
            )}

            <ListItemText
              className={classes.listItemText}
              ref={messageRef}
              primary={
                <Box display={"flex"} alignItems={"center"}>
                  <Box mr={2}>
                    <span
                      style={{ unicodeBidi: "bidi-override", display: "block" }}
                    >
                      {getCallMessageText(message.callStatus, isBSide)}
                    </span>
                    {renderMessageTime(message.ts, false)}
                  </Box>
                  <CallIconTransparent
                    onClick={onRecallClick}
                    style={{
                      cursor: "pointer",
                      color: isBSide
                        ? palette.primary.main
                        : palette.background.default
                    }}
                  />
                </Box>
              }
              primaryTypographyProps={{
                className: clsx(
                  classes.message,
                  isBSide ? classes.messageB : ""
                )
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </ListItem>
  );
}

export default observer(ChatCallMessage);
