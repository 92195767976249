import { createTheme } from "@material-ui/core/styles";
import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

const defaultTheme = createTheme();
const { breakpoints, typography } = defaultTheme;

export const palette = {
  primary: {
    main: "#5656FC"
  },
  secondary: {
    main: "#F5F5FB",
    text: "#9595B1"
  },
  error: {
    main: "#E25039"
  },
  background: {
    default: "#fff",
    // Light Grey in figma
    input: "#F5F5FB",
    disabledInput: "#EAEAF3",
    callGrey: "#ECECF3",
    green: "#33CC99",
    violet: "#6161FC",
    grey: "#F6F6FE",
    darkGrey: "rgba(40, 40, 40, 0.3)",
    darkestGrey: "#E5E5FB",
    red: "#E80000",
    lightGreen: "#EBFAF5",
    lightRed: "#FCEEEB",
    lightYellow: "#FFF5E6",
    scrollBarGrey: "#D6D6EF",
    lighterGrey: "#EAEAF5",
    lightGrey: "#F9FAFF",
    yellow: "#F3CB45",
    lightMain: "#EEEEFF",
    lightestYellow: "#FEFAEC",
    lightBlue: "#EBF5FF"
  },
  text: {
    default: "#000",
    grey: "#9595B1",
    purple: "#5656FC",
    secondary: "#fff",
    yellow: "#FF9900",
    blue: "#56ACFC"
  },
  buttons: {
    purpleHover: "#7272FB",
    purpleActive: "#1313EB",
    greenHover: "#54D9AC",
    greenActive: "#26C08D"
  },
  menuLine: "#EFEFEF"
};

export const figmaFonts = {
  desktopH1: {
    fontWeight: 500,
    fontSize: typography.pxToRem(32),
    lineHeight: typography.pxToRem(39)
  },
  mobileH1: {
    fontWeight: 500,
    fontSize: typography.pxToRem(26),
    lineHeight: typography.pxToRem(31)
  },
  desktopH2: {
    fontWeight: 300,
    fontSize: typography.pxToRem(22),
    lineHeight: typography.pxToRem(30)
  },
  mobileH2: {
    fontWeight: 300,
    fontSize: typography.pxToRem(18),
    lineHeight: typography.pxToRem(24)
  },
  desktopLink: {
    fontWeight: 500,
    fontSize: typography.pxToRem(16),
    lineHeight: typography.pxToRem(19)
  },
  mobileLink: {
    fontWeight: 500,
    fontSize: typography.pxToRem(13),
    lineHeight: typography.pxToRem(16)
  },
  desktopMenu: {
    fontWeight: 500,
    fontSize: typography.pxToRem(15),
    lineHeight: typography.pxToRem(18)
  },
  desktopCapsBtn18: {
    fontWeight: "bold",
    fontSize: typography.pxToRem(18),
    lineHeight: typography.pxToRem(22),
    textTransform: "uppercase"
  },
  desktopCapsBtn14: {
    fontWeight: "bold",
    fontSize: typography.pxToRem(14),
    lineHeight: typography.pxToRem(17),
    textTransform: "uppercase"
  },
  textRegular15: {
    fontWeight: "normal",
    fontSize: typography.pxToRem(15),
    lineHeight: typography.pxToRem(24)
  },
  textRegular16: {
    fontWeight: "normal",
    fontSize: typography.pxToRem(16),
    lineHeight: typography.pxToRem(24)
  },
  textMedium16: {
    fontWeight: 500,
    fontSize: typography.pxToRem(16),
    lineHeight: typography.pxToRem(19)
  },
  textMedium18: {
    fontWeight: 500,
    fontSize: typography.pxToRem(18),
    lineHeight: typography.pxToRem(22)
  },
  desktopSmallTextMedium: {
    fontWeight: 500,
    fontSize: typography.pxToRem(13),
    lineHeight: typography.pxToRem(16)
  },
  mobileSmallTextMedium: {
    fontWeight: 500,
    fontSize: typography.pxToRem(12),
    lineHeight: typography.pxToRem(15)
  },
  desktopSmallTextRegular: {
    fontWeight: "normal",
    fontSize: typography.pxToRem(13),
    lineHeight: typography.pxToRem(19)
  },
  mobileSmallTextRegular: {
    fontWeight: "normal",
    fontSize: typography.pxToRem(12),
    lineHeight: typography.pxToRem(16)
  },
  textRegular14: {
    fontWeight: "normal",
    fontSize: typography.pxToRem(14),
    lineHeight: typography.pxToRem(20)
  },
  textMedium14: {
    fontWeight: 500,
    fontSize: typography.pxToRem(14),
    lineHeight: typography.pxToRem(17)
  },
  mobileTextSemibold: {
    fontWeight: 600,
    fontSize: typography.pxToRem(16),
    lineHeight: typography.pxToRem(22)
  }
};

const buttonActiveStyles = {
  "&:hover": {
    boxShadow: "none",
    backgroundColor: palette.buttons.purpleHover,
    color: palette.text.secondary,
    // material-ui touch devices hover override
    "@media (hover: none)": {
      boxShadow: "none",
      backgroundColor: palette.buttons.purpleHover,
      color: palette.text.secondary
    }
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: palette.buttons.purpleActive,
    color: palette.text.secondary
  },
  "&:disabled": {
    backgroundColor: palette.background.input,
    color: palette.text.grey
  }
};

const BREAKPOINTS = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1280,
  hd: 1366,
  xl: 1920
};

const breakpointsFull = createBreakpoints({
  values: { ...BREAKPOINTS },
  keys: Object.keys(BREAKPOINTS)
});

// A custom theme for this app
const theme = createTheme({
  ...defaultTheme,
  breakpoints: breakpointsFull,
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.07)"
      }
    },
    MuiContainer: {
      root: {
        [breakpoints.up("xs")]: {
          padding: 0
        }
      }
    },
    MuiButton: {
      root: {
        // due to bug in safari this is required
        transition: "color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
      },
      outlined: {
        borderRadius: 10,
        ...figmaFonts.desktopCapsBtn14,
        [breakpoints.up("lg")]: {
          ...figmaFonts.desktopCapsBtn18
        },
        padding: defaultTheme.spacing(2),
        ...buttonActiveStyles
      },
      outlinedPrimary: {
        ...buttonActiveStyles
      },
      contained: {
        ...figmaFonts.desktopCapsBtn14,
        [breakpoints.up("lg")]: {
          ...figmaFonts.desktopCapsBtn18
        },
        padding: defaultTheme.spacing(1.9375),
        borderRadius: 10,
        backgroundColor: palette.background.input,
        color: palette.primary.main,
        boxShadow: "none",
        ...buttonActiveStyles
      },
      containedPrimary: {
        color: palette.text.secondary,
        ...buttonActiveStyles
      },
      containedSecondary: {
        ...buttonActiveStyles
      },
      text: {
        textTransform: "initial"
      },
      sizeSmall: {
        textTransform: "none",
        ...figmaFonts.mobileLink,
        padding: "7px 16px",
        [breakpoints.up("lg")]: {
          ...figmaFonts.desktopLink,
          padding: "9px 24px"
        }
      },
      sizeLarge: {
        fontWeight: "bold",
        padding: 18,
        ...figmaFonts.desktopCapsBtn18,
        [breakpoints.up("lg")]: {
          padding: 21
        }
      },
      startIcon: {
        marginLeft: 0
      }
    },
    MuiFormHelperText: {
      root: {
        position: "absolute",
        right: 0,
        top: -31
      },
      contained: {
        marginLeft: 0,
        marginRight: 0,
        marginTop: 0
      }
    },
    MuiOutlinedInput: {
      root: {
        ...figmaFonts.textRegular14,
        [breakpoints.up("lg")]: {
          ...figmaFonts.textRegular16
        },
        borderRadius: 10,
        "&:hover:not(.Mui-error):not(.Mui-focused) $notchedOutline": {
          borderColor: palette.background.input
        },
        "& .Mui-focused $notchedOutline": {
          borderWidth: "1px"
        },
        "&.Mui-focused:not(.Mui-error) $notchedOutline, &.Mui-disabled $notchedOutline": {
          borderColor: palette.background.input
        }
      },
      notchedOutline: {
        borderColor: palette.background.input
      },
      input: {
        "&::placeholder": {
          color: palette.text.grey,
          opacity: 1
        },
        outline: "none !important",
        height: 20,
        padding: defaultTheme.spacing(2, 3),
        ...figmaFonts.textRegular14,
        [breakpoints.up("lg")]: {
          ...figmaFonts.textRegular16,
          height: 24,
          padding: defaultTheme.spacing(2, 3)
        }
      },
      multiline: {
        padding: defaultTheme.spacing(1.125, 2),
        ...figmaFonts.textRegular14,
        [breakpoints.up("lg")]: {
          ...figmaFonts.textRegular16,
          padding: defaultTheme.spacing(2.5, 3)
        }
      }
    },
    MuiInputLabel: {
      root: {
        ...figmaFonts.textMedium14,
        [breakpoints.up("lg")]: {
          ...figmaFonts.textMedium16
        }
      }
    },
    MuiFormLabel: {
      root: {
        ...figmaFonts.textMedium14,
        [breakpoints.up("lg")]: {
          ...figmaFonts.textMedium16
        }
      }
    },
    MuiBottomNavigationAction: {
      label: {
        fontSize: defaultTheme.typography.pxToRem(10),
        lineHeight: defaultTheme.typography.pxToRem(12),
        color: palette.text.grey,
        "&.Mui-selected": {
          fontSize: defaultTheme.typography.pxToRem(10),
          lineHeight: defaultTheme.typography.pxToRem(12),
          color: palette.primary.main
        }
      }
    },
    MuiChip: {
      label: {
        paddingLeft: 0,
        paddingRight: 0,
        ...figmaFonts.mobileSmallTextMedium,
        [breakpoints.up("lg")]: {
          ...figmaFonts.desktopSmallTextMedium
        }
      },
      deleteIcon: {
        marginLeft: "4px",
        marginRight: 0,
        width: 16,
        height: 16
      }
    }
  },
  typography: {
    fontFamily: ["Inter", "sans-serif"],
    h1: {
      ...figmaFonts.mobileH1,
      [breakpoints.up("lg")]: {
        ...figmaFonts.desktopH1
      }
    },
    h2: {
      ...figmaFonts.mobileH2,
      [breakpoints.up("lg")]: {
        ...figmaFonts.desktopH2
      }
    },
    body1: {
      ...figmaFonts.textMedium16,
      [breakpoints.up("lg")]: {
        ...figmaFonts.textMedium18
      }
    },
    body2: {
      color: palette.text.default,
      ...figmaFonts.textMedium14,
      [breakpoints.up("lg")]: {
        ...figmaFonts.textMedium16
      }
    },
    subtitle1: {
      ...figmaFonts.textRegular14,
      [breakpoints.up("lg")]: {
        ...figmaFonts.textRegular16
      }
    },
    subtitle2: {
      ...figmaFonts.mobileSmallTextRegular,
      [breakpoints.up("lg")]: {
        ...figmaFonts.desktopSmallTextRegular
      }
    },
    caption: {
      ...figmaFonts.mobileSmallTextRegular,
      [breakpoints.up("lg")]: {
        ...figmaFonts.textRegular16
      }
    }
  },
  palette
});

export default theme;
