import React, { useEffect, useState } from "react";
import BasePage from "../BasePage/BasePage";
import { makeStyles } from "@material-ui/core/styles";
import { AppBar, Box, Tab, Tabs } from "@material-ui/core";
import withBottomBar from "../../components/BottomNavBar/withBottomBar";
import TabPanel from "../../components/TabPanel";
import { useLocation } from "react-router-dom";
import withRequest from "../../utils/withRequest";
import useBreakpoints from "../../utils/useBreakpoints";
import LocalCallHistoryTab from "./Tabs/LocalCallHistoryTab";
import PhoneCallHistoryTab from "./Tabs/PhoneCallHistoryTab";
import useAppBarLeftContent from "../../utils/hooks/useAppBarLeftContent";
import clsx from "clsx";
import useAppBarHeight from "../../utils/useAppBarHeight";

const useStyles = makeStyles(theme => ({
  appBar: {
    backgroundColor: theme.palette.background.input,
    border: "none",
    boxShadow: "none",
    padding: "7px 5px",
    width: "fit-content",
    borderRadius: 5,
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.up("lg")]: {
      marginLeft: 0
    }
  },
  tabsRoot: {
    minHeight: "auto"
  },
  indicator: {
    display: "none"
  },
  flexContainer: {
    justifyContent: "center"
  },
  tab: {
    minWidth: 80,
    minHeight: "auto",
    fontSize: theme.typography.pxToRem(10),
    lineHeight: theme.typography.pxToRem(12),
    fontWeight: 400,
    color: theme.palette.text.grey,
    padding: theme.spacing(1, 2),
    opacity: 1,
    textTransform: "none",
    [theme.breakpoints.up("lg")]: {
      fontSize: theme.typography.pxToRem(15),
      lineHeight: theme.typography.pxToRem(18),
      fontWeight: 500
    },
    borderRadius: 5
  },
  tabActive: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.primary.main
  },
  tabPanel: {
    height: "100%",
    width: "100%",
    maxWidth: 898
  }
}));

function HistoryPage({ getDataWithCallback }) {
  const classes = useStyles();
  const { isDesktop, isTablet, isHd } = useBreakpoints();
  const location = useLocation();
  const prevState = location.state || { tab: 0 };
  const [tab, setTab] = useState(prevState.tab);
  const [data, setData] = useState();
  useAppBarLeftContent({ title: "History" });
  const appBarHeight = useAppBarHeight();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    setData([]);
    if (tab === 0) {
      loadData();
    } else if (tab === 1) {
      loadPhoneCallsData();
    }
    // eslint-disable-next-line
  }, [tab]);

  function loadData() {
    getDataWithCallback("/api/calls", {}, data => {
      setData(data);
      setLoading(false);
    });
  }

  function loadPhoneCallsData() {
    getDataWithCallback("/api/phonecalls", {}, data => {
      setData(data);
      setLoading(false);
    });
  }

  useEffect(() => {
    if (prevState.tab !== undefined) {
      setTab(prevState.tab);
    }
  }, [prevState.tab]);

  function changeTab(event, tabIndex) {
    setLoading(true);
    setTab(tabIndex);
  }

  function tabProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
      className: clsx(classes.tab, { [classes.tabActive]: tab === index })
    };
  }

  const showDesktopLayout = isDesktop || isHd || isTablet;

  if (data === undefined) {
    return null;
  }

  return (
    <BasePage>
      <Box
        pt={showDesktopLayout ? 5 : 2}
        ml={showDesktopLayout ? 8 : 0}
        px={showDesktopLayout ? 0 : 3.125}
        display={"flex"}
        flexDirection={"column"}
        maxWidth={"100%"}
        flex={1}
        height={`calc(100vh - ${appBarHeight}px)`}
        position={"relative"}
        style={{ overflowY: "auto" }}
      >
        <AppBar className={classes.appBar} position="static">
          <Tabs
            classes={{
              root: classes.tabsRoot,
              flexContainer: classes.flexContainer,
              indicator: classes.indicator
            }}
            value={tab}
            onChange={changeTab}
          >
            <Tab label="Online Meetings" {...tabProps(0)} />
            <Tab label="Phone Calls" {...tabProps(1)} />
          </Tabs>
        </AppBar>

        {!loading && (
          <Box mt={5}>
            <TabPanel className={classes.tabPanel} value={tab} index={0}>
              <LocalCallHistoryTab
                data={data}
                showDesktopLayout={showDesktopLayout}
              />
            </TabPanel>

            <TabPanel className={classes.tabPanel} value={tab} index={1}>
              <PhoneCallHistoryTab
                data={data}
                showDesktopLayout={showDesktopLayout}
              />
            </TabPanel>
          </Box>
        )}
      </Box>
    </BasePage>
  );
}

export default withBottomBar(withRequest(HistoryPage));
