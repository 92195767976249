export function setTokenValue(value) {
  localStorage.setItem("token", value);
}

export function getTokenValue() {
  return localStorage.getItem("token");
}

export function removeTokenValue() {
  return localStorage.removeItem("token");
}

export function setInvitedBy(value) {
  localStorage.setItem("invitedBy", value);
}

export function getInvitedBy() {
  return localStorage.getItem("invitedBy");
}

export function removeInvitedBy() {
  return localStorage.removeItem("invitedBy");
}

export function setUtmSource(value) {
  localStorage.setItem("utmSource", value);
}

export function getUtmSource() {
  return localStorage.getItem("utmSource");
}

export function removeUtmSource() {
  return localStorage.removeItem("utmSource");
}

export function setCookieState(cookieState) {
  localStorage.setItem("cookieState", cookieState);
}

export function getCookieState() {
  return localStorage.getItem("cookieState");
}

export function setMenuExpanded(expanded) {
  localStorage.setItem("menuExpanded", expanded);
}

export function getMenuExpanded() {
  return localStorage.getItem("menuExpanded");
}
