import React from "react";
import { Box, Typography } from "@material-ui/core";
import useBreakpoints from "../../../../utils/useBreakpoints";
import StoreBalance from "./components/StoreBalance/StoreBalance";
import StoreItems from "./components/StoreItems/StoreItems";
import useAppBarHeight from "../../../../utils/useAppBarHeight";
import withBottomBar from "../../../../components/BottomNavBar/withBottomBar";

function Store({ balance, showDesktopLayout }) {
  const { isDesktop } = useBreakpoints();
  const appBarHeight = useAppBarHeight();
  return (
    <Box pb={4}>
      {!showDesktopLayout && (
        <Box
          textAlign={showDesktopLayout ? "left" : "center"}
          mt={isDesktop ? 5 : 2}
          mb={2}
        >
          <Typography variant={"h1"}>Store</Typography>
        </Box>
      )}

      <Box
        mb={3}
        textAlign={showDesktopLayout ? "left" : "center"}
        whiteSpace={"pre"}
      >
        <Typography variant={"h2"}>
          Exchange your bonuses{isDesktop ? " " : "\n"}for useful items
        </Typography>
      </Box>
      <Box position={"sticky"} top={appBarHeight - 40} zIndex={5}>
        <StoreBalance showDesktopLayout={showDesktopLayout} balance={balance} />
      </Box>
      <Box mt={5.5}>
        <StoreItems showDesktopLayout={showDesktopLayout} />
      </Box>
    </Box>
  );
}

export default withBottomBar(Store);
