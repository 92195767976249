import React, { useContext, useRef, useState } from "react";
import { Box, Button, Typography } from "@material-ui/core";
import AvatarWithFallback from "../../components/Avatar/AvatarWithFallback";
import { makeStyles } from "@material-ui/core/styles";
import { figmaFonts, palette } from "../../theme";
import useBreakpoints from "../../utils/useBreakpoints";
import AvatarModified from "./AvatarModified";
import { BaseContext } from "../../utils/baseProvider";

const useStyles = makeStyles(theme => ({
  typography1: {
    color: theme.palette.text.grey,
    ...figmaFonts.textRegular14,
    [theme.breakpoints.up["lg"]]: {
      fontWeight: 300,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(25)
    }
  },
  typography2: {
    fontWeight: 300,
    fontSize: theme.typography.pxToRem(16),
    lineHeight: theme.typography.pxToRem(19),
    color: theme.palette.text.grey
  }
}));

function AvatarEdit({ onSave, onClose, saveButtonContent }) {
  const labelRef = useRef();
  const classes = useStyles();
  const [avatar, setAvatar] = useState();
  const { isDesktop } = useBreakpoints();
  const baseContext = useContext(BaseContext);

  function onCloseAvatar() {
    setAvatar("");
  }

  async function onSaveClick() {
    await onSave(avatar);
    baseContext.setCurrentUser({
      ...baseContext.currentUser,
      avatar: avatar
    });
  }

  function onCropAvatar(preview) {
    setAvatar(preview);
  }

  return (
    <Box
      textAlign={isDesktop ? "initial" : "center"}
      mx={isDesktop ? 9 : 3}
      mb={isDesktop ? 8 : 3}
    >
      <Typography variant={"h2"}>Upload profile photo</Typography>
      <Box
        mt={isDesktop ? 5 : 1}
        display={"flex"}
        flexDirection={isDesktop ? "row" : "column"}
      >
        <Box>
          <Typography className={classes.typography1}>
            Drag to reposition and crop{!isDesktop && <br />} to resize photo
          </Typography>
          <Box
            borderRadius={10}
            overflow={"hidden"}
            mt={2}
            width={isDesktop ? 440 : 257}
            height={isDesktop ? 280 : 164}
          >
            <AvatarModified
              width={isDesktop ? 440 : 257}
              height={isDesktop ? 280 : 164}
              onCrop={onCropAvatar}
              onClose={onCloseAvatar}
              cropRadius={isDesktop ? 100 : 48}
              shadingColor={palette.primary.main}
              shadingOpacity={0.2}
              backgroundColor={palette.background.input}
              cropColor={palette.primary.main}
              exportSize={400}
              exportQuality={1}
              borderStyle={{
                border: "1px dashed " + palette.primary.main,
                borderRadius: 10,
                backgroundColor: palette.secondary.main
              }}
              labelStyle={{
                width: "100%",
                textAlign: "center"
              }}
              label={
                <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  height={isDesktop ? 280 : 164}
                  ref={labelRef}
                >
                  <Button
                    style={
                      isDesktop
                        ? {
                            alignSelf: "center",
                            paddingLeft: 36,
                            paddingRight: 36
                          }
                        : {
                            alignSelf: "center",
                            minWidth: 145,
                            paddingTop: 12,
                            paddingBottom: 12
                          }
                    }
                    color={"primary"}
                    variant={"contained"}
                    onClick={() => labelRef.current.click()}
                  >
                    Choose a file
                  </Button>
                  <Box color={palette.text.grey} mt={2}>
                    <Typography variant={"subtitle2"}>
                      File types: jpg, jpeg, png, up to 4Mb
                    </Typography>
                  </Box>
                </Box>
              }
            />
          </Box>
        </Box>
        {isDesktop ? (
          <Box ml={8}>
            <Typography className={classes.typography2}>Preview</Typography>
            <Box mt={2} mb={3.5}>
              <AvatarWithFallback
                height={200}
                width={200}
                src={avatar}
                noIcon
              />
            </Box>
            {avatar && (
              <Button
                onClick={onSaveClick}
                color={"primary"}
                variant={"contained"}
                fullWidth
              >
                {saveButtonContent}
              </Button>
            )}
          </Box>
        ) : (
          <>
            {avatar && (
              <Box mt={2} mb={1}>
                <Button
                  onClick={onSaveClick}
                  color={"primary"}
                  variant={"contained"}
                  style={{ minWidth: 145, paddingTop: 12, paddingBottom: 12 }}
                >
                  {saveButtonContent}
                </Button>
              </Box>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}
export default AvatarEdit;
