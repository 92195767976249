import React, { memo } from "react";
import { ReactComponent as ChevronLeft } from "./chevronLeft.svg";
import { Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  handleContainer: {
    width: 16,
    height: 40,
    borderRadius: "0 10px 10px 0",
    backgroundColor: theme.palette.background.input,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer"
  },
  iconContainer: {
    transform: props => (!props.expanded ? "rotate(180deg)" : "rotate(0)"),
    transition: "transform 100ms linear"
  }
}));

const BasePageDrawerHandle = ({ expanded, onClick }) => {
  const classes = useStyles({ expanded });

  return (
    <Box onClick={onClick} className={classes.handleContainer}>
      <Box className={classes.iconContainer}>
        <ChevronLeft />
      </Box>
    </Box>
  );
};

export default memo(BasePageDrawerHandle);
